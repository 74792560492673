import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTransition, animated } from 'react-spring';

import CloseButton from '../close-button/close-button';

import './modal.scss';

interface ModalProps {
  className?: string;
  children: any;
  closeModal(): any;
  requireCloseAction?: Boolean;
}

function Modal({
  className,
  children,
  closeModal,
  requireCloseAction,
}: ModalProps) {
  const modalRootId = 'modal-root';

  const modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', modalRootId);

  if (!document.querySelector(`#${modalRootId}`)) {
    document.body.appendChild(modalRoot);
  }

  // Required to trigger close transition before removing portal
  const [isOpen, setIsOpen] = useState(true);

  useEffect(
    () => () => {
      document.body.removeChild(modalRoot);
    },
    [modalRoot]
  );

  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  function onCloseModal() {
    setIsOpen(false);
    setTimeout(closeModal, 500);
  }

  return createPortal(
    <div
      className={`modal-overlay ${className}`}
      onClick={requireCloseAction ? () => {} : onCloseModal}
    >
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="modal"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal__header">
                {!requireCloseAction && <CloseButton onClick={onCloseModal} />}
              </div>
              <div className="content">{children}</div>
            </animated.div>
          )
      )}
    </div>,
    document.querySelector(`#${modalRootId}`)
  );
}

export default Modal;
