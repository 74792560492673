/// <reference types="../../../../../types" />

import ReactDomServer from 'react-dom/server';

import { type FlightRequestViewModel, shapeOptions } from 'fims-api-types';
import type { UserRole } from 'argus-common/enums';

import MarkerInfoWithActions from '../../../../shared/marker-info-with-actions';
import { geometryToGooglePaths } from '../../../../../lib/google-helpers';
import { closeActiveInfoWindows } from '../helpers';

import { getLeadPinOptions, getMarkerPosition } from './lib/pin-styling';
import {
  FlightRequestMarkerElement,
  getLabeledPin,
} from '../../../../../lib/styled-features';
import { ItemDataType, ListProps } from '../../../../shared/marker-info';
import { DateTime } from 'luxon';
import { Authorizer } from 'argus-data-model/db/schemas/authorizers';

export interface FlightRequestMarker {
  flight: FlightRequestViewModel;
  marker: FlightRequestMarkerElement;
  flightAreas: google.maps.Polygon[];
  appearanceTracker: string;
  lastZoom: number;
}

function getMarkerContent(
  flight: FlightRequestViewModel,
  zoom: number
): { markerContent: Node; appearanceTracker: string } {
  const { pinOptions, appearanceTracker } = getLeadPinOptions(flight);

  const customPin = new google.maps.marker.PinElement(pinOptions);

  const markerContent =
    zoom <= 12
      ? customPin.element
      : getLabeledPin(`#${flight.flightId}`, customPin.element);

  return { markerContent, appearanceTracker };
}

export function getFlightRequestMarker(
  flight: FlightRequestViewModel,
  zoom: number
): FlightRequestMarker {
  const position = getMarkerPosition(flight);
  const { markerContent, appearanceTracker } = getMarkerContent(flight, zoom);

  const marker: FlightRequestMarkerElement =
    new google.maps.marker.AdvancedMarkerElement({
      position,
      title: `flight:${flight.flightId}`,
      content: markerContent,
    });

  const flightAreas = flight.flightAreas
    .map((area) => {
      const { properties, geometry } = area;
      const { areaStyling } = properties;

      if (areaStyling.fillOpacity === 0) return;

      return new google.maps.Polygon({
        paths: geometryToGooglePaths(geometry),
        ...shapeOptions,
        ...areaStyling,
      });
    })
    .filter((x) => x);

  return {
    flight,
    marker,
    flightAreas,
    appearanceTracker,
    lastZoom: Math.round(zoom),
  };
}

export function addMarkerListeners(
  map: google.maps.Map,
  authorizer: Authorizer,
  marker: FlightRequestMarkerElement,
  flight: FlightRequestViewModel,
  navigateToFlight: (flightId: number) => void,
  userRoles: UserRole[],
  additionalInfo: ListProps[] = [],
  requestToLand?: (flightId: number) => void
): () => void {
  marker.infowindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: true,
  });

  let timeElapsedUpdateTimer: ReturnType<typeof setInterval>;
  const listener = google.maps.event.addListener(
    marker.infowindow,
    'domready',
    () => {
      const markerInfoEl = document.getElementById(
        `flight-${flight.flightId}-marker-info`
      );
      if (markerInfoEl) {
        markerInfoEl.addEventListener(
          'click',
          () => {
            navigateToFlight(flight.flightId);
          },
          { passive: true }
        );
      }
      const elapsedTimeElements = markerInfoEl.querySelectorAll(
        `[data-type='${ItemDataType.ElapsedTime}']`
      );
      if (elapsedTimeElements.length > 0) {
        updateElapsedTimeElements(elapsedTimeElements);
        timeElapsedUpdateTimer = setInterval(() => {
          if (
            !elapsedTimeElements[0].checkVisibility() &&
            timeElapsedUpdateTimer
          ) {
            clearInterval(timeElapsedUpdateTimer);
          }
          updateElapsedTimeElements(elapsedTimeElements);
        }, 1000);
      }

      const requestToLandButton = document.getElementById(
        `flight-${flight.flightId}-button-request-to-land`
      );
      requestToLandButton?.addEventListener('click', () => {
        marker.infowindow.close();
        requestToLand(flight.flightId);
      });
    }
  );

  const clickListener = marker.addListener('click', () => {
    closeActiveInfoWindows();

    const content = ReactDomServer.renderToStaticMarkup(
      MarkerInfoWithActions(
        flight,
        authorizer,
        userRoles,
        {
          requestToLand: !!requestToLand,
        },
        additionalInfo
      )
    );

    marker.infowindow.setContent(content);
    marker.infowindow.open({ map, anchor: marker, shouldFocus: false });
    marker.infowindow.addListener('closeclick', () => {
      setLabelForMarker(marker, flight, marker.map?.getZoom());
    });
    window.activeMarker = marker;
  });

  return () => [listener, clickListener].map((l) => l.remove());

  function updateElapsedTimeElements(elapsedTimeElements: NodeListOf<Element>) {
    elapsedTimeElements.forEach((el) => {
      const value = el.getAttribute('data-value');
      if (value) {
        const elapsed = DateTime.now().diff(DateTime.fromISO(value));
        const days = Math.floor(elapsed.as('days'));
        const hours = Math.floor(elapsed.as('hours'));
        const minutes = Math.floor(elapsed.as('minutes'));
        const seconds = Math.floor(elapsed.as('seconds'));
        switch (true) {
          case days > 0:
            el.firstChild.textContent = `Sent ${days} day${days > 1 ? 's' : ''} ago`;
            break;
          case hours > 0:
            el.firstChild.textContent = `Sent ${hours} hour${hours > 1 ? 's' : ''} ${minutes % 60} minute${minutes > 1 ? 's' : ''} ago`;
            break;
          case minutes > 0:
            el.firstChild.textContent = `Sent ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            break;
          default:
            el.firstChild.textContent = `Sent ${seconds} seconds ago`;
            break;
        }
      }
    });
  }
}

export function setLabelForMarker(
  marker: FlightRequestMarkerElement,
  flight: FlightRequestViewModel,
  pZoom: number
): void {
  if (!marker) return;
  const { markerContent } = getMarkerContent(flight, pZoom);
  marker.content = markerContent;
}
