import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
import { TableHeading } from '~/lib/table';

export const TABLE_HEADINGS: TableHeading[] = [
  {
    label: 'Name',
    sortKey: 'name',
    align: 'left',
  },
  {
    label: 'Description',
    sortKey: 'description',
    align: 'left',
  },
  {
    label: 'Type',
    sortKey: 'type',
    align: 'left',
  },
  {
    label: 'Start Date/Time',
    sortKey: 'startDateTime',
    align: 'left',
  },
  {
    label: 'End Date/Time',
    sortKey: 'startDateTime',
    align: 'left',
  },
  {
    label: 'Min Altitude',
    sortKey: 'minAltitude',
    align: 'left',
  },
  {
    label: 'Max Altitude',
    sortKey: 'maxAltitude',
    align: 'left',
  },
  {
    label: '',
    sortKey: '',
    align: 'left',
  },
];

export const ManagedAreaCodeLabels: Record<ManagedAreaCode, string> = {
  [ManagedAreaCode.NFZ]: 'Prohibited',
  [ManagedAreaCode.Advisory]: 'Advisory',
  [ManagedAreaCode.FlightPath]: 'Flight Path',
  [ManagedAreaCode.ExclusiveZone]: 'Exclusive Zone',
  [ManagedAreaCode.Aerodrome]: 'Aerodrome',
  [ManagedAreaCode.EmergencyAirspaceClosure]: 'Emergency Airspace Closure',
};
