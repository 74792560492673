import { useEffect, useRef } from 'react';
import { LatLng } from 'fims-api-types';

export default function useShowCenterMarker(
  center: LatLng,
  showCenter: boolean,
  google: any,
  map: google.maps.Map | null
) {
  const centerMarker = useRef<google.maps.Marker | null>(null);

  return useEffect(() => {
    centerMarker.current?.setMap(null);
    if (map && showCenter) {
      const marker: google.maps.Marker = new google.maps.Marker({
        position: center,
        map,
        google,
        title: 'center',
      });
      centerMarker.current = marker;
    }
  }, [map, center, showCenter, google]);
}
