import { Schema, Document } from 'mongoose';
import uniqueValidatorPlugin from 'mongoose-unique-validator';
import { toJsonOptions } from '../../lib/shared-schema-options';

export interface RulesConfig {
  code: string;
  isActive: true;
  entry: Rule[];
  lib: {
    leadTimeLte48HoursExit: Rule[];
  };
}

export interface Rule {
  type: ruleTypes;
  func: string;
  params: any;
  isTrue?: Rule[];
  isFalse?: Rule[];
}

export enum ruleTypes {
  CONDITION = 'CONDITION',
  ACTION = 'ACTION',
  LIB = 'LIB',
  EXIT = 'EXIT',
}

export type RulesConfigSchema = RulesConfig & Document;

const entrySchema = new Schema<Rule>({
  type: {
    type: String,
    required: true,
  },
  func: {
    type: String,
    required: true,
  },
  params: {
    type: Schema.Types.Mixed,
    required: true,
  },
  isTrue: {
    type: Schema.Types.Mixed,
    required: false,
    default: [],
  },
  isFalse: {
    type: Schema.Types.Mixed,
    required: false,
    default: [],
  },
});

const rulesConfigSchema = new Schema<RulesConfig>({
  code: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  entry: {
    type: [entrySchema],
    required: true,
    default: [],
  },
  lib: {
    type: Schema.Types.Mixed,
    required: false,
  },
});

rulesConfigSchema.plugin(uniqueValidatorPlugin);
rulesConfigSchema.set('toJSON', toJsonOptions);

export default rulesConfigSchema;
