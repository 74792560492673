/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import envelope from '@turf/envelope';

export function Map(props: any) {
  const [gmap, setGmap] = useState<any>(null);
  const polygonRefObj = useRef(null);
  const subscriptionPolygonsRefObj = useRef([]);
  const uasZonesPolygonsRefObj = useRef([]);

  useEffect(() => {
    if (props.selectedCenter && gmap && props.selectedObject) {
      let horizontalProjection = props.selectedObject.airspaceVolume
        ? props.selectedObject.airspaceVolume.horizontalProjection
        : props.selectedObject?.geometry[0].horizontalProjection;
      if (horizontalProjection.type === 'Circle') {
        gmap.panTo(
          {
            lat: horizontalProjection.coordinates[0][1],
            lng: horizontalProjection.coordinates[0][0],
          },
          100
        );
      } else {
        let enveloped = envelope(horizontalProjection);
        gmap.panToBounds(
          {
            south: enveloped.geometry.coordinates[0][0][1],
            west: enveloped.geometry.coordinates[0][0][0],
            north: enveloped.geometry.coordinates[0][2][1],
            east: enveloped.geometry.coordinates[0][2][0],
          },
          100
        );
      }
    }
  }, [props.selectedCenter, props.selectedObject, gmap]);

  useEffect(() => {
    if (!gmap) {
      return;
    }
    if (!props.enableSubscriptionCreationEditor) {
      clearSubscriptionEditionState(polygonRefObj);
      return;
    }
    if (!polygonRefObj.current) {
      setInitialPolygon(gmap, polygonRefObj);
    }
    const newSubscriptionControls = document.getElementById(
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'map-new-subscriptions'
    );
    if (!newSubscriptionControls) {
      setSubscriptionControls(props, polygonRefObj, gmap);
    }
  }, [gmap, props]);

  useEffect(() => {
    function onScriptLoad() {
      const map = new window.google.maps.Map(
        document.getElementById(props.id),
        props.options
      );
      setGmap(map);
      props.onMapLoad(map);
    }

    if (!window.google) {
      loadGoogleMaps(onScriptLoad);
    } else {
      loadUasZonesPolygons(uasZonesPolygonsRefObj, props, gmap);
      loadSubscriptionsPolygons(subscriptionPolygonsRefObj, props, gmap);
    }
  });

  return <>{/* // <div style={{ width: 500, height: 500 }} id="mapX" /> */}</>;
}
function loadGoogleMaps(onScriptLoad: () => void) {
  var s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = `https://maps.google.com/maps/api/js?key=${window.env.GOOGLE_MAPS_API_KEY}&callback=console.log`;
  var x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);
  s.addEventListener('load', (_) => {
    onScriptLoad();
  });
}

function loadUasZonesPolygons(
  uasZonesPolygonsRefObj: React.MutableRefObject<any[]>,
  props: any,
  gmap: any
) {
  uasZonesPolygonsRefObj.current.map((p) => {
    p.setMap(null);
  });

  uasZonesPolygonsRefObj.current = props.uasZones.map((zone: any) => {
    const coords =
      zone.area.geometry[0].horizontalProjection.coordinates[0].map(
        (coord: any) => {
          if (coord.length >= 2) {
            return { lat: coord[1], lng: coord[0] };
          }
          return { lat: 0, lng: 0 };
        }
      );
    const mapPolygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: '#333333',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#555555',
      fillOpacity: 0.35,
    });

    mapPolygon.setMap(gmap);

    return mapPolygon;
  });
}

function loadSubscriptionsPolygons(
  subscriptionPolygonsRefObj: React.MutableRefObject<any[]>,
  props: any,
  gmap: any
) {
  subscriptionPolygonsRefObj.current.map((p) => {
    p.setMap(null);
  });
  subscriptionPolygonsRefObj.current = props.subscriptions.map(
    (subscription: any) => {
      const coords =
        subscription.airspaceVolume.horizontalProjection.coordinates[0].map(
          (coord: any) => {
            if (coord.length >= 2) {
              return { lat: coord[1], lng: coord[0] };
            }
            return { lat: 0, lng: 0 };
          }
        );
      const mapPolygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: '#3333FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5555FF',
        fillOpacity: 0.35,
      });

      mapPolygon.setMap(gmap);

      return mapPolygon;
    }
  );
}

function setSubscriptionControls(
  props: any,
  polygonRefObj: React.MutableRefObject<any>,
  gmap: any
) {
  const controlButtonCancel = document.createElement('button');
  const controlButtonOk = document.createElement('button');
  controlButtonCancel.innerHTML = 'Cancel';
  controlButtonOk.innerHTML = 'Create';
  controlButtonCancel.addEventListener('click', () => {
    props.subscriptionCreationEditionCancel(false);
  });
  controlButtonOk.addEventListener('click', () => {
    props.subscriptionCreationEditionContinue(
      polygonRefObj.current
        .getPath()
        .getArray()
        .map((row: any) => [row.lng(), row.lat()])
    );
  });
  const centerControlDiv = document.createElement('div');
  centerControlDiv.id = 'map-new-subscriptions';
  const h2 = document.createElement('h2');
  h2.innerHTML = 'Create a subscription area';
  centerControlDiv.appendChild(h2);
  centerControlDiv.appendChild(controlButtonCancel);
  centerControlDiv.appendChild(controlButtonOk);
  gmap.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
}

function clearSubscriptionEditionState(
  polygonRefObj: React.MutableRefObject<any>
) {
  const newSubscriptionControls = document.getElementById(
    'map-new-subscriptions'
  );
  if (newSubscriptionControls) {
    newSubscriptionControls.remove();
  }

  if (polygonRefObj?.current) {
    polygonRefObj.current.setMap(null);
    polygonRefObj.current = null;
  }
}

function setInitialPolygon(
  gmap: any,
  polygonRefObj: React.MutableRefObject<any>
) {
  const center = gmap.getCenter();
  polygonRefObj.current = new google.maps.Polygon({
    paths: [
      new google.maps.LatLng(center.lat() - 0.1, center.lng() - 0.1),
      new google.maps.LatLng(center.lat() - 0.1, center.lng() + 0.1),
      new google.maps.LatLng(center.lat() + 0.1, center.lng() + 0.1),
      new google.maps.LatLng(center.lat() + 0.1, center.lng() - 0.1),
      new google.maps.LatLng(center.lat() - 0.1, center.lng() - 0.1),
    ],
    map: gmap,
    editable: true,
  });
}
