import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import AuditLog from './audit-log/AuditLog';
import { listItemStyle } from './styles';

import './audit-logs.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  auditLogItemsChanged: boolean;
  setAuditLogItemsChanged: (value: boolean) => void;
}

const AuditLogs = ({
  focussedFlightRequest,
  auditLogItemsChanged,
  setAuditLogItemsChanged,
}: Props) => {
  const [auditLogsOpen, setAuditLogsOpen] = useState(false);
  const [auditLogOpenItems, setAuditLogOpenItems] = useState<boolean[]>([]);

  const toggleLogItem = (idx: number) => {
    if (!auditLogOpenItems[idx]) {
      auditLogOpenItems[idx] = true;
    } else {
      auditLogOpenItems[idx] = !auditLogOpenItems[idx];
    }

    setAuditLogOpenItems([...auditLogOpenItems]);
    setAuditLogItemsChanged(!auditLogItemsChanged);
  };

  return (
    <div data-testid="flight-info-audit-logs">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setAuditLogsOpen(!auditLogsOpen)}
        data-testid="flight-audit-log-heading"
      >
        <ListItemText primary="Audit Log" />
        {auditLogsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={auditLogsOpen} timeout="auto" unmountOnExit>
        {focussedFlightRequest?.auditLog &&
          focussedFlightRequest.auditLog
            .sort((a, b) => {
              if (
                new Date(b.createdAt).getTime() ===
                new Date(a.createdAt).getTime()
              ) {
                return b.action < a.action ? -1 : 1;
              }
              return new Date(b.createdAt).getTime() <
                new Date(a.createdAt).getTime()
                ? -1
                : 1;
            })
            .map((log, index) => (
              <AuditLog
                auditLogOpenItems={auditLogOpenItems}
                index={index}
                key={`auditLog-${index}`}
                log={log}
                toggleLogItem={toggleLogItem}
              />
            ))}
      </Collapse>
    </div>
  );
};

export default AuditLogs;
