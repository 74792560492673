import React from 'react';

import '../audit-logs.scss';

export const ATCNote = ({ atcNote }: { atcNote?: string }) => {
  return (
    <tr>
      <th
        className="note-header"
        data-testid="flight-audit-log-note-to-atc-title"
      >
        Note for ATC
      </th>
      <td className="note" data-testid="flight-audit-log-note-to-atc-value">
        {atcNote || 'N/A'}
      </td>
    </tr>
  );
};
