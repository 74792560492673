import { createSelector } from 'reselect';
import { AppState } from '../store';

const getLocalState = (state: AppState) => state.conflictedFlights;

export const getConflictedFlights = createSelector(
  [getLocalState],
  (state) => state.data
);

export const getConflictedFlightsFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);
