import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

interface Props {
  title: string;
  open: boolean;
  setOpen: Function;
  submitCallback: Function;
  warning: string;
}

const ConfirmationDialog: React.FC<Props> = ({
  title,
  open,
  setOpen,
  submitCallback,
  warning,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleClose = (reason?: string) => {
    setErrors(null);
    if (reason?.length) {
      return;
    }

    setLoading(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors(null);
    const submissionResult = await submitCallback();
    if (submissionResult?.errors) {
      setErrors(submissionResult.errors);
    } else {
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(_e, reason) => handleClose(reason)}
      data-testid="dialog"
    >
      <DialogTitle data-testid="dialog-title">{`${title}`}</DialogTitle>
      <DialogContent style={{ maxWidth: 400, paddingBottom: '16px' }}>
        {warning && (
          <Alert severity="warning" data-testid="dialog-warning">
            {warning}
          </Alert>
        )}
        {errors && (
          <Alert
            severity="error"
            data-testid="dialog-error"
            style={{ marginTop: '24px' }}
          >
            {errors}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={() => handleClose()}
          data-testid="dialog-cancel-button"
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            disabled
            variant="contained"
            style={{ width: 85 }}
            data-testid="dialog-submit-disabled-button"
          >
            <CircularProgress size={24} color="inherit" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            data-testid="dialog-submit-button"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
