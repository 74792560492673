import React, { useState } from 'react';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
  ManagedAreaAudit,
  ManagedAreaAuditAction,
} from 'argus-data-model/db/schemas/managed-areas/audit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    auditLogDiv: {
      marginTop: '16px',
    },
    auditLogPanel: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: '100%',
    },
    auditLogEntry: {
      width: '100%',
      marginTop: `calc(0px - ${theme.spacing(1)}px)`,
      marginBottom: `calc(0px - ${theme.spacing(1)}px)`,
    },
    auditLogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    auditLogEntryDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '90%',
      marginLeft: theme.spacing(1),
      marginTop: '-4px',
      marginBottom: `calc(0px - ${theme.spacing(1)}px)`,
    },
    auditLogChange: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      marginLeft: theme.spacing(1),
    },
    auditLogUser: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      marginTop: `calc(0px - ${theme.spacing(1)}px)`,
    },
    auditLogDropDown: {
      paddingLeft: theme.spacing(2),
    },
    auditLogPanelRow: {
      color: `var(--color-shade-primary-darker)`,
      fontSize: 12,
      textAlign: 'right',
      width: '100%',
      display: 'inline-block',
      verticalAlign: 'sub',
    },
    actionLabel: {
      fontWeight: 'bold',
      width: '15%',
      textAlign: 'left',
      color: 'var(--color-shade-primary-darkest)',
      display: 'inline-block',
    },
    actionData: {
      width: '81%',
      textAlign: 'right',
      color: 'var(--color-shade-primary-darker)',
      display: 'inline-block',
    },
    actionDataSpan: {
      display: 'inline-block',
      width: '40%',
    },
    changeDetailSpan: {
      overflowWrap: 'anywhere',
    },
    hr: {
      width: '94%',
      color: '#222',
    },
    userNameLabel: {
      marginRight: '23px',
    },
    expandingIcon: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  })
);

export default function AuditLog({
  auditLog,
}: Readonly<{
  auditLog: ManagedAreaAudit[];
}>) {
  const classes = useStyles();

  const [auditLogsOpen, setAuditLogsOpen] = useState(false);
  const [auditLogItemsChanged, setAuditLogItemsChanged] = useState(false);
  const [auditLogOpenItems, setAuditLogOpenItems] = useState([]);

  const toggleLogItem = (idx: number) => {
    if (!auditLogOpenItems[idx]) {
      auditLogOpenItems[idx] = true;
    } else {
      auditLogOpenItems[idx] = !auditLogOpenItems[idx];
    }

    setAuditLogOpenItems(auditLogOpenItems);
    setAuditLogItemsChanged(!auditLogItemsChanged);
  };

  const getLogActionTitle = (action: ManagedAreaAuditAction): string => {
    switch (action) {
      case ManagedAreaAuditAction.CREATED:
        return 'Created';
      case ManagedAreaAuditAction.UPDATED:
        return 'Updated';
      case ManagedAreaAuditAction.AMEND_FLIGHTS:
        return 'Flights Updated';
      case ManagedAreaAuditAction.UPDATED_FLIGHT_REQUESTS_SMS_SENT:
        return 'FR Declined SMS Sent';
      default:
        return 'Unknown';
    }
  };

  const getAuditChangeDisplay = (change: string): string => {
    const key = change.substring(0, change.indexOf(' '));
    let val = change
      .substring(change.indexOf(' '))
      .replace(' updated to: ', '');
    if (key.includes('DateTime') && !val.includes('null')) {
      val = val.replace(/"/g, '');
      val = new Date(val).toLocaleString();
    }
    return val;
  };

  return (
    <div>
      <hr />
      <List>
        <ListItem dense button onClick={() => setAuditLogsOpen(!auditLogsOpen)}>
          <ListItemText
            primary="Audit Log"
            className="area-audit-log-title"
            key="audit-log"
            data-testid="audit-log"
          />
          {auditLogsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={auditLogsOpen} timeout="auto" unmountOnExit>
          {auditLog
            ?.sort((a, b) => {
              if (
                new Date(b.createdAt).getTime() ===
                new Date(a.createdAt).getTime()
              ) {
                return b.action < a.action ? -1 : 1;
              }
              return new Date(b.createdAt).getTime() <
                new Date(a.createdAt).getTime()
                ? -1
                : 1;
            })
            .map((log, index) => (
              <>
                <table
                  className={classes.auditLogPanel}
                  data-testid="audit-log-table"
                >
                  <tbody>
                    <tr
                      className={classes.auditLogEntry}
                      onClick={() => toggleLogItem(index)}
                      data-testid="audit-log-action-entry"
                    >
                      <th
                        className={classes.actionLabel}
                        data-testid="audit-log-action-label"
                      >
                        Action
                      </th>
                      <td
                        className={classes.actionData}
                        data-testid="audit-log-action-data"
                      >
                        <div>
                          <span className={classes.actionDataSpan}>
                            {new Date(log.createdAt).toLocaleString()}
                          </span>
                          <span className={classes.actionDataSpan}>
                            {getLogActionTitle(log.action)}
                          </span>
                          <span className={classes.expandingIcon}>
                            {auditLogOpenItems[index] && <ExpandLess />}
                            {!auditLogOpenItems[index] && <ExpandMore />}
                          </span>
                        </div>
                      </td>
                    </tr>
                    {auditLogOpenItems[index] && (
                      <>
                        <tr
                          className={classes.auditLogEntry}
                          data-testid="audit-log-user-entry"
                        >
                          <th
                            className={classes.actionLabel}
                            data-testid="audit-log-user-label"
                          >
                            User
                          </th>
                          <td
                            className={classes.actionData}
                            data-testid="audit-log-user-data"
                          >
                            <div className={classes.userNameLabel}>
                              {log.userName || 'N/A'}
                            </div>
                          </td>
                        </tr>

                        {log.changes.length > 0 && (
                          <tr
                            className={classes.auditLogEntry}
                            data-testid="audit-log-changes-entry"
                          >
                            <th
                              className={classes.actionLabel}
                              data-testid="audit-log-changes-label"
                            >
                              Changes
                            </th>
                            <td
                              className={classes.actionData}
                              data-testid="audit-log-changes-data"
                            >
                              {log.changes.map((change: string, i: number) => (
                                <div
                                  className={classes.auditLogChange}
                                  key={`auditLog-${index}-change-${i}`}
                                >
                                  <span>
                                    {change
                                      .substring(0, change.indexOf(' '))
                                      .replace('properties.', '')}
                                  </span>
                                  <span className={classes.changeDetailSpan}>
                                    {getAuditChangeDisplay(change)}
                                  </span>
                                </div>
                              ))}
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <hr className={classes.hr} />
              </>
            ))}
        </Collapse>
      </List>
    </div>
  );
}
