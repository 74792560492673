import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { LoadingButton, COMPLETION_DELAY } from 'asm-web-components';
import { Recaptcha } from 'common-ui-components';

import { useTimeoutEffect } from '../../../hooks';
import {
  useFormState,
  useInputHandlerProps,
  useIsErrored,
  useFieldError,
  useErrors,
  useHandleSubmit,
  useClearForm,
} from '../../../state/session/auth-forms/login/hooks';
import { useLogin, LogInStatus } from '../../../state/session';

function LoginForm() {
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>();
  const recaptchaRef = useRef<any>(null);

  const [loginStatus, attemptLogin] = useLogin();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const form: any = useFormState();
  const handlerProps = useInputHandlerProps();
  const isErrored = useIsErrored();
  const errors = useErrors();
  const fieldError = useFieldError();
  const clearForm = useClearForm();
  const isLoggingIn = loginStatus === LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS;

  useTimeoutEffect(
    () => navigate('/'),
    COMPLETION_DELAY,
    loginStatus === LogInStatus.SUCCESS
  );

  const clearFormCallback = useCallback(() => {
    clearForm;
  }, [clearForm]);

  useEffect(() => clearFormCallback(), [clearFormCallback]);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const handleSubmit = useHandleSubmit(() => {
    if (
      loginStatus !== LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS &&
      recaptchaToken
    ) {
      const { email } = form;
      attemptLogin({ ...form, email: email.trim(), recaptchaToken });
    }
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  }

  useEffect(() => {
    // Reset the recaptcha on login failure
    if (loginStatus === LogInStatus.FAILED && recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [errors, loginStatus]);

  return (
    <form
      onSubmit={handleSubmit}
      className="form signin-form"
      data-testid="signin-form"
    >
      <FormControl className="form-control" error={isErrored('email')}>
        <TextField
          type="text"
          name="email"
          className="email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="input-icon email" />
              </InputAdornment>
            ),
            value: form.email,
            placeholder: 'Email Address',
            autoComplete: 'username',
          }}
          inputProps={{
            autoCapitalize: 'none',
            autoCorrect: 'off',
            ref: emailRef,
            'data-testid': 'signin-form:email-input',
          }}
          data-testid="username-input"
          error={isErrored('email')}
          {...handlerProps}
        />
        <FormHelperText className="input-hint">
          {fieldError('email')}
        </FormHelperText>
      </FormControl>

      <FormControl className="form-control" error={isErrored('password')}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          className="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="input-icon password" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            value: form.password,
            placeholder: 'Password',
            autoComplete: 'current-password',
          }}
          inputProps={{
            'data-testid': 'signin-form:password-input',
          }}
          error={isErrored('password')}
          {...handlerProps}
          // @ts-ignore
          onKeyDown={handleKeyDown}
        />
        <FormHelperText className="input-hint">
          {fieldError('password')}
        </FormHelperText>
      </FormControl>

      <FormHelperText
        data-testid="validation-errors"
        className="validation-errors"
        error
      >
        {errors.message}
      </FormHelperText>
      <FormControl className="recaptcha-control">
        <Recaptcha ref={recaptchaRef} setRecaptchaToken={setRecaptchaToken} />
      </FormControl>

      <LoadingButton
        isLoading={isLoggingIn}
        className="form-control"
        type="submit"
        data-testid="signin-form:submit-button"
        disabled={!recaptchaToken}
      >
        Submit
      </LoadingButton>

      <Link
        to={{ pathname: '/reset-password' }}
        className="sub-link"
        style={{
          color: 'var(--color-mui-light-black)',
          textDecoration: 'none',
        }}
        data-testid="forgot-password"
      >
        Forgot password?
      </Link>
    </form>
  );
}

export default LoginForm;
