import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms';
import { actions, LOGIN_FORM_KEY } from './actions';

export const schema = yup.object().shape({
  email: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

const defaultState = { email: '', password: '' };

export const loginFormReducer = {
  [LOGIN_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
