import * as React from 'react';
import cn from 'classnames';

import './close-button.scss';

interface CloseButtonProps {
  onClick(): any;
  className?: string;
  size?: string;
  actionDescription?: string;
}

function CloseButton(props: CloseButtonProps) {
  const {
    onClick,
    className,
    size = 'large',
    actionDescription = 'Close',
    ...rest
  } = props;

  return (
    <button
      {...rest}
      type="button"
      onClick={onClick}
      className={cn('close-button', {
        [className]: Boolean(className),
        [size]: Boolean(size),
      })}
    >
      {actionDescription}
    </button>
  );
}

export default CloseButton;
