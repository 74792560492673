import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { UserViewModel } from 'fims-api-types';
import { UserRole, UserStatus } from 'argus-common/enums';

import {
  AdminActionType,
  AdminFetchUsersSucceededAction,
  AdminFetchUsersFailedAction,
  AdminAddUserSucceededAction,
  AdminAddUserFailedAction,
  AdminUpdateUserSucceededAction,
  AdminUpdateUserFailedAction,
  AdminFetchAdminDefaultsSucceededAction,
  AdminFetchAdminDefaultsFailedAction,
} from './actions';

import { AdminFetchStatus } from './constants';

export interface AdminState {
  adminDefaults: {
    userRoles: UserRole[];
    userStatuses: UserStatus[];
  };
  error: string;
  fetchStatus: AdminFetchStatus;
  users: UserViewModel[];
  userError: string;
}

const INITIAL_FETCH_STATUS = AdminFetchStatus.IDLE;

const fetchStatusReducer = createReducer(
  {
    [AdminActionType.FETCH_USERS_REQUESTED]: () =>
      AdminFetchStatus.FETCH_IN_PROGRESS,
    [AdminActionType.FETCH_USERS_SUCCEEDED]: () => AdminFetchStatus.SUCCESS,
    [AdminActionType.FETCH_USERS_FAILED]: () => AdminFetchStatus.FAILED,
  },
  INITIAL_FETCH_STATUS,
  false
);

const usersReducer = createReducer(
  {
    [AdminActionType.FETCH_USERS_SUCCEEDED]: (
      _: UserViewModel,
      { payload }: AdminFetchUsersSucceededAction
    ) => payload,
    [AdminActionType.ADD_USER_SUCCEEDED]: (
      _: UserViewModel,
      { payload }: AdminAddUserSucceededAction
    ) => payload,
    [AdminActionType.UPDATE_USER_SUCCEEDED]: (
      _: UserViewModel,
      { payload }: AdminUpdateUserSucceededAction
    ) => payload,
  },
  [],
  false
);

const adminDefaultsReducer = createReducer(
  {
    [AdminActionType.FETCH_ADMIN_DEFAULTS_SUCCEEDED]: (
      _: UserViewModel,
      { payload }: AdminFetchAdminDefaultsSucceededAction
    ) => ({
      userRoles: payload.userRole,
      userStatuses: payload.userStatus,
    }),
  },
  {},
  false
);

const adminErrorReducer = createReducer(
  {
    [AdminActionType.FETCH_USERS_FAILED]: (
      _: string,
      { payload }: AdminFetchUsersFailedAction
    ) => payload,
    [AdminActionType.FETCH_ADMIN_DEFAULTS_FAILED]: (
      _: string,
      { payload }: AdminFetchAdminDefaultsFailedAction
    ) => payload,
    [AdminActionType.CLEAR_ERROR]: (): null => null,
  },
  null,
  false
);

const adminUserErrorReducer = createReducer(
  {
    [AdminActionType.ADD_USER_FAILED]: (
      _: string,
      { payload }: AdminAddUserFailedAction
    ) => payload,
    [AdminActionType.UPDATE_USER_FAILED]: (
      _: string,
      { payload }: AdminUpdateUserFailedAction
    ) => payload,
    [AdminActionType.CLEAR_USER_ERROR]: (): null => null,
  },
  null,
  false
);

export default combineReducers({
  adminDefaults: adminDefaultsReducer,
  error: adminErrorReducer,
  fetchStatus: fetchStatusReducer,
  users: usersReducer,
  userError: adminUserErrorReducer,
});
