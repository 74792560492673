import React, { useContext, useState } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  LoginContext,
  MapView24Hour,
} from '../../../../../../context/LoginState';
import { theme } from '../../../../../app/mui-theme';
import { Today } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    buttonGroup: {},
  })
);

export default function TimeFrameControl() {
  const {
    mapView24hour,
    setMapView24hour,
    mapViewDateTimeRange,
    setMapViewDateTimeRange,
  } = useContext(LoginContext);
  const classes = useStyles();

  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const buttonStylesLeft = { borderRadius: '4px 0 0 4px', padding: '4px 8px' };
  const buttonStylesMiddle = { borderRadius: '0', padding: '4px 8px' };
  const buttonStylesRight = { borderRadius: '0 4px 4px 0', padding: '4px 8px' };
  const butColorOff = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };
  const butColorOn = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  };
  const butSecondaryColorOff = {
    backgroundColor: '#FFFF00',
    color: theme.palette.warning.contrastText,
  };

  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      color="primary"
      className={classes.buttonGroup}
      data-testid="time-frame-control-group"
    >
      <Button
        style={
          mapView24hour === MapView24Hour.TODAY
            ? { ...buttonStylesLeft, ...butColorOff }
            : { ...buttonStylesLeft, ...butColorOn }
        }
        onClick={() => setMapView24hour(MapView24Hour.TODAY)}
        data-testid={`button-${MapView24Hour.TODAY}`}
      >
        Today
      </Button>
      <Button
        style={
          mapView24hour === MapView24Hour.CURRENT
            ? { ...buttonStylesMiddle, ...butColorOff }
            : { ...buttonStylesMiddle, ...butColorOn }
        }
        onClick={() => setMapView24hour(MapView24Hour.CURRENT)}
        data-testid={`button-${MapView24Hour.CURRENT}`}
      >
        Current
      </Button>
      <Button
        style={
          mapView24hour === MapView24Hour.NEXT24
            ? { ...buttonStylesMiddle, ...butSecondaryColorOff }
            : { ...buttonStylesMiddle, ...butColorOn }
        }
        onClick={() => setMapView24hour(MapView24Hour.NEXT24)}
        data-testid={`button-${MapView24Hour.NEXT24}`}
      >
        Next 24H
      </Button>
      <Button
        data-testid={`button-${MapView24Hour.DATE}`}
        style={
          mapView24hour === MapView24Hour.DATE
            ? { ...buttonStylesRight, ...butSecondaryColorOff }
            : { ...buttonStylesRight, ...butColorOn }
        }
        onClick={() => {
          setDateTimePickerOpen(true);
          setMapView24hour(MapView24Hour.DATE);
        }}
      >
        <Today fontSize="small" />
        {mapView24hour === MapView24Hour.DATE && mapViewDateTimeRange?.[0] && (
          <>
            &nbsp;
            {DateTime.fromJSDate(mapViewDateTimeRange[0]).toFormat(
              ' yyyy-MM-dd HH:mm'
            )}
          </>
        )}
      </Button>
      <DateTimePicker
        style={{ display: 'none' }}
        value={mapViewDateTimeRange[0]}
        onChange={(dateSelected) => {
          const dateTime = dateSelected ? dateSelected.toJSDate() : null;
          setMapViewDateTimeRange([dateTime, dateTime]);
          setDateTimePickerOpen(false);
        }}
        onClose={() => {
          setDateTimePickerOpen(false);
        }}
        open={dateTimePickerOpen}
        minDate={DateTime.now().minus({ days: 7 }).toJSDate()}
        maxDate={DateTime.now().plus({ days: 7 }).toJSDate()}
      />
    </ButtonGroup>
  );
}
