import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';

import { useInterval } from '../../../state/custom-hooks/useInterval';
import { ReportsData, getReports } from '../../../clients/fims-api-client';
import NewRegistrationsTable from './new-registrations-table';
import FlightRequestsByZoneTable from './flight-requests-by-zone-table';
import FlightsPerMonth from './flights-per-month';
import FlightsControlled from './flights-controlled';
import AtcImpactTable from './atc-impact-table';

const POLL_INTERVAL = 120000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    circularProgress: {
      marginTop: 60 + theme.spacing(2),
      height: 160,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    mediumTile: {
      height: 500,
    },
    sharedTile: {
      marginBottom: theme.spacing(2),
    },
    registrationTotal: {
      paddingTop: theme.spacing(6),
      height: 120,
      fontWeight: 600,
    },
    table: {
      marginTop: theme.spacing(2),
    },
  })
);

const Reports = () => {
  const [reportsData, setReportsData] = useState<ReportsData | null>(null);
  const [requestError, setRequestError] = useState<string>('');
  const classes = useStyles();

  const getReportsData = async () => {
    const { data, error } = await getReports();
    if (data) setReportsData(data);
    else if (error) setRequestError(error);
  };

  useEffect(() => {
    async function x() {
      getReportsData();
    }
    x();
  }, []);
  useInterval(getReportsData, POLL_INTERVAL);
  const isLoading = !reportsData && !requestError;

  return (
    <div className={classes.root} data-testid="reports">
      <Grid container spacing={2}>
        {(isLoading || typeof reportsData?.totalRegistrations === 'number') && (
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography data-testid="total-registration-header">
                Total Pilot Registrations
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <Typography
                  variant="h1"
                  className={classes.registrationTotal}
                  data-testid="total-registration-count"
                >
                  {reportsData?.totalRegistrations}
                </Typography>
              )}
            </Paper>
          </Grid>
        )}
        {(isLoading || typeof reportsData?.totalFlights === 'number') && (
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography data-testid="flight-logged-in-airshare-header">
                Flights logged in AirShare - Last 12 Months
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <Typography
                  variant="h1"
                  className={classes.registrationTotal}
                  data-testid="flight-logged-in-airshare-count"
                >
                  {reportsData?.totalFlights}
                </Typography>
              )}
            </Paper>
          </Grid>
        )}
        {(isLoading || reportsData?.newRegistrations) && (
          <Grid item xs={6}>
            <Paper className={classnames(classes.paper, classes.mediumTile)}>
              <Typography data-testid="new-pilot-registration-header">
                New Pilot Registrations - Recreational vs Commercial
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <NewRegistrationsTable
                  className={classes.table}
                  data={reportsData?.newRegistrations}
                />
              )}
            </Paper>
          </Grid>
        )}
        {(isLoading || reportsData?.flightRequestsByZone) && (
          <Grid item xs={6}>
            <Paper className={classnames(classes.paper, classes.mediumTile)}>
              <Typography data-testid="airshare-flights-logged-header">
                AirShare Flights Logged per Month - Last Twelve Months
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <FlightsPerMonth
                  className={classes.table}
                  data={reportsData?.flightsPerMonth}
                />
              )}
            </Paper>
          </Grid>
        )}
        <Grid item xs={6}>
          {(isLoading || reportsData?.flightsControlled) && (
            <Paper
              className={classnames(
                classes.paper,
                classes.mediumTile,
                classes.sharedTile
              )}
            >
              <Typography data-testid="airshare-flights-requests-control-zone-vs-outsize-header">
                AirShare Flight Requests - Control Zone vs Outside Control Zone
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <FlightsControlled
                  className={classes.table}
                  data={reportsData?.flightsControlled}
                />
              )}
            </Paper>
          )}
          {(isLoading || reportsData?.atcImpact) && (
            <Paper
              className={classnames(
                classes.paper,
                classes.mediumTile,
                classes.sharedTile
              )}
            >
              <Typography data-testid="airshare-flights-requests-atc-impact-header">
                AirShare Flight Requests - ATC Impact
              </Typography>
              <Typography variant="subtitle2">
                Total Flights less Cancelled Flight Requests and Shielded Ops in
                Control Zones
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <AtcImpactTable
                  className={classes.table}
                  data={reportsData?.atcImpact}
                />
              )}
            </Paper>
          )}
        </Grid>
        {(isLoading || reportsData?.flightRequestsByZone) && (
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography data-testid="airshare-flights-requests-by-control-zone-header">
                AirShare Flight Requests by Control Zone
              </Typography>
              {isLoading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress size={80} color="inherit" />
                </div>
              ) : (
                <FlightRequestsByZoneTable
                  className={classes.table}
                  data={reportsData?.flightRequestsByZone}
                />
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Reports;
