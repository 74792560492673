import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Collapse,
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import {
  DetailedFlightRequestViewModelV2,
  FRStatusCode,
  RequiredAuthChangeSubmission,
  AuthChangeField,
} from 'fims-api-types';
import { MinAltitudeInFeet } from 'argus-common/consts';
import { isNotifiedFlightISO } from 'airspace-manager-common/flight-requests/activation/notified-flight';
import { fimsAPI } from '../../../../../clients/api';

interface Props {
  open: boolean;
  setOpen: Function;
  newStatus: FRStatusCode | null;
  atcNote: string;
  setAtcNote: Function;
  operatorNote: string;
  setOperatorNote: Function;
  submitter: string;
  focussedSubmitter: string;
  flightRequest?: DetailedFlightRequestViewModelV2;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    textField: {
      minWidth: '400px',
      margin: '0 0 20px 0',
    },
    changesReqDropdown: {
      width: 'calc(100% - 16px)',
      margin: '2px 8px 6px 8px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    changesReqTitle: {
      margin: '0px',
      fontWeight: 500,
    },
    altitudeContainer: {
      display: 'flex',
      width: 'calc(100% - 16px)',
      margin: '2px 8px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    altitudeLabel: {
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '8px',
    },
    altitudeValue: {
      width: '80px',
      '&& input': {
        padding: '6px 6px',
      },
    },
    submissionError: {
      color: 'red',
    },
  })
);

const StatusConfirmationDialog: React.FC<Props> = ({
  open,
  setOpen,
  newStatus,
  atcNote,
  setAtcNote,
  operatorNote,
  setOperatorNote,
  submitter,
  focussedSubmitter,
  flightRequest,
}) => {
  const [loading, setLoading] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const [changesReqOpen, setChangesReqOpen] = useState<boolean>(false);
  const [altitude, setAltitude] = useState<number>();
  const [isAltitudeReq, setIsAltitudeReq] = useState<boolean>(false);
  const [altitudeErr, setAltitudeErr] = useState<string>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const classes = useStyles();

  const handleClose = (reason?: string, clearNotes: boolean = false) => {
    if (reason?.length) {
      return;
    }

    if (loading || clearNotes) {
      setAtcNote('');
      setOperatorNote('');
    }
    setSubmissionFailed(false);
    setLoading(false);
    setOpen(false);
    setSubmissionError('');
    setAltitude(undefined);
    setIsAltitudeReq(false);
    setChangesReqOpen(false);
  };
  if (loading && submitter !== focussedSubmitter) {
    handleClose();
  }

  const updateStatus = async () => {
    const changes: RequiredAuthChangeSubmission[] = altitude
      ? [
          {
            field: AuthChangeField.ALTITUDE,
            previousValue: flightRequest?.maxAltitudeFeet,
            newValue: altitude,
            isAcknowledged: false,
          },
        ]
      : [];

    if (
      (!submissionFailed && atcNote && operatorNote) ||
      newStatus === FRStatusCode.Declared ||
      newStatus === FRStatusCode.Authorized ||
      newStatus === FRStatusCode.ProvAuth
    ) {
      try {
        await fimsAPI.post(
          `/flight-requests/${flightRequest?.flightId}/decision`,
          {
            atcNote,
            operatorNote,
            statusCode: newStatus,
            submitter,
            changes,
          }
        );
        setLoading(true);
        setSubmissionError('');
        setSubmissionFailed(false);
      } catch (error) {
        setSubmissionError(
          error?.response?.data?.errorMessage || error.message
        );
        setSubmissionFailed(true);
      }
    } else {
      setSubmissionFailed(true);
    }
  };

  const handleSubmit = () => {
    if (isSending) {
      return;
    }
    setIsSending(true);
    updateStatus();
  };

  useEffect(() => {
    setCanSubmit(!submissionError && !altitudeErr);
  }, [submissionError, altitudeErr]);

  const atcError =
    newStatus === FRStatusCode.Declined && submissionFailed && !atcNote;
  const operatorError =
    newStatus === FRStatusCode.Declined && submissionFailed && !operatorNote;
  const conditionalAuthEnabled =
    window.env.CONDITIONAL_AUTHORISATION_ENABLED?.toLowerCase() === 'true' &&
    newStatus === FRStatusCode.ProvAuth;

  const handleAltitudeChanged = (inputAltitude: string) => {
    try {
      const alt = Number.parseInt(inputAltitude);
      setAltitude(alt);
      if (!alt || alt < MinAltitudeInFeet) {
        setAltitudeErr(
          `Required altitude must be greater than ${MinAltitudeInFeet}ft`
        );
      } else {
        setAltitudeErr('');
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const handleNoteChange = (formField: string, value: string) => {
    switch (formField) {
      case 'operatorNote':
        setOperatorNote(value);
        break;
      case 'atcNote':
        setAtcNote(value);
        break;
      default:
        return;
    }
    setSubmissionFailed(false);
    setIsSending(false);
  };

  const handleConditionalAuthRequiredToggle = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsAltitudeReq(checked);
    if (!checked) {
      setAltitude(undefined);
      setAltitudeErr('');
    }
  };

  useEffect(() => {
    if (open) {
      setIsSending(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(_e, reason) => handleClose(reason)}
      data-testid="dialog"
    >
      <DialogTitle data-testid="dialog-title">{`Change status to ${newStatus}`}</DialogTitle>
      <DialogContent style={{ maxWidth: 400 }}>
        <TextField
          placeholder="Note for Authoriser"
          multiline
          value={atcNote}
          onChange={(e) => handleNoteChange('atcNote', e.target.value)}
          minRows={4}
          variant="outlined"
          error={atcError}
          helperText={atcError && `${newStatus} status requires note`}
          className={classes.textField}
          InputProps={{
            style: {
              padding: '8px 10px',
            },
          }}
          data-testid="dialog-note-to-authoriser-text-field"
        />
        <TextField
          placeholder="Note for Operator"
          multiline
          value={operatorNote}
          onChange={(e) => handleNoteChange('operatorNote', e.target.value)}
          minRows={4}
          variant="outlined"
          error={operatorError}
          helperText={operatorError && `${newStatus} status requires note`}
          className={classes.textField}
          InputProps={{
            style: {
              padding: '8px 10px',
            },
          }}
          data-testid="dialog-note-to-operator-text-field"
        />
        {conditionalAuthEnabled &&
          (!isNotifiedFlightISO(flightRequest?.utcStartDateTime) ? (
            <>
              <div
                className={classes.changesReqDropdown}
                data-testid="dialog-auth-changes-required-dropdown"
                onClick={() => setChangesReqOpen(!changesReqOpen)}
              >
                <h4 className={classes.changesReqTitle}>Changes Required:</h4>
                {changesReqOpen ? <ExpandLess /> : <ExpandMore />}
              </div>
              <Collapse in={changesReqOpen} timeout="auto" unmountOnExit>
                <div className={classes.altitudeContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={isAltitudeReq}
                        name="isAltitudeRequiredSwitch"
                        onChange={handleConditionalAuthRequiredToggle}
                        data-testid="dialog-is-altitude-required-switch"
                      />
                    }
                    label="Req."
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.altitudeLabel}
                  >
                    Altitude (ft)
                  </Typography>
                  <TextField
                    data-testid="dialog-new-altitude-value"
                    type="number"
                    placeholder={
                      flightRequest?.maxAltitudeFeet.toString() ?? ''
                    }
                    value={
                      altitude ?? flightRequest?.maxAltitudeFeet.toString() ?? 0
                    }
                    onChange={(e) => handleAltitudeChanged(e.target.value)}
                    variant="outlined"
                    error={
                      altitudeErr && altitudeErr.length
                        ? altitudeErr.length > 0
                        : false
                    }
                    helperText={altitudeErr}
                    disabled={!isAltitudeReq}
                    className={classes.altitudeValue}
                    inputProps={{
                      step: 10,
                    }}
                  />
                </div>
              </Collapse>
            </>
          ) : (
            <Typography>
              Conditional authorisation is only available more than 30 minutes
              prior to flight start time.
            </Typography>
          ))}
        <br />
        {submissionError && (
          <Typography variant="caption" className={classes.submissionError}>
            {submissionError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={() => handleClose(null, true)}
          data-testid="dialog-cancel-button"
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            disabled
            variant="contained"
            style={{ width: 85 }}
            data-testid="dialog-submit-disabled-button"
          >
            <CircularProgress size={24} color="inherit" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            data-testid="dialog-submit-button"
            disabled={isSending || !canSubmit}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default StatusConfirmationDialog;
