import { createSelector } from 'reselect';
import { MapOptionsState } from './reducers';

interface StateWithMapStyles {
  mapOptions: MapOptionsState;
}

const getLocalState = (state: StateWithMapStyles) => state.mapOptions;

export const getMapOptions = createSelector([getLocalState], (state) => state);
