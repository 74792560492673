import type { ObjectId } from 'mongodb';

export const ExponentRole = {
  Pilot: 'Pilot',
  OrgAdmin: 'Organisation Admin',
  AuthAdmin: 'Authority Admin',
  AuthApprover: 'Authority Approver',
  AuthMonitor: 'Authority Monitor',
  AuthViewer: 'Authority Viewer',
};

export const PILOT_NAME_HACK = '|$|%|^|%|$|';

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  AUTHORISER = 'AUTHORISER',
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER',
}

export enum CertificationType {
  PART_101 = 'Part 101',
  PART_102 = 'Part 102',
}

export enum FlightRule {
  PART_101 = 'Part 101',
  PART_102 = 'Part 102',
  BVLOS = 'BVLOS',
  LOS = 'LOS',
}

export enum UasOperationCategory {
  OPEN = 'OPEN',
  SPECIFIC = 'SPECIFIC',
  CERTIFIED = 'CERTIFIED',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Disabled = 'DISABLED',
}

export enum OperatorType {
  RECREATIONAL = 'RECREATIONAL',
  COMMERCIAL = 'COMMERCIAL',
  UAM_OPERATION = 'UAM_OPERATOR',
}

export interface OrganisationRef {
  organisation: ObjectId;
  defaultUav?: ObjectId;
}
