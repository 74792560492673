import {
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  SetCtrSettingsPayload,
  SetCtrZoneVisibilityPayload,
  CtrSettings as CtrZoneSettings,
} from 'fims-api-types';
import { useInterval } from '~/state/custom-hooks/useInterval';
import {
  getCtrSettings,
  postCtrSettings,
  getCtrZoneVisibilitySettings,
  updateCtrZoneVisibilitySettings,
} from '../../../clients/fims-api-client';
import { SettingsModalTitles } from '../../helpers';

const titleModalDA = SettingsModalTitles.DIGITAL_ACTIVATION;

export default function CtrSettings({
  loadingStyle,
  title,
}: {
  loadingStyle: string;
  title: string;
}) {
  const [ctrSettings, setCtrSettings] = useState<CtrZoneSettings[] | null>(
    null
  );
  const [ctrData, setCtrData] = useState([]);
  const [ctrZoneVisibility, setCtrZoneVisibility] = useState(null);
  const [apiError, setApiError] = useState('');
  const RETRIEVE_CTR_DATA_INTERVAL = 10000;
  const retreiveCtrData = useCallback(async () => {
    const res = await getCtrSettings();
    if (title === SettingsModalTitles.CTR_ZONES) {
      retrieveCtrZoneSettings();
    }
    setCtrSettings(res.data);
    setApiError('');
  }, [title]);

  const retrieveCtrZoneSettings = async () => {
    const res = await getCtrZoneVisibilitySettings();
    setCtrZoneVisibility(res.data.ctrZoneSettings);
    setApiError('');
  };

  const updateCtrData = async (data: SetCtrSettingsPayload) => {
    const callSetCtrSettings = await postCtrSettings(data);
    return callSetCtrSettings?.status === 200;
  };

  useEffect(() => {
    retreiveCtrData();
  }, [retreiveCtrData]);

  useEffect(() => {
    const processCtrData = () => {
      if (ctrSettings) {
        const data: any[] = [];
        if (ctrZoneVisibility && title === SettingsModalTitles.CTR_ZONES) {
          ctrSettings.forEach((ctr) => {
            const zone = ctrZoneVisibility.find(
              (z: { ctrCode?: string }) => z.ctrCode === ctr.ctrCode
            );

            data.push({
              ctrName: ctr.ctrName,
              ctrCode: ctr.ctrCode,
              isOn: zone?.isVisible === false ? zone?.isVisible : true,
            });
          });

          setCtrData(data);
        } else if (title === titleModalDA) {
          ctrSettings.forEach((ctr) => {
            data.push({
              ctrName: ctr.ctrName,
              ctrCode: ctr.ctrCode,
              isOn: ctr.digitalAuthorization,
            });
          });
          setCtrData(data);
        }
      }
    };

    processCtrData();
  }, [ctrZoneVisibility, ctrSettings, title]);

  useInterval(() => {
    retreiveCtrData();
  }, RETRIEVE_CTR_DATA_INTERVAL);

  const handleSwitchChange = async (event: any) => {
    if (!ctrData) return;
    setApiError('');
    let resultsUpdateCtrData;
    let temporaryCtrData: any[] = [];
    if (title === titleModalDA) {
      temporaryCtrData = JSON.parse(JSON.stringify(ctrData));
      const foundCtr = ctrData.findIndex(
        (e) => e.ctrCode === event.target.name
      );

      if (foundCtr === -1) return;
      temporaryCtrData[foundCtr].isOn = event.target.checked;
      const updatedCtrData: SetCtrSettingsPayload = {
        ctrCode: event.target.name,
        digitalAuthorization: event.target.checked,
      };

      resultsUpdateCtrData = await updateCtrData(updatedCtrData);
    } else if (title === SettingsModalTitles.CTR_ZONES) {
      temporaryCtrData = JSON.parse(
        JSON.stringify(ctrData)
      ) as CtrZoneSettings[];
      const foundCtr = ctrData.findIndex(
        (e) => e.ctrCode === event.target.name
      );

      if (foundCtr === -1) return;
      temporaryCtrData[foundCtr].isOn = event.target.checked;
      const updatedCtrData: SetCtrZoneVisibilityPayload = {
        ctrCode: event.target.name,
        ctrName: temporaryCtrData[foundCtr].ctrName,
        isVisible: event.target.checked,
      };

      resultsUpdateCtrData =
        await updateCtrZoneVisibilitySettings(updatedCtrData);
    }

    if (resultsUpdateCtrData) {
      setCtrData(temporaryCtrData);
    } else {
      setApiError('There was an error updating the settings');
    }
  };

  const allData: any[] = [];
  if (ctrData) {
    ctrData.forEach((ctr) => {
      allData.push(
        <Grid item xs={6} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={ctr.isOn}
                name={ctr.ctrCode}
                onChange={handleSwitchChange}
                color="primary"
                data-testid="settings-switch"
              />
            }
            label={
              <Typography variant="body2" data-testid="settings-name">
                {ctr.ctrCode} {ctr.ctrName}
              </Typography>
            }
            labelPlacement="end"
          />
        </Grid>
      );
    });
  }
  return (
    <Grid
      container
      spacing={1}
      alignContent="center"
      data-testid="settings-data"
    >
      {ctrData ? (
        <Grid container spacing={0} alignItems="center">
          {allData.map((_, i) => (
            <Fragment key={ctrData[i].ctrCode}>{allData[i]}</Fragment>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Box className={loadingStyle} sx={{ display: 'flex' }}>
            <CircularProgress size={64} />
          </Box>
        </Grid>
      )}
      <Box width={1} height={20} />
      {apiError ? (
        <Grid>
          <Alert severity="error" data-testid="settings-serror">
            {apiError}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
