import React, { useState } from 'react';
import { CheckCircle, CallRounded, Cancel } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import {
  DigitalClearanceStatus,
  FRStatusCode,
  SegmentStatusCode,
  getStatusColor,
} from 'fims-api-types';
import * as fimsClient from '../../../../clients/fims-api-client';
import ConfirmActionDialog from '../map/confirm-action-dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    decline: {
      float: 'right',
      margin: '8px 8px 8px 0',
      minWidth: 158,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    callTower: {
      float: 'right',
      margin: '8px 8px 8px 0',
      minWidth: 158,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    authorize: {
      float: 'right',
      margin: '8px 8px 8px 0',
      minWidth: 158,
      color: theme.palette.common.white,
      backgroundColor: getStatusColor(FRStatusCode.Activated),
      '&:hover': {
        backgroundColor: '#388534',
      },
    },
    errorMessage: {
      width: '100%',
      textAlign: 'right',
      padding: '0 10px',
      color: theme.palette.error.dark,
    },
  })
);

export const AuthorizationActions = ({
  flightId,
  segmentId,
  updateFlightRequests,
}: {
  flightId: number;
  segmentId?: string;
  updateFlightRequests: () => void;
}) => {
  const [waitingStatus, setWaitingStatus] =
    useState<DigitalClearanceStatus>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();

  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);

  const onAction = (status: DigitalClearanceStatus) => {
    setWaitingStatus(status);
    sendAuthorizationState(status);
  };

  const sendAuthorizationState = async (
    status: DigitalClearanceStatus,
    reason?: string
  ) => {
    try {
      const data = await fimsClient.updateActivationStatus({
        flightId: flightId.toString(),
        segmentId,
        status: segmentId ? SegmentStatusCode.ACTIVATED : status,
        ...(reason ? { reason } : {}),
      });
      if (data.error) {
        setError(true);
        setErrorMessage(data.error);
      }
      updateFlightRequests();
    } catch (err) {
      setError(true);
      setErrorMessage(err.message);
    }
    setWaitingStatus(null);
  };

  const returnProgressButton = () => (
    <CircularProgress size={24} color="inherit" />
  );

  const isButtonWaiting = () => !!waitingStatus;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      data-testid="authorisation-actions"
    >
      {error && (
        <Typography
          data-testid="authorisation-error-message"
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {!segmentId && (
        <Button
          aria-label="decline"
          className={classes.decline}
          color="inherit"
          variant="contained"
          disabled={!!segmentId || isButtonWaiting()}
          onClick={() => {
            setIsDeclineDialogOpen(true);
          }}
          startIcon={
            waitingStatus === DigitalClearanceStatus.Declined ? null : (
              <Cancel />
            )
          }
          data-testid={`authorisation-decline-button-${flightId}-${segmentId ?? ''}`}
        >
          {waitingStatus === DigitalClearanceStatus.Declined
            ? returnProgressButton()
            : 'Decline'}
        </Button>
      )}
      {!segmentId && (
        <Button
          aria-label="request-call"
          className={classes.callTower}
          color="inherit"
          variant="contained"
          disabled={!!segmentId || isButtonWaiting()}
          onClick={() => onAction(DigitalClearanceStatus.CallTower)}
          startIcon={
            waitingStatus === DigitalClearanceStatus.CallTower ? null : (
              <CallRounded />
            )
          }
          data-testid={`authorisation-request-call-button-${flightId}-${segmentId ?? ''}`}
        >
          {waitingStatus === DigitalClearanceStatus.CallTower
            ? returnProgressButton()
            : 'Request Call'}
        </Button>
      )}
      <Button
        aria-label="authorize"
        className={classes.authorize}
        color="inherit"
        variant="contained"
        disabled={isButtonWaiting()}
        onClick={() => onAction(DigitalClearanceStatus.Authorized)}
        startIcon={
          waitingStatus === DigitalClearanceStatus.Authorized ? null : (
            <CheckCircle />
          )
        }
        data-testid={`authorisation-authorise-button-${flightId}-${segmentId ?? ''}`}
      >
        {waitingStatus === DigitalClearanceStatus.Authorized
          ? returnProgressButton()
          : 'Activate'}
      </Button>

      <ConfirmActionDialog
        precannedMessagesAppCode="DECLINE_DA_REASONS"
        description="This option will decline the digital authorization, the pilot will be notified and the flight request will remain with the same status."
        title={`Decline digital authorization`}
        placeholder="Indicate the reason for the decline, you can select one of the options above or write your own message."
        open={isDeclineDialogOpen}
        setOpen={setIsDeclineDialogOpen}
        callback={(reason: string) => {
          if (reason) {
            sendAuthorizationState(DigitalClearanceStatus.Declined, reason);
          }
        }}
      />
    </Grid>
  );
};
