import { useCallback, useContext, useEffect } from 'react';
import { DateTime } from 'luxon';

import { LatLng, ManagedAirspaceViewModel } from 'fims-api-types';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
import { ManagedAreaAudit } from 'argus-data-model/db/schemas/managed-areas/audit';

import { getManagedAirspace } from '../../../../clients/fims-api-client';
import { LoginContext } from '../../../../context/LoginState';
import { getCenter } from '../../home-screen/map/helpers';

interface UpdateFields {
  setType: (type: ManagedAreaCode | null) => void;
  setDescription: (description?: string) => void;
  setName: (name?: string) => void;
  setICAOCode: (ICAOCode?: string) => void;
  setMinAltitude: (minAltitude: number) => void;
  setMaxAltitude: (maxAltitude?: number) => void;
  setStartDateTime: (startDateTime?: Date | null) => void;
  setEndDateTime: (endDateTime?: Date | null) => void;
  setPhone: (phone?: string) => void;
  setEmail: (email?: string) => void;
  setIsVisible: (isVisible: boolean) => void;
  setIsTileDisplay: (isTileDisplay: boolean) => void;
  setPilotVisibility: (pilotVisibility: string) => void;
}

export default function useLoadAirspace(
  add: boolean,
  {
    setName,
    setDescription,
    setICAOCode,
    setEmail,
    setPhone,
    setMinAltitude,
    setMaxAltitude,
    setType,
    setStartDateTime,
    setEndDateTime,
    setIsVisible,
    setIsTileDisplay,
    setPilotVisibility,
  }: UpdateFields,
  setOriginalAirspace: (airspace: ManagedAirspaceViewModel) => void,
  setFatalError: (err: string) => void,
  setMapCenter: (center?: LatLng) => void,
  setAuditLog: (auditLog: ManagedAreaAudit[]) => void,
  setAuthorizedPilots: (authorizedPilots: string[]) => void,
  setAuthorizedPilotsOpen: (isOpen: boolean) => void,
  pilotVisibilityOptions: {
    label: string;
    options: { isVisible: boolean; isTileDisplay: boolean };
  }[],
  setIsLoading: (isLoading: boolean) => void,
  areaId?: string
) {
  const { selectedAirspace } = useContext(LoginContext);

  const loadExistingArea = useCallback(async () => {
    try {
      if (!areaId) throw new Error('No areaId provided');
      setIsLoading(true);
      const data = await getManagedAirspace(areaId);
      setIsLoading(false);
      const { managedArea } = data;
      setOriginalAirspace(managedArea);
      setName(managedArea?.properties?.name);
      setDescription(managedArea?.properties?.description);
      setICAOCode(managedArea?.properties?.icaoCode);
      setEmail(managedArea?.properties?.email);
      setPhone(managedArea?.properties?.phone);
      setMinAltitude(managedArea?.properties?.lowerLimitFeet);
      setMaxAltitude(managedArea?.properties?.upperLimitFeet);
      setType(managedArea?.properties?.code || null);
      setStartDateTime(
        managedArea?.properties?.startDateTime
          ? DateTime.fromISO(managedArea?.properties?.startDateTime).toJSDate()
          : null
      );
      setEndDateTime(
        managedArea?.properties?.endDateTime
          ? DateTime.fromISO(managedArea?.properties?.endDateTime).toJSDate()
          : null
      );
      setIsVisible(managedArea?.properties?.pilotVisible);
      setIsTileDisplay(managedArea?.properties?.tileDisplay);

      const pilotVisibilityOption = pilotVisibilityOptions.find(
        ({ options }) =>
          options.isVisible === !!managedArea?.properties?.pilotVisible &&
          options.isTileDisplay === !!managedArea?.properties?.tileDisplay
      );
      if (pilotVisibilityOption) {
        setPilotVisibility(pilotVisibilityOption.label);
      }

      const { center } = getCenter({
        geometry: managedArea.geometry,
      });
      setMapCenter(center);
      setAuditLog(managedArea.auditLog ?? []);

      const authedPilots = managedArea?.properties?.authorizedPilots ?? [];
      setAuthorizedPilots(authedPilots);
      setAuthorizedPilotsOpen(authedPilots.length > 0);
    } catch (error) {
      setFatalError('Unable to load managed area');
      setIsLoading(false);
    }
  }, [
    areaId,
    pilotVisibilityOptions,
    setDescription,
    setEmail,
    setEndDateTime,
    setFatalError,
    setMapCenter,
    setMaxAltitude,
    setMinAltitude,
    setName,
    setICAOCode,
    setOriginalAirspace,
    setPhone,
    setStartDateTime,
    setType,
    setIsVisible,
    setIsTileDisplay,
    setAuditLog,
    setAuthorizedPilots,
    setAuthorizedPilotsOpen,
    setPilotVisibility,
    setIsLoading,
  ]);

  const loadSelectedAirspace = useCallback(() => {
    if (!selectedAirspace) {
      setFatalError('No airspace area loaded');
    }
    const { center } = getCenter({
      geometry: selectedAirspace,
    });
    setMapCenter(center);
  }, [selectedAirspace, setFatalError, setMapCenter]);

  useEffect(() => {
    if (add) {
      loadSelectedAirspace();
    } else {
      loadExistingArea();
    }
  }, [add, loadSelectedAirspace, loadExistingArea]);
}
