import React from 'react';
import { versionDescription } from 'fims-api-types';

import './about.scss';

export default function About() {
  return (
    <div className="about">
      <h1>About</h1>
      <div>
        <span className="about-version-header">Version:</span>{' '}
        {versionDescription}
      </div>
    </div>
  );
}
