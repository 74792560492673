import { MapStyleCode } from './actions';
import { MapOptionsState } from './reducers';

const defaultStyles: MapOptionsState = {
  [MapStyleCode.Standard]: {
    mapId: 'bc0e5691bf4f1265',
    mapTypeId: 'roadmap' as google.maps.MapTypeId,
  },
  [MapStyleCode.Gray]: {
    mapId: '22b0e802814926ea',
    mapTypeId: 'roadmap' as google.maps.MapTypeId,
  },
  [MapStyleCode.Dark]: {
    mapId: '9116774f47aa1e3d',
    mapTypeId: 'roadmap' as google.maps.MapTypeId,
  },
  [MapStyleCode.Terrain]: {
    mapId: '9116774f47aa1e3d',
    mapTypeId: 'terrain' as google.maps.MapTypeId,
  },
  [MapStyleCode.Satellite]: {
    mapId: '9116774f47aa1e3d',
    mapTypeId: 'satellite' as google.maps.MapTypeId,
  },
  [MapStyleCode.Hybrid]: {
    mapId: '9116774f47aa1e3d',
    mapTypeId: 'hybrid' as google.maps.MapTypeId,
  },
};

export default defaultStyles;
