import { useCallback, useMemo, useState } from 'react';
import { NotificationEvent } from 'argus-events/notification';
import { type SurveillanceWebsocketEvent } from 'argus-events/websocket';
import { AircraftTelemetryWithAlerts as SurveillanceViewModel } from 'argus-events/interfaces';

import { useSocket } from './use-socket';

export const useRawSurveillance = (): [SurveillanceViewModel[]] => {
  const [surveillanceData, setSurveillanceData] = useState<
    SurveillanceViewModel[]
  >([]);

  const socket = useSocket();

  const onEvent = useCallback(({ payload }: SurveillanceWebsocketEvent) => {
    setSurveillanceData(payload);
  }, []);

  const onSocketEstablished = useCallback(() => {
    socket.on(NotificationEvent.FimsSurveillanceEvent, onEvent);
  }, [socket, onEvent]);

  useMemo(() => {
    if (socket?.id) {
      onSocketEstablished();
    }
  }, [socket?.id, onSocketEstablished]);

  return [surveillanceData];
};
