import React from 'react';
import type { ReceiveSmsContext } from 'fims-api-types';

import '../audit-logs.scss';

export const ContextReceiveMessage = ({
  context,
}: {
  context: ReceiveSmsContext;
}) => {
  return (
    <>
      <tr>
        <th data-testid="flight-audit-log-sent-by-title">
          SMS response sent to
        </th>
        <td className="note" data-testid="flight-audit-log-sent-by-value">
          {`${context?.responseTo?.organisationName} ${context?.responseTo?.userName}`}
        </td>
      </tr>

      <tr>
        <th data-testid="flight-audit-log-receive-sms-title">Message</th>
        <td className="note" data-testid="flight-audit-log-receive-sms-value">
          {context?.message || 'N/A'}
        </td>
      </tr>
    </>
  );
};
