import { useState } from 'react';
import zipObject from 'lodash/zipObject';
import { MapStyleCode } from './actions';
import { MapOptionsState } from './reducers';
import defaultStyles from './defaultStyles';

export interface MapStyle {
  mapId: string;
  mapTypeId: google.maps.MapTypeId;
  styleCode: MapStyleCode;
}

const DEFAULT_MAP_OPTIONS_STATE: MapOptionsState = defaultStyles;

export const getMapStylesByCode = (styleCode: MapStyleCode): MapStyle => {
  return {
    ...defaultStyles[styleCode],
    styleCode,
  };
};

const getDefaultMapIndex = (styleCode: MapStyleCode) => {
  return [
    MapStyleCode.Standard,
    MapStyleCode.Gray,
    MapStyleCode.Dark,
    MapStyleCode.Terrain,
    MapStyleCode.Satellite,
    MapStyleCode.Hybrid,
  ].findIndex((x) => x === styleCode);
};

export function useMapStyle(
  styleCode: MapStyleCode,
  setMapStyleCode: (code: MapStyleCode) => void
): [MapStyle, () => MapStyle] {
  const mapOptions: MapOptionsState = DEFAULT_MAP_OPTIONS_STATE;
  const [mapStyleIndex, updateMapStyleIndex] = useState(
    getDefaultMapIndex(styleCode)
  );
  const [mapStyle, updateMapStyle] = useState<MapStyle>(
    getMapStylesByCode(styleCode)
  );

  const nextMapStyle = () => {
    const mapStyleValues = Object.values(mapOptions).map((options) => ({
      ...options,
    }));
    const mapStyleEnum = zipObject(
      mapStyleValues.map((_, index) => index),
      mapStyleValues
    );
    const nextMapStyleIndex = (mapStyleIndex + 1) % mapStyleValues.length;
    const newMapStyle: MapStyle = {
      ...mapStyleEnum[nextMapStyleIndex],
      styleCode:
        mapStyleIndex > Object.values(MapStyleCode).length - 1
          ? null
          : (Object.values(MapStyleCode)[nextMapStyleIndex] as MapStyleCode),
    };

    updateMapStyleIndex(nextMapStyleIndex);
    updateMapStyle(newMapStyle);

    setMapStyleCode(newMapStyle.styleCode);

    return newMapStyle;
  };
  return [mapStyle, nextMapStyle];
}
