import { Scope } from 'argus-common/flight-requests.lib';
import { AircraftType, AlertStatus } from 'argus-common/enums';
import { FRStatusCode, FlightRequestViewModel } from 'fims-api-types';
import { AircraftTelemetryWithAlerts } from 'argus-events/interfaces';
import { Authorizer } from 'argus-data-model/db/schemas/authorizers';
import {
  FlightCountByStatus,
  UpcomingFlight,
  UpcomingFlightTypesByStatus,
} from './types';
import { MapView24Hour } from '../../../context/LoginState';
import { filterFlightStatusTime } from './flights-today-filters';

const upcomingTypes: UpcomingFlightTypesByStatus = {
  Authorized: {
    id: 'authorized',
    text: 'Authorised',
    scope: Scope.upcoming,
  },
  NowAuthorized: {
    id: 'nowAuthorized',
    text: 'Authorised',
    scope: Scope.upcoming,
  },
  TodayAuthorized: {
    id: 'todayAuthorized',
    text: 'Authorised',
    scope: Scope.upcoming,
  },
  Pending: {
    id: 'pending',
    text: 'Pending',
    scope: Scope.unapproved,
  },
  ProvisionallyAuthorized: {
    id: 'provisionallyAuthorized',
    text: 'Prov. Auth.',
    scope: Scope.upcoming,
  },
  NowPending: {
    id: 'nowPending',
    text: 'Pending',
    scope: Scope.unapproved,
  },
  NowProvisionallyAuthorized: {
    id: 'nowProvisionallyAuthorized',
    text: 'Prov. Auth.',
    scope: Scope.upcoming,
  },
  TodayProvisionallyAuthorized: {
    id: 'todayProvisionallyAuthorized',
    text: 'Prov. Auth.',
    scope: Scope.upcoming,
  },
  TodayPending: {
    id: 'todayPending',
    text: 'Pending',
    scope: Scope.unapproved,
  },
  Activated: {
    id: 'activated',
    text: 'Activated',
    scope: Scope.activated,
  },
  NowActivated: {
    id: 'nowActivated',
    text: 'Activated',
    scope: Scope.activated,
  },
  TodayActivated: {
    id: 'todayActivated',
    text: 'Activated',
    scope: Scope.activated,
  },
};

export function getCounterData(
  surveillanceData: AircraftTelemetryWithAlerts[]
): { type: { id: string; text: string }; value: number }[] {
  const flightsInBreach = surveillanceData.filter(
    ({
      alertConformanceStatus,
      alertProximityStatus,
      type,
    }: AircraftTelemetryWithAlerts) =>
      type === AircraftType.Drone &&
      (alertConformanceStatus === AlertStatus.Breach ||
        alertProximityStatus === AlertStatus.Breach)
  );
  const flightsInAlert = surveillanceData.filter(
    ({
      alertConformanceStatus,
      alertProximityStatus,
      type,
    }: AircraftTelemetryWithAlerts) =>
      type === AircraftType.Drone &&
      alertConformanceStatus !== AlertStatus.Breach &&
      alertProximityStatus !== AlertStatus.Breach &&
      (alertConformanceStatus === AlertStatus.Alert ||
        alertProximityStatus === AlertStatus.Alert)
  );
  const flightsInSafeOps = surveillanceData.filter(
    ({
      alertConformanceStatus,
      alertProximityStatus,
      type,
    }: AircraftTelemetryWithAlerts) =>
      type === AircraftType.Drone &&
      alertConformanceStatus === AlertStatus.SafeOps &&
      alertProximityStatus === AlertStatus.SafeOps
  );

  return [
    { type: { id: 'breach', text: 'Breach' }, value: flightsInBreach.length },
    { type: { id: 'alert', text: 'Alert' }, value: flightsInAlert.length },
    { type: { id: 'safe', text: 'Safe' }, value: flightsInSafeOps.length },
  ];
}

export function getupcomingData(
  dashboardStats: FlightCountByStatus,
  activatedEnabled = false
): UpcomingFlight[] {
  const {
    authorized,
    authorizedNow,
    authorizedToday,
    pending,
    pendingToday,
    provisionallyAuthorized,
    provisionallyAuthorizedNow,
    provisionallyAuthorizedToday,
    activated,
    activatedNow,
    activatedToday,
  } = dashboardStats;

  const pathname = '/flights';

  const activatedStatus = activatedEnabled
    ? [
        {
          type: upcomingTypes.Activated,
          value: activated,
          scope: upcomingTypes.Activated.scope,
          pathname,
        },
        {
          type: upcomingTypes.NowActivated,
          value: activatedNow,
          scope: upcomingTypes.Activated.scope,
          pathname,
        },
        {
          type: upcomingTypes.TodayActivated,
          value: activatedToday,
          scope: upcomingTypes.TodayActivated.scope,
          pathname,
        },
      ]
    : [];

  return [
    ...activatedStatus,
    {
      type: upcomingTypes.Authorized,
      value: authorized,
      scope: upcomingTypes.Authorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.NowAuthorized,
      value: authorizedNow,
      scope: upcomingTypes.NowAuthorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.TodayAuthorized,
      value: authorizedToday,
      scope: upcomingTypes.TodayAuthorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.ProvisionallyAuthorized,
      value: provisionallyAuthorized,
      scope: upcomingTypes.ProvisionallyAuthorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.NowProvisionallyAuthorized,
      value: provisionallyAuthorizedNow,
      scope: upcomingTypes.ProvisionallyAuthorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.TodayProvisionallyAuthorized,
      value: provisionallyAuthorizedToday,
      scope: upcomingTypes.TodayProvisionallyAuthorized.scope,
      pathname,
    },
    {
      type: upcomingTypes.TodayPending,
      value: pendingToday,
      scope: upcomingTypes.TodayPending.scope,
      pathname,
    },
    {
      type: upcomingTypes.Pending,
      value: pending,
      scope: upcomingTypes.Pending.scope,
      pathname,
    },
  ];
}

export function getFilteredSurveillance(
  surveillanceData: any,
  alert: any,
  tracking: any,
  isAlertStateFilter: boolean = false
) {
  let filteredSurveillance = [...surveillanceData];

  if (isAlertStateFilter ? !alert.aircraftBreaches : !tracking.airwaysAtmFeed) {
    filteredSurveillance = filteredSurveillance.filter(
      (flight) => flight.type !== AircraftType.Airplane
    );
  }

  if (
    isAlertStateFilter
      ? !alert.operationAreaBreaches
      : !tracking.airshareTracker
  ) {
    filteredSurveillance = filteredSurveillance.filter(
      (flight) => flight.type !== AircraftType.Drone
    );
  }

  return filteredSurveillance;
}

const countFlightFunc =
  (flightData: FlightRequestViewModel[], authorizer: Authorizer) =>
  (mapView: MapView24Hour, statusCode: FRStatusCode) => {
    return flightData.filter((x) =>
      filterFlightStatusTime(x, authorizer, mapView, statusCode)
    ).length;
  };

export function getFlightsInTheNext24Hours(
  flightData: FlightRequestViewModel[],
  authorizer: Authorizer
): FlightCountByStatus {
  const countFlights = countFlightFunc(flightData, authorizer);

  return {
    pending: countFlights(MapView24Hour.NEXT24, FRStatusCode.Pending),
    pendingToday: countFlights(MapView24Hour.TODAY, FRStatusCode.Pending),
    conflicted: countFlights(MapView24Hour.NEXT24, FRStatusCode.Conflicted),
    provisionallyAuthorized: countFlights(
      MapView24Hour.NEXT24,
      FRStatusCode.ProvAuth
    ),
    provisionallyAuthorizedNow: countFlights(
      MapView24Hour.CURRENT,
      FRStatusCode.ProvAuth
    ),
    provisionallyAuthorizedToday: countFlights(
      MapView24Hour.TODAY,
      FRStatusCode.ProvAuth
    ),
    authorized: countFlights(MapView24Hour.NEXT24, FRStatusCode.Authorized),
    authorizedNow: countFlights(MapView24Hour.CURRENT, FRStatusCode.Authorized),
    authorizedToday: countFlights(MapView24Hour.TODAY, FRStatusCode.Authorized),
    activated: countFlights(MapView24Hour.NEXT24, FRStatusCode.Activated),
    activatedNow: countFlights(MapView24Hour.CURRENT, FRStatusCode.Activated),
    activatedToday: countFlights(MapView24Hour.TODAY, FRStatusCode.Activated),
    loaded: true,
  };
}

export default {
  upcomingTypes,
  getCounterData,
  getupcomingData,
  getFilteredSurveillance,
};
