import React from 'react';
import { DateTime } from 'luxon';
import { Delete } from '@material-ui/icons';
import {
  TableCell,
  TableRow,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ManagedAirspaceViewModel } from 'fims-api-types';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
import { ManagedAreaCodeLabels } from './managed-airspace-constants';
import EmergencyAirspaceClosureEnabledIcon from '../emergency-airspace-closure/emergency-airspace-closure-enabled-icon';

interface ManagedAirspaceTableRowProps {
  managedAirspace: ManagedAirspaceViewModel;
  isAdmin: boolean;
  handleSelectAirspace: (event: any) => void;
  deleteAirspace: (event: any) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameGroup: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    nameText: {
      alignItems: 'center',
      paddingRight: theme.spacing(1),
    },
  })
);

const ManagedAirspaceTableRow: React.FC<ManagedAirspaceTableRowProps> = ({
  managedAirspace: ma,
  isAdmin,
  handleSelectAirspace,
  deleteAirspace,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow
        className="table__row"
        data-testid="manage-airspace-table-row"
        key={ma.id}
        onClick={handleSelectAirspace}
      >
        <TableCell data-testid="manage-airspace-table-name">
          {ma?.properties?.code === ManagedAreaCode.EmergencyAirspaceClosure ? (
            <div className={classes.nameGroup}>
              <div className={classes.nameText}>{ma?.properties?.name}</div>
              <EmergencyAirspaceClosureEnabledIcon />
            </div>
          ) : (
            ma?.properties?.name
          )}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-description">
          {ma?.properties?.description}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-code">
          {ma?.properties?.code
            ? ManagedAreaCodeLabels[ma?.properties?.code]
            : ''}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-start-date">
          {ma?.properties?.startDateTime
            ? DateTime.fromISO(ma?.properties?.startDateTime).toFormat(
                'yyyy-LL-dd HH:mm'
              )
            : undefined}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-end-date">
          {ma?.properties?.endDateTime
            ? DateTime.fromISO(ma?.properties?.endDateTime).toFormat(
                'yyyy-LL-dd HH:mm'
              )
            : undefined}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-lower-limit">
          {ma?.properties?.lowerLimitFeet ||
          ma?.properties?.lowerLimitFeet === 0
            ? `${ma?.properties?.lowerLimitFeet} ft`
            : 'N/A'}
        </TableCell>
        <TableCell data-testid="manage-airspace-table-upper-limit">
          {ma.properties.code !== ManagedAreaCode.Aerodrome &&
          (ma?.properties?.upperLimitFeet ||
            ma?.properties?.upperLimitFeet === 0)
            ? `${ma?.properties?.upperLimitFeet} ft`
            : 'N/A'}
        </TableCell>

        <TableCell data-testid="manage-airspace-table-delete-icon">
          {isAdmin &&
            ma?.properties?.code !==
              ManagedAreaCode.EmergencyAirspaceClosure && (
              <IconButton
                onClick={deleteAirspace}
                data-testid="manage-airspace-delete-button"
              >
                <Delete />
              </IconButton>
            )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ManagedAirspaceTableRow;
