import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  type StyledFeature,
  formatSegmentClearanceStatus,
  formatSegmentStatus,
} from 'fims-api-types';

import { segmentStatusIcon } from './StatusPills';

import { getFormattedDateTime } from '../timehelpers';
import { subListItemStyle } from './styles';

import '../flight.scss';
import { AlarmClockColoured } from '../../../../lib/icons';

interface Props {
  timezone: string;
  properties: StyledFeature['properties'];
  segmentsOpen: string[];
  setSegmentsOpen: (segments: string[]) => void;
}

export const Segment = ({
  timezone,
  properties,
  segmentsOpen,
  setSegmentsOpen,
}: Props) => {
  const open = segmentsOpen?.includes(properties.segmentId);

  return (
    <>
      <ListItem
        style={subListItemStyle}
        dense
        button
        autoFocus={open}
        onClick={() => {
          setSegmentsOpen(
            segmentsOpen?.includes(properties.segmentId)
              ? segmentsOpen?.filter(
                  (segment) => segment !== properties.segmentId
                )
              : [...segmentsOpen, properties.segmentId]
          );
        }}
        data-testid={`flight-segment-heading-${properties.segmentId}`}
      >
        <ListItemText secondary={properties.segmentId} />
        {segmentStatusIcon(properties)}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <table
          className="flight-info-table"
          data-testid={`flight-segments-table-${properties.segmentId}`}
        >
          <tbody>
            <tr>
              <th>Segment Id</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-id-value`}
              >
                {properties.segmentId}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-status-value`}
              >
                {formatSegmentStatus(properties.status)}
              </td>
            </tr>
            <tr>
              <th>Activated Status</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-clearance-value`}
              >
                {formatSegmentClearanceStatus(properties.clearance)}
              </td>
            </tr>
            <tr>
              <th>Start</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-start-value`}
              >
                {' '}
                {getFormattedDateTime(properties.localStartDateTime, timezone)}
              </td>
            </tr>
            <tr>
              <th>Finish</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-finish-value`}
              >
                {properties?.exceededAllocatedTime ? AlarmClockColoured : null}{' '}
                {getFormattedDateTime(properties.localEndDateTime, timezone)}
              </td>
            </tr>
            <tr>
              <th>Max Altitude AGL (ft)</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-altitude-agl-value`}
              >
                {properties.altitude}
              </td>
            </tr>
            <tr>
              <th>Street Address</th>
              <td
                data-testid={`flight-segments-table-${properties.segmentId}-street-address`}
              >
                {properties.segmentStreetAddress}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </>
  );
};

export default Segment;
