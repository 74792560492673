import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import startCase from 'lodash/startCase';
import Typography from '@material-ui/core/Typography';

import LoginForm from './login-form/login-form';
import { ResetPasswordForm } from './reset-password/reset-password';
import Logo from '../protected-layout/application-bar/logo.png';
import './auth-layout.scss';

export const AuthLayout = () => {
  const { pathname } = useLocation();
  const heading = startCase(pathname.replace('/', ''));

  return (
    <div className="auth-layout">
      <div className="auth-layout__form-container">
        <div className="auth-layout__form-header">
          <img src={Logo} alt="logo" className="logo" data-testid="logo" />
        </div>
        <div className="auth-layout__heading" data-testid="heading">
          <Typography variant="h5">{heading}</Typography>
        </div>
        <Routes>
          <Route path="/sign-in" element={<LoginForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthLayout;
