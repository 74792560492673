import { useCallback, useEffect, useState } from 'react';
import { OrganisationSettingsResponseBody } from 'fims-api-types';

import { useProfile } from '../../state/session';
import * as fimsClient from '../../clients/fims-api-client';

export const useOrganisationSettings = () => {
  const { organisation } = useProfile();
  const [settings, setSettings] = useState<
    OrganisationSettingsResponseBody | undefined
  >(undefined);

  const getOrg = useCallback(async () => {
    if (organisation) {
      const resp = await fimsClient.getOrganisationSettings(organisation);
      setSettings(resp);
    }
  }, [organisation]);

  useEffect(() => {
    getOrg();
  }, [getOrg, organisation]);

  return { settings };
};
