import type { Polygon, MultiPolygon } from 'geojson';
import { createContext } from 'react';
import type { Socket } from 'socket.io-client';

import { MapHelpers } from '../components/protected-layout/home-screen/map/hooks/helpers';
import { MapStyleCode } from '../state/map-styles';

export enum MapView24Hour {
  CURRENT = 'current',
  TODAY = 'today',
  NEXT24 = 'next24',
  DATE = 'date',
}

export interface LoginState {
  mapHelpers: MapHelpers | null;
  setMapHelpers: (mapHelpers: MapHelpers | null) => void;
  mapStyleCode: MapStyleCode;
  setMapStyleCode: (mapStyleCode: MapStyleCode) => void;
  mapView24hour: MapView24Hour;
  setMapView24hour: (mapDefault: MapView24Hour) => void;
  mapViewDateTimeRange: [Date, Date];
  setMapViewDateTimeRange: (mapViewDateTimeRange: [Date, Date]) => void;
  showSecondaryNOA: boolean;
  toggleShowSecondaryNOA: () => void;
  isCreatingAirspace: boolean;
  setIsCreatingAirspace: (isCreating: boolean) => void;
  selectedAirspace?: Polygon | MultiPolygon | null;
  setSelectedAirspace: (area: Polygon | MultiPolygon | null) => void;
  airspaceIsClosed: boolean;
  setAirspaceIsClosed: (isClosed: boolean) => void;
  userCanUpdateEmergencyAirspaceClosure: boolean;
  setUserCanUpdateEmergencyAirspaceClosure: (canLand: boolean) => void;
  forceReloadMap: boolean;
  setForceReloadMap: (foreceReloadMapAreas: boolean) => void;
  socket: Socket | null;
  setSocket: React.Dispatch<React.SetStateAction<Socket | null>>;
}

export const defaultLoginState: LoginState = {
  mapHelpers: null,
  setMapHelpers: (_: MapHelpers | null) => {},
  mapStyleCode: null,
  setMapStyleCode: (_: MapStyleCode) => {},
  mapView24hour: MapView24Hour.TODAY,
  setMapView24hour: (_: MapView24Hour) => {},
  mapViewDateTimeRange: [new Date(), new Date()],
  setMapViewDateTimeRange: (_: [Date, Date]) => {},
  showSecondaryNOA: false,
  toggleShowSecondaryNOA: () => {},
  isCreatingAirspace: false,
  setIsCreatingAirspace: (_: boolean) => {},
  selectedAirspace: null,
  setSelectedAirspace: (_: Polygon | MultiPolygon | null) => {},
  airspaceIsClosed: false,
  setAirspaceIsClosed: (_: boolean) => {},
  userCanUpdateEmergencyAirspaceClosure: false,
  setUserCanUpdateEmergencyAirspaceClosure: (_: boolean) => {},
  forceReloadMap: false,
  setForceReloadMap: (_: boolean) => {},
  socket: null,
  setSocket: () => {},
};

export const LoginContext = createContext<LoginState>(defaultLoginState);
