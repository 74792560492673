import { useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { alertAudio } from './audio';

const SoundPlayer = () => {
  const audio: HTMLAudioElement = useMemo(
    () => new Audio(`data:audio/wav;base64, ${alertAudio}`),
    []
  );
  audio.muted = false;

  const stopAudio = () => {
    audio.pause();
    audio.currentTime = 0;
  };

  return async () => {
    try {
      stopAudio();
      await audio.play();
      audio.addEventListener('ended', () => stopAudio());
    } catch (error) {
      const errMessage = `Error playing alert audio: ${error.message}`;
      Sentry.captureException(errMessage);
      console.error(errMessage);
    }
  };
};

export default SoundPlayer;
