import { POINT_MIN_ZOOM } from 'argus-data-model/db/schemas/map-points/map-code-properties';
import { useEffect, useRef, useCallback, useContext } from 'react';

import hangGlidingIcon from './images/hang-gliding.png';
import parachutingIcon from './images/parachuting.png';
import * as contentAPI from '~/clients/content-api-client';
import { LoginContext } from '~/context/LoginState';
import { useInterval } from '~/state/custom-hooks/useInterval';

interface MapLayerCache {
  mapCode: string;
  dataLayer?: google.maps.Data;
  mapLayerZoomThreshold?: number;
}

const styleProps = [
  'mapCode',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'strokeWidth',
  'fillColor',
  'fillOpacity',
];

const ALL_AIRSPACES = ['ctr', 'moa', 'ad', 'd', 'lfz', 'r', 'nfz', 'tl'];
const ALL_POINTS = ['hang-gliding', 'parachuting'];
const MAP_LAYERS_RELOAD_INTERVAL = 30000;

export function useMapLayers(
  google: any,
  map: google.maps.Map | null,
  zoom?: number | null
) {
  const { forceReloadMap, setForceReloadMap } = useContext(LoginContext);

  const dataLayersCache = useRef<MapLayerCache[]>();
  const prevDataLayersCache = useRef<MapLayerCache[]>();

  useEffect(() => {
    dataLayersCache.current = [];
    prevDataLayersCache.current = [];
  }, []);

  const loadDataLayer = useCallback(
    (mapCode: string): Promise<void> => {
      return new Promise((resolve) => {
        const dataLayer: google.maps.Data = new google.maps.Data({});

        contentAPI
          .getAdvisoryLayer(mapCode)
          .then((featureCollection) => {
            dataLayer.addGeoJson(featureCollection);
            let mapLayerZoomThreshold = 0;

            dataLayer.setStyle((feature: google.maps.Data.Feature) => {
              const mz = feature.getProperty('mapZoom');

              mapLayerZoomThreshold =
                mz && mz > mapLayerZoomThreshold ? mz : mapLayerZoomThreshold;

              return styleProps.reduce(
                (acc1, prop) => ({
                  ...acc1,
                  [prop]: feature.getProperty(prop),
                }),
                {
                  icon: {
                    url:
                      feature.getProperty('code') === 'hang-gliding'
                        ? hangGlidingIcon
                        : parachutingIcon,
                  },
                  title: feature.getProperty('name'),
                }
              );
            });

            dataLayer.setMap(map);

            const cachedLayer = dataLayersCache.current.find(
              (dl) => dl.mapCode === mapCode
            );
            cachedLayer.dataLayer = dataLayer;
            cachedLayer.mapLayerZoomThreshold = mapLayerZoomThreshold;

            const prevCachedLayer = prevDataLayersCache.current.find(
              (dl) => dl.mapCode === mapCode
            );
            prevCachedLayer?.dataLayer?.setMap(null);
            prevDataLayersCache.current = prevDataLayersCache.current.filter(
              (dl) => dl.mapCode !== mapCode
            );

            resolve();
          })
          .catch((e) => {
            // ignore when content API returns 400 if no areas are found
            if (e.response && e.response.status !== 400) {
              console.error(e);
            }
            resolve();
          });
      });
    },
    [google, map]
  );

  const onMapCodesChange = useCallback(async () => {
    const existingMapCodes = dataLayersCache.current.map((dl) => dl.mapCode);

    const allLayers =
      zoom >= POINT_MIN_ZOOM ? ALL_AIRSPACES.concat(ALL_POINTS) : ALL_AIRSPACES;

    const newMapCodes = allLayers.filter((mc) => {
      if (existingMapCodes.includes(mc)) {
        return false;
      } else {
        dataLayersCache.current.push({ mapCode: mc });
        return true;
      }
    });

    if (newMapCodes.length > 0) {
      const loadPromises = newMapCodes.map(loadDataLayer);
      await Promise.all(loadPromises);
    }

    dataLayersCache.current.forEach(({ dataLayer, mapCode }) => {
      const m = allLayers.includes(mapCode) ? map : null;
      dataLayer?.setMap(m);
    });

    setForceReloadMap(false);
  }, [zoom, setForceReloadMap, loadDataLayer, map]);

  useEffect(() => {
    if (forceReloadMap) {
      dataLayersCache.current.forEach((dl) =>
        prevDataLayersCache.current.push(dl)
      );
      dataLayersCache.current = [];
      if (google && map) {
        onMapCodesChange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReloadMap]);

  useInterval(() => {
    if (google && map) {
      dataLayersCache.current.forEach((dl) =>
        prevDataLayersCache.current.push(dl)
      );
      dataLayersCache.current = [];
      onMapCodesChange();
    }
  }, MAP_LAYERS_RELOAD_INTERVAL);

  useEffect(() => {
    if (google && map) {
      onMapCodesChange();
    }
  }, [google, map, onMapCodesChange]);
}
