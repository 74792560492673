import type { Geometry, Position, MultiPolygon, Polygon } from '@turf/helpers';
import type { LatLng } from '@airshare/external-api-types';

export const geometryToGooglePaths = (
  geo?: Geometry
): google.maps.PolygonOptions['paths'] => {
  const points: google.maps.PolygonOptions['paths'] = [];
  if (geo?.type === 'MultiPolygon') {
    const positionArray = geo.coordinates as MultiPolygon['coordinates'];
    positionArray.forEach((polygonList) => {
      const inner: LatLng[] = [];
      polygonList.forEach((polygon: Position[]) => {
        polygon.forEach((coords: Position) => {
          inner.push({
            lng: coords[0],
            lat: coords[1],
          });
        });
      });
      points.push(inner);
    });
  }
  if (geo?.type === 'Polygon' || geo?.type === 'MultiLineString') {
    const positionArray = geo.coordinates as Polygon['coordinates'];

    positionArray.forEach((polygon: Position[]) => {
      const inner: LatLng[] = [];
      polygon.forEach((coords: Position) => {
        inner.push({
          lng: coords[0],
          lat: coords[1],
        });
      });
      points.push(inner);
    });
  }

  return points;
};
