import React from 'react';
import { OrganisationResponse } from 'fims-api-types';

import { deleteOrganisation } from '../../../clients/fims-api-client';
import DeleteGenericModal from '../common/delete-generic-modal';

interface OrgModalProps {
  closeModal(): any;
  organisation: OrganisationResponse;
}

function DeleteModal({ closeModal, organisation }: OrgModalProps) {
  const { id, name } = organisation;
  const handleSubmit = async (): Promise<void> => {
    await deleteOrganisation({ id });
  };
  return (
    <DeleteGenericModal
      closeModal={closeModal}
      title="Delete Organisation"
      text={`Do you want to continue deleting the organisation "${name}" ?`}
      onSubmit={handleSubmit}
    />
  );
}

export default DeleteModal;
