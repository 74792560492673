import {
  FRStatusCode,
  FlightStatusColorCode,
  getStatusColor,
} from 'fims-api-types';
import { DecisionStatusEnum } from 'argus-api-shared/lib/constants';

export interface Status {
  authorizer: string;
  code: FRStatusCode;
  actionRequired: DecisionStatusEnum;
  decisionMakerName: string;
  decisionMakerId?: string;
  decisionMakerControlZone?: string;
  decisionMakerPhone?: string;
  canAuthorize: boolean;
}

export const enum SettingsModalTitles {
  DIGITAL_AUTHORIZATION = 'Digital Authorisation',
  DIGITAL_ACTIVATION = 'Digital Activation',
  ORGANISATION_SETTINGS = 'Organisation Settings',
  CTR_ZONES = 'CONTROL ZONES',
}

export enum ColorCode {
  Breach = '#d32f2f',
  Alert = '#f57c00',
  Safe = '#388e3c',
}

export function colorFromType(type: string): ColorCode | FlightStatusColorCode {
  switch (type) {
    case 'Authorised':
      return getStatusColor(FRStatusCode.Authorized);
    case 'Pending Now':
      return getStatusColor(FRStatusCode.Pending);
    case 'Prov. Auth.':
    case 'Prov. Auth. Now':
      return getStatusColor(FRStatusCode.ProvAuth);
    case 'Alert':
      return ColorCode.Alert;
    case 'Breach':
      return ColorCode.Breach;
    case 'Safe':
      return ColorCode.Safe;
  }

  return getStatusColor(type as FRStatusCode);
}

export const getStatusClassnames = (code?: FRStatusCode) => [
  { 'is-provisionally-authorized': code === FRStatusCode.ProvAuth },
  { 'is-activated': code === FRStatusCode.Activated },
  { 'is-authorized': code === FRStatusCode.Authorized },
  { 'is-conflicted': code === FRStatusCode.Conflicted },
  { 'is-cancelled': code === FRStatusCode.Cancelled },
  { 'is-terminated': code === FRStatusCode.Terminated },
  { 'is-pending': code === FRStatusCode.Pending },
  { 'is-declared': code === FRStatusCode.Declared },
  { 'is-declined': code === FRStatusCode.Declined },
];
