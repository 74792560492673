export enum FocussedFlightRequestFetchStatus {
  IDLE = 'IDLE',
  FOCUSSED_FETCH_IN_PROGRESS = 'FOCUSSED_FETCH_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum FormInputStatus {
  IDLE = 'IDLE',
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export enum FormStatus {
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  SUBMITTED = 'SUBMITTED',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
}
