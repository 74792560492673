import React from 'react';
import { MenuItem } from '@material-ui/core';

import { TimeFrame } from 'argus-common/flight-requests.lib';
import FilterDropdown from './filter-dropdown';
import { menuStyles } from './menu-styles';

interface Props {
  open: string;
  anchorRefTimeframe: React.MutableRefObject<HTMLButtonElement>;
  handleTimeframeClose: (event: React.MouseEvent<EventTarget>) => void;
  handleToggleTimeframe: () => void;
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}

export const TimeFrameSelector = ({
  open,
  anchorRefTimeframe,
  handleToggleTimeframe,
  handleTimeframeClose,
  timeframe,
  setTimeframe,
}: Props) => {
  const classes = menuStyles();

  const menuListItems = [TimeFrame.all, TimeFrame.past, TimeFrame.future].map(
    (label) => (
      <MenuItem
        key={label}
        className={classes.menuItem}
        selected={timeframe?.includes(label)}
        onClick={() => setTimeframe(label)}
        data-testid="timeframe-list-item"
      >
        {label}
      </MenuItem>
    )
  );

  return (
    <FilterDropdown
      buttonText="Time"
      open={open}
      openItem="timeframe"
      anchorRef={anchorRefTimeframe}
      closeButton={handleTimeframeClose}
      toggleButton={handleToggleTimeframe}
      menuListItems={menuListItems}
    />
  );
};

export default TimeFrameSelector;
