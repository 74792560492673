export const UNIVERSAL_DATE_FORMAT = 'yyyy-MM-dd';
export const UNIVERSAL_TIME_FORMAT = 'HH:mm';
export const UNIVERSAL_DATE_TIME_FORMAT = `${UNIVERSAL_DATE_FORMAT} ${UNIVERSAL_TIME_FORMAT}`; // locale independent date string

// HARD CODED to nz date format at the moment.
// Need to modify in the future to account for
// the user's time zone, or do date time display
// formatting on the client(s).
export const DATE_FORMAT = 'DD/MM/YY';
export const DATE_TIME_FORMAT = `${UNIVERSAL_TIME_FORMAT} ${DATE_FORMAT}`;
