import { createSelector } from 'reselect';
import { AppState } from '../store';

const getLocalState = (state: AppState) => state.settings;

export const getGlobalSettings = createSelector(
  [getLocalState],
  (state) => state.global
);

export const getGlobalFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);
