import React from 'react';
import classnames from 'classnames';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: 10,
    },
    submitButton: {
      minWidth: 145,
    },
    buttonPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
      marginRight: theme.spacing(1),
    },
  })
);

interface Props {
  disabled: boolean;
  add: boolean;
  onCancel: () => void;
  onConfirm: (event: React.UIEvent) => Promise<void>;
  isLoading: boolean;
}

export default function EditAreaButtons({
  disabled,
  add,
  onCancel,
  onConfirm,
  isLoading,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.buttonPanel}>
      <Button
        test-id="edit-area-cancel-button"
        className={classes.button}
        onClick={() => onCancel()}
        variant="outlined"
        color="primary"
        role="button"
        data-testid="edit-area-cancel-button"
      >
        Cancel
      </Button>

      <Button
        className={classnames(classes.button, classes.submitButton)}
        onClick={onConfirm}
        disabled={disabled}
        variant="contained"
        color="primary"
        data-testid="edit-area-confirm-button"
      >
        {isLoading ? (
          <CircularProgress
            data-testid="edit-airspace-progress"
            size={24}
            color="inherit"
          />
        ) : add ? (
          'Create Managed Area'
        ) : (
          'Save Changes'
        )}
      </Button>
    </div>
  );
}
