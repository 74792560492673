import { useEffect, useState, useCallback } from 'react';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import {
  AircraftType,
  AlertStatus,
  DroneType,
  DroneTypeColour,
} from 'argus-common/enums';

import { SurveillanceViewModel } from 'fims-api-types';
import { getHighestAlertStatus } from 'argus-common/helpers';

import {
  getAircraftSymbol,
  getDroneSymbol,
} from 'asm-web-components/src/lib/map/marker-helpers';

let surveillanceMarkers: any[] = [];

export function useMapSurveillance(
  google: any,
  mapInstance: any,
  surveillanceData: SurveillanceViewModel[] = [],
  zoom: number,
  infoWindow: any,
  onMarkerClick: any
) {
  const [infoWindowTypes] = useState(infoWindow.supportedTypes);

  const labelColor = 'white';

  const createMarker = useCallback(
    (
      type: AircraftType,
      lat: number,
      lng: number,
      heading: number | null,
      status: AlertStatus,
      droneType: DroneType,
      surveillanceItem: { flightId?: number },
      labelContent: string | HTMLElement,
      flightId: number
    ) => {
      if (type === AircraftType.Drone) {
        const marker = new MarkerWithLabel({
          map: mapInstance,
          position: new google.maps.LatLng(lat, lng),
          icon: getDroneSymbol(heading, zoom, status, droneType),
          title: `Flight: ${surveillanceItem.flightId}`,
          labelContent,
          labelAnchor: new google.maps.Point(-25, 20),
        } as any);
        marker.set('flightId', surveillanceItem.flightId);
        return marker;
      }
      const marker = new google.maps.Marker({
        map: mapInstance,
        google,
        position: { lng, lat },
        icon: getAircraftSymbol(heading, mapInstance.getZoom(), status),
        title: `Flight: ${surveillanceItem.flightId}`,
        label:
          zoom > 11
            ? {
                text: '',
                color: labelColor,
                className: 'flight-surveillance-label',
              }
            : '',
      });
      marker.set('flightId', surveillanceItem.flightId);

      if (infoWindowTypes.includes(type)) {
        infoWindow.setup(surveillanceItem, marker, status);
      }

      google.maps.event.addListener(marker, 'click', () =>
        onMarkerClick(flightId, type)
      );

      return marker;
    },
    [
      google,
      infoWindow,
      infoWindowTypes,
      mapInstance,
      onMarkerClick,
      zoom,
      labelColor,
    ]
  );

  useEffect(() => {
    if (google && mapInstance && surveillanceData) {
      cleanUpMarkers(surveillanceData);

      surveillanceData.forEach((surveillanceItem) => {
        const existingMarker = surveillanceMarkers.find(
          ({ flightId }) => surveillanceItem.flightId === flightId
        );

        const {
          flightId,
          lng,
          lat,
          heading,
          type,
          alertConformanceStatus,
          alertProximityStatus,
          altitude,
          speed,
        } = surveillanceItem;

        if (lng === null || lat === null) {
          return;
        }

        const droneType: DroneType =
          surveillanceItem?.traceProperties?.source ===
          'ExternalAPIDroneDetection'
            ? DroneType.Unknown
            : DroneType.Known;

        const status = getHighestAlertStatus([
          alertConformanceStatus,
          alertProximityStatus,
        ]);

        const labelContent =
          zoom > 11
            ? `<div style="font-size: 12px; text-align: center; color:${
                droneType === DroneType.Known
                  ? 'white'
                  : DroneTypeColour.Unknown
              };">#${
                surveillanceItem.flightId
                  ? `${surveillanceItem.flightId}<br/>`
                  : ''
              }${
                altitude || altitude === 0 || speed || speed === 0
                  ? `${
                      altitude || altitude === 0
                        ? `${Math.round(altitude)}ft`
                        : ''
                    }
              ${
                speed || speed === 0 ? ` ${Math.round(speed)}kt<br/>` : '<br/>'
              }`
                  : ''
              }${
                heading
                  ? `${'0'.repeat(
                      3 - `${Math.round(heading)}`.length
                    )}${Math.round(heading)}°`
                  : ''
              }</div>`
            : '';

        if (existingMarker) {
          if (type === AircraftType.Drone) {
            existingMarker.setOptions({
              icon: getDroneSymbol(heading, zoom, status, droneType),
              labelContent,
              labelAnchor: new google.maps.Point(-25, 20),
              position: new google.maps.LatLng(lat, lng),
            });
          } else {
            const [labelDisplay, labelClass] = getLabelForFlight(
              mapInstance.getZoom(),
              altitude,
              speed,
              heading
            );
            existingMarker.setOptions({
              position: { lng, lat },
              icon: getAircraftSymbol(heading, mapInstance.getZoom(), status),
              title: `Flight: ${surveillanceItem.flightId}`,
              label:
                mapInstance.getZoom() > 11
                  ? {
                      text: labelDisplay,
                      color: labelColor,
                      className: labelClass,
                    }
                  : '',
            });
          }

          if (infoWindowTypes.includes(type)) {
            infoWindow.setup(surveillanceItem, existingMarker, status);
          }

          if (!isWithinViewport(mapInstance, lat, lng)) {
            existingMarker.setMap(null);

            const index = surveillanceMarkers.indexOf(existingMarker);
            surveillanceMarkers.splice(index, 1);
          }

          return;
        }

        if (isWithinViewport(mapInstance, lat, lng)) {
          const newMarker = createMarker(
            type,
            lat,
            lng,
            heading,
            status,
            droneType,
            surveillanceItem,
            labelContent,
            flightId
          );

          surveillanceMarkers.push(newMarker);
        }
      });
    }
  }, [
    google,
    mapInstance,
    surveillanceData,
    zoom,
    onMarkerClick,
    infoWindow,
    infoWindowTypes,
    createMarker,
    labelColor,
  ]);
}

function cleanUpMarkers(surveillanceData: SurveillanceViewModel[]) {
  if (surveillanceData.length === 0) {
    removeMarkers();
    return;
  }

  removeStaleMarkers(surveillanceData);
}

function removeMarkers() {
  surveillanceMarkers.forEach((marker: any) => {
    marker.setMap(null);
  });

  surveillanceMarkers = [];
}

function removeStaleMarkers(surveillanceData: SurveillanceViewModel[]) {
  surveillanceMarkers.forEach((marker) => {
    const isMarkerStale = !surveillanceData.find(
      ({ flightId }) => flightId === marker.flightId
    );

    if (isMarkerStale) {
      marker.setMap(null);

      const index = surveillanceMarkers.indexOf(marker);
      surveillanceMarkers.splice(index, 1);
    }
  });
}

function isWithinViewport(mapInstance: any, lat: number, lng: number) {
  const viewport = mapInstance.getBounds();
  return viewport ? viewport.contains({ lat, lng }) : false;
}

function getLabelForFlight(
  zoom: number,
  altitude: number,
  speed: number,
  heading: number
) {
  return [
    getLabelTextForFlight(zoom, altitude, speed),
    getLabelClassForHeading(heading),
  ];
}

function getLabelTextForFlight(zoom: number, altitude: number, speed: number) {
  if (zoom > 11) {
    const knots = speed * 1.94384;
    return `${Math.round(altitude)}ft ${Math.round(knots)}kt`;
  }
  return '';
}

function getLabelClassForHeading(heading: number) {
  if (heading >= 23 && heading < 68) {
    return 'flight-surveillance-label heading-045';
  } else if (heading >= 68 && heading < 113) {
    return 'flight-surveillance-label heading-090';
  } else if (heading >= 113 && heading < 158) {
    return 'flight-surveillance-label heading-135';
  } else if (heading >= 158 && heading < 203) {
    return 'flight-surveillance-label heading-180';
  } else if (heading >= 203 && heading < 248) {
    return 'flight-surveillance-label heading-225';
  } else if (heading >= 248 && heading < 293) {
    return 'flight-surveillance-label heading-270';
  } else if (heading >= 293 && heading < 338) {
    return 'flight-surveillance-label heading-315';
  }
  return 'flight-surveillance-label heading-000';
}
