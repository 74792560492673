import { cancel, take, fork } from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { LOG_OUT } from 'airshare-web-utils/redux-helpers';

import { adminSaga } from './admin/saga';
import { conflictedFlightsSaga } from './conflicted-flights/saga';
import { settingsSaga } from './settings/saga';
import { sessionSaga } from './session/saga';
import { flightAreaSaga } from './flight-area/saga';
import { focussedFlightRequestSaga } from './focussed-flight-request/saga';
import { SessionActionType } from './session/actions';
import { flightRequestsWithFlightAreaSaga } from './flight-requests-with-flight-area';
import { mapStylesSaga } from './map-styles/saga';

export function* rootSaga() {
  yield fork(sessionSaga);

  while (true) {
    const { payload } = yield take(SessionActionType.SET_PROFILE);

    if (payload) {
      const tasks: Task[] = [];

      tasks.push(yield fork(adminSaga));
      tasks.push(yield fork(mapStylesSaga));
      tasks.push(yield fork(flightAreaSaga));
      tasks.push(yield fork(settingsSaga));
      tasks.push(yield fork(focussedFlightRequestSaga));
      tasks.push(yield fork(conflictedFlightsSaga));
      tasks.push(yield fork(flightRequestsWithFlightAreaSaga));

      yield take(LOG_OUT);

      yield cancel(tasks);
    }
  }
}

export const sagaConfig = {};
