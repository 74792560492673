import React from 'react';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      margin: 'auto',
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    cancel: {
      float: 'right',
      margin: '8px 8px 8px auto',
      width: 158,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
    confirm: {
      float: 'right',
      margin: '8px auto 8px 0',
      width: 158,
      backgroundColor: theme.palette.primary.main,
      '&:disabled': {
        backgroundColor: 'rgba(100,100,100,0.5)',
      },
    },
  })
);

export default function ConfirmAreaButtons({
  onConfirm,
  isDisabled = true,
  onClose,
}: {
  onConfirm: () => void;
  isDisabled: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.container}
    >
      <Button
        aria-label="cancel"
        className={classes.cancel}
        variant="outlined"
        color="primary"
        disabled={false}
        onClick={() => onClose()}
        startIcon={<Cancel />}
        data-testid="cancel-button"
      >
        Cancel
      </Button>
      <Button
        aria-label="confirm"
        className={classes.confirm}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={() => onConfirm()}
        startIcon={<CheckCircle />}
        data-testid="confirm-button"
      >
        Confirm
      </Button>
    </Grid>
  );
}
