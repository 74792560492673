import axios from 'axios';

// Hold the promise that will refresh the token so that multiple threads can await this to ensure only one refresh is done
let refreshPromise = null;
const clearPromise = () => (refreshPromise = null);

export default (baseURL) => {
  const api = axios.create({
    baseURL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest', // This must be set...see CSRF attack
    },
  });

  api.defaults.headers.Accept = 'application/json';
  api.defaults.headers['Content-Type'] = 'application/json';
  api.defaults.timeout = 20000;

  function getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  function setTokens({ profile, refreshToken }) {
    localStorage.setItem('profile', JSON.stringify(profile));
    localStorage.setItem('refreshToken', refreshToken);
    setAuthHeader(profile.token);
  }

  async function refreshToken(tokenRefreshFullUrl, refreshToken, origin) {
    const res = await axios.post(tokenRefreshFullUrl, {
      refreshToken,
      origin,
    });

    if (res.status === 201) {
      setTokens(res.data);
    }

    return res.data.profile.token;
  }

  const onRequestAddAuthHeader = (callback) => {
    api.interceptors.request.use(
      (config) => {
        const token = callback();
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  };

  const handleErrorStatus = (
    status,
    callback,
    origin,
    tokenRefreshFullUrl,
    allowableFailureEndpoint
  ) => {
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (!(error && error.response)) {
          return Promise.reject(error);
        }

        if (error.response.status !== status) {
          // Not an unauthentication error
          return Promise.reject(error);
        }

        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          originalRequest.url === tokenRefreshFullUrl
        ) {
          // We've already tried to refresh the token
          callback();
          return Promise.reject(error);
        }

        if (!refreshPromise) {
          refreshPromise = refreshToken(
            tokenRefreshFullUrl,
            getRefreshToken(),
            origin
          )
            .catch((e) => {
              if (
                allowableFailureEndpoint &&
                originalRequest?.url?.endsWith(allowableFailureEndpoint)
              ) {
                return Promise.reject(e);
              }
              return callback(e, tokenRefreshFullUrl);
            })
            .finally(clearPromise);
        }

        const token = await refreshPromise;

        // Update the bearer token and try again
        let request = {
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${token}`,
          },
        };
        return axios(request);
      }
    );
  };

  const setAuthHeader = (token) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const clearAuthHeader = () => {
    api.defaults.headers.Authorization = null;
  };

  return {
    onRequestAddAuthHeader,
    handleErrorStatus,
    setAuthHeader,
    clearAuthHeader,
    ...api,
  };
};
