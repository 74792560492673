export enum SegmentStatusCode {
  ACTIVATED = 'Activated',
  TERMINATED = 'Terminated',
  IDLE = 'Idle',
}

export enum SegmentClearanceStatusCode {
  APPROVED = 'Approved',
  REQUESTED = 'Requested',
  REQUIRED = 'Required',
  NOTREQUIRED = 'NotRequired',
}
