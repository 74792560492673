import React, { ReactElement } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

interface GoogleApiLoaderProps {
  children: React.JSX.Element;
  className: string;
}

function GoogleApiLoader({ children, className }: GoogleApiLoaderProps) {
  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <div className={className} />;
    if (status === Status.FAILURE)
      return (
        <div className={className}>
          There was a failure loading the maps. Please reload to try again
        </div>
      );
    return <></>;
  };

  return (
    <Wrapper
      apiKey={window.env.GOOGLE_MAPS_API_KEY}
      render={render}
      libraries={['places', 'drawing', 'geometry', 'marker']}
      version="3.56"
    >
      {children}
    </Wrapper>
  );
}
export default GoogleApiLoader;
