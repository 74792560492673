import { useEffect, useState } from 'react';
import { useWindowSize } from './window-size.hook';

export function useMobileDetection() {
  const windowSize = useWindowSize();

  const [isMobile, updateIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize.width < 600 || windowSize.height < 530) {
      updateIsMobile(true);
    } else {
      updateIsMobile(false);
    }
  }, [windowSize.width, windowSize.height]);

  return isMobile;
}
