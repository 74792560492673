import React from 'react';
import { useOrganisationSettings } from '../../protected-layout/use-organisation-setting.hook';
import DetailMap, { Props } from './detail-map';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

type OrganisationSettingsWrapper = Omit<Props, 'settings'>;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    detailMap: {
      position: 'relative',
      width: '100%',
      height: 'calc(100vh - 82px - var(--header-height))',
      minHeight: '240px',
    },
  })
);
export default function DetailMapSettingsWrapper(
  props: OrganisationSettingsWrapper
) {
  const { settings } = useOrganisationSettings();
  const classes = useStyles();

  if (!settings?.mapDefaults) {
    return <div className={classes.detailMap}></div>;
  }

  return <DetailMap {...props} settings={settings} />;
}
