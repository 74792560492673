import { FlightRule, CertificationType } from '@airshare/external-api-types';

export const mapToEuroRegOperationType = (
  operationType: FlightRule | CertificationType | string
): FlightRule.BVLOS | FlightRule.LOS => {
  switch (operationType) {
    case FlightRule.PART_102:
      return FlightRule.BVLOS;
    case FlightRule.PART_101:
    default:
      return FlightRule.LOS;
  }
};
