import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import {
  Cluster,
  defaultOnClusterClickHandler,
} from '@googlemaps/markerclusterer';
import { List, ListItem, ListItemText } from '@material-ui/core';
import './cluster-marker-info.scss';
import { closeActiveInfoWindows } from './helpers';

function getClusterMarkup(flightIds: string[]) {
  const renderListItem = (flightId: string) => (
    <ListItemText
      id={`cluster-marker-flight-${flightId}`}
      data-testid="cluster-marker-flight-item"
      className="cluster-marker-flight-item"
      style={{ cursor: 'pointer', margin: '2px' }}
      key={flightId}
      primaryTypographyProps={{ style: { color: 'white' } }}
      primary={`#${flightId}`}
    />
  );
  return (
    <List
      dense
      className="cluster-marker-info-window"
      id="flight-cluster-marker-info"
      data-testid="flight-cluster-marker-info"
    >
      <ListItem
        id="cluster-marker-info-flights-title"
        data-testid="cluster-marker-info-flights-title"
        style={{
          display: 'flex',
          textAlign: 'left',
          margin: '0px 0px 4px 0px',
          padding: '0px',
        }}
      >
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{
            style: {
              color: 'white',
              fontWeight: 'bold',
            },
          }}
          primary={'Flight IDs:'}
        />
      </ListItem>
      <ListItem
        id="cluster-marker-flights-list"
        data-testid="cluster-marker-flights-list"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderTop: '1px solid white',
          borderBottom: '1px solid white',
        }}
      >
        {flightIds
          .sort((a, b) => Number.parseInt(a) - Number.parseInt(b))
          .map(renderListItem)}
      </ListItem>
      <ListItem
        id="cluster-marker-info-zoom"
        data-testid="cluster-marker-info-zoom"
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          width: '68%',
          padding: '0px',
          margin: '8px auto -4px auto',
          cursor: 'pointer',
        }}
      >
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{
            style: {
              color: 'white',
              backgroundColor: 'var(--color-brand-primary)',
              border: '1px solid white',
              borderRadius: '5px',
            },
          }}
          primary={'Zoom In'}
        />
      </ListItem>
    </List>
  );
}

function addEventListenersForId(id: string, onClick: Function) {
  const listElem = document.getElementById(id);
  if (listElem) {
    listElem.addEventListener('click', () => onClick());
  }
}

export function buildClusterInfoWindow(
  event: google.maps.MapMouseEvent,
  cluster: Cluster,
  map: google.maps.Map,
  navigateToFlight: Function
) {
  closeActiveInfoWindows();

  const flightIds = cluster.markers?.map(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      marker.title?.substring(7)
  );

  const marker = cluster.marker as any;
  marker.infoWindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: true,
  });
  const markUp = getClusterMarkup(flightIds);
  const content = ReactDomServer.renderToStaticMarkup(markUp);
  marker.infoWindow.setContent(content);
  window.activeClusterWindow = marker.infoWindow;

  google.maps.event.addListener(marker.infoWindow, 'domready', () => {
    addEventListenersForId('cluster-marker-info-zoom', () =>
      defaultOnClusterClickHandler(event, cluster, map)
    );
    flightIds.forEach((id) => {
      addEventListenersForId(`cluster-marker-flight-${id}`, () => {
        const flightNum = Number.parseInt(id);
        navigateToFlight(flightNum);
      });
    });
  });

  marker.infoWindow.open({ map, anchor: cluster.marker, shouldFocus: false });
}
