import { useEffect, useRef } from 'react';
import type { Polygon, MultiPolygon } from '@turf/helpers';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
import { getMapCodeStyleInfo } from 'argus-data-model/db/schemas/managed-areas/map-code-properties';

export function useAirspaceLayer(
  google: any,
  map: google.maps.Map | null,
  advisoryType: ManagedAreaCode | 'unknown' | null,
  airspace?: Polygon | MultiPolygon | null
) {
  const layerRef = useRef<google.maps.Polygon[]>([]);

  return useEffect(() => {
    layerRef.current.forEach((r) => r.setMap(null));
    if (map && airspace) {
      const style = getMapCodeStyleInfo(
        advisoryType || ManagedAreaCode.Advisory
      );

      if (airspace.type === 'Polygon') {
        const polygon: google.maps.Polygon = new google.maps.Polygon({
          map,
          google,
          title: 'Add',
          paths: airspace.coordinates
            .flat(1)
            .map((x) => ({ lat: x[1], lng: x[0] })),
          clickable: false,
          editable: false,
          zIndex: 1,
          fillColor: style.fillColor,
          fillOpacity: style.fillOpacity,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight,
        });
        layerRef.current = [polygon];
      }
      if (airspace.type === 'MultiPolygon') {
        const polygons: google.maps.Polygon[] = airspace.coordinates.map(
          (coords) =>
            new google.maps.Polygon({
              map,
              google,
              title: 'Add',
              paths: coords.flat(1).map((x) => ({ lat: x[1], lng: x[0] })),
              clickable: false,
              editable: false,
              zIndex: 1,
              fillColor: style.fillColor,
              fillOpacity: style.fillOpacity,
              strokeColor: style.strokeColor,
              strokeOpacity: style.strokeOpacity,
              strokeWeight: style.strokeWeight,
            })
        );
        layerRef.current = polygons;
      }
    }
  }, [map, airspace, google, advisoryType]);
}
