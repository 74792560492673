import { combineReducers } from 'redux';

import { loginFormReducer } from './login/reducers';
import { resetPasswordFormReducer } from './reset-password/reducers';

export const REDUCER_KEY = 'authForms';

export const authFormsReducer = combineReducers({
  ...loginFormReducer,
  ...resetPasswordFormReducer,
});
