import { call, takeLatest, put } from 'redux-saga/effects';
import { FlightAreaViewModel } from 'fims-api-types';
import { fimsAPI } from '~/clients/api';
import {
  FlightAreaActionType,
  flightAreaFetchSucceeded,
  flightAreaFetchFailed,
  FlightAreaFetchRequestsAction,
} from './actions';

interface FlightAreaResponse {
  data: FlightAreaViewModel;
}

function* fetchSelectedFlightArea({
  payload: { flightId },
}: FlightAreaFetchRequestsAction) {
  try {
    const { data: flightArea }: FlightAreaResponse = yield call(
      fimsAPI.get,
      `/flight-area/${flightId}`
    );
    yield put(flightAreaFetchSucceeded(flightArea));
  } catch (error) {
    yield put(flightAreaFetchFailed());
  }
}

export function* flightAreaSaga() {
  yield takeLatest(
    FlightAreaActionType.FETCH_REQUESTED,
    fetchSelectedFlightArea
  );
}
