import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import bluePolygon from './images/blue-polygon.png';
import greenPolygon from './images/green-polygon.png';
import orangePolygon from './images/orange-polygon.png';
import purplePolygon from './images/purple-polygon.png';
import magentaPolygon from './images/magenta-polygon.png';
import redPolygon from './images/red-polygon.png';
import turquoisePolygon from './images/turquoise-polygon.png';
import goldenYellowPolygon from './images/golden-yellow-polygon.png';
import turquoiseLine from './images/turquoise-line.png';
import greenCorridor from './images/green-corridor.png';
import noopsLines from './images/noops-lines.png';
import beigeNotamArea from './images/beige-notam-area.png';

import './layer-legend-modal.scss';

interface LayerLegendModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  config: {
    key: string;
    name: string;
    description: string;
  }[];
}

function getImageFromCategory(key: string) {
  switch (key) {
    case 'noops-lines':
      return noopsLines;

    case 'red-polygon':
      return redPolygon;

    case 'purple-polygon':
      return purplePolygon;

    case 'turquoise-polygon':
      return turquoisePolygon;

    case 'orange-polygon':
      return orangePolygon;

    case 'blue-polygon':
      return bluePolygon;

    case 'green-polygon':
      return greenPolygon;

    case 'magenta-polygon':
      return magentaPolygon;

    case 'golden-yellow-polygon':
      return goldenYellowPolygon;

    case 'turquoise-line':
      return turquoiseLine;

    case 'green-corridor':
      return greenCorridor;

    case 'beige-notam-area':
      return beigeNotamArea;

    default:
      return null;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: '80%',
      overflow: 'hidden',
    },
    header: {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '10px',
    },
    content: {
      overflow: 'auto',
      display: 'block',
      '&::-webkit-scrollbar': { display: 'inline', width: '5px' },
      '&::-webkit-scrollbar-track': { width: '5px' },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[500],
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track-piece:start': { marginTop: '10px' },
      '&::-webkit-scrollbar-track-piece:end': { marginBottom: '40px' },
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginLeft: 'auto',
    },
    headerLeft: {
      marginLeft: 'auto',
    },
  })
);

function LayerLegendModal({
  config,
  setIsModalOpen,
  isModalOpen,
}: LayerLegendModalProps) {
  const classes = useStyles();

  useEffect(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Modal
      aria-labelledby="modal-map-legend-title"
      className={classes.modal}
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <div data-testid="modal-map-legend-title" className={classes.header}>
            <Typography variant="h4" className={classes.headerLeft}>
              Zone Information
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={['body', classes.content].join(' ')}>
            {config?.map(({ key, name, description }) => (
              <div className="item" key={key}>
                <img
                  className="legend"
                  alt={name}
                  src={getImageFromCategory(key)}
                />
                <div className="text-container">
                  <Typography className="item-title" variant="subtitle1">
                    {name}
                  </Typography>
                  <Typography variant="caption">{description}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default LayerLegendModal;
