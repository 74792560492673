import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { DateTime } from 'luxon';

import {
  type ApprovalUpdateContext,
  type AuditLogViewModel,
  type AuditLogViewModelContextDecision,
  FlightRequestAuditAction,
  type MessageMobileContext,
  type StatusUpdateContext,
  ReceiveSmsContext,
} from 'fims-api-types';

import { ContextApprovalUpdate } from './ContextApprovalUpdate';
import { ContextMessageMobile } from './ContextMessageMobile';
import { ContextOther } from './ContextOther';
import { ContextReceiveMessage } from './ContextReceiveMessage';
import { ContextStatusUpdate } from './ContextStatusUpdate';
import { UserRow } from './UserRow';

interface Props {
  auditLogOpenItems: boolean[];
  index: number;
  log: AuditLogViewModel;
  toggleLogItem: (index: number) => void;
}

import '../audit-logs.scss';

const returnAuditLogContext = (log: AuditLogViewModel) => {
  switch (log.action) {
    case FlightRequestAuditAction.ADHOC_SMS:
    case FlightRequestAuditAction.NON_CONFORMANT_ACTIVE_FLIGHT_SMS:
      return (
        <ContextMessageMobile
          context={
            log.context as MessageMobileContext &
              AuditLogViewModelContextDecision
          }
        />
      );
    case FlightRequestAuditAction.SMS_RECEIVED:
      return (
        <ContextReceiveMessage
          context={
            log.context as ReceiveSmsContext & AuditLogViewModelContextDecision
          }
        />
      );
    case FlightRequestAuditAction.STATUS_UPDATE:
      return (
        <ContextStatusUpdate
          context={
            log.context as StatusUpdateContext &
              AuditLogViewModelContextDecision
          }
        />
      );
    case FlightRequestAuditAction.SEGMENT_UPDATE_APPROVAL:
      return (
        <ContextApprovalUpdate
          context={
            log.context as ApprovalUpdateContext &
              AuditLogViewModelContextDecision
          }
        />
      );
    default:
      return <ContextOther action={log.action} context={log.context} />;
  }
};

const AuditLog = ({ auditLogOpenItems, index, log, toggleLogItem }: Props) => {
  return (
    <div
      className="flight-audit-log-group"
      data-testid={`flight-audit-log-group-${index}`}
    >
      <table
        className="flight-info-table"
        data-testid={`flight-audit-log-table-${index}`}
      >
        <tbody>
          <tr
            className="audit-table-header-row"
            onClick={() => toggleLogItem(index)}
          >
            <th data-testid={`flight-audit-log-action-title-${index}`}>
              Action
            </th>
            <td data-testid={`flight-audit-log-action-date-value-${index}`}>
              <span className="date-span">
                {DateTime.fromISO(log.createdAt, {
                  zone: 'utc',
                }).toFormat("dd-LL-yyyy HH:mm:ss 'UTC'") || 'N/A'}
              </span>
              <span className="action-span">
                {log.action.toLowerCase().replace(/_/g, ' ')}
              </span>
            </td>
            <td data-testid={`flight-audit-log-action-log-value-${index}`}>
              <div>
                {auditLogOpenItems[index] && <ExpandLess />}
                {!auditLogOpenItems[index] && <ExpandMore />}
              </div>
            </td>
          </tr>
          {auditLogOpenItems[index] && (
            <>
              <UserRow log={log} />
              {returnAuditLogContext(log)}
            </>
          )}
        </tbody>
      </table>
      <hr />
    </div>
  );
};

export default AuditLog;
