import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { menuStyles } from './menu-styles';

type Props = {
  buttonText: string;
  open: string;
  openItem: string;
  anchorRef: React.MutableRefObject<HTMLButtonElement>;
  closeButton: (event: React.MouseEvent<EventTarget>) => void;
  toggleButton: () => void;
  menuListItems: React.JSX.Element[];
};

export default function FilterDropdown({
  buttonText,
  open,
  openItem,
  anchorRef,
  closeButton,
  toggleButton,
  menuListItems,
}: Readonly<Props>) {
  const classes = menuStyles();

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open === openItem ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={toggleButton}
        className={classes.labelButton}
        size="small"
        endIcon={<ArrowDropDownIcon />}
        data-testid={`${openItem}-filter-button`}
      >
        {buttonText}
      </Button>
      <Popper
        open={open === openItem}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuList}
        placement="bottom"
        data-testid={`${openItem}-popper`}
      >
        {({ TransitionProps }) => (
          <Grow
            in={TransitionProps?.in}
            onEnter={TransitionProps?.onEnter}
            onExited={TransitionProps?.onExited}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeButton}>
                <MenuList
                  autoFocusItem={open === openItem}
                  data-testid={`${openItem}-list`}
                >
                  {menuListItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
