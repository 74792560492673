import { useState, useEffect } from 'react';
import { LatLng } from 'fims-api-types';
import { usePosition } from './use-position.hook';
import { MapHelpers } from '../../components/protected-layout/home-screen/map/hooks/helpers';

import myLocationIcon from './images/my-location.png';

export function useCenterOnUserLocation(
  google: any,
  mapInstance: google.maps.Map | null,
  intialMapInteractionDetected: boolean,
  userMarker: LatLng,
  updateUserMarker: (userMarker: LatLng) => void,
  mapHelpers: MapHelpers | null,
  animationPrep: () => void = () => {},
  isFocussedOnAFeature: boolean = true,
  zoomLevel?: number,
  preventInitialMove?: boolean
) {
  const [initiallyCentered, updateInitiallyCentered] = useState(false);
  const usersPosition = usePosition();

  useEffect(() => {
    if (
      google &&
      mapInstance &&
      usersPosition.longitude &&
      usersPosition.latitude
    ) {
      const position = {
        lng: usersPosition.longitude,
        lat: usersPosition.latitude,
      };

      updateUserMarker(position);

      // eslint-disable-next-line no-new
      new google.maps.Marker({
        map: mapInstance,
        google,
        position,
        icon: {
          url: myLocationIcon,
          anchor: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(12, 12),
        },
      });
    }
  }, [
    google,
    usersPosition.longitude,
    usersPosition.latitude,
    mapInstance,
    updateUserMarker,
  ]);

  useEffect(() => {
    if (!initiallyCentered && mapInstance && userMarker && mapHelpers) {
      updateInitiallyCentered(true);

      // ? If the map loads focussed on a particular feature or has
      // ? been interacted with already, don't recenter.
      if (
        !isFocussedOnAFeature &&
        !intialMapInteractionDetected &&
        !preventInitialMove
      ) {
        animationPrep();
        mapHelpers.smoothlyAnimatePanTo(
          new google.maps.LatLng(userMarker),
          () => {
            if (!zoomLevel) {
              return;
            }

            mapHelpers.animateMapZoomTo(zoomLevel);
          }
        );
      }
    }
  }, [
    google,
    mapInstance,
    intialMapInteractionDetected,
    isFocussedOnAFeature,
    userMarker,
    initiallyCentered,
    mapHelpers,
    animationPrep,
    zoomLevel,
    preventInitialMove,
  ]);
}
