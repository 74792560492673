export enum LogInStatus {
  IDLE = 'IDLE',
  LOG_IN_ATTEMPT_IN_PROGRESS = 'LOG_IN_ATTEMPT_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ResetPasswordStatus {
  IDLE = 'IDLE',
  RESET_PASSWORD_ATTEMPT_IN_PROGRESS = 'RESET_PASSWORD_ATTEMPT_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ChangePasswordStatus {
  IDLE = 'IDLE',
  CHANGE_PASSWORD_ATTEMPT_IN_PROGRESS = 'CHANGE_PASSWORD_ATTEMPT_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
