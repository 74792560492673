import React, { useEffect, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import cn from 'classnames';
import { makeStyles } from '@material-ui/styles';

import './loading-button.scss';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: ' 44px',
    marginTop: '1rem',
    color: 'var(--color-true-white)',
    background: 'var(--gradient-primary)',
    borderRadius: '22px',
    transition: 'background ease 0.1s',
    '&.secondary': {
      background: 'var(--gradient-secondary)',
    },
    '&.tertiary': {
      background: 'var(--gradient-tertiary)',
    },
    '&:disabled, &[disabled]': {
      background: 'var(--gradient-grayscale)',
    },
  },
});

interface Props {
  className: string;
  type: string;
  variant?: string;
  onClick?: React.EventHandler<any>;
  isLoading: boolean;
  value?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  id?: string;
}

const LOADING_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
};

export const COMPLETION_DELAY = 500;

export const LoadingButton: React.FC<Props> = ({
  className,
  type,
  children,
  variant,
  onClick,
  isLoading = false,
  id,
  ...rest
}) => {
  const [loadingState, updateLoadingState] = useState(LOADING_STATE.IDLE);

  useEffect(() => {
    if (isLoading) {
      updateLoadingState(LOADING_STATE.LOADING);
    } else if (!isLoading && loadingState === LOADING_STATE.LOADING) {
      updateLoadingState(LOADING_STATE.COMPLETE);

      const identifier = setTimeout(() => {
        updateLoadingState(LOADING_STATE.IDLE);
      }, COMPLETION_DELAY);

      return () => {
        clearTimeout(identifier);
      };
    } else if (!isLoading && loadingState === LOADING_STATE.COMPLETE) {
      updateLoadingState(LOADING_STATE.IDLE);
    }

    return () => {};
  }, [isLoading, loadingState]);

  const classes = useStyles({});

  const variantClass = variant || 'primary';

  return (
    <ButtonBase
      className={cn('loading-button', {
        [className]: className,
        [variantClass]: true,
        [classes.root]: true,
      })}
      type={type}
      onClick={onClick}
      href={null}
      data-loading-state={loadingState}
      id={id}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};
