import { createAction } from 'airshare-web-utils/redux-helpers';
import { ConflictGroup } from 'fims-api-types';

export enum ConflictedFlightsActionType {
  FETCH_REQUESTED = 'conflicted-flights/FETCH_REQUESTED',
  FETCH_SUCCEEDED = 'conflicted-flights/FETCH_SUCCEEDED',
  FETCH_FAILED = 'conflicted-flights/FETCH_FAILED',
  STARTED_POLLING = 'conflicted-flights/STARTED_POLLING',
  STOPPED_POLLING = 'conflicted-flights/STOPPED_POLLING',
}

export interface ConflictedFlightsFetchRequestedAction {
  type: ConflictedFlightsActionType.FETCH_REQUESTED;
}
export interface ConflictedFlightsFetchSucceededAction {
  type: ConflictedFlightsActionType.FETCH_SUCCEEDED;
  payload: ConflictGroup | ConflictGroup[];
}
export interface ConflictedFlightsFetchFailedAction {
  type: ConflictedFlightsActionType.FETCH_FAILED;
}
export interface ConflictedFlightsStartedPollingAction {
  type: ConflictedFlightsActionType.STARTED_POLLING;
}
export interface ConflictedFlightsStoppedPollingAction {
  type: ConflictedFlightsActionType.STOPPED_POLLING;
}

export const conflictedFlightsFetchRequested: () => ConflictedFlightsFetchRequestedAction =
  createAction(ConflictedFlightsActionType.FETCH_REQUESTED);
export const conflictedFlightsFetchSucceeded: (
  payload: ConflictGroup[]
) => ConflictedFlightsFetchSucceededAction = createAction(
  ConflictedFlightsActionType.FETCH_SUCCEEDED
);
export const conflictedFlightsFetchFailed: () => ConflictedFlightsFetchFailedAction =
  createAction(ConflictedFlightsActionType.FETCH_FAILED);
export const conflictedFlightsPollingStarted: () => ConflictedFlightsStartedPollingAction =
  createAction(ConflictedFlightsActionType.STARTED_POLLING);
export const conflictedFlightsPollingStopped: () => ConflictedFlightsStoppedPollingAction =
  createAction(ConflictedFlightsActionType.STOPPED_POLLING);
