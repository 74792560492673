import React, { useEffect, useRef, useImperativeHandle } from 'react';
import {
  ReCaptcha as GoogleReCaptcha,
  loadReCaptcha,
} from 'react-recaptcha-google';

interface WindowContext extends Window {
  env: {
    ENVIRONMENT: string;
    GOOGLE_RECAPTCHA_API_KEY: string;
  };
  grecaptcha: {
    render: any;
  };
}

declare let window: WindowContext;

interface Props {
  setRecaptchaToken?: (token: string | null) => void;
}

interface RecaptchaMethods {
  reset: () => void;
}

const Recaptcha: React.ForwardRefRenderFunction<RecaptchaMethods, Props> = (
  { setRecaptchaToken },
  ref
) => {
  const recaptchaRef = useRef<any>(null);

  const onLoadRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const verifyCallback = (recaptchaToken: any) => {
    if (setRecaptchaToken) {
      setRecaptchaToken(recaptchaToken);
    }
  };

  useEffect(() => {
    loadReCaptcha();
    onLoadRecaptcha();
  }, []);

  // Expose the reset method to the parent component
  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
          if (setRecaptchaToken) {
            setRecaptchaToken(null);
          }
        }
      },
    }),
    [setRecaptchaToken]
  );

  return window.grecaptcha?.render ? (
    <GoogleReCaptcha
      ref={recaptchaRef}
      size="normal"
      render="explicit"
      sitekey={window.env.GOOGLE_RECAPTCHA_API_KEY}
      onloadCallback={onLoadRecaptcha}
      verifyCallback={verifyCallback}
    />
  ) : null;
};

export default React.forwardRef<RecaptchaMethods, Props>(Recaptcha);
