import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FRStatusCode } from 'argus-api-shared/lib/constants';
import PurposeSelector from './purpose-selector';
import StatusSelector from './status-selector';
import TimeFrameSelector from './timeframe-selector';

type Props = {
  statusFilterValues: FRStatusCode[];
  purposeFilterValues: string[];
  selectedPurpose: string[];
  setSelectedPurpose: (purposes: string[]) => void;
  selectedStatus: FRStatusCode[];
  setSelectedStatus: (statuses: FRStatusCode[]) => void;
  timeframe: string;
  setTimeframe: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      border: '1px solid lightgray',
      padding: `0 ${theme.spacing(1)}px`,
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 350,
      borderRadius: 4,
    },
    title: {
      fontWeight: 'bold',
    },
  })
);

export default function FilterMenu({
  statusFilterValues,
  purposeFilterValues,
  selectedPurpose,
  setSelectedPurpose,
  selectedStatus,
  setSelectedStatus,
  timeframe,
  setTimeframe,
}: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState<string>('');
  const anchorRefPurpose = useRef<HTMLButtonElement>(null);
  const anchorRefStatus = useRef<HTMLButtonElement>(null);
  const anchorRefTimeframe = useRef<HTMLButtonElement>(null);

  const handleTogglePurpose = () => {
    setOpen((prevOpen) => (prevOpen === 'purpose' ? '' : 'purpose'));
  };

  const handleToggleStatus = () => {
    setOpen((prevOpen) => (prevOpen === 'status' ? '' : 'status'));
  };

  const handleToggleTimeframe = () => {
    setOpen((prevOpen) => (prevOpen === 'timeframe' ? '' : 'timeframe'));
  };

  const handleStatusClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRefStatus?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefTimeframe?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefPurpose?.current?.contains(event.target as HTMLElement)) {
      setOpen('purpose');
    }

    setOpen('');
  };

  const handlePurposeClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRefPurpose?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefTimeframe?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefStatus?.current?.contains(event.target as HTMLElement)) {
      setOpen('status');
    }

    setOpen('');
  };

  const handleTimeframeClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRefStatus?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefPurpose?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    if (anchorRefTimeframe?.current?.contains(event.target as HTMLElement)) {
      setOpen('timeframe');
    }

    setOpen('');
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      if (prevOpen.current === 'purpose') anchorRefPurpose.current!.focus();
      if (prevOpen.current === 'status') anchorRefStatus.current!.focus();
      if (prevOpen.current === 'timeframe') anchorRefTimeframe.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.container}>
      <Typography className={classes.title} data-testid="filter">
        Filter
      </Typography>
      <PurposeSelector
        open={open}
        anchorRefPurpose={anchorRefPurpose}
        handlePurposeClose={handlePurposeClose}
        handleTogglePurpose={handleTogglePurpose}
        purposeFilterValues={purposeFilterValues}
        selectedPurpose={selectedPurpose}
        setSelectedPurpose={setSelectedPurpose}
      />
      <StatusSelector
        open={open}
        anchorRefStatus={anchorRefStatus}
        handleStatusClose={handleStatusClose}
        handleToggleStatus={handleToggleStatus}
        statusFilterValues={statusFilterValues}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <TimeFrameSelector
        open={open}
        anchorRefTimeframe={anchorRefTimeframe}
        handleTimeframeClose={handleTimeframeClose}
        handleToggleTimeframe={handleToggleTimeframe}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
      />
    </div>
  );
}
