import { FlightRequestViewModel } from 'fims-api-types';
import { Note } from 'argus-data-model/db/schemas/flight-request/_note';
import { DateTime } from 'luxon';

export function pilotOrgName(flightRequest: FlightRequestViewModel): string {
  const givenName = flightRequest?.pilot?.givenName || '';
  const familyName = flightRequest?.pilot?.familyName || '';
  const company = flightRequest?.company || '';

  const name = `${givenName} ${familyName}`.trim();

  const hasCompanyAndName = name !== '' && company !== '';
  const separatorIfRequired = hasCompanyAndName ? ' - ' : '';
  const ret = name + separatorIfRequired + company;

  return ret;
}

export function atcNotes(flightRequest: FlightRequestViewModel): string {
  const byMostRecent = (a: Note, b: Note) =>
    -DateTime.fromJSDate(a.createdAt).diff(DateTime.fromJSDate(b.createdAt));
  return flightRequest?.atcNotes?.sort(byMostRecent)[0]?.text || '';
}

export default {
  pilotOrgName,
  atcNotes,
};
