import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { FlightRequestViewModel } from 'fims-api-types';
import { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';
import { useProfile } from '../../../state/session';
import { useSurveillanceWithLatency } from '../../../websocket/use-surveillance';

import { Loading } from 'asm-web-components';
import HomeList from './home-list/home-list';
import Map from './map/map';
import GoogleApiLoader from '../../shared/google-api-loader';

import { useGlobalSettings } from '../../../state/settings/hooks';
import {
  getFilteredSurveillance,
  getFlightsInTheNext24Hours,
} from './home-screen.lib';
import './home-screen.scss';
import { useInterval } from '../../../state/custom-hooks/useInterval';
import * as fimsClient from '../../../clients/fims-api-client';
import { FlightCountByStatus } from './types';
import { useOrganisationSettings } from '../use-organisation-setting.hook';
import './map/map.scss';
import {
  LoginContext,
  MapView24Hour,
} from '../../../../src/context/LoginState';
import { GlobalMapAlert } from './global-map-alert';

const FLIGHT_REQUEST_REFRESH_INTERVAL = 10000;

function HomeScreen() {
  const { authorizer } = useProfile();
  const [surveillanceData] = useSurveillanceWithLatency();

  const EMPTY_DASHBOARD_STATS = useMemo(
    () => ({
      authorized: 0,
      authorizedNow: 0,
      authorizedToday: 0,
      conflicted: 0,
      pending: 0,
      pendingToday: 0,
      provisionallyAuthorized: 0,
      provisionallyAuthorizedNow: 0,
      provisionallyAuthorizedToday: 0,
      activated: 0,
      activatedNow: 0,
      activatedToday: 0,
      loaded: true,
    }),
    []
  );
  const LOADING_DASHBOARD_STATS = useMemo(
    () => ({
      ...EMPTY_DASHBOARD_STATS,
      loaded: false,
    }),
    [EMPTY_DASHBOARD_STATS]
  );

  const [dashboardStats, setDashboardStats] = useState<FlightCountByStatus>(
    LOADING_DASHBOARD_STATS
  );
  const [canDisplaySurvillance, setCanDisplaySurvillance] = useState(true);

  const [flightRequests, setFlightRequests] = useState<
    FlightRequestViewModel[]
  >([]);

  const [errors, setErrors] = useState<string[]>([]);

  const { mapView24hour, mapViewDateTimeRange } = useContext(LoginContext);

  const fetchFlights = useCallback(() => {
    async function getFlightRequests() {
      return mapView24hour !== MapView24Hour.DATE
        ? await fimsClient.getFlightRequestsToday()
        : await fimsClient.getFlightRequestsByDate(mapViewDateTimeRange);
    }
    async function getFlights() {
      const flightDetails = authorizer ? await getFlightRequests() : [];
      if (fimsClient.isErrorState(flightDetails)) {
        setDashboardStats(EMPTY_DASHBOARD_STATS);
        setFlightRequests([]);
        setErrors(flightDetails.errors ?? []);
      } else {
        const flightStats = authorizer
          ? getFlightsInTheNext24Hours(flightDetails, authorizer)
          : EMPTY_DASHBOARD_STATS;

        setFlightRequests(flightDetails);
        setDashboardStats(flightStats);
        setErrors([]);
      }
    }
    getFlights();
  }, [authorizer, EMPTY_DASHBOARD_STATS, mapView24hour, mapViewDateTimeRange]);

  useEffect(() => {
    return fetchFlights();
  }, [
    fetchFlights,
    authorizer,
    EMPTY_DASHBOARD_STATS,
    mapView24hour,
    mapViewDateTimeRange,
  ]);

  useEffect(() => {
    if (mapView24hour === MapView24Hour.DATE) {
      setCanDisplaySurvillance(false);
    } else if (!canDisplaySurvillance) {
      setCanDisplaySurvillance(true);
    }
  }, [mapView24hour, canDisplaySurvillance]);

  useInterval(async () => {
    return fetchFlights();
  }, FLIGHT_REQUEST_REFRESH_INTERVAL);

  const [{ tracking, alert }] = useGlobalSettings();

  const { settings } = useOrganisationSettings();

  return (
    <div className="home-screen">
      <div className="home-screen__surveillance">
        <HomeList
          isCtrZoneAuthorizer={authorizer?.type === AuthorizerType.ControlZone}
          dashboardStats={dashboardStats}
        />
        {settings ? (
          <>
            <GlobalMapAlert errors={errors} />
            <GoogleApiLoader className="map">
              <Map
                flightRequests={flightRequests}
                fetchFlightList={fetchFlights}
                settings={settings}
                surveillanceData={getFilteredSurveillance(
                  canDisplaySurvillance ? surveillanceData : [],
                  alert,
                  tracking
                )}
              />
            </GoogleApiLoader>
          </>
        ) : (
          <Loading className="loading" />
        )}
      </div>
    </div>
  );
}

export default HomeScreen;
