import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LatLng, ManagedAirspaceViewModel, UserRole } from 'fims-api-types';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import { ManagedAreaAudit } from 'argus-data-model/db/schemas/managed-areas/audit';
import { getAuthorizedAirspaceTypesForRoles } from 'airspace-manager-common/authorization/authorized-area-types';

import { ManagedAreaCodeLabels } from './managed-airspace-constants';
import DetailMap from '../../shared/detail-map/index';
import DetailPageHeader from '../common/detail-page-header';
import EditAreaButtons from './edit-area-buttons';
import { LoginContext } from '../../../context/LoginState';
import { useProfile } from '../../../state/session/hooks';
import useManagedAirspaceErrors from './hooks/errors.hook';
import useSubmitAirspace from './hooks/submit.hook';
import useLoadAirspace from './hooks/load.hook';
import useEditAirspaceConfirmDialog from './hooks/confirm-dialog.hook';
import './edit-airspace-page.scss';
import EmergencyAirspaceClosureEnabledIcon from '../emergency-airspace-closure/emergency-airspace-closure-enabled-icon';
import GoogleApiLoader from '../../../components/shared/google-api-loader';
import EditAirspaceForm from './edit-airspace-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: 10,
    },
    submitButton: {
      minWidth: 145,
    },
    editAirspaceDialog: {
      width: 520,
      margin: 'auto',
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      width: `calc(100% - (${theme.spacing(2)} * 2)px)`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    dateTime: {
      minWidth: 170,
    },
    dateTimePickers: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
    },
    page: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    airspacePanelContainer: {
      width: '35%',
      height: `calc(100vh - var(--header-height))`,
      backgroundColor: 'white',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    formControl: {
      width: '100%',
      paddingRight: theme.spacing(2),
    },
    managedAreaMap: {
      flex: 3,
    },
    buttonPanel: {
      display: 'flex',
    },
    hr: {
      width: '94%',
      color: '#222',
    },
  })
);

const pilotVisibilityOptions = [
  { label: 'Hidden', options: { isVisible: false, isTileDisplay: false } },
  {
    label: 'Show in left panel only',
    options: { isVisible: true, isTileDisplay: true },
  },
  {
    label: 'Visible in map and left panel',
    options: { isVisible: true, isTileDisplay: false },
  },
];

export const EditAirspaceComponent = ({ add }: { add: boolean }) => {
  const navigate = useNavigate();
  const { areaId } = useParams();

  const classes = useStyles();

  const { selectedAirspace, setSelectedAirspace } = useContext(LoginContext);

  const { roles } = useProfile();

  const isAdmin = [UserRole.ADMIN, UserRole.SUPER_ADMIN].some((value) =>
    roles.includes(value)
  );

  const enabledAreaTypes = getAuthorizedAirspaceTypesForRoles(
    roles as UserRole[]
  )
    .filter((code) => code !== ManagedAreaCode.EmergencyAirspaceClosure)
    .map((code) => ({
      label: ManagedAreaCodeLabels[code],
      value: code,
    }));

  const [originalAirspace, setOriginalAirspace] = useState<
    ManagedAirspaceViewModel | undefined
  >(undefined);
  const [name, setName] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');
  const [icaoCode, setICAOCode] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [phone, setPhone] = useState<string | undefined>('');
  const [minAltitude, setMinAltitude] = useState<number>(0);
  const [maxAltitude, setMaxAltitude] = useState<number | undefined>(undefined);
  const [type, setType] = useState<ManagedAreaCode | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isTileDisplay, setIsTileDisplay] = useState<boolean>(false);
  const [authorizedPilots, setAuthorizedPilots] = useState<string[]>([]);
  const [authorizedPilotsOpen, setAuthorizedPilotsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState<Date | null | undefined>(
    undefined
  );
  const [endDateTime, setEndDateTime] = useState<Date | null | undefined>(null);

  const [mapCenter, setMapCenter] = useState<LatLng | undefined>(undefined);
  const [error, setError] = useState<string | null>();
  const [warning, setWarning] = useState<string | null>();
  const [fatalError, setFatalError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [auditLog, setAuditLog] = useState<ManagedAreaAudit[]>([]);

  const [pilotVisibility, setPilotVisibility] = useState(
    pilotVisibilityOptions[0].label
  );

  useEffect(
    () => () => {
      setSelectedAirspace(null);
    },
    [setSelectedAirspace]
  );

  useLoadAirspace(
    add,
    {
      setType,
      setDescription,
      setName,
      setICAOCode,
      setMinAltitude,
      setMaxAltitude,
      setStartDateTime,
      setEndDateTime,
      setPhone,
      setEmail,
      setIsVisible,
      setIsTileDisplay,
      setPilotVisibility,
    },
    setOriginalAirspace,
    setFatalError,
    setMapCenter,
    setAuditLog,
    setAuthorizedPilots,
    setAuthorizedPilotsOpen,
    pilotVisibilityOptions,
    setIsLoading,
    areaId
  );

  useManagedAirspaceErrors(setWarning, setError, {
    type,
    maxAltitude,
    minAltitude,
    endDateTime,
    startDateTime,
    authorizedPilots,
    originalAirspace,
  });

  const editingWithoutPermission =
    (!add &&
      Boolean(type && !enabledAreaTypes.find((x) => x.value === type))) ||
    type === ManagedAreaCode.EmergencyAirspaceClosure ||
    !isAdmin;

  const disabled = editingWithoutPermission || !type;
  const showICAOCode = type === ManagedAreaCode.Aerodrome;

  const [isValid, setIsValid] = useState(false);

  const { handleSubmit } = useSubmitAirspace(
    add,
    originalAirspace,
    {
      type,
      description,
      name,
      icaoCode,
      minAltitude,
      maxAltitude,
      startDateTime,
      endDateTime,
      phone,
      email,
      isVisible,
      isTileDisplay,
      authorizedPilots,
    },
    setIsValid,
    setIsLoading,
    setError,
    () => navigate(-1)
  );

  const { editAirspaceConfirmDialog, handleConfirmSubmit } =
    useEditAirspaceConfirmDialog(
      originalAirspace,
      type,
      setType,
      startDateTime,
      setStartDateTime,
      endDateTime,
      setEndDateTime,
      authorizedPilots,
      handleSubmit,
      add
    );

  return (
    <div data-testid="managed-airspace-page" className={classes.page}>
      <div className={classes.airspacePanelContainer}>
        {add || originalAirspace?.properties?.name ? (
          <DetailPageHeader
            title={
              add
                ? 'Add Managed Area'
                : `${
                    editingWithoutPermission ? 'View' : 'Update'
                  } Managed Area "${originalAirspace?.properties?.name}"`
            }
            status={
              type === ManagedAreaCode.EmergencyAirspaceClosure ? (
                <EmergencyAirspaceClosureEnabledIcon />
              ) : null
            }
          />
        ) : null}
        <br />
        {isLoading && (
          <CircularProgress
            data-testid="edit-airspace-panel-progress"
            size={24}
            color="inherit"
          />
        )}
        {error || warning ? (
          <Typography
            data-testid="error-or-warning-message"
            className={classnames(classes.item, classes.error)}
          >
            {error || warning}
          </Typography>
        ) : null}
        {fatalError ? (
          <Typography
            data-testid="fatal-error-message"
            className={classnames(classes.item, classes.error)}
          >
            {fatalError}
          </Typography>
        ) : null}
        {!isLoading && !fatalError ? (
          <EditAirspaceForm
            editingWithoutPermission={editingWithoutPermission}
            type={type}
            setType={setType}
            enabledAreaTypes={enabledAreaTypes}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            disabled={disabled}
            pilotVisibility={pilotVisibility}
            setPilotVisibility={setPilotVisibility}
            pilotVisibilityOptions={pilotVisibilityOptions}
            setIsTileDisplay={setIsTileDisplay}
            name={name}
            setName={setName}
            showICAOCode={showICAOCode}
            icaoCode={icaoCode}
            setICAOCode={setICAOCode}
            description={description}
            setDescription={setDescription}
            startDateTime={startDateTime}
            setStartDateTime={setStartDateTime}
            endDateTime={endDateTime}
            setEndDateTime={setEndDateTime}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            minAltitude={minAltitude}
            setMinAltitude={setMinAltitude}
            maxAltitude={maxAltitude}
            setMaxAltitude={setMaxAltitude}
            authorizedPilots={authorizedPilots}
            setAuthorizedPilots={setAuthorizedPilots}
            authorizedPilotsOpen={authorizedPilotsOpen}
            setAuthorizedPilotsOpen={setAuthorizedPilotsOpen}
            auditLog={auditLog}
          />
        ) : null}
      </div>
      <div className={classes.managedAreaMap}>
        <GoogleApiLoader className="map">
          <DetailMap
            surveillanceData={[]}
            airspace={add ? selectedAirspace : originalAirspace?.geometry}
            advisoryType={type}
            center={mapCenter}
            showCenter
            flightAreas={[]}
          />
        </GoogleApiLoader>
        <EditAreaButtons
          disabled={disabled || (add && !isValid) || !!error}
          add={add}
          isLoading={isLoading}
          onCancel={() => navigate(-1)}
          onConfirm={handleConfirmSubmit}
        />
        {editAirspaceConfirmDialog}
      </div>
    </div>
  );
};

export default EditAirspaceComponent;
