import { DateTime } from 'luxon';
import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

export function getFormattedDuration(
  flightRequest?: DetailedFlightRequestViewModelV2
) {
  if (flightRequest?.durationMinutes) {
    const minutes = flightRequest.durationMinutes % 60 || 0;
    const hours = (flightRequest.durationMinutes - minutes) / 60 || 0;
    const hh = hours < 10 ? `0${hours}` : `${hours}`;
    const mm = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${hh}:${mm}`;
  }
  return '00:00';
}

export function getFormattedDateTime(
  initialDateTime: string,
  timezone: string
) {
  const dateTimeWithZone = DateTime.fromISO(initialDateTime, {
    zone: timezone,
  });
  return dateTimeWithZone.toFormat('HH:mm dd LLL yyyy - ZZZZ');
}

export function getShortFormattedDateTime(
  initialDateTime: string,
  timezone: string
) {
  const dateTimeWithZone = DateTime.fromISO(initialDateTime, {
    zone: timezone,
  });
  return dateTimeWithZone.toFormat('yyyy-MM-dd HH:mm');
}
