import { useEffect } from 'react';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import { ManagedAreaCodeLabels } from '../managed-airspace-constants';
import { ManagedAirspaceViewModel } from 'fims-api-types';

interface Props {
  type: ManagedAreaCode | null;
  maxAltitude: number | undefined;
  minAltitude: number;
  endDateTime?: Date | null;
  startDateTime?: Date | null;
  authorizedPilots?: string[];
  originalAirspace?: ManagedAirspaceViewModel;
}

const decliningTypes = [
  ManagedAreaCode.NFZ,
  ManagedAreaCode.FlightPath,
  ManagedAreaCode.ExclusiveZone,
];

export default function useManagedAirspaceErrors(
  setWarning: (warning: string | null) => void,
  setError: (error: string | null) => void,
  {
    type,
    maxAltitude,
    minAltitude,
    endDateTime,
    startDateTime,
    authorizedPilots,
    originalAirspace,
  }: Props
) {
  useEffect(() => {
    if (maxAltitude && maxAltitude < minAltitude) {
      setError('Minimum altitude cannot exceed the maximum altitude');
    } else {
      setError('');
    }
  }, [minAltitude, maxAltitude, endDateTime, startDateTime, setError]);

  useEffect(() => {
    const originalType = originalAirspace?.properties?.code;
    if (type === originalType || decliningTypes.includes(originalType)) {
      return setWarning(null);
    }

    const label = ManagedAreaCodeLabels[type];
    if (type === ManagedAreaCode.NFZ || type === ManagedAreaCode.FlightPath) {
      return setWarning(
        `Selecting a type of '${label}' will decline any existing flights over this area.`
      );
    }
    if (
      type === ManagedAreaCode.ExclusiveZone &&
      authorizedPilots?.length > 0
    ) {
      return setWarning(
        `Selecting a type of '${label}' with at least one allowed pilot will decline any existing flights over this area for other pilots.`
      );
    }
    if (type === ManagedAreaCode.EmergencyAirspaceClosure) {
      return setWarning(
        `"Emergency Airspace Closure" areas cannot be modified here. Use the button under the AIRSPACE menu to remove the Emergency Airspace Closure. Only users from the organisation that created the area can remove it.`
      );
    }
    return setWarning(null);
  }, [setWarning, type, authorizedPilots?.length, originalAirspace]);
}
