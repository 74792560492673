import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as fimsClient from '~/clients/fims-api-client';
import { PrivilegedOrganisation } from '~/clients/fims-api-client';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: 400,
    },
    container: {
      width: 400,
    },
    apiKey: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      display: 'block',
    },
    generateKeyButton: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      minWidth: 146,
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      width: `calc(100 % - ${theme.spacing(2)} * 2)`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
    infoMessage: {
      color: theme.palette.info.main,
      textAlign: 'center',
    },
  })
);

interface OrgModalProps {
  closeModal(): any;
  organization: PrivilegedOrganisation;
}

function EditOrganisationModal({ closeModal, organization }: OrgModalProps) {
  const showSubscriptionApi =
    window.env.EXTERNAL_API_ENABLE_GEOFENCE_SUBSCRIPTION_API?.toLowerCase() ===
    'true';
  const classes = useStyles();
  const { name, id, apiKey, apiSubscriptionActive } = organization;
  const isNew = id === 'new';

  const [updatedName, setUpdatedName] = useState(name);
  const [apiAccess, setApiAccess] = useState(Boolean(apiKey));
  const [apiSubscriptionAccess, setApiSubscriptionAccess] = useState(
    apiSubscriptionActive
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatedKey, setGeneratedKey] = useState<string>(undefined);
  const [generatedSubscriptionUsername, setGeneratedSubscriptionUsername] =
    useState<string>(undefined);
  const [generatedSubscriptionPassword, setGeneratedSubscriptionPassword] =
    useState<string>(undefined);
  const [error, setError] = useState('');

  const addApiAccess = (privilegedOrganisation: PrivilegedOrganisation) => {
    privilegedOrganisation.apiKey = apiAccess ? generatedKey : null;
  };

  const addSubscriptionAccess = (
    privilegedOrganisation: PrivilegedOrganisation
  ) => {
    privilegedOrganisation.apiSubscriptionUser = apiSubscriptionAccess
      ? generatedSubscriptionUsername
      : null;
    privilegedOrganisation.apiSubscriptionPassword = apiSubscriptionAccess
      ? generatedSubscriptionPassword
      : null;
    privilegedOrganisation.apiSubscriptionActive = apiSubscriptionAccess;
  };

  const handleSubmit = async (_event: any): Promise<void> => {
    try {
      setIsSubmitting(true);
      const privilegedOrganisation: PrivilegedOrganisation = {
        name: updatedName.trim(),
      };
      if (isNew) {
        addApiAccess(privilegedOrganisation);
        addSubscriptionAccess(privilegedOrganisation);
        await fimsClient.createPrivilegedOrganisation(privilegedOrganisation);
      } else {
        addApiAccess(privilegedOrganisation);
        addSubscriptionAccess(privilegedOrganisation);
        await fimsClient.updatePrivilegedOrganisation({
          id,
          ...privilegedOrganisation,
        });
      }
      setIsSubmitting(false);
      closeModal();
    } catch (e: any) {
      setError(e.toString());
      setIsSubmitting(false);
    }
  };

  function generateRandomString(keyLength = 16) {
    let key = '';
    const str = 'ABCDEFGHIJKLMNPQRSTUVWXYZ0123456789';

    for (let i = 1; key.length < keyLength; i += 1) {
      const char = Math.floor(Math.random() * str.length + 1);
      key += str.charAt(char);
    }
    return key;
  }

  const toggleApiAccess = () => setApiAccess(!apiAccess);

  const generateSubscriptionUserAndPassword = () => {
    setGeneratedSubscriptionUsername(generateRandomString(12));
    setGeneratedSubscriptionPassword(generateRandomString(12));
  };

  const toggleApiSubscriptionAccess = () =>
    setApiSubscriptionAccess(!apiSubscriptionAccess);

  return (
    <Dialog onClose={closeModal} open>
      <DialogTitle data-testid="external-organisation-modal-header">{`${
        isNew ? 'Add' : 'Update'
      } External Organisation`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                type="text"
                label="Name"
                name="name"
                className={classes.textField}
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
                data-testid="external-organisation-name"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={apiAccess}
                  name="apiAccess"
                  onChange={toggleApiAccess}
                  data-testid="external-organisation-api-switch"
                />
              }
              label="External API Access"
            />
          </Grid>
          {apiAccess && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setGeneratedKey(generateRandomString())}
                fullWidth
                className={classes.generateKeyButton}
                data-testid="external-organisation-generate-api-button"
              >
                Generate API Key
              </Button>
              {generatedKey && (
                <span className={classes.apiKey}>
                  <Typography data-testid="external-organisation-key-generated-text">
                    Key Generated:
                  </Typography>
                  <Typography
                    color="secondary"
                    data-testid="external-organisation-generated-key"
                  >
                    {generatedKey}
                  </Typography>
                  <span className={classes.infoMessage}>
                    <Typography data-testid="external-organisation-generated-key-save-warning">
                      {`Copy the API Key now, once submitted it will not be available again.`}
                    </Typography>
                  </span>
                </span>
              )}
            </Grid>
          )}
          {showSubscriptionApi && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={apiSubscriptionAccess}
                    name="subscriptionApiAccess"
                    onChange={toggleApiSubscriptionAccess}
                    data-testid="external-organisation-subscription-api-switch"
                  />
                }
                label="External Subscription API Access"
              />
            </Grid>
          )}
          {showSubscriptionApi && apiSubscriptionAccess && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => generateSubscriptionUserAndPassword()}
                fullWidth
                className={classes.generateKeyButton}
                data-testid="external-organisation-generate-subscription-api-button"
              >
                Generate Credentials
              </Button>
              {generatedSubscriptionUsername && (
                <>
                  <span className={classes.apiKey}>
                    <Typography data-testid="external-organisation-subscription-user-generated-text">
                      User:
                    </Typography>
                    <Typography
                      color="secondary"
                      data-testid="external-organisation-generated-subscription-user"
                    >
                      {generatedSubscriptionUsername}
                    </Typography>
                  </span>
                  <span className={classes.apiKey}>
                    <Typography data-testid="external-organisation-subscription-password-generated-text">
                      Password:
                    </Typography>
                    <Typography
                      color="secondary"
                      data-testid="external-organisation-generated-subscription-password"
                    >
                      {generatedSubscriptionPassword}
                    </Typography>
                  </span>
                  <span className={classes.infoMessage}>
                    <Typography data-testid="external-organisation-generated-credentials-save-warning">
                      Copy the credentials now, once submitted they will not be
                      seen again.
                    </Typography>
                  </span>
                </>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              className={classnames(classes.item, classes.error)}
              data-testid="external-organisation-error-message"
            >
              {error}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
          data-testid="external-organisation-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isSubmitting ? () => {} : handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            isSubmitting ||
            (!apiKey && !generatedKey && apiAccess) ||
            (!apiSubscriptionActive &&
              !generatedSubscriptionUsername &&
              apiSubscriptionAccess)
          }
          className={classes.saveButton}
          data-testid="external-organisation-save-button"
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : isNew ? (
            'Save Organisation'
          ) : (
            'Save Changes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditOrganisationModal;
