import React from 'react';
import Icon from '@material-ui/icons/AirplanemodeInactiveOutlined';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    icon: {
      color: '#ff9800',
    },
  })
);

export default function EmergencyAirspaceClosureEnabledIcon() {
  const classes = useStyles();

  return <Icon className={classes.icon} />;
}
