import { useEffect, useCallback, useContext } from 'react';
import { ValidationError } from 'yup';

import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import { ManagedAirspaceViewModel } from 'fims-api-types';

import {
  editManagedAirspace,
  createManagedAirspace,
  CreateManagedAreaBody,
  EditManagedAreaBody,
} from '../../../../clients/fims-api-client';
import {
  addAirspaceSchema,
  editAirspaceSchema,
} from './edit-airspace-page-schemas';
import { LoginContext } from '../../../../context/LoginState';

interface Fields {
  type: ManagedAreaCode | null;
  description?: string;
  name?: string;
  minAltitude: number;
  maxAltitude?: number;
  startDateTime?: Date | null;
  endDateTime?: Date | null;
  phone?: string;
  email?: string;
  icaoCode?: string;
  isVisible?: boolean;
  isTileDisplay?: boolean;
  authorizedPilots: string[];
}

export default function useSubmitAirspace(
  add: boolean,
  originalAirspace: ManagedAirspaceViewModel | undefined,
  {
    type,
    description,
    name,
    minAltitude,
    maxAltitude,
    startDateTime,
    endDateTime,
    phone,
    email,
    icaoCode,
    isVisible,
    isTileDisplay,
    authorizedPilots,
  }: Fields,
  setIsValid: (isValid: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  setError: (err: string) => void,
  goBack: () => void
) {
  const { selectedAirspace, setSelectedAirspace, setForceReloadMap } =
    useContext(LoginContext);

  const getCreateManagedAreaBody = useCallback(() => {
    if (!type) return undefined;
    const data: CreateManagedAreaBody = {
      name,
      description,
      icaoCode,
      minAltitude,
      type,
      geometry: selectedAirspace,
      startDateTime: startDateTime || new Date(),
      endDateTime: endDateTime || undefined,
      authorizedPilots: authorizedPilots,
    };
    if (phone) data.phone = phone;
    if (email) data.email = email;
    if (icaoCode) data.icaoCode = icaoCode;
    if (maxAltitude) data.maxAltitude = maxAltitude;
    if (isVisible !== undefined) data.isVisible = isVisible;
    if (isTileDisplay !== undefined) data.isTileDisplay = isTileDisplay;
    return data;
  }, [
    description,
    name,
    icaoCode,
    email,
    minAltitude,
    maxAltitude,
    type,
    selectedAirspace,
    startDateTime,
    endDateTime,
    phone,
    isVisible,
    isTileDisplay,
    authorizedPilots,
  ]);

  const createNewAirspace = async () => {
    setIsLoading(true);

    const body = getCreateManagedAreaBody();
    if (!body) {
      setIsLoading(false);
      setError(`Invalid airspace, select area type`);
      return;
    }

    addAirspaceSchema
      .validate(body, {
        strict: true,
        abortEarly: false,
      })
      .then(() =>
        createManagedAirspace(body)
          .then(() => {
            setError('');
            setSelectedAirspace(null);
            setForceReloadMap(true);
            goBack();
          })
          .catch((err) => {
            setError(`${err}`);
          })
      )
      .catch((e: ValidationError) => {
        setError(e.errors.join(','));
      })
      .finally(() => setIsLoading(false));
  };

  const getEditManagedAreaBody = useCallback(() => {
    if (!originalAirspace?.id || !type) return undefined;
    const data: EditManagedAreaBody = {
      id: originalAirspace.id,
    };
    const isVisibilitySet = isVisible !== undefined;
    if (isVisibilitySet || originalAirspace?.properties?.code !== type)
      data.type = type;
    if (isVisibilitySet) data.isVisible = isVisible;
    data.isTileDisplay = isTileDisplay;
    if (originalAirspace?.properties?.name !== name) data.name = name;
    if (originalAirspace?.properties?.description !== description)
      data.description = description;
    if (originalAirspace?.properties?.email !== email) data.email = email;
    if (originalAirspace?.properties?.phone !== phone) data.phone = phone;
    if (originalAirspace?.properties?.upperLimitFeet !== maxAltitude)
      data.maxAltitude = maxAltitude;
    if (originalAirspace?.properties?.lowerLimitFeet !== minAltitude)
      data.minAltitude = minAltitude;
    if (
      authorizedPilots &&
      JSON.stringify(originalAirspace?.properties?.authorizedPilots ?? '[]') !==
        JSON.stringify(authorizedPilots)
    ) {
      data.authorizedPilots = authorizedPilots.slice(0);
    }
    if (icaoCode) {
      data.icaoCode = icaoCode;
    }

    const origStart = originalAirspace?.properties?.startDateTime;
    const origEnd = originalAirspace?.properties?.endDateTime;
    if (
      startDateTime !== undefined &&
      ((origStart &&
        (startDateTime === null ||
          origStart !== new Date(startDateTime)?.toISOString())) ||
        (!origStart && startDateTime !== null))
    ) {
      data.startDateTime = startDateTime;
    }
    if (
      endDateTime !== undefined &&
      ((origEnd &&
        (endDateTime === null ||
          origEnd !== new Date(endDateTime)?.toISOString())) ||
        (!origEnd && endDateTime !== null))
    ) {
      data.endDateTime = endDateTime;
    }
    return data;
  }, [
    originalAirspace,
    type,
    name,
    description,
    email,
    phone,
    minAltitude,
    maxAltitude,
    startDateTime,
    endDateTime,
    isVisible,
    isTileDisplay,
    authorizedPilots,
    icaoCode,
  ]);

  const editExistingAirspace = async () => {
    setIsLoading(true);
    const body = getEditManagedAreaBody();

    if (!body) {
      setIsLoading(false);
      setError(`Invalid Airspace`);
      return;
    }

    editAirspaceSchema
      .validate(body, {
        strict: true,
        abortEarly: false,
      })
      .then(() => {
        editManagedAirspace(body)
          .then(() => {
            setError('');
            setSelectedAirspace(null);
            setForceReloadMap(true);
            goBack();
          })
          .catch((err) => {
            setError(`${err}`);
          });
      })
      .catch((e: ValidationError) => {
        setError(e.errors.join(','));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (add) {
      const body = getCreateManagedAreaBody();
      addAirspaceSchema
        .validate(body, {
          strict: true,
          abortEarly: false,
        })
        .then(() => {
          setError('');
          setIsValid(true);
        })
        .catch(() => setIsValid(false));
    } else {
      const body = getEditManagedAreaBody();
      editAirspaceSchema
        .validate(body, {
          strict: true,
          abortEarly: false,
        })
        .then(() => {
          setError('');
          setIsValid(true);
        })
        .catch(() => setIsValid(false));
    }
  }, [
    add,
    getCreateManagedAreaBody,
    getEditManagedAreaBody,
    setError,
    setIsValid,
  ]);

  const handleSubmit = async (event: React.UIEvent): Promise<void> => {
    event.preventDefault();
    if (add) {
      createNewAirspace();
    } else {
      editExistingAirspace();
    }
  };

  return { handleSubmit };
}
