import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocationSearching from '@material-ui/icons/LocationSearching';
import type { FlightNotificationSummary } from 'fims-api-types';

import { LoginContext } from '../../../../../src/context/LoginState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    typography: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    heading: {
      fontWeight: 600,
    },
    locate: {
      float: 'right',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px 0`,
      width: 158,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
  })
);

interface Props {
  flightSummaryInfo?: FlightNotificationSummary;
  closeDrawer?: () => void;
}

function FlightSummary({ flightSummaryInfo, closeDrawer }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { mapHelpers, setMapHelpers } = useContext(LoginContext);
  const [isLocating, setIsLocating] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isLocating && mapHelpers) {
      mapHelpers.smoothlyAnimatePanTo(
        new google.maps.LatLng({
          lng: flightSummaryInfo?.deadCenter[0],
          lat: flightSummaryInfo?.deadCenter[1],
        }),
        () => {
          mapHelpers.animateMapZoomTo(14);
        }
      );
      setIsLocating(false);
    }
  }, [mapHelpers, isLocating, flightSummaryInfo?.deadCenter]);

  if (!flightSummaryInfo) {
    return null;
  }

  const handleDigitalAuthLocate = () => {
    if (
      ['/managed-airspaces/', '/flight/'].find((mapPageUrl) =>
        location.pathname.includes(mapPageUrl)
      )
    ) {
      setMapHelpers(null);
    }
    navigate(`/`);
    if (closeDrawer) {
      closeDrawer();
    }
    setIsLocating(true);
  };

  const {
    startDateTime,
    endDateTime,
    pilot,
    maxAltitudeFeet,
    streetAddress,
    atcNotes,
    locateEnabled,
  } = flightSummaryInfo;

  const TIME = flightStripTimes(startDateTime, endDateTime);
  const PILOT_NAME = `${pilot.givenName} ${pilot.familyName}`;

  return (
    <Grid
      data-testid="flight-summary"
      container
      spacing={1}
      className={classes.container}
    >
      <Grid item xs={6}>
        <Typography
          className={classes.typography}
          data-testid="flight-summary-pilot-name"
        >
          <span className={classes.heading}>Pilot:</span> {PILOT_NAME}
        </Typography>
        <Typography
          className={classes.typography}
          data-testid="flight-summary-pilot-mobile"
        >
          <span className={classes.heading}>Mobile:</span> {pilot.mobileNo}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.typography}
          data-testid="flight-summary-time"
        >
          <span className={classes.heading}>Time:</span> {TIME}
        </Typography>
        <Typography
          className={classes.typography}
          data-testid="flight-summary-altitude"
        >
          <span className={classes.heading}>Altitude:</span> {maxAltitudeFeet}{' '}
          ft
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={classes.typography}
          data-testid="flight-summary-address"
        >
          <span className={classes.heading}>Address:</span> {streetAddress}
        </Typography>
        {locateEnabled && (
          <Button
            aria-label="locate"
            className={classes.locate}
            color="inherit"
            variant="contained"
            disabled={false}
            onClick={handleDigitalAuthLocate}
            startIcon={<LocationSearching />}
            data-testid="flight-summary-locate-button"
          >
            LOCATE
          </Button>
        )}
        {atcNotes.length ? (
          <Typography
            className={classes.typography}
            data-testid="flight-summary-atc-notes"
          >
            <span className={classes.heading}>ATC Notes:</span>{' '}
            {atcNotes.map((note) => note.text).join(', ')}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}

const flightStripTimes = (startDateTime: string, endDateTime: string) => {
  const START_TIME = DateTime.fromISO(startDateTime).toFormat('HH:mm');
  const END_DATE = DateTime.fromISO(endDateTime).toFormat('HH:mm');

  return `${START_TIME}-${END_DATE} Local`;
};

export default FlightSummary;
