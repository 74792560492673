import type { FeatureCollection, Polygon } from 'geojson';

import { SegmentClearanceStatusCode, SegmentStatusCode } from './segment';
import { FeatureStyle } from '../misc/style';
import { AltitudeType } from './position';
import { FlightSegmentProperties } from './database';

interface LatLng {
  lat: number;
  lng: number;
}

export interface FlightSegmentPropertiesRequest {
  id?: string;
  startDateTime: string | Date;
  endDateTime: string | Date;
  maxAltitudeFeet: number;
  minAltitudeFeet: number;
  altitudeType: AltitudeType;
}

export interface FlightSegmentOverlapProperties {
  id: string;
  startDateTime: Date | string;
  endDateTime: Date | string;
  launchElevationFeet?: number;
  maxAltitudeFeet: number;
  minAltitudeFeet: number;
  altitudeType: AltitudeType;
  segmentStatus?: SegmentStatusCode;
}

export interface FlightSegmentPropertiesJSONView {
  id: string;
  startDateTime: string;
  endDateTime: string;
  maxAltitudeFeet: number;
  minAltitudeFeet: number;
  altitudeType: AltitudeType;
  launchElevationFeet: number;
  segmentStatus: SegmentStatusCode;
  segmentClearanceStatus: SegmentClearanceStatusCode;
  center: LatLng;
  segmentStreetAddress: string;
}

export interface StyledFlightSegmentPropertiesJSONView extends FeatureStyle {
  id: string;
  startDateTime: string;
  endDateTime: string;
  maxAltitudeFeet: number;
  minAltitudeFeet: number;
  altitudeType: AltitudeType;
  launchElevationFeet: number;
  segmentStatus: SegmentStatusCode;
  segmentClearanceStatus: SegmentClearanceStatusCode;
  center: LatLng;
  segmentStreetAddress: string;
}

export interface SegmentFeatureCollection
  extends FeatureCollection<Polygon, FlightSegmentProperties> {}

export interface SegmentFeatureCollectionJSONView
  extends FeatureCollection<Polygon, FlightSegmentPropertiesJSONView> {}

export type StyledSegmentFeatureCollectionJSONView = FeatureCollection<
  Polygon,
  StyledFlightSegmentPropertiesJSONView
>;

export const getSegmentId = (ix: number, segmentsCount: number): string => {
  if (ix < 0 || segmentsCount < 0) {
    throw new Error('ix and segmentsCount must be positive');
  }

  if (ix >= segmentsCount) {
    throw new Error('ix must be less than segmentsCount');
  }

  const maxLetterValue = 26;

  const charsNeeded = Math.ceil(
    Math.log(segmentsCount) / Math.log(maxLetterValue)
  );

  const chars: string[] = [];

  for (let i = 0; i < charsNeeded; i++) {
    const relativeCharIx =
      Math.floor(ix / Math.pow(maxLetterValue, i)) % maxLetterValue;
    chars.push(String.fromCharCode(relativeCharIx + 'A'.charCodeAt(0)));
  }

  return chars.reverse().join('');
};
