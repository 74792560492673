/* eslint-disable no-underscore-dangle, max-len */
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import { mapStylesReducer, MapOptionsState } from './map-styles/reducers';

import adminReducer, { AdminState } from './admin/reducers';
import conflictedFlightsReducer, {
  ConflictedFlightsState,
} from './conflicted-flights/reducers';
import settingsReducer, { SettingsState } from './settings/reducers';
import sessionReducer, { SessionState } from './session/reducers';
import flightAreaReducer, { FlightAreaState } from './flight-area/reducers';
import focussedFlightRequestReducer, {
  FocussedFlightRequestState,
} from './focussed-flight-request/reducers';

import { rootSaga, sagaConfig } from './root-saga';
import flightRequestsWithFlightAreaReducer, {
  FlightRequestsWithFlightAreaState,
} from './flight-requests-with-flight-area';

let composeEnhancers;

if (window.env.ENABLE_DEVTOOLS) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const sagaMiddleware = createSagaMiddleware(sagaConfig);
const allMiddleware = applyMiddleware(
  sagaMiddleware,
  LogRocket.reduxMiddleware()
);
const rootEnhancer = composeEnhancers(allMiddleware);

export interface AppState {
  admin: AdminState;
  conflictedFlights: ConflictedFlightsState;
  mapStyles: MapOptionsState;
  session: SessionState;
  settings: SettingsState;
  flightArea: FlightAreaState;
  focussedFlightRequests: FocussedFlightRequestState;
  flightRequestsWithFlightArea: FlightRequestsWithFlightAreaState;
}

export const rootReducer = combineReducers({
  admin: adminReducer,
  mapStyles: mapStylesReducer,
  session: sessionReducer,
  settings: settingsReducer,
  flightArea: flightAreaReducer,
  conflictedFlights: conflictedFlightsReducer,
  focussedFlightRequests: focussedFlightRequestReducer,
  flightRequestsWithFlightArea: flightRequestsWithFlightAreaReducer,
});

const store = createStore(rootReducer, rootEnhancer);

export default store;

// Sagas must be started after other
// dependencies have been initialized
// by explicitly calling this from the
// code after initialization.
export const startSagas = () => {
  sagaMiddleware.run(rootSaga);
};
