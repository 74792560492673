import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { DateTime } from 'luxon';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Divider,
  Typography,
  ListItem,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { NotificationResponse } from 'fims-api-types';
import FlightSummary from './flight-summary';
import * as fimsClient from '../../../../clients/fims-api-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageTime: {
      fontSize: '12px',
    },
    messageContent: {
      padding: theme.spacing(1),
      backgroundColor: 'whitesmoke',
      borderRadius: theme.spacing(1),
    },
    listItem: {
      paddingBottom: 0,
    },
  })
);

const panelId = (id: string) => `notification-${id}`;

interface Props {
  notification: NotificationResponse;
  updateNotifications: () => Promise<void>;
  expanded: boolean;
  setExpanded: (id: string | false) => void;
  setManuallyClosed: (value: boolean) => void;
}

type UpdatingType = 'DISMISS' | 'VIEW' | false;

function LandRequestNotification({
  notification,
  updateNotifications,
  expanded,
  setExpanded,
  setManuallyClosed,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();
  const [updating, setUpdating] = useState<UpdatingType>(false);
  const handleClick = async (value: UpdatingType) => {
    setUpdating(value);
    try {
      const response = await fimsClient.updateNotificationStatus(
        notification.id
      );
      if (response.success) {
        await updateNotifications();
      }
      setUpdating(false);
      if (value === 'VIEW') {
        const path = `/flight/${notification.flightId}`;
        if (location.pathname !== path) navigate(path);
        setManuallyClosed(true);
      }
    } catch {
      setUpdating(false);
    }
  };

  if (!notification?.flightSummaryInfo) {
    return null;
  }

  return (
    <ListItem
      key={panelId(notification.id)}
      className={classes.listItem}
      data-testid="land-request-notification-list-item"
    >
      <Accordion
        expanded={expanded}
        onChange={() =>
          setExpanded(expanded ? false : panelId(notification.id))
        }
        style={{ flexDirection: 'row-reverse', width: '100%' }}
      >
        <AccordionSummary
          id={`${panelId}bh-header`}
          aria-controls={`${panelId}bh-content`}
          expandIcon={<ExpandMore />}
          style={{
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
          }}
          data-testid="land-request-panel-summary"
        >
          <Typography
            variant="h6"
            style={{ marginLeft: '1rem' }}
            data-testid="land-request-recieved"
          >
            {`Land Request Acknowledged: Flight ${notification.flightId}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ minWidth: '200px', display: 'inherit' }}>
          <FlightSummary flightSummaryInfo={notification.flightSummaryInfo} />
          <Typography
            className={classes.messageTime}
            data-testid="notification-message-time"
          >
            {notification.createdAt
              ? `${DateTime.fromISO(notification?.createdAt, {
                  zone: notification?.flightSummaryInfo.timezone,
                }).toFormat('HHmm')} Local`
              : 'Unknown'}
          </Typography>
          <Typography
            className={classes.messageContent}
            data-testid="notification-message-text"
          >
            {notification.message}
          </Typography>
        </AccordionDetails>
        <Divider light />
        <Button
          variant="contained"
          color="primary"
          style={{ float: 'right', margin: '8px 8px 8px 0', minWidth: 121 }}
          disabled={!!updating}
          onClick={() => handleClick('VIEW')}
          data-testid="view-flight-button"
        >
          {updating === 'VIEW' ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'View Flight'
          )}
        </Button>
        <Button
          variant="outlined"
          style={{ float: 'right', margin: '8px 8px 8px 0', minWidth: 121 }}
          disabled={!!updating}
          onClick={() => handleClick('DISMISS')}
          data-testid="dismiss-button"
        >
          {updating === 'DISMISS' ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Dismiss'
          )}
        </Button>
      </Accordion>
    </ListItem>
  );
}

export default LandRequestNotification;
