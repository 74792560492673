import { Schema, Document } from 'mongoose';
import { ObjectId } from 'mongodb';
import { toJsonOptions, Timestamps } from '../../lib/shared-schema-options';

export enum NotificationType {
  SMS_RECEIVED = 'SMS_RECEIVED',
  DIGITAL_AUTH_REQUESTED = 'DIGITAL_AUTH_REQUESTED',
  LAND_REQUEST_FINISHED = 'LAND_REQUEST_FINISHED',
}

export interface Notification {
  user: ObjectId;
  message: string;
  notificationType: NotificationType;
  flightId: number;
  read: boolean;
}

export interface NotificationSchema
  extends Notification,
    Document,
    Timestamps {}

const notificationsSchema = new Schema<NotificationSchema>(
  {
    user: {
      type: Schema.Types.ObjectId,
      ref: 'User',
      required: [true, 'Is required'],
    },
    message: {
      type: String,
      required: [true, 'Is required'],
    },
    notificationType: {
      type: String,
      required: [true, 'Is required'],
      enum: Object.values(NotificationType),
    },
    read: {
      type: Boolean,
      required: [true, 'Is required'],
    },
    flightId: {
      type: Number,
      required: [true, 'Is required'],
    },
    createdAt: {
      type: Schema.Types.Date,
      required: false,
    },
    updatedAt: {
      type: Schema.Types.Date,
      required: false,
    },
  },
  { timestamps: true }
);
notificationsSchema.set('toJSON', toJsonOptions);
export default notificationsSchema;
