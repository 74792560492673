import React from 'react';
import {
  List,
  DialogContent,
  ListItem,
  ListItemText,
  DialogContentText,
} from '@material-ui/core';
import { ManagedAirspaceViewModel } from 'fims-api-types';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import { ManagedAreaCodeLabels } from '../managed-airspace-constants';
import { useConfirmDialog } from 'asm-web-components';
import { DateTime } from 'luxon';

const riskyAreaTypes = [
  ManagedAreaCode.NFZ,
  ManagedAreaCode.ExclusiveZone,
  ManagedAreaCode.FlightPath,
];

const pilotListTypes = [
  ManagedAreaCode.ExclusiveZone,
  ManagedAreaCode.FlightPath,
];

const getDateOrNull = (date?: Date | string | null) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }
  return DateTime.fromJSDate(date);
};

const getAreaWithNoPilotsJsx = (
  authorizedPilots: string[],
  type: ManagedAreaCode
): JSX.Element | undefined => {
  if (pilotListTypes.includes(type) && authorizedPilots.length === 0) {
    return (
      <DialogContentText style={{ marginBottom: '0px' }}>
        Warning - since no pilots have been added to the zone then all pilots
        will be able to create flights in this area
      </DialogContentText>
    );
  }
};

const getAreaChangeJsx = (
  originalAirspace: ManagedAirspaceViewModel,
  type: ManagedAreaCode
): JSX.Element | undefined => {
  const prevAreaCode = originalAirspace?.properties?.code;
  if (prevAreaCode !== type) {
    return (
      <>
        <DialogContentText style={{ marginBottom: '0px' }}>
          Area Type Changed:
        </DialogContentText>
        <List dense>
          <ListItem>
            <ListItemText primary={ManagedAreaCodeLabels[type]} />
          </ListItem>
        </List>
      </>
    );
  }
};

const getTimeChangeJsx = (
  originalAirspace: ManagedAirspaceViewModel,
  startDateTime?: Date | null,
  endDateTime?: Date | null
): JSX.Element | undefined => {
  const prevStart = getDateOrNull(originalAirspace?.properties?.startDateTime);
  const newStart = getDateOrNull(startDateTime);
  const prevEnd = getDateOrNull(originalAirspace?.properties?.endDateTime);
  const newEnd = getDateOrNull(endDateTime);
  if ((prevStart && prevStart > newStart) || (prevEnd && prevEnd < newEnd)) {
    return (
      <>
        <DialogContentText style={{ marginBottom: '0px' }}>
          Timeframe Change:
        </DialogContentText>
        <List dense>
          {prevStart && prevStart > newStart && (
            <ListItem>
              <ListItemText
                primary={`Start date-time is now earlier: ${newStart
                  .toJSDate()
                  .toLocaleString()}`}
              />
            </ListItem>
          )}
          {prevEnd && prevEnd < newEnd && (
            <ListItem>
              <ListItemText
                primary={`End date-time is now later: ${newEnd
                  .toJSDate()
                  .toLocaleString()}`}
              />
            </ListItem>
          )}
        </List>
      </>
    );
  }
};

const getPilotsRemovedJsx = (
  originalAirspace: ManagedAirspaceViewModel,
  authorizedPilots: string[]
): JSX.Element | undefined => {
  const prevPilots = originalAirspace?.properties?.authorizedPilots ?? [];
  const removed = prevPilots.filter((prev) => !authorizedPilots.includes(prev));
  if (removed.length) {
    return (
      <>
        <DialogContentText style={{ marginBottom: '0px' }}>
          Pilots Removed:
        </DialogContentText>
        <List dense>
          {removed.map((pilot) => (
            <ListItem
              key={`pilot-removed-${pilot}`}
              data-testid="pilot-removed"
            >
              <ListItemText primary={pilot} />
            </ListItem>
          ))}
        </List>
      </>
    );
  }
};

export default function useEditAirspaceConfirmDialog(
  originalAirspace: ManagedAirspaceViewModel,
  type: ManagedAreaCode,
  setType: (val: ManagedAreaCode) => void,
  startDateTime: Date | null | undefined,
  setStartDateTime: (val: Date | null) => void,
  endDateTime: Date | null | undefined,
  setEndDateTime: (val: Date | null) => void,
  authorizedPilots: string[],
  handleSubmit: (event?: any) => void,
  isCreation: boolean = false
) {
  const handleRevert = (_event: any) => {
    const prevPilots = originalAirspace?.properties?.authorizedPilots ?? [];
    prevPilots.forEach((prev) => {
      if (!authorizedPilots.includes(prev)) {
        authorizedPilots.push(prev);
      }
    });

    const decliningAreaJsx = getAreaChangeJsx(originalAirspace, type);
    if (decliningAreaJsx) {
      setType(originalAirspace?.properties?.code ?? type);
    }

    const timeFrameJsx = getTimeChangeJsx(
      originalAirspace,
      startDateTime,
      endDateTime
    );
    if (timeFrameJsx) {
      setStartDateTime(
        originalAirspace?.properties?.startDateTime
          ? new Date(originalAirspace?.properties?.startDateTime)
          : null
      );
      setEndDateTime(
        originalAirspace?.properties?.endDateTime
          ? new Date(originalAirspace?.properties?.endDateTime)
          : null
      );
    }
  };

  const {
    dialog: editAirspaceConfirmDialog,
    setShowConfirm: setShowConfirm,
    setContentToConfirm: setChangesToConfirm,
  } = useConfirmDialog(
    'Warning - These changes could potentially decline existing flights in the area',
    handleSubmit,
    handleRevert,
    undefined,
    isCreation ? null : { text: 'Revert Changes' },
    {
      text: 'Continue Editing',
      variant: 'contained',
      color: 'primary',
      style: { marginLeft: '16px' },
    },
    { maxWidth: 'md' }
  );

  const handleConfirmSubmit = async (event: React.UIEvent): Promise<void> => {
    if (!riskyAreaTypes.includes(type)) {
      return handleSubmit(event);
    }

    const noPilotsNoteJsx = getAreaWithNoPilotsJsx(authorizedPilots, type);
    const decliningAreaJsx = getAreaChangeJsx(originalAirspace, type);
    const timeFrameJsx = getTimeChangeJsx(
      originalAirspace,
      startDateTime,
      endDateTime
    );
    const removedPilotsJsx = getPilotsRemovedJsx(
      originalAirspace,
      authorizedPilots
    );

    if (
      noPilotsNoteJsx ||
      decliningAreaJsx ||
      timeFrameJsx ||
      removedPilotsJsx
    ) {
      const changes = (
        <DialogContent dividers={true}>
          {noPilotsNoteJsx}
          {decliningAreaJsx}
          {timeFrameJsx}
          {removedPilotsJsx}
        </DialogContent>
      );
      setChangesToConfirm(changes);
      setShowConfirm(true);
    } else {
      return handleSubmit(event);
    }
  };

  return {
    editAirspaceConfirmDialog,
    handleConfirmSubmit,
  };
}
