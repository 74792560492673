import { OrganisationMapDefault } from 'argus-common/interfaces';
import * as yup from 'yup';

export const LAT_MIN = -90;
export const LAT_MAX = 90;
export const LNG_MIN = -180;
export const LNG_MAX = 180;
export const ZOOM_MIN = -1;
export const ZOOM_MAX = 22;

interface ContactDetailsFormBody {
  email: string;
  address: string;
  phone: string;
  website: string;
}

interface OrganisationFormBody {
  name: string;
  authorizer: string;
}

export const mapDefaultFormSchema: yup.SchemaOf<OrganisationMapDefault> =
  yup.object({
    lat: yup
      .number()
      .optional()
      .nullable()
      .min(LAT_MIN)
      .max(LAT_MAX)
      .label('Latitude'),
    lng: yup
      .number()
      .optional()
      .nullable()
      .min(LNG_MIN)
      .max(LNG_MAX)
      .label('Longitude'),
    zoom: yup
      .number()
      .optional()
      .nullable()
      .min(ZOOM_MIN)
      .max(ZOOM_MAX)
      .label('Zoom'),
    mapType: yup.string().optional().nullable().label('Map mode'),
    focusedZoom: yup
      .number()
      .optional()
      .nullable()
      .min(ZOOM_MIN)
      .max(ZOOM_MAX)
      .label('Focused Zoom'),
  });

export const contactDetailsFormSchema: yup.SchemaOf<ContactDetailsFormBody> =
  yup.object({
    email: yup.string().required().email().label('Email'),
    address: yup.string().required().label('Address'),
    phone: yup
      .string()
      .matches(/^[0-9\s+\-()]{0,17}$/, 'Must be a valid phone number')
      .label('Phone'),
    website: yup
      .string()
      .matches(
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
        { excludeEmptyString: true, message: 'Must be a valid url' }
      )
      .nullable()
      .label('Website'),
  });

export const organisationFormSchema: yup.SchemaOf<OrganisationFormBody> =
  yup.object({
    name: yup.string().required().label('Name'),
    authorizer: yup.string().nullable().required().label('Authoriser'),
  });
