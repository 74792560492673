import { useCallback, useContext } from 'react';
import { createMapOptions } from '../../components/protected-layout/home-screen/map/hooks/helpers';

import { useMapStyle } from '../../state/map-styles/hooks';

import { MapStyleCode } from '../../state/map-styles/actions';
import { LoginContext } from '../../context/LoginState';
import { GoogleMapConfig } from './use-map.hook';

export function useMapStyleChange(
  map: google.maps.Map,
  initialZoom: number,
  handleMapReady: () => void,
  rerenderMap: (config: GoogleMapConfig) => void,
  styleCode?: MapStyleCode
): [MapStyleCode, () => void] {
  const { setMapStyleCode } = useContext(LoginContext);
  const [mapStyleOptions, nextMapStyleOptions] = useMapStyle(
    styleCode,
    setMapStyleCode
  );

  const handleMapStyleChange = useCallback(() => {
    if (map) {
      const prevOptions = createMapOptions(mapStyleOptions);
      const newStyle = nextMapStyleOptions();

      const options = createMapOptions(newStyle);

      if (options.mapId && prevOptions?.mapId !== options.mapId) {
        const center = {
          lat: map?.getCenter()?.lat() || -41.2864603,
          lng: map?.getCenter()?.lng() || 174.77623600000004,
        };
        const mapOptions = {
          center,
          zoom: map?.getZoom() || initialZoom,
          onReady: handleMapReady,
          disableDefaultUI: true,
          mapId: options.mapId,
          mapTypeId: options.mapTypeId,
          gestureHandling: 'greedy',
        };

        rerenderMap(mapOptions);
      } else {
        map.setOptions(options);
      }
    }
  }, [
    handleMapReady,
    initialZoom,
    map,
    mapStyleOptions,
    nextMapStyleOptions,
    rerenderMap,
  ]);

  return [mapStyleOptions?.styleCode, handleMapStyleChange];
}
