import { createAction } from 'airshare-web-utils/redux-helpers';
import {
  RequiredAuthChangeSubmission,
  DetailedFlightRequestViewModelV2,
} from 'fims-api-types';

export enum FlightRequestsActionType {
  FETCH_FOCUSSED_REQUESTED = 'focussed-flight-request/FETCH_FOCUSSED_REQUESTED',
  FETCH_FOCUSSED_SUCCEEDED = 'focussed-flight-request/FETCH_FOCUSSED_SUCCEEDED',
  FETCH_FOCUSSED_FAILED = 'focussed-flight-request/FETCH_FOCUSSED_FAILED',
  CLEAR_FOCUSSED_ERROR = 'focussed-flight-request/CLEAR_FOCUSSED_ERROR',
  CLEAR_FOCUSSED_FLIGHT = 'focussed-flight-request/CLEAR_FOCUSSED_ERROR',
  UPDATE_DECISION_REQUESTED = 'focussed-flight-request/UPDATE_DECISION_REQUESTED',
  UPDATE_DECISION_SUCCEEDED = 'focussed-flight-request/UPDATE_DECISION_SUCCEEDED',
  UPDATE_DECISION_FAILED = 'focussed-flight-request/UPDATE_DECISION_FAILED',
  UPDATE_DECISION_ERROR = 'focussed-flight-request/UPDATE_DECISION_ERROR',
}

export interface FlightIdPayload {
  flightId: number;
}
export interface AtcNotePayload {
  flightId: number;
  atcNote: string;
}
export interface OperatorNotePayload {
  flightId: number;
  operatorNote: string;
}
export interface DecisionPayload {
  atcNote: string;
  flightId: number;
  operatorNote: string;
  submitter: string;
  statusCode: string;
  changes?: RequiredAuthChangeSubmission[];
}

export interface FlightRequestsFetchFocussedRequestedAction {
  type: FlightRequestsActionType.FETCH_FOCUSSED_REQUESTED;
  payload: FlightIdPayload;
}
export interface FlightRequestsFetchFocussedSucceededAction {
  type: FlightRequestsActionType.FETCH_FOCUSSED_SUCCEEDED;
  payload: DetailedFlightRequestViewModelV2;
}
export interface FlightRequestsFetchFocussedFailedAction {
  type: FlightRequestsActionType.FETCH_FOCUSSED_FAILED;
  payload: string;
}
export interface FlightRequestsClearFocussedErrorAction {
  type: FlightRequestsActionType.CLEAR_FOCUSSED_ERROR;
}
export interface FlightRequestsClearFocussedFlightAction {
  type: FlightRequestsActionType.CLEAR_FOCUSSED_FLIGHT;
}
export interface FlightRequestsUpdateDecisionRequestedAction {
  type: FlightRequestsActionType.UPDATE_DECISION_REQUESTED;
  payload: DecisionPayload;
}
export interface FlightRequestsUpdateDecisionSucceededAction {
  type: FlightRequestsActionType.UPDATE_DECISION_SUCCEEDED;
  payload: DetailedFlightRequestViewModelV2;
}
export interface FlightRequestsUpdateDecisionFailedAction {
  type: FlightRequestsActionType.UPDATE_DECISION_FAILED;
}
export interface FlightRequestsUpdateDecisionErrorAction {
  type: FlightRequestsActionType.UPDATE_DECISION_ERROR;
}

export const flightRequestsFetchFocussedRequested: (
  payload: FlightIdPayload
) => FlightRequestsFetchFocussedRequestedAction = createAction(
  FlightRequestsActionType.FETCH_FOCUSSED_REQUESTED
);
export const flightRequestsFetchFocussedSucceeded: (
  payload: DetailedFlightRequestViewModelV2
) => FlightRequestsFetchFocussedSucceededAction = createAction(
  FlightRequestsActionType.FETCH_FOCUSSED_SUCCEEDED
);
export const flightRequestsFetchFocussedFailed: (
  payload: string
) => FlightRequestsFetchFocussedFailedAction = createAction(
  FlightRequestsActionType.FETCH_FOCUSSED_FAILED
);
export const flightRequestsClearFocussedError: () => FlightRequestsClearFocussedErrorAction =
  createAction(FlightRequestsActionType.CLEAR_FOCUSSED_ERROR);
export const flightRequestsClearFocussedFlight: () => FlightRequestsClearFocussedFlightAction =
  createAction(FlightRequestsActionType.CLEAR_FOCUSSED_FLIGHT);
export const flightRequestsUpdateDecisionRequested: (
  payload: DecisionPayload
) => FlightRequestsUpdateDecisionRequestedAction = createAction(
  FlightRequestsActionType.UPDATE_DECISION_REQUESTED
);
export const flightRequestsUpdateDecisionSucceeded: (
  payload: DetailedFlightRequestViewModelV2
) => FlightRequestsUpdateDecisionSucceededAction = createAction(
  FlightRequestsActionType.UPDATE_DECISION_SUCCEEDED
);
export const flightRequestsUpdateDecisionFailed: (
  payload: string
) => FlightRequestsUpdateDecisionFailedAction = createAction(
  FlightRequestsActionType.UPDATE_DECISION_FAILED
);
export const flightRequestsUpdateDecisionError: () => FlightRequestsUpdateDecisionErrorAction =
  createAction(FlightRequestsActionType.UPDATE_DECISION_ERROR);
