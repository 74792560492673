import * as React from 'react';
import { FRStatusCode, type FlightRequestViewModel } from 'fims-api-types';
import { Button, Chip } from '@material-ui/core';
import { UserRole } from 'argus-common/enums';
import MarkerInfo, { ListProps } from './marker-info';
import type { Authorizer } from 'argus-data-model/db/schemas/authorizers';

const DisplayStatuses = {
  RequestToLand: [FRStatusCode.Activated],
};

export default function MarkerInfoWithActions(
  flight: FlightRequestViewModel,
  authorizer: Authorizer,
  userRoles: UserRole[],
  enabledButtons: {
    requestToLand: boolean;
  },
  additionalInfo: ListProps[] = []
) {
  const canDisplay = (statuses: FRStatusCode[]) => {
    const authorisedRoles = [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.AUTHORISER,
    ];
    const isAuthorised = userRoles.some((x) => authorisedRoles.includes(x));
    const status = flight.status.find((s) => s.authorizer === authorizer?.name);
    const isDisplayableStatus = statuses.includes(status?.code);

    return isAuthorised && status?.canAuthorize && isDisplayableStatus;
  };

  const feature = flight.flightAreas[0];

  return (
    <>
      {flight.isPriorityFlight && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '0.8rem 0.5rem 0',
            boxSizing: 'border-box',
          }}
        >
          <Chip
            label="Priority Flight"
            style={{
              background: '#ff9800',
              color: '#000',
              borderColor: '#ff9800',
              padding: '0.5rem 1rem',
              width: '100%',
            }}
          />
        </div>
      )}
      <MarkerInfo
        feature={feature}
        timezone={flight.timezone}
        details={false}
        additionalInfo={additionalInfo}
      />
      {canDisplay(DisplayStatuses.RequestToLand) && (
        <div style={{ padding: '0 1rem' }}>
          <Button
            size="small"
            variant="contained"
            className="request-to-land-button"
            style={{
              width: '100%',
              height: '1.5rem',
              ...(!enabledButtons?.requestToLand
                ? { backgroundColor: '#e0e0e0' }
                : {}),
            }}
            id={`flight-${flight.flightId}-button-request-to-land`}
            disabled={!enabledButtons?.requestToLand}
          >
            Cancel Flight Authorisation
          </Button>
        </div>
      )}
    </>
  );
}
