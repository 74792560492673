import { useEffect, useRef } from 'react';

// https://gist.github.com/babakness/faca3b633bc23d9a0924efb069c9f1f5
// This is still using polling, webhooks would be preferred!!!

type IntervalFunction = () => unknown | void;

export function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = useRef<IntervalFunction | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
