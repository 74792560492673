import { Dictionary } from 'lodash';
import { createAction } from '../redux-helpers';

export interface FormActionType {
  FORM_UPDATED: string;
  FORM_CLEARED: string;
  FORM_TOUCHED: string;
  FORM_VALIDATED: string;
  FORM_VALIDATION_FAILED: string;
  FORM_VALIDATION_RESET: string;
  FORM_FIELD_VALIDATION_RESET: string;
  FORM_ERRORED: string;
  FORM_ERRORS_RESET: string;
  FORM_SUBMITTED: string;
}

export function createFormActions(formName: string): FormActionType {
  const FORM_UPDATED = `forms/${formName}/FORM_UPDATED`;
  const FORM_CLEARED = `forms/${formName}/FORM_CLEARED`;
  const FORM_TOUCHED = `forms/${formName}/FORM_TOUCHED`;
  const FORM_VALIDATED = `forms/${formName}/FORM_VALIDATED`;
  const FORM_VALIDATION_FAILED = `forms/${formName}/FORM_VALIDATION_FAILED`;
  const FORM_VALIDATION_RESET = `forms/${formName}/FORM_VALIDATION_RESET`;
  const FORM_FIELD_VALIDATION_RESET = `forms/${formName}/FORM_FIELD_VALIDATION_RESET`;
  const FORM_ERRORED = `forms/${formName}/FORM_ERRORED`;
  const FORM_ERRORS_RESET = `forms/${formName}/FORM_ERRORS_RESET`;
  const FORM_SUBMITTED = `forms/${formName}/FORM_SUBMITTED`;

  return {
    FORM_UPDATED,
    FORM_CLEARED,
    FORM_TOUCHED,
    FORM_VALIDATED,
    FORM_VALIDATION_FAILED,
    FORM_VALIDATION_RESET,
    FORM_FIELD_VALIDATION_RESET,
    FORM_ERRORED,
    FORM_ERRORS_RESET,
    FORM_SUBMITTED,
  };
}

export function createFormActionCreators(actions: FormActionType) {
  return {
    formUpdated: createAction<Object, string>(actions.FORM_UPDATED),
    formCleared: createAction<void, string>(actions.FORM_CLEARED),
    formTouched: createAction<Dictionary<boolean>, string>(
      actions.FORM_TOUCHED
    ),
    formValidated: createAction(actions.FORM_VALIDATED),
    formValidationFailed: createAction(actions.FORM_VALIDATION_FAILED),
    formValidationReset: createAction(actions.FORM_VALIDATION_RESET),
    formFieldValidationReset: createAction(actions.FORM_FIELD_VALIDATION_RESET),
    formErrored: createAction(actions.FORM_ERRORED),
    formErrorsReset: createAction<void, string>(actions.FORM_ERRORS_RESET),
    formSubmitted: createAction(actions.FORM_SUBMITTED),
  };
}
