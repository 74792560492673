import React, { useState } from 'react';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { ToggleButtonGroup } from 'asm-web-components';

import Add from '@material-ui/icons/Add';

import { UavManufacturersViewModel } from 'fims-api-types';
import { UavModel } from 'argus-data-model/db/schemas/uav-manufacturer';
import {
  updateUavManufacturer,
  createUavManufacturer,
} from '../../../clients/fims-api-client';

export const OperatorTypeDisplay: {
  [key: string]: string;
} = {
  RECREATIONAL: 'Recreational',
  COMMERCIAL: 'Commercial',
  UAM_OPERATOR: 'UAM Operator',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addModel: {
      width: 400,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    listItemAction: {
      display: 'flex',
      cursor: 'pointer',
    },
    modelsList: {
      height: 200,
      width: 400,
    },
    textField: {
      width: 400,
    },
    toggleButtonGroup: {
      margin: `${theme.spacing(2)}px 0`,
      width: '100%',
      justifyContent: 'center',
    },
  })
);

const updateManufacturerSchema = yup.object({
  _id: yup.string().required('Required'),
  name: yup.string().required('Required'),
  isActive: yup.boolean().required('Required'),
  models: yup.array().required('Required'),
});

const addManufacturerSchema = yup.object({
  name: yup.string().required('Required'),
  isActive: yup.boolean().required('Required'),
  models: yup.array().required('Required'),
});

export default function EditManufacturersModal({
  closeModal,
  manufacturer,
}: {
  closeModal: () => void;
  manufacturer: UavManufacturersViewModel;
}) {
  const classes = useStyles();

  const isNew = !manufacturer?._id;

  const [status, setStatus] = useState(manufacturer.isActive);
  const [name, setName] = useState(manufacturer.name);
  const [models, setModels] = useState([].concat(manufacturer.models));
  const [newModel, setNewModel] = useState('');

  const handleSubmit = async (event: React.UIEvent): Promise<void> => {
    event.preventDefault();

    const data = {
      name,
      isActive: status,
      models,
    };

    if (isNew) {
      try {
        await addManufacturerSchema.validate(data, {
          strict: true,
          abortEarly: false,
        });
        await createUavManufacturer({ ...data });
      } finally {
        closeModal();
      }
    } else {
      try {
        await updateManufacturerSchema.validate(
          { ...data, _id: manufacturer._id },
          {
            strict: true,
            abortEarly: false,
          }
        );
        await updateUavManufacturer({ ...data, _id: manufacturer._id });
      } finally {
        closeModal();
      }
    }
  };

  return (
    <Dialog onClose={closeModal} open>
      <DialogTitle data-testid="add-update-heading">
        {`${isNew ? 'Add' : 'Update'} UAV Manufacturer`}
      </DialogTitle>
      <br />
      <DialogContent>
        <FormControl>
          <TextField
            type="text"
            label="Name"
            name="name"
            className={classes.textField}
            value={isNew ? name : manufacturer.name}
            onChange={isNew ? (e) => setName(e.target.value) : () => {}}
            disabled={!isNew}
            data-testid="name"
          />
        </FormControl>
        <br />
        <ToggleButtonGroup
          name="status"
          className={classes.toggleButtonGroup}
          options={[
            { label: 'Active', value: 'Active' },
            { label: 'Inactive', value: 'Inactive' },
          ]}
          selectedValue={status ? 'Active' : 'Inactive'}
          onChange={(e: React.MouseEvent<HTMLInputElement>) =>
            setStatus(e.currentTarget.value === 'Active')
          }
          data-testid="status"
        />
        <br />
        <Typography className="models-heading" data-testid="models-heading">
          Models
        </Typography>
        <List
          className={classes.modelsList}
          style={{ overflowY: models.length < 6 ? 'hidden' : 'scroll' }}
          data-testid="model-list"
        >
          <Divider />
          {models.map((model: UavModel, i: number) => (
            <div key={model.name} data-testid="model-row">
              <Divider />
              <div
                onClick={() => {
                  const updatedModels = [...models];
                  updatedModels[i].isActive = !model.isActive;
                  setModels(updatedModels);
                }}
              >
                <ListItem button dense>
                  <ListItemText primary={model.name} data-testid="model-name" />
                  <ListItemSecondaryAction className={classes.listItemAction}>
                    <Typography data-testid="model-status">
                      {model.isActive ? 'Active' : 'Inactive'}
                    </Typography>
                    <Switch
                      name="newStatus"
                      size="small"
                      color="primary"
                      checked={model.isActive}
                      data-testid="model-status-switch"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            </div>
          ))}
          <Divider />
        </List>
        <Divider />
        <TextField
          data-testid="add-model-text"
          label="Add Model"
          variant="outlined"
          className={classes.addModel}
          value={newModel}
          onChange={(e) => setNewModel(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="add-model-button"
                  size="small"
                  disabled={!newModel}
                  onClick={() => {
                    if (newModel) {
                      const newModels = [
                        {
                          name: newModel,
                          isActive: true,
                        },
                        ...models,
                      ];
                      setModels(newModels);
                      setNewModel('');
                    }
                  }}
                >
                  <Add />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          data-testid="submit-button"
        >
          {isNew ? 'Save Manufacturer' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
