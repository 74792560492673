import { Reducer, combineReducers } from 'redux';
import zipObject from 'lodash/zipObject';
import set from 'lodash/fp/set';
import { createReducer } from '../redux-helpers';
import { Dictionary } from 'lodash';

export interface FormState<State extends Object> {
  errors: Dictionary<any>;
  form: State;
  isValid: boolean;
  isSubmitted: boolean;
  touched: Dictionary<boolean>;
  validation: Dictionary<any>;
}

export function createFormReducer<State extends Object>(
  actions: any,
  defaultState: State,
  schema: any
): Reducer<FormState<State>> {
  const {
    FORM_UPDATED,
    FORM_TOUCHED,
    FORM_CLEARED,
    FORM_ERRORED,
    FORM_ERRORS_RESET,
    FORM_VALIDATED,
    FORM_FIELD_VALIDATION_RESET,
    FORM_SUBMITTED,
    FORM_VALIDATION_RESET,
  } = actions;

  const formFieldKeys = Object.keys(defaultState);

  const DEFAULT_FORM_STATE = defaultState;
  const DEFAULT_VALIDATION_STATE = zipObject(
    formFieldKeys,
    formFieldKeys.map(() => null)
  );
  const DEFAULT_IS_VALID_STATE = false;
  const DEFAULT_IS_SUBMITTED_STATE = false;
  const DEFAULT_TOUCHED_STATE = zipObject(
    formFieldKeys,
    formFieldKeys.map(() => false)
  );
  const DEFAULT_ERROR_STATE = zipObject(
    formFieldKeys,
    formFieldKeys.map(() => null)
  );

  const form = createReducer<State>(
    {
      [FORM_UPDATED]: (state: any, { payload }: { payload: any }) =>
        Object.keys(payload).reduce(
          (accumulator, key) => set(key, payload[key], accumulator),
          state
        ),
      [FORM_CLEARED]: () => DEFAULT_FORM_STATE,
    },
    DEFAULT_FORM_STATE
  );

  const validation = createReducer(
    {
      [FORM_VALIDATED]: (_: any, { payload }: { payload: any }) => {
        try {
          schema.validateSync(payload, { abortEarly: false });
        } catch (error) {
          return error.inner.reduce(
            (
              nextState: any,
              {
                path,
                type,
                message,
              }: { path?: string; type: string; message: string }
            ) => ({
              ...nextState,
              [path || type]: message,
            }),
            DEFAULT_VALIDATION_STATE
          );
        }

        return DEFAULT_VALIDATION_STATE;
      },
      [FORM_VALIDATION_RESET]: () => DEFAULT_VALIDATION_STATE,
      [FORM_FIELD_VALIDATION_RESET]: (
        state: any,
        { payload }: { payload: any }
      ) => ({
        ...state,
        [payload.fieldName]: null,
      }),
      [FORM_CLEARED]: () => DEFAULT_VALIDATION_STATE,
    },
    DEFAULT_VALIDATION_STATE
  );

  const isValid = createReducer(
    {
      [FORM_VALIDATED]: (_: any, { payload }: { payload: any }) =>
        schema.isValidSync(payload, { abortEarly: false }),
      [FORM_CLEARED]: () => DEFAULT_IS_VALID_STATE,
    },
    DEFAULT_IS_VALID_STATE
  );

  const isSubmitted = createReducer(
    {
      [FORM_SUBMITTED]: (_: any, { payload }: { payload: any }) => payload,
      [FORM_CLEARED]: () => DEFAULT_IS_SUBMITTED_STATE,
    },
    DEFAULT_IS_SUBMITTED_STATE
  );

  const touched = createReducer(
    {
      [FORM_TOUCHED]: (_: any, { payload }: { payload: any }) => payload,
      [FORM_CLEARED]: () => DEFAULT_TOUCHED_STATE,
    },
    DEFAULT_TOUCHED_STATE
  );

  const errors = createReducer(
    {
      [FORM_ERRORED]: (_: any, { payload }: { payload: any }) => payload,
      [FORM_ERRORS_RESET]: () => DEFAULT_ERROR_STATE,
      [FORM_CLEARED]: () => DEFAULT_ERROR_STATE,
    },
    DEFAULT_ERROR_STATE
  );

  return combineReducers<FormState<State>>({
    errors,
    form,
    isValid,
    isSubmitted,
    touched,
    validation,
  });
}
