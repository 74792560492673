import { SuperAdminConsoleGlobalSettings } from 'fims-api-types';

export enum SettingsFetchStatus {
  IDLE = 'IDLE',
  SETTINGS_FETCH_IN_PROGRESS = 'SETTINGS_FETCH_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum SettingsUpdateStatus {
  IDLE = 'IDLE',
  SETTINGS_UPDATE_IN_PROGRESS = 'SETTINGS_UPDATE_IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export const DEFAULT_SETTINGS: SuperAdminConsoleGlobalSettings = {
  alert: {
    aircraftBreaches: true,
    operationAreaBreaches: true,
    warningOrAlerts: true,
  },
  filter: {
    altitudeInFeet: {
      min: 0,
      max: 0,
    },
    speedInMetersPerSecond: {
      min: 0,
      max: 0,
    },
    alertIntervalInMinutes: 0,
  },
  tracking: {
    airwaysAtmFeed: true,
    droneDetection: true,
    airshareTracker: true,
  },
  trafficStatsAndAnalysis: {
    alert: {
      horizontalSeparationInKm: 0,
      verticalSeparationInFeet: 0,
    },
    breach: {
      horizontalSeparationInKm: 0,
      verticalSeparationInFeet: 0,
    },
  },
};
