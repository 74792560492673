import { takeLatest, call, put } from 'redux-saga/effects';

import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';
import { fimsAPI } from '~/clients/api';
import { conflictedFlightsFetchRequested } from '~/state/conflicted-flights/actions';
import {
  FlightRequestsActionType,
  FlightRequestsFetchFocussedRequestedAction,
  FlightRequestsUpdateDecisionRequestedAction,
  flightRequestsFetchFocussedFailed,
  flightRequestsFetchFocussedSucceeded,
  flightRequestsUpdateDecisionFailed,
  flightRequestsUpdateDecisionSucceeded,
} from './actions';

interface FlightRequestResponse {
  data: DetailedFlightRequestViewModelV2;
}

function* fetchFlightRequest({
  payload: { flightId },
}: FlightRequestsFetchFocussedRequestedAction) {
  try {
    const { data }: FlightRequestResponse = yield call(
      fimsAPI.get,
      `/flight-requests/${flightId}`
    );

    yield put(flightRequestsFetchFocussedSucceeded(data));
  } catch (e) {
    yield put(
      flightRequestsFetchFocussedFailed(
        'We could not retrieve details for this flight'
      )
    );
    throw e;
  }
}

function* updateDecisionRequest({
  payload,
}: FlightRequestsUpdateDecisionRequestedAction) {
  const { atcNote, flightId, operatorNote, statusCode, changes } = payload;
  try {
    const { data }: FlightRequestResponse = yield call(
      fimsAPI.post,
      `/flight-requests/${flightId}/decision`,
      { atcNote, operatorNote, statusCode, changes }
    );
    yield put(flightRequestsUpdateDecisionSucceeded(data));
    yield put(conflictedFlightsFetchRequested());
  } catch (e) {
    yield put(flightRequestsUpdateDecisionFailed(''));
    throw e;
  }
}

export function* focussedFlightRequestSaga() {
  yield takeLatest(
    FlightRequestsActionType.FETCH_FOCUSSED_REQUESTED,
    fetchFlightRequest
  );
  yield takeLatest(
    FlightRequestsActionType.UPDATE_DECISION_REQUESTED,
    updateDecisionRequest
  );
}
