import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  TextareaAutosize,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { fimsAPI } from '~/clients/api';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    title: {
      fontSize: 20,
    },
    closeButton: {
      position: 'absolute',
      right: 12,
      top: 12,
      color: 'gray',
    },
    errorMessage: {
      textAlign: 'right',
      width: '100%',
    },
    precannedMessagesPanel: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop: '1.5rem',
      marginBottom: '0.2rem',
      paddingBottom: '0.4rem',
      width: '100%',
    },
    loaderPanel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    chip: {
      margin: '0.2rem',
      height: '1.6rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flex: '40%',
    },
    form: {
      width: '100%',
    },
    textarea: {
      resize: 'none',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '0.5rem',
    },
  })
);

export const ConfirmActionDialog = (properties: {
  description: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  placeholder: string;
  precannedMessagesAppCode: string;
  callback: Function;
  title: string;
}) => {
  const classes = useStyles();

  const [isSubmitting, setIssubmitting] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [precannedMessages, setPrecannedMessages] = useState<string[]>([]);

  const { description, open, placeholder, callback, setOpen, title } =
    properties;

  const setPrecannedMessage = (message: string) => {
    setReason(message);
  };

  useEffect(() => {
    setErrorMessage('');
    setIssubmitting(false);
    if (open) {
      fimsAPI
        .get(`/app-config/${properties.precannedMessagesAppCode}`)
        .then((response) => {
          setPrecannedMessages(response.data);
        })
        .catch((error) => {
          console.error(error);
          setPrecannedMessages([]);
        });
    } else {
      setPrecannedMessages([]);
    }
  }, [open, properties]);

  const handleClickSend = async () => {
    setErrorMessage('');
    setIssubmitting(true);
    try {
      callback(reason);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIssubmitting(false);
    }
  };

  function closeButtonHandler() {
    setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="confirm-action-dialog"
        aria-describedby="confirm-action-dialog"
        fullWidth
        style={{ zIndex: 1400 }}
        maxWidth="sm"
      >
        <DialogTitle disableTypography>
          <Typography className={classes.title}>{title}</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={closeButtonHandler}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
            {errorMessage && (
              <Typography
                variant="body2"
                color="error"
                className={classes.errorMessage}
              >
                {errorMessage}
              </Typography>
            )}
          </DialogContentText>

          <div className={classes.precannedMessagesPanel}>
            {precannedMessages.length === 0 && (
              <div className={classes.loaderPanel}>
                <CircularProgress size={24} />
              </div>
            )}
            {precannedMessages.map((item, index) => {
              return (
                <Chip
                  key={index}
                  size="medium"
                  className={classes.chip}
                  variant="outlined"
                  color="default"
                  label={item}
                  onClick={() => {
                    setPrecannedMessage(item);
                    setCanSubmit(true);
                  }}
                />
              );
            })}
          </div>
          <FormControl className={classes.form}>
            <TextareaAutosize
              data-testid="authorisation-decline-reason"
              minRows={4}
              maxRows={4}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setCanSubmit(e.target.value.trim().length > 0);
              }}
              className={classes.textarea}
              aria-label="maximum height"
              maxLength={1000}
              placeholder={placeholder}
            />
            <FormHelperText className="input-hint"></FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            data-testid="dialog-close-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSend}
            disabled={!canSubmit || isSubmitting}
            data-testid="dialog-submit-button"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmActionDialog;
