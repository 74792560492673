import React, { useMemo, useState } from 'react';
import {
  DialogTitle,
  DialogActions,
  Dialog,
  Button,
  PropTypes,
  DialogProps,
} from '@material-ui/core';

export interface ButtonOptions {
  text?: string;
  style?: React.CSSProperties;
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
}

export function useConfirmDialog(
  title: string,
  onConfirm: (event?: any) => void,
  onCancel: (event?: any) => void,
  confirmButtonOptions: ButtonOptions = {
    text: 'Confirm',
    variant: 'contained',
    color: 'primary',
    style: { marginLeft: '16px' },
  },
  cancelButtonOptions: ButtonOptions = {
    text: 'Cancel',
    variant: 'contained',
    color: 'primary',
    style: {},
  },
  continueButtonOptions?: ButtonOptions,
  dialogOptions: Partial<DialogProps> = {
    maxWidth: 'sm',
    fullWidth: true,
    scroll: 'paper',
  }
) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [contentToConfirm, setContentToConfirm] = useState<JSX.Element | null>(
    null
  );

  const dialog = useMemo(() => {
    const handleCancel = (event: any) => {
      if (onCancel) {
        onCancel(event);
      }
      setShowConfirm(false);
    };
    const handleConfirm = (event: any) => {
      if (onConfirm) {
        onConfirm(event);
      }
      setShowConfirm(false);
    };

    return (
      <Dialog
        maxWidth={dialogOptions.maxWidth ?? 'sm'}
        fullWidth={dialogOptions.fullWidth ?? true}
        scroll={dialogOptions.scroll ?? 'paper'}
        open={showConfirm}
      >
        <DialogTitle data-testid="confirm-dialog-title">{title}</DialogTitle>
        {contentToConfirm}
        <DialogActions>
          {cancelButtonOptions && (
            <Button
              variant={cancelButtonOptions.variant ?? 'contained'}
              color={cancelButtonOptions.color ?? 'primary'}
              style={cancelButtonOptions.style ?? {}}
              onClick={handleCancel}
              data-testid="confirm-dialog-cancel-button"
            >
              {cancelButtonOptions.text ?? 'Cancel'}
            </Button>
          )}
          {continueButtonOptions && (
            <Button
              variant={continueButtonOptions.variant ?? 'contained'}
              color={continueButtonOptions.color ?? 'primary'}
              style={continueButtonOptions.style ?? {}}
              onClick={() => setShowConfirm(false)}
              data-testid="confirm-dialog-continue-button"
            >
              {continueButtonOptions.text ?? 'Continue'}
            </Button>
          )}
          <Button
            variant={confirmButtonOptions.variant ?? 'contained'}
            color={confirmButtonOptions.color ?? 'primary'}
            style={confirmButtonOptions.style ?? { marginLeft: '16px' }}
            onClick={handleConfirm}
            data-testid="confirm-dialog-confirm-button"
          >
            {confirmButtonOptions.text ?? 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [
    title,
    onConfirm,
    onCancel,
    showConfirm,
    contentToConfirm,
    cancelButtonOptions,
    confirmButtonOptions,
    continueButtonOptions,
    dialogOptions,
  ]);

  return {
    dialog,
    showConfirm,
    setShowConfirm,
    contentToConfirm,
    setContentToConfirm,
  };
}
