import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CtrSettings from './CtrSettings';
import { SettingsModalTitles } from '../../helpers';

interface SettingsModalProps {
  closeModal(): any;
  open: boolean;
  title: SettingsModalTitles;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      height: 640,
      width: 500,
      color: 'rgb(105, 105, 105)',
    },
    group: {
      flex: 1,
      justifyContent: 'space-between',
    },
    inlineItem: {
      display: 'flex',
      paddingTop: theme.spacing(1),
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      height: 640,
      alignItems: 'center',
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  })
);

function SettingsModal({
  closeModal,
  open,
  title,
}: Readonly<SettingsModalProps>) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogContent className={classes.content}>
        <div className={classes.section}>
          <div className={classes.group}>
            <Typography variant="h6" data-testid="settings-modal-heading">
              {title}
            </Typography>
            <div className={classes.inlineItem}>
              <CtrSettings loadingStyle={classes.loading} title={title} />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SettingsModal;
