import React from 'react';
import { SplashPage } from './splash-page';

import './download-mobile-app.scss';

export function DownloadMobileApp() {
  return (
    <SplashPage
      primaryText="This website is optimised for tablet, laptop or desktop"
      secondaryText="If using a tablet please rotate to landscape mode"
      tertiaryText="If you are using a smaller device please download the Airshare mobile app from your App Store"
    >
      <div className="app-link-container">
        <a
          className="app-link"
          href="https://apps.apple.com/nz/app/id1455453643"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="badge apple" />
        </a>
        <a
          className="app-link"
          href="https://play.google.com/store/apps/details?id=com.airshare.flightplanner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="badge google" />
        </a>
      </div>
    </SplashPage>
  );
}
