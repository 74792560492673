import { FRStatusCode } from '../flight-requests';

export const FlightRequestTemplate = {
  [FRStatusCode.Declared]: 'fr-no-clearance',
  [FRStatusCode.ProvAuth]: 'fr-prov-auth',
  [FRStatusCode.Authorized]: 'fr-prov-auth',
  [FRStatusCode.RNZAFAuthReq]: 'fr-rnzaf-auth-req',
  [FRStatusCode.Pending]: 'fr-pending',
  [FRStatusCode.Cancelled]: 'fr-cancelled',
  [FRStatusCode.Declined]: 'fr-declined',
  [FRStatusCode.Conflicted]: 'fr-conflicted',
  [FRStatusCode.Terminated]: 'fr-terminated',
};

export const MultiFlightRequestTemplate: { [index: string]: string } = {
  [FRStatusCode.ProvAuth]: 'fr-multi-provauth',
  [FRStatusCode.Authorized]: 'fr-multi-authorised',
  [FRStatusCode.Declined]: 'fr-multi-declined',
  [FRStatusCode.Pending]: 'fr-multi-pending',
};
