import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { call, put, takeLatest } from 'redux-saga/effects';

import { FlightRequestViewModel } from 'fims-api-types';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { fimsAPI } from '~/clients/api';
import { AppState } from './store';

export interface FlightRequestsWithFlightAreaState {
  data: FlightRequestViewModel[];
  error: string;
}

enum FlightRequestsWithFlightAreaActionType {
  FETCH_FLIGHT_REQUESTS = 'flight-requests-with-flight-areaFETCH',
  FETCH_SUCCESS = 'flight-requests-with-flight-areaFETCH_SUCCESS',
  FETCH_FAILURE = 'flight-requests-with-flight-areaFETCH_FAILURE',
}

interface FlightRequestsWithFlightAreaAction {
  type: FlightRequestsWithFlightAreaActionType.FETCH_FLIGHT_REQUESTS;
}

interface FlightRequestsWithFlightAreaSuccessAction {
  type: FlightRequestsWithFlightAreaActionType.FETCH_SUCCESS;
  payload: FlightRequestViewModel[];
}

interface FlightRequestsWithFlightAreaFailureAction {
  type: FlightRequestsWithFlightAreaActionType.FETCH_FAILURE;
  payload: string;
}

// action creators
export const fetchFlightRequestsWithFlightArea =
  (): FlightRequestsWithFlightAreaAction => ({
    type: FlightRequestsWithFlightAreaActionType.FETCH_FLIGHT_REQUESTS,
  });

// reducers
const listReducer = createReducer(
  {
    [FlightRequestsWithFlightAreaActionType.FETCH_SUCCESS]: (
      _: FlightRequestViewModel[],
      { payload }: FlightRequestsWithFlightAreaSuccessAction
    ) => payload,
  },
  []
);

const errorReducer = createReducer({
  [FlightRequestsWithFlightAreaActionType.FETCH_FLIGHT_REQUESTS]: (): null =>
    null,
  [FlightRequestsWithFlightAreaActionType.FETCH_SUCCESS]: (): null => null,
  [FlightRequestsWithFlightAreaActionType.FETCH_FAILURE]: (
    _: string,
    { payload }: FlightRequestsWithFlightAreaFailureAction
  ) => payload,
});

const flightRequestsWithFlightAreaReducer = combineReducers({
  data: listReducer,
  error: errorReducer,
});

export default flightRequestsWithFlightAreaReducer;

// selectors
const selectLocalState = (state: AppState) =>
  state.flightRequestsWithFlightArea;

export const selectFlightRequestsWithFlightArea = createSelector(
  [selectLocalState],
  (state: FlightRequestsWithFlightAreaState) => state.data
);

export const selectError = createSelector(
  [selectLocalState],
  (state: FlightRequestsWithFlightAreaState) => state.error
);

// sagas
export function* flightRequestsWithFlightAreaSaga() {
  yield takeLatest(
    FlightRequestsWithFlightAreaActionType.FETCH_FLIGHT_REQUESTS,
    onFetchFlightRequestsWithFlightArea
  );
}

function* onFetchFlightRequestsWithFlightArea() {
  try {
    const { data: result } = yield call(fimsAPI.get, '/flight-requests-today');

    yield put({
      type: FlightRequestsWithFlightAreaActionType.FETCH_SUCCESS,
      payload: result,
    });
  } catch (e) {
    yield put({
      type: FlightRequestsWithFlightAreaActionType.FETCH_FAILURE,
      payload: 'Failed to load stuff!',
    });
  }
}

// hooks
export function useFlightRequestsWithFlightArea(): [
  FlightRequestViewModel[],
  () => FlightRequestsWithFlightAreaAction,
] {
  const flights = useSelector(selectFlightRequestsWithFlightArea);

  return [flights, useFetchFlightRequestsWithFlightArea()];
}

export function useFetchFlightRequestsWithFlightArea(): () => FlightRequestsWithFlightAreaAction {
  const dispatch = useDispatch();

  return () => dispatch(fetchFlightRequestsWithFlightArea());
}
