import { Schema, Document } from 'mongoose';
import type { Feature, Point } from 'geojson';
import { ObjectId } from 'mongodb';
import { generateFeatureSchema, pointSchema } from '../geojson/geometry';
import { toJsonOptions } from '../../../lib/shared-schema-options';

export enum MapPointCode {
  HangGliding = 'hang-gliding',
  Parachuting = 'parachuting',
}

export interface MapPointProperties {
  code: MapPointCode;
  name: string;
  isActive: boolean;
  height?: {
    AGL?: number; // At Ground Level
    MSL?: number; // Mean Sea Level
  };
}

export interface MapPoint extends Feature<Point, MapPointProperties> {
  authorizer?: ObjectId;
}

export type MapPointSchema = MapPoint & Document;

const mapPointsSchema = new Schema<MapPointProperties>(
  {
    code: {
      type: String,
      enum: Object.values(MapPointCode),
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: true,
      required: true,
    },
    height: {
      type: Object,
      required: false,
      AGL: {
        type: Number,
        required: false,
      },
      MSL: {
        type: Number,
        required: false,
      },
    },
  },
  { _id: false }
);

const featureSchema = generateFeatureSchema<Point, MapPointProperties>(
  pointSchema,
  mapPointsSchema
);

const mapPointSchema = new Schema<MapPointSchema>(
  // @ts-ignore
  {
    ...featureSchema.obj,
    authorizer: {
      type: Schema.Types.ObjectId,
      ref: 'Authorizer',
      default: undefined,
    },
  }
);

mapPointSchema.set('toJSON', toJsonOptions);

export default mapPointSchema;
