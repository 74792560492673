import React from 'react';
import cx from 'classnames';

import {
  CertificationType,
  formatStatus,
  formatCombinedSegmentStatus,
  type FRStatusCode,
  type StyledFeature,
} from 'fims-api-types';
import { mapToEuroRegOperationType } from 'argus-common/european-regulations.lib';
import { FlightRule } from 'argus-common/enums';

import { getStatusClassnames } from '../../../helpers';

export const flightStatusIcon = (statusCode: FRStatusCode) => (
  <span
    data-testid="flight-heading-status-icon"
    className={cx('flight-heading-status', ...getStatusClassnames(statusCode))}
  >
    {formatStatus(statusCode)}
  </span>
);

export const segmentStatusIcon = (properties: StyledFeature['properties']) => (
  <span
    data-testid={`segment-heading-status-icon-${properties.segmentId}`}
    className={'flight-heading-status'}
    style={{
      backgroundColor: properties.areaStyling.fillColor,
      borderColor: properties.areaStyling.strokeColor,
      borderWidth: 1,
      borderStyle: 'solid',
    }}
  >
    {formatCombinedSegmentStatus(properties.status, properties.clearance)}
  </span>
);

export const rulePartIcon = (
  rule: FlightRule | CertificationType
): JSX.Element => {
  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';
  const isPart102 = rule === FlightRule.PART_102;

  return (
    <span
      style={{
        backgroundColor: isPart102 ? '#ff8219' : '#bbb',
        borderRadius: 15,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
        width: 80,
        minWidth: 80,
        color: '#fff',
      }}
    >
      {isEuroRegulation ? mapToEuroRegOperationType(rule) : rule}
    </span>
  );
};
