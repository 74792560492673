import React, { useEffect, useState, useCallback } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { UavViewModel } from 'fims-api-types';

import { useTableStyles, Order } from '~/lib/table';
import {
  TABLE_HEADINGS,
  DEFAULT_PAGE_OPTIONS,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_COUNT,
  DEFAULT_SORT_KEY,
} from './uavs-table-constants';
import * as fimsClient from '../../../clients/fims-api-client';

import './uavs.scss';

function Uavs() {
  const classes = useTableStyles({});
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_KEY);
  const [order, setOrder] = useState(Order.ascending);
  const [uavs, setUavs] = useState([]);
  const [meta, setMeta] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: 'name',
    order: Order.ascending,
    totalRecords: 0,
  });

  const getUAVs = useCallback(
    async (newPage: number, pageSize: number) => {
      const uavsData = await fimsClient.getUavs(
        newPage,
        pageSize,
        sortBy,
        order
      );
      setMeta({
        pageIndex: uavsData.pageIndex,
        pageSize: uavsData.pageSize,
        sortBy,
        order,
        totalRecords: uavsData.totalRecords,
      });
      setUavs(uavsData.data);
    },
    [order, sortBy]
  );

  useEffect(() => {
    getUAVs(DEFAULT_PAGE, 10);
  }, [getUAVs]);

  const handleChangePage = (_event: any, newPage: number) => {
    getUAVs(newPage, meta.pageSize);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getUAVs(DEFAULT_PAGE, parseInt(event.target.value, 10));
  };

  function onSortClick(heading: string) {
    setSortBy(heading);

    const updatedOrder =
      order === Order.ascending ? Order.descending : Order.ascending;
    setOrder(updatedOrder);

    getUAVs(meta.pageIndex, meta.pageSize);
  }

  return (
    <>
      <div className="uavs">
        <Typography
          className="uavs__heading"
          variant="h3"
          data-testid="uav-heading"
        >
          UAVs
        </Typography>
        <Paper className={classes.root}>
          <Table className="table">
            <TableHead>
              <TableRow>
                {TABLE_HEADINGS.map((heading, index) => (
                  <TableCell align={heading.align} key={index}>
                    {['email', 'givenName', 'company'].includes(
                      heading.sortKey
                    ) ? (
                      heading.label
                    ) : (
                      <TableSortLabel
                        active={sortBy === heading.sortKey}
                        direction={order}
                        onClick={() => onSortClick(heading.sortKey)}
                      >
                        {heading.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {uavs.map((uav: UavViewModel, index) => (
                <TableRow
                  className="table__row"
                  key={index}
                  data-testid="uav-table-row"
                >
                  <TableCell>{uav.pilot.email}</TableCell>
                  <TableCell>
                    {[uav.pilot.givenName, uav.pilot.familyName].join(' ')}
                  </TableCell>
                  <TableCell>{uav.pilot.company}</TableCell>
                  <TableCell>{uav.name}</TableCell>
                  <TableCell>{uav.manufacturer}</TableCell>
                  <TableCell>{uav.model}</TableCell>
                  <TableCell>{uav.weight}</TableCell>
                  <TableCell>{uav.serialNumber || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
            component="div"
            count={meta ? meta.totalRecords : DEFAULT_ROWS_COUNT}
            rowsPerPage={meta ? meta.pageSize : DEFAULT_ROWS_PER_PAGE}
            page={meta ? meta.pageIndex : DEFAULT_PAGE}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Uavs;
