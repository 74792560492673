import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from 'asm-web-components';

import {
  FRStatusCode,
  DetailedFlightRequestViewModelV2,
  getConsolidatedStatus,
  StyledFeatures,
} from 'fims-api-types';
import * as fimsApi from '../../../clients/fims-api-client';
import {
  FocussedFlightRequestFetchStatus,
  useFocussedFlightRequest,
} from '../../../state/focussed-flight-request';
import { useSurveillanceWithLatency } from '../../../websocket/use-surveillance';
import { useProfile } from '../../../state/session';
import DetailMap from '../../shared/detail-map/index';
import ErrorMessage from '../../shared/error-message/error-message';
import DetailPageHeader from '../common/detail-page-header';
import GoogleApiLoader from '../../../components/shared/google-api-loader';

import FlightInformation from './flight-information/FlightInformation';
import ActionPanel from './action-panel/action-panel';
import { flightStatusIcon } from './flight-information/StatusPills';

import './flight.scss';

const Flight = () => {
  const params = useParams();

  const flightId = Number(params.flightId);
  const { authorizer } = useProfile();

  const [surveillanceData] = useSurveillanceWithLatency();

  const { fetchStatus } = useFocussedFlightRequest(flightId);
  const [focussedFlightRequest, setFocussedFlightRequest] =
    useState<DetailedFlightRequestViewModelV2>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [focussedStatus, setFocussedStatus] = useState<{
    canAuthorize: boolean;
    code: FRStatusCode;
  } | null>(null);
  const [flightAreas, setFlightAreas] = useState<StyledFeatures>([]);
  const [segmentsOpen, setSegmentsOpen] = useState<string[]>([]);

  const isLoading =
    !(focussedFlightRequest || errorMessage) ||
    fetchStatus === FocussedFlightRequestFetchStatus.FOCUSSED_FETCH_IN_PROGRESS;

  useEffect(() => {
    if (focussedFlightRequest) {
      const status = focussedFlightRequest?.status.find(
        (x) => x.authorizer === authorizer?.name
      );

      const shouldRefresh =
        focussedStatus?.code === undefined ||
        status?.code !== focussedStatus?.code ||
        JSON.stringify(focussedFlightRequest.flightAreas) !==
          JSON.stringify(flightAreas);

      if (shouldRefresh) {
        const { code, canAuthorize } = getConsolidatedStatus(
          focussedFlightRequest.status,
          status
        );
        setFocussedStatus({
          code,
          canAuthorize,
        });
        setFlightAreas(focussedFlightRequest.flightAreas);
      }
    }
  }, [focussedFlightRequest, authorizer, focussedStatus, flightAreas]);

  useEffect(() => {
    let shouldSetFlight = true;
    const fetchFlight = async () => {
      try {
        const flightRequest = await fimsApi.getFlightRequest(flightId);
        setErrorMessage('');
        if (shouldSetFlight) setFocussedFlightRequest(flightRequest);
      } catch (e) {
        setErrorMessage(
          e?.message ||
            `Error retrieving flight request for flightId ${flightId}`
        );
        if (shouldSetFlight) setFocussedFlightRequest(null);
      }
    };

    fetchFlight();
    const id = setInterval(() => fetchFlight(), 5000);

    return () => {
      shouldSetFlight = false;
      clearInterval(id);
    };
  }, [flightId]);

  return (
    <div className="flight" data-testid="flight">
      <div className="flight-details">
        {isLoading ? (
          <div className="flight__loading-container">
            <Loading className="loading" />
          </div>
        ) : errorMessage ? (
          <ErrorMessage main="Error" sub={errorMessage} />
        ) : (
          <>
            <DetailPageHeader
              title={`Flight ID #${focussedFlightRequest.flightId}`}
              status={flightStatusIcon(focussedStatus?.code)}
            />
            <FlightInformation
              flightId={flightId}
              focussedFlightRequest={focussedFlightRequest}
              setFocussedFlightRequest={setFocussedFlightRequest}
              setErrorMessage={setErrorMessage}
              segmentsOpen={segmentsOpen}
              setSegmentsOpen={setSegmentsOpen}
            />
          </>
        )}
      </div>

      <div className="flight-map" data-testid="flight-map">
        <div className="detail-map-wrapper">
          <GoogleApiLoader className="detail-map">
            <DetailMap
              surveillanceData={surveillanceData}
              center={focussedFlightRequest?.deadCenter}
              flightAreas={flightAreas}
              flightRequest={focussedFlightRequest}
              flightMarkerAction={(id: string) => setSegmentsOpen([id])}
            />
          </GoogleApiLoader>
        </div>
        <ActionPanel
          focussedFlightRequest={focussedFlightRequest}
          focussedStatus={focussedStatus}
        />
      </div>
    </div>
  );
};

export default Flight;
