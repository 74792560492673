import React, { useEffect } from 'react';
import LuxonUtils from '@date-io/luxon';
import { BrowserRouter } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { ErrorBoundary } from 'common-ui-components';
import {
  ErrorPage,
  MobileNotSupported,
  Offline,
} from 'asm-web-components/src/components';
import { useMobileDetection, useOfflineListeners } from '../../hooks';
import { theme } from './mui-theme';

import ProtectedLayout from '../protected-layout/protected-layout';
import AuthLayout from '../auth-layout/auth-layout';
import { useIsAuthenticated } from '../../state/session/hooks';
import { fimsAPI } from './../../clients/api';

function App() {
  const isMobile = useMobileDetection();
  const isAuthenticated = useIsAuthenticated();
  const { isOfflineMode, isBackendOfflineMode } = useOfflineListeners();

  useEffect(() => {
    loadReCaptcha();
  }, []);

  if (isOfflineMode || isBackendOfflineMode) {
    return (
      <Offline
        isOffline={isOfflineMode}
        isBackendOffline={isBackendOfflineMode}
        retry={() => {
          fimsAPI.get('/flight-requests');
        }}
      />
    );
  }

  if (isMobile) return <MobileNotSupported />;

  return (
    <ErrorBoundary customErrorPage={ErrorPage}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {isAuthenticated ? <ProtectedLayout /> : <AuthLayout />}
          </BrowserRouter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
}

export default App;
