import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  DialogContentText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';

import { CreateOrgRequestBody, OrganisationResponse } from 'fims-api-types';
import * as fimsClient from '../../../clients/fims-api-client';
import { useStyles } from './useStyles';
import {
  contactDetailsFormSchema,
  organisationFormSchema,
} from './organisation.lib';
import { ATC_AUTH_DEFAULT_NAME } from 'argus-api-shared/lib/constants';

interface OrgModalProps {
  closeModal(): any;
  organisation: OrganisationResponse;
}

function EditOrganisationModal({
  closeModal,
  organisation,
}: Readonly<OrgModalProps>) {
  const classes = useStyles();
  const { name, id, contactDetails, authorizer } = organisation;
  const isNew = id === 'new';

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedAddress, setUpdatedAdaddress] = useState(
    contactDetails?.address || ''
  );
  const [updatedEmail, setUpdatedEmail] = useState(contactDetails?.email || '');
  const [updatedPhone, setUpdatedPhone] = useState(contactDetails?.phone || '');
  const [updatedWebsite, setUpdatedWebsite] = useState(
    contactDetails?.website || ''
  );
  const [updatedAuthorizer, setUpdatedAuthorizer] = useState(authorizer);
  const [authorizers, setAuthorizers] = useState<
    fimsClient.AuthorizerResponse[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  async function getAuthorizers() {
    const data = await fimsClient.getAuthorizers();
    setAuthorizers(data);
  }

  useEffect(() => {
    getAuthorizers();
  }, []);

  const handleSubmit = async (_event: any): Promise<void> => {
    setIsSubmitting(true);
    const params: CreateOrgRequestBody = {
      name: updatedName.trim(),
      authorizer: updatedAuthorizer,
      contactDetails: {
        email: updatedEmail,
        address: updatedAddress.trim(),
        phone: updatedPhone,
        website: updatedWebsite,
      },
    };
    try {
      await organisationFormSchema.validate(params, {
        strict: true,
        abortEarly: false,
      });

      await contactDetailsFormSchema.validate(params.contactDetails, {
        strict: true,
        abortEarly: false,
      });

      if (isNew) {
        await fimsClient.createOrganisation(params);
      } else {
        await fimsClient.updateOrganisation({ id, ...params });
      }
      setIsSubmitting(false);
      closeModal();
    } catch (e: any) {
      document.getElementById('scrollLink').scrollIntoView();
      setError(e.errors ? e.errors.join(', ') : e.toString());
      setIsSubmitting(false);
    }
  };

  const atcAuthName =
    window.env.AIR_TRAFFIC_CONTROL_DISPLAY_NAME ?? 'Airways ATC';
  const getAuthName = (auth: string) =>
    auth === ATC_AUTH_DEFAULT_NAME ? atcAuthName : auth;

  const submitButtonText = isSubmitting ? (
    <CircularProgress size={24} color="inherit" />
  ) : isNew ? (
    'Save Organisation'
  ) : (
    'Save Changes'
  );

  return (
    <Dialog onClose={closeModal} open>
      <DialogTitle data-testid="organisation-modal-header">{`${
        isNew ? 'Add' : 'Update'
      } Organisation`}</DialogTitle>
      <br />
      <DialogContent className={classes.content}>
        <FormControl>
          <TextField
            type="text"
            label="Name"
            name="name"
            className={classes.textField}
            value={updatedName}
            required
            onChange={(e) => setUpdatedName(e.target.value)}
            data-testid="organisation-name"
          />
        </FormControl>
        <FormControl>
          <InputLabel data-testid="label">Authoriser</InputLabel>
          {authorizers.length > 0 && (
            <Select
              className={classes.input}
              displayEmpty
              data-testid="authorizer"
              name="authorizer"
              value={updatedAuthorizer || ''}
              required
              onChange={(e) => setUpdatedAuthorizer(e.target.value as string)}
            >
              {!updatedAuthorizer && <MenuItem value="" />}
              {authorizers.map(
                (authorizerItem: fimsClient.AuthorizerResponse) => (
                  <MenuItem
                    value={authorizerItem.id}
                    key={authorizerItem.id}
                    data-testid="authorizer-list-item"
                  >
                    {getAuthName(authorizerItem.name)}
                  </MenuItem>
                )
              )}
            </Select>
          )}
        </FormControl>
        <br />
        <DialogContentText>Contact details</DialogContentText>
        <br />
        <FormControl>
          <TextField
            type="email"
            label="Email"
            name="email"
            className={classes.textField}
            value={updatedEmail}
            required
            onChange={(e) => setUpdatedEmail(e.target.value)}
            data-testid="organisation-email"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="text"
            label="Address"
            name="address"
            className={classes.textField}
            value={updatedAddress}
            required
            onChange={(e) => setUpdatedAdaddress(e.target.value)}
            data-testid="organisation-address"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="tel"
            label="Phone"
            name="phone"
            className={classes.textField}
            value={updatedPhone}
            onChange={(e) => setUpdatedPhone(e.target.value)}
            data-testid="organisation-phone"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="text"
            label="Website"
            name="website"
            className={classes.textField}
            value={updatedWebsite}
            onChange={(e) => setUpdatedWebsite(e.target.value)}
            data-testid="organisation-website"
          />
        </FormControl>
        <Typography
          className={classnames(classes.item, classes.error)}
          data-testid="organisation-modal-error-message"
        >
          {error}
        </Typography>
        <span id="scrollLink" data-testid="scrollLink" />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
          data-testid="organisation-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isSubmitting ? () => {} : handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={classes.saveButton}
          data-testid="organisation-modal-save-button"
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditOrganisationModal;
