import React from 'react';
import Typography from '@material-ui/core/Typography';

import './password-changed.scss';

export default function PasswordChanged() {
  return (
    <>
      <Typography className="verification-heading" variant="subtitle1">
        Your password was successfully changed
      </Typography>
      <div className="big-tick" />
    </>
  );
}
