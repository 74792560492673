/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { getAreas, getSubscriptions } from './subscriptions-api-client';
import { Paper, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export function ConfigForm(props: any) {
  const params = useLocation();
  const searchParams = new URLSearchParams(params.search);

  const [username, setUsername] = useState<string>(
    searchParams.get('username') ? searchParams.get('username') : ''
  );
  const [password, setPassword] = useState<string>(
    searchParams.get('password') ? searchParams.get('password') : ''
  );
  const [endpoint, setEndpoint] = useState<string>(
    searchParams.get('endpoint') ? searchParams.get('endpoint') : ''
  );
  const [organisationId, setOrganisationId] = useState<string>(
    searchParams.get('organisationId') ? searchParams.get('organisationId') : ''
  );
  const [accessKey, setAccessKey] = useState<string>(
    searchParams.get('accessKey') ? searchParams.get('accessKey') : ''
  );

  const [initButtonDisabled, setInitButtonDisabled] = useState<boolean>(true);

  function tryEnableInitButton() {
    if (!username || !password || !endpoint || !organisationId || !accessKey) {
      setInitButtonDisabled(true);
      return;
    }
    setInitButtonDisabled(false);
  }

  useEffect(() => {
    tryEnableInitButton();
  });

  const initData = async () => {
    try {
      //TODO: review get areas, looks like pagination is not working as expected
      //TODO: Check all identifiers are different
      let responseAreas = await getAreas(endpoint, organisationId, accessKey);
      let areas = responseAreas.data.result.features;
      responseAreas = await getAreas(endpoint, organisationId, accessKey, 1);
      areas = areas.concat(responseAreas.data.result.features);
      const responseSubscriptions = await getSubscriptions(
        endpoint,
        username,
        password
      );

      props.onConfigSet(
        username,
        password,
        endpoint,
        organisationId,
        accessKey,
        areas,
        responseSubscriptions
      );
    } catch (error: any) {
      console.log('There is an error in the configuration', error);
      window.alert(
        'There is an error in the configuration, plese review the values set and try again'
      );
    }
  };

  const handleClickInitButton = () => {
    initData();
  };

  return (
    <div className="auth">
      <h3>Subscriptions Demo</h3>
      <br />
      <Paper className="demo-auth-form">
        <div className="panel">
          <Typography variant="h4">Configuration</Typography>

          <label htmlFor="username">username</label>
          <input
            id="username"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
              tryEnableInitButton();
            }}
          />

          <label htmlFor="password">password</label>
          <input
            id="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
              tryEnableInitButton();
            }}
          />

          <label htmlFor="endpoint">endpoint</label>
          <input
            id="endpoint"
            value={endpoint}
            onChange={(event) => {
              setEndpoint(event.target.value);
              tryEnableInitButton();
            }}
          />

          <label htmlFor="organisationId">organisationId</label>
          <input
            id="organisationId"
            value={organisationId}
            onChange={(event) => {
              setOrganisationId(event.target.value);
              tryEnableInitButton();
            }}
          />

          <label htmlFor="accessKey">accessKey</label>
          <input
            id="accessKey"
            value={accessKey}
            onChange={(event) => {
              setAccessKey(event.target.value);
              tryEnableInitButton();
            }}
          />

          <br />
          <br />

          <Button
            variant="contained"
            disabled={initButtonDisabled}
            onClick={handleClickInitButton}
          >
            Initiate
          </Button>
        </div>
      </Paper>
    </div>
  );
}
