import get from 'lodash/get';
import { put, takeLatest, takeEvery, getContext } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';
import { LOG_OUT } from 'airshare-web-utils/redux-helpers';

import { UserRole } from 'argus-common/enums';
import { fetchSettings } from '~/state/settings/actions';
import { authAPI, fimsAPI } from '~/clients/api';
import {
  SessionActionType,
  SessionAttemptLoginAction,
  setLoginStatus,
  setProfile,
  setError,
  setResetPasswordStatus,
  setChangePasswordStatus,
  SessionAttemptResetPasswordAction,
} from './actions';

import {
  actionCreators as loginFormActionCreators,
  actionCreators as resetPasswordFormActionCreators,
} from './auth-forms/login/actions';
import {
  LogInStatus,
  ResetPasswordStatus,
  ChangePasswordStatus,
} from './constants';

import { Socket } from 'socket.io-client';
import * as Sentry from '@sentry/browser';
import { Profile } from 'argus-common/interfaces';

const { call }: { call: any } = Eff;

const AUTHORIZED_ROLES = [
  UserRole.SUPER_ADMIN,
  UserRole.ADMIN,
  UserRole.VIEWER,
  UserRole.AUTHORISER,
];

export function* sessionSaga() {
  yield takeLatest(SessionActionType.ATTEMPT_LOG_IN, onAttemptLogIn);
  yield takeLatest(
    SessionActionType.ATTEMPT_RESET_PASSWORD,
    onAttemptResetPassword
  );
  yield takeLatest(
    SessionActionType.ATTEMPT_CHANGE_PASSWORD,
    onAttemptChangePassword
  );
  yield takeEvery(LOG_OUT, onLogOut);

  const profileJson: string = yield call([localStorage, 'getItem'], 'profile');
  const refreshToken: string = yield call(
    [localStorage, 'getItem'],
    'refreshToken'
  );

  if (profileJson) {
    const profile: Object = JSON.parse(profileJson);
    yield call(onSetProfile, profile, refreshToken);
    yield put(setProfile(profile as Profile));
  }
}

function* onAttemptLogIn({
  payload: { email, password, recaptchaToken },
}: SessionAttemptLoginAction) {
  const loginForm = {
    userName: email,
    password,
    origin: 'fims-web',
    recaptchaToken,
  };

  try {
    yield put(setLoginStatus(LogInStatus.LOG_IN_ATTEMPT_IN_PROGRESS));

    const { data } = yield call(authAPI.post, '/v2/log-in', loginForm);
    const { profile, refreshToken } = data;

    if (
      profile.roles.some((role: UserRole) => AUTHORIZED_ROLES.includes(role))
    ) {
      yield call(onSetProfile, profile, refreshToken);
      yield put(setProfile(profile));
      yield put(setLoginStatus(LogInStatus.SUCCESS));
    } else {
      yield put(setLoginStatus(LogInStatus.FAILED));
      yield put(
        loginFormActionCreators.formErrored({
          message:
            "Your account type doesn't have access, please register or contact the system administrators",
        })
      );
    }
  } catch (error) {
    yield put(setLoginStatus(LogInStatus.FAILED));

    if (!error.response || error.status === 500) {
      yield put(
        setError('An unexpected error has occured. Please try again later')
      );
      return;
    }

    const errors = get(error, 'response.data', {});

    const formErrors = {
      email: errors.userName,
      password: errors.password,
      message: errors.message,
    };

    yield put(loginFormActionCreators.formErrored(formErrors));
  }
}

function* onAttemptResetPassword({
  payload,
}: SessionAttemptResetPasswordAction) {
  try {
    yield put(
      setResetPasswordStatus(
        ResetPasswordStatus.RESET_PASSWORD_ATTEMPT_IN_PROGRESS
      )
    );

    yield call(authAPI.post, '/v2/reset-password', { email: payload });

    yield put(setResetPasswordStatus(ResetPasswordStatus.SUCCESS));
  } catch (error) {
    yield put(setResetPasswordStatus(ResetPasswordStatus.FAILED));

    const errors = get(error, 'response.data', {});

    yield put(resetPasswordFormActionCreators.formErrored(errors));
  }
}

function* onAttemptChangePassword({
  payload: { email, newPassword, resetKey },
}: SessionAttemptResetPasswordAction) {
  try {
    yield put(
      setChangePasswordStatus(
        ChangePasswordStatus.CHANGE_PASSWORD_ATTEMPT_IN_PROGRESS
      )
    );

    yield call(authAPI.post, '/v2/change-password', {
      email,
      newPassword,
      resetKey,
    });

    yield put(setChangePasswordStatus(ChangePasswordStatus.SUCCESS));
  } catch (error) {
    yield put(setChangePasswordStatus(ChangePasswordStatus.FAILED));

    const errors = get(error, 'response.data', {});

    yield put(resetPasswordFormActionCreators.formErrored(errors));
  }
}

function* onSetProfile(
  profile: { token?: string; email: string },
  refreshToken?: string
) {
  yield call([localStorage, 'setItem'], 'profile', JSON.stringify(profile));
  Sentry.setUser({ email: profile.email });
  if (refreshToken) {
    yield call([localStorage, 'setItem'], 'refreshToken', refreshToken);
  }
  yield call(authAPI.setAuthHeader, profile.token);
  yield call(fimsAPI.setAuthHeader, profile.token);
  yield put(fetchSettings());
}

function* onLogOut() {
  const socket: Socket = yield getContext('socket');
  if (socket) {
    socket.emit('logoff');
    socket.disconnect();
  }

  // Logout so that we clear the refresh token on the server
  const logoutForm = { origin: 'fims-web' };
  yield call(authAPI.post, '/v2/log-out', logoutForm);

  const browserId: string = yield call(
    [localStorage, 'getItem'],
    'pushNotification-browserId'
  );
  yield call([localStorage, 'clear']);
  if (browserId !== null) {
    yield call(
      [localStorage, 'setItem'],
      'pushNotification-browserId',
      browserId
    );
  }
  yield call(authAPI.clearAuthHeader);
  yield call(fimsAPI.clearAuthHeader);
}
