import { DateTime } from 'luxon';
import type { FlightRequest } from './database';
import { ExternalFlightResponseBody } from './responses';

export const ACTIVATABLE_TIME_PERIOD_MINS = 30;

export enum DigitalClearanceStatus {
  Requested = 'Requested',
  CallTower = 'Call Tower',
  Authorized = 'Authorized',
  Declined = 'Declined',
}
export interface DigitalClearanceFlightRequest {
  status?: DigitalClearanceStatus;
  reason?: string;
  updatedAt?: string;
  createdAt?: string;
  nextAllowableDateTime?: string;
  expiryPeriodMinutes: number;
  retryPeriodMinutes: number;
  activatablePeriodMinutes: number;
}

export interface ActivationEligibility {
  atcHasDigitalAuthEnabled: boolean;
  isActivatable: boolean;
  eligible: boolean;
  isSegmented: boolean;
  errors: string[];
}

export interface RequestRetry {
  nextAllowableDateTime?: string;
  expiryPeriodMinutes?: number;
  retryPeriodMinutes?: number;
  activatablePeriodMinutes?: number;
}
export type RequestActivationResponseBody = ExternalFlightResponseBody &
  RequestRetry;

export type ActivationResponseBody = ExternalFlightResponseBody;

export const CLEARANCE_EXPIRY_PERIOD_MINUTES =
  parseInt(process.env.CLEARANCE_EXPIRY_PERIOD_MINUTES!) || 5;
export const CLEARANCE_RETRY_PERIOD_MINUTES =
  parseInt(process.env.CLEARANCE_RETRY_PERIOD_MINUTES!) || 5;

export const nextAllowableDateTime = (
  digitalClearance: FlightRequest['digitalClearance'],
  flightDateTime: FlightRequest['flightDateTime']
) => {
  if (digitalClearance?.updatedAt) {
    if (digitalClearance?.status === DigitalClearanceStatus.Declined) {
      return DateTime.fromJSDate(digitalClearance?.updatedAt)
        .setZone(flightDateTime.timezone)
        .plus({
          minutes: CLEARANCE_RETRY_PERIOD_MINUTES,
        })
        .toISO();
    }
    if (digitalClearance?.status === DigitalClearanceStatus.Requested) {
      return DateTime.fromJSDate(digitalClearance?.updatedAt)
        .setZone(flightDateTime.timezone)
        .plus({
          minutes: CLEARANCE_EXPIRY_PERIOD_MINUTES,
        })
        .toISO();
    }
  }
  return undefined;
};

export const isRequestingDA = <
  F extends { digitalClearance?: FlightRequest['digitalClearance'] },
>(
  flight: F
): boolean => {
  return (
    flight.digitalClearance?.status === DigitalClearanceStatus.Requested &&
    flight.digitalClearance.updatedAt &&
    DateTime.fromISO(flight.digitalClearance?.updatedAt.toISOString()).plus({
      minutes: CLEARANCE_EXPIRY_PERIOD_MINUTES,
    }) > DateTime.now()
  );
};

export const isRequestingCallTower = <
  F extends { digitalClearance?: FlightRequest['digitalClearance'] },
>(
  flight: F
): boolean =>
  flight.digitalClearance?.status === DigitalClearanceStatus.CallTower;
