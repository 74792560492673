import { makeStyles } from '@material-ui/core/styles';
import { TableCellProps } from '@material-ui/core/TableCell';

export interface TableHeading {
  label: string;
  sortKey: string;
  align: TableCellProps['align'];
}

export enum Order {
  ascending = 'asc',
  descending = 'desc',
}

export const useTableStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
