import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  type StyledFeature,
  formatSegmentClearanceStatus,
  formatSegmentStatus,
} from 'fims-api-types';
import { mapToEuroRegOperationType } from 'argus-common/european-regulations.lib';

import { getShortFormattedDateTime } from '../../components/protected-layout/flight/timehelpers';

import './marker-info.scss';

export enum ItemDataType {
  ElapsedTime = 'elapsed_time',
}
export interface ListProps {
  key: string;
  value: string;
  isHighlighted?: boolean;
  itemDataType?: ItemDataType;
}

const renderListItem = (
  item: ListProps,
  details: boolean,
  titleWidth: number = 80
) => (
  <ListItem style={{ display: 'flex' }} key={item.key}>
    <ListItemText
      style={{
        maxWidth: details ? 130 : titleWidth,
        margin: 0,
      }}
      primaryTypographyProps={{
        style: {
          fontWeight: 600,
          width: details ? 130 : titleWidth,
          color: 'white',
          whiteSpace: 'nowrap',
        },
      }}
      primary={`${item.key}: `}
    />
    <ListItemText
      style={{
        margin: 0,
        padding: 2,
        background: item.isHighlighted ? '#e7aa01' : 'transparent',
      }}
      primaryTypographyProps={{
        style: {
          float: 'left',
          color: 'white',
        },
      }}
      data-type={item.itemDataType || undefined}
      data-value={item.itemDataType ? item.value : undefined}
      primary={item.itemDataType !== ItemDataType.ElapsedTime ? item.value : ''}
    />
  </ListItem>
);

export const markerInfoId = (
  { properties }: StyledFeature,
  details: boolean
) =>
  properties.segmentId && details
    ? `segment-${properties.segmentId}-marker-info`
    : `flight-${properties.flightId}-marker-info`;

export default function MarkerInfo({
  feature,
  details,
  timezone,
  additionalInfo = [],
}: {
  feature: StyledFeature;
  timezone: string;
  details: boolean;
  additionalInfo?: { key: string; value: string }[];
}) {
  const {
    flightId,
    segmentId,
    pilot,
    altitude,
    rule,
    status,
    clearance,
    localStartDateTime,
    localEndDateTime,
    exceededAllocatedTime,
  } = feature.properties;

  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';

  const flightIdInfo: ListProps[] =
    details && segmentId
      ? [
          { key: 'ID', value: `${flightId}-${segmentId}` },
          { key: 'Status', value: formatSegmentStatus(status) },
          {
            key: 'Activated Status',
            value: formatSegmentClearanceStatus(clearance),
          },
          { key: 'Altitude', value: altitude },
        ]
      : [
          { key: 'Flight ID', value: flightId?.toString() },
          { key: 'Pilot', value: pilot },
          { key: 'Altitude', value: altitude },
          {
            key: 'Rule',
            value: isEuroRegulation ? mapToEuroRegOperationType(rule) : rule,
          },
        ].concat(additionalInfo);
  const otherInfo = [
    {
      key: 'Start',
      value: getShortFormattedDateTime(localStartDateTime, timezone),
    },
    {
      key: 'End',
      value: getShortFormattedDateTime(localEndDateTime, timezone),
      isHighlighted: exceededAllocatedTime,
    },
  ];
  const info = flightIdInfo.concat(otherInfo);

  const customWidth = info.find((x) => x.itemDataType) ? 130 : 80;

  return (
    <List
      dense
      style={{ minWidth: 220, cursor: 'pointer' }}
      data-testid="flight-marker-info"
      id={markerInfoId(feature, details)}
    >
      {info.map((x) => renderListItem(x, details, customWidth))}
    </List>
  );
}
