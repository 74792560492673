import React from 'react';
import Button from '@material-ui/core/Button';

import { FRStatusCode, getStatusColor } from 'fims-api-types';

export const DeclaredButton = ({
  style,
  isWithinControlZone,
  handleOpenDialog,
  disabledButtons,
}: {
  style: object;
  isWithinControlZone: boolean;
  handleOpenDialog: (
    status: FRStatusCode,
    action: 'approve' | 'reject'
  ) => void;
  disabledButtons: Partial<{ [key in FRStatusCode]: boolean }>;
}) => {
  const isDisabled =
    disabledButtons[FRStatusCode.Declared] ||
    disabledButtons[FRStatusCode.Activated] ||
    !isWithinControlZone;

  return (
    <Button
      disabled={isDisabled}
      onClick={() => handleOpenDialog(FRStatusCode.Declared, 'approve')}
      value="approve"
      variant="contained"
      style={
        isDisabled
          ? style
          : {
              ...style,
              backgroundColor: getStatusColor(FRStatusCode.Declared),
              color: 'white',
            }
      }
      data-testid="flight-clear-required-button"
    >
      {`No ${isWithinControlZone ? 'ATC ' : ''}Auth Req.`}
    </Button>
  );
};
