import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  CertificationType,
  type DetailedFlightRequestViewModelV2,
} from 'fims-api-types';

import { rulePartIcon } from './StatusPills';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const How = ({ focussedFlightRequest }: Props) => {
  const [howOpen, setHowOpen] = useState(false);

  const [initialized, setInitialized] = useState(false);

  if (!initialized && focussedFlightRequest) {
    setInitialized(true);
    if (focussedFlightRequest.rule === CertificationType.PART_102)
      setHowOpen(true);
  }

  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';

  return (
    <div data-testid="flight-info-how">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setHowOpen(!howOpen)}
        data-testid="flight-how-heading"
      >
        <ListItemText primary="How" />
        {howOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={howOpen} timeout="auto" unmountOnExit>
        <table className="flight-info-table" data-testid="flight-how-table">
          <tbody>
            <tr>
              <th data-testid="flight-how-shielded-operation-title">
                Shielded Operation
              </th>
              <td data-testid="flight-how-shielded-operation-value">
                {focussedFlightRequest.isShielded ? 'Y' : 'N'}
              </td>
            </tr>
            {isEuroRegulation &&
              focussedFlightRequest?.uasOperationCategory && (
                <tr>
                  <th data-testid="flight-how-operation-category-title">
                    UAS Operation Category
                  </th>
                  <td data-testid="flight-how-operation-category-value">
                    {focussedFlightRequest.uasOperationCategory}
                  </td>
                </tr>
              )}
            <tr>
              <th data-testid="flight-how-operation-rule-title">
                Operating Rule
              </th>
              <td data-testid="flight-how-operation-rule-value">
                {rulePartIcon(focussedFlightRequest.rule)}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default How;
