import { useEffect, useState } from 'react';

export function useIsOffline() {
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    function handleStatus() {
      setIsOffline(!window.navigator.onLine);
    }
    if (!window.navigator.onLine) {
      setIsOffline(true);
    }
    window.addEventListener('online', handleStatus);
    window.addEventListener('offline', handleStatus);

    return () => {
      window.removeEventListener('online', handleStatus);
      window.removeEventListener('offline', handleStatus);
    };
  }, [isOffline, setIsOffline]);

  return isOffline;
}
