import React from 'react';
import { DialogContent, Typography, List } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NotificationResponse } from 'fims-api-types';
import { NotificationType } from 'argus-data-model/db/schemas/notifications';
import SmsNotification from './sms-notification';
import LandRequestNotification from './land-request-notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '1rem',
      backgroundColor: theme.palette.common.white,
      height: 600,
      overflow: 'auto',
      display: 'block',
      '&::-webkit-scrollbar': { display: 'inline', width: '5px' },
      '&::-webkit-scrollbar-track': { width: '5px' },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[500],
        borderRadius: '10px',
      },
    },
  })
);

const panelId = (id: string) => `notification-${id}`;

export default function Notifications({
  notifications = [],
  updateNotifications,
  setManuallyClosed,
}: Readonly<{
  notifications: NotificationResponse[];
  updateNotifications: () => Promise<void>;
  setManuallyClosed: (value: boolean) => void;
}>) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(
    panelId(notifications[0]?.id)
  );

  return (
    <DialogContent className={classes.dialogContent}>
      {notifications?.length === 0 && (
        <Typography data-testid="no-notificaton-text">
          No new notifications
        </Typography>
      )}
      <List data-testid="notifications-list">
        {notifications.map((notification) => {
          if (
            notification?.notificationType === NotificationType.SMS_RECEIVED
          ) {
            return (
              <SmsNotification
                key={notification.id}
                expanded={expanded === panelId(notification.id)}
                setExpanded={setExpanded}
                notification={notification}
                updateNotifications={updateNotifications}
                setManuallyClosed={setManuallyClosed}
              />
            );
          }
          if (
            notification?.notificationType ===
            NotificationType.LAND_REQUEST_FINISHED
          ) {
            return (
              <LandRequestNotification
                key={notification.id}
                expanded={expanded === panelId(notification.id)}
                setExpanded={setExpanded}
                notification={notification}
                updateNotifications={updateNotifications}
                setManuallyClosed={setManuallyClosed}
              />
            );
          }
          return null;
        })}
      </List>
    </DialogContent>
  );
}
