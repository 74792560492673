import { createAction } from 'airshare-web-utils/redux-helpers';
import { FlightAreaViewModel } from 'fims-api-types';

export enum FlightAreaActionType {
  FETCH_REQUESTED = 'flight-area/FETCH_REQUESTED',
  FETCH_SUCCEEDED = 'flight-area/FETCH_SUCCEEDED',
  FETCH_FAILED = 'flight-area/FETCH_FAILED',
  FETCH_RESET = 'flight-area/FETCH_RESET',
}

interface FlightIdPayload {
  flightId: number;
}

export interface FlightAreaFetchRequestsAction {
  type: FlightAreaActionType.FETCH_REQUESTED;
  payload: FlightIdPayload;
}

export interface FlightAreaFetchSucceededAction {
  type: FlightAreaActionType.FETCH_SUCCEEDED;
  payload: FlightAreaViewModel;
}

export interface FlightAreaFetchFailedAction {
  type: FlightAreaActionType.FETCH_FAILED;
}

export interface FlightAreaFetchResetAction {
  type: FlightAreaActionType.FETCH_RESET;
}

export const flightAreaFetchRequested: (
  payload: FlightIdPayload
) => FlightAreaFetchRequestsAction = createAction(
  FlightAreaActionType.FETCH_REQUESTED
);
export const flightAreaFetchSucceeded: (
  payload: FlightAreaViewModel
) => FlightAreaFetchSucceededAction = createAction(
  FlightAreaActionType.FETCH_SUCCEEDED
);
export const flightAreaFetchFailed: () => FlightAreaFetchFailedAction =
  createAction(FlightAreaActionType.FETCH_FAILED);
export const flightAreaFetchReset: () => FlightAreaFetchResetAction =
  createAction(FlightAreaActionType.FETCH_RESET);
