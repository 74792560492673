import { useContext } from 'react';
import * as fimsClient from '~/clients/fims-api-client';
import { LoginContext } from '../context/LoginState';

const emergencyAirspaceClosureEnabled =
  window.env.EMERGENCY_AIRSPACE_CLOSURE_ENABLED?.toLowerCase() === 'true';

export default function useEmergencyAirspaceClosure() {
  const {
    airspaceIsClosed,
    setAirspaceIsClosed,
    setUserCanUpdateEmergencyAirspaceClosure,
  } = useContext(LoginContext);

  async function toggleEmergencyAirspaceClosure() {
    if (!emergencyAirspaceClosureEnabled) {
      return false;
    }

    let response = !airspaceIsClosed;
    if (airspaceIsClosed) {
      const deactivationResponse =
        await fimsClient.deactivateEmergencyAirspaceClosure();
      setAirspaceIsClosed(deactivationResponse.airspaceIsClosed);
    } else {
      const activationResponse =
        await fimsClient.activateEmergencyAirspaceClosure();
      setAirspaceIsClosed(activationResponse.airspaceIsClosed);
    }
    return response;
  }

  async function handleEmergencyAirspaceClosureStatusRequest() {
    if (!emergencyAirspaceClosureEnabled) {
      setUserCanUpdateEmergencyAirspaceClosure(false);
      setAirspaceIsClosed(false);
      return;
    }

    const resp = await fimsClient.getEmergencyAirspaceClosureStatus();

    setUserCanUpdateEmergencyAirspaceClosure(
      resp.userCanUpdateEmergencyAirspaceClosure
    );
    setAirspaceIsClosed(resp.airspaceIsClosed);
  }

  return {
    handleEmergencyAirspaceClosureStatusRequest,
    toggleEmergencyAirspaceClosure,
  };
}
