export default () => {
  // Where the device has touch events but not mouse events,
  // add the 'touch-only' css class to the body tag
  const supportsTouch =
    'ontouchstart' in window || // iOS & android
    'ontouchstart' in document.documentElement; // Controversial way to check touch support

  const supportsMouse = 'onmousedown' in document.documentElement;
  return supportsTouch && !supportsMouse;
};
