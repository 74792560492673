import { useDispatch, useSelector } from 'react-redux';
import { type Profile, UserRole } from 'fims-api-types';

import {
  getChangePasswordStatus,
  getError,
  getLoginStatus,
  getProfile,
  getResetPasswordStatus,
} from './selectors';
import {
  attemptChangePassword,
  AttemptChangePasswordPayload,
  attemptLogin,
  AttemptLoginPayload,
  attemptResetPassword,
  AttemptResetPasswordPayload,
  logOut,
  SessionAttemptChangePasswordAction,
  SessionAttemptLoginAction,
  SessionAttemptResetPasswordAction,
  SessionSetChangePasswordStatusAction,
  SessionSetLoginStatusAction,
  SessionSetResetPasswordStatusAction,
  setChangePasswordStatus,
  setLoginStatus,
  setResetPasswordStatus,
} from './actions';
import {
  ChangePasswordStatus,
  LogInStatus,
  ResetPasswordStatus,
} from './constants';

export function useSessionErrors() {
  const errors = useSelector(getError);

  return errors;
}

export function useSignout() {
  const dispatch = useDispatch();

  function dispatchLogout() {
    return dispatch(logOut());
  }

  return dispatchLogout;
}

export function useIsAuthenticated() {
  const profile = useSelector(getProfile);
  return Boolean(profile);
}

export function useProfile(): Profile {
  const profile = useSelector(getProfile);
  return { ...profile };
}

export function useIsAirspaceOwner() {
  const profile = useSelector(getProfile);
  return profile?.roles?.includes(UserRole.AUTHORISER);
}

export function useIsSuperAdmin() {
  const profile = useSelector(getProfile);
  return profile?.roles?.includes(UserRole.SUPER_ADMIN);
}

export function useLogin(): [
  LogInStatus,
  (payload: AttemptLoginPayload) => SessionAttemptLoginAction,
  () => SessionSetLoginStatusAction,
] {
  const dispatch = useDispatch();

  const loginStatus = useSelector(getLoginStatus);

  function dispatchAttemptLogin(payload: AttemptLoginPayload) {
    return dispatch(attemptLogin(payload));
  }

  function dispatchResetLoginStatus() {
    return dispatch(setLoginStatus(LogInStatus.IDLE));
  }

  return [loginStatus, dispatchAttemptLogin, dispatchResetLoginStatus];
}

export function useChangePassword(): [
  ChangePasswordStatus,
  (payload: AttemptChangePasswordPayload) => SessionAttemptChangePasswordAction,
  () => SessionSetChangePasswordStatusAction,
] {
  const dispatch = useDispatch();

  const changePasswordStatus = useSelector(getChangePasswordStatus);

  function dispatchAttemptChangePassword(
    payload: AttemptChangePasswordPayload
  ) {
    return dispatch(attemptChangePassword(payload));
  }

  function dispatchResetChangePasswordStatus() {
    return dispatch(setChangePasswordStatus(ChangePasswordStatus.IDLE));
  }

  return [
    changePasswordStatus,
    dispatchAttemptChangePassword,
    dispatchResetChangePasswordStatus,
  ];
}

export function useRequestVerificationCode(): [
  ResetPasswordStatus,
  (payload: AttemptResetPasswordPayload) => SessionAttemptResetPasswordAction,
  () => SessionSetResetPasswordStatusAction,
] {
  const dispatch = useDispatch();

  const resetPasswordStatus = useSelector(getResetPasswordStatus);

  function dispatchAttemptResetPassword(payload: AttemptResetPasswordPayload) {
    return dispatch(attemptResetPassword(payload));
  }

  function dispatchSetResetPasswordStatus() {
    return dispatch(setResetPasswordStatus(ResetPasswordStatus.IDLE));
  }

  return [
    resetPasswordStatus,
    dispatchAttemptResetPassword,
    dispatchSetResetPasswordStatus,
  ];
}
