import type { DateTime } from 'luxon';

export enum AlertType {
  Conformance = 'CONFORMANCE_ALERT',
  Proximity = 'PROXIMITY_ALERT',
}

export enum AlertStatus {
  SafeOps = 'SAFE_OPS',
  Alert = 'ALERT',
  Breach = 'BREACH',
}

export interface Alert {
  flightId: number;
  type: AlertType;
  status: AlertStatus;
}

export interface ProximityAlert extends Alert {
  type: AlertType.Proximity;
}

export interface OutsideBoundaryTime {
  startTime: DateTime;
  endTime: DateTime | null;
}

export interface ConformanceAlertDetails {
  status: AlertStatus;
}

export interface ConformanceAlert extends Alert {
  type: AlertType.Conformance;
  outsideBoundaryTimes: OutsideBoundaryTime[];
  flightStatus: ConformanceAlertDetails;
  altitudeStatus: ConformanceAlertDetails;
  timeStatus: ConformanceAlertDetails;
  boundaryStatus: ConformanceAlertDetails;
  deviationThresholdHorizontalMetres: number;
  deviationThresholdVerticalFeet: number;
  segmentId?: string;
}
