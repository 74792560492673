import { getStatusText } from 'airspace-manager-common/status/multi-status-check';
import { FRStatusCode, FlightPurpose } from 'argus-api-shared/lib/constants';

export const statusFilters: FRStatusCode[] = Object.values(FRStatusCode)
  .filter(() => true)
  .sort((a, b) => getStatusText(a, true).localeCompare(getStatusText(b, true)));

export const purposeFilters: string[] = Object.values(FlightPurpose).map((x) =>
  x.toString()
);

export const addRemoveElement = (element: string, array: string[]) => {
  const index = array.indexOf(element);
  const newArray = [...array];
  if (index < 0) return newArray.concat(element);
  newArray.splice(index, 1);
  return newArray;
};
