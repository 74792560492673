import {
  FRStatusCode,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
  shapeOptions,
} from '.';

export enum FlightStatusColorCode {
  Activated = '#388e3c',
  Required = '#529FC2',
  Cancelled = '#d32f2f',
  Conflicted = '#ae7071',
  Pending = '#ffa500',
  ProvAuth = '#0cb4fe',
  Auth = '#1976d2',
  Declared = '#000000',
  Terminated = '#9da7c0',
  Requesting = 'blue',
}

export const getStatusColor = (
  status?: FRStatusCode
): FlightStatusColorCode => {
  switch (status) {
    case FRStatusCode.ProvAuth:
      return FlightStatusColorCode.ProvAuth;
    case FRStatusCode.Activated:
      return FlightStatusColorCode.Activated;
    case FRStatusCode.Authorized:
      return FlightStatusColorCode.Auth;
    case FRStatusCode.Pending:
      return FlightStatusColorCode.Pending;
    case FRStatusCode.Conflicted:
      return FlightStatusColorCode.Conflicted;
    case FRStatusCode.Cancelled:
    case FRStatusCode.Declined:
      return FlightStatusColorCode.Cancelled;
    case FRStatusCode.Terminated:
      return FlightStatusColorCode.Terminated;
    default:
      return FlightStatusColorCode.Declared;
  }
};

const idleStatusSegmentFillColor = (
  clearanceStatus: SegmentClearanceStatusCode,
  stroke: boolean
) => {
  switch (clearanceStatus) {
    case SegmentClearanceStatusCode.REQUIRED:
      return FlightStatusColorCode.Required;
    case SegmentClearanceStatusCode.APPROVED:
      return FlightStatusColorCode.ProvAuth;
    case SegmentClearanceStatusCode.REQUESTED:
      return stroke
        ? FlightStatusColorCode.Activated
        : FlightStatusColorCode.Requesting;
    case SegmentClearanceStatusCode.NOTREQUIRED:
    default:
      return FlightStatusColorCode.Declared;
  }
};

const showIdleStatus = (
  showDetails: boolean,
  clearanceStatus: SegmentClearanceStatusCode
): {
  fillColor: FlightStatusColorCode;
  strokeColor: FlightStatusColorCode;
  fillOpacity: number;
  strokeOpacity: number;
  strokeWeight: number;
} => {
  if (
    !showDetails &&
    clearanceStatus === SegmentClearanceStatusCode.NOTREQUIRED
  ) {
    return {
      fillColor: FlightStatusColorCode.Declared,
      strokeColor: FlightStatusColorCode.Declared,
      fillOpacity: 0,
      strokeOpacity: 0,
      strokeWeight: 0,
    };
  }

  return {
    fillColor: idleStatusSegmentFillColor(clearanceStatus, false),
    strokeColor: idleStatusSegmentFillColor(clearanceStatus, true),
    fillOpacity: shapeOptions.fillOpacity,
    strokeOpacity: shapeOptions.strokeOpacity,
    strokeWeight:
      clearanceStatus === SegmentClearanceStatusCode.REQUESTED
        ? 3
        : shapeOptions.strokeWeight,
  };
};

export const getSegmentStatusStyling = (
  showDetails: boolean,
  status: FRStatusCode,
  segmentStatus: SegmentStatusCode,
  clearanceStatus: SegmentClearanceStatusCode
): {
  fillColor: FlightStatusColorCode;
  strokeColor: FlightStatusColorCode;
  fillOpacity: number;
  strokeOpacity: number;
  strokeWeight: number;
} => {
  if (status !== FRStatusCode.Activated) {
    const color = getStatusColor(status);

    return {
      fillColor: color,
      strokeColor: color,
      fillOpacity: shapeOptions.fillOpacity,
      strokeOpacity: shapeOptions.strokeOpacity,
      strokeWeight: shapeOptions.strokeWeight,
    };
  }

  switch (segmentStatus) {
    case SegmentStatusCode.ACTIVATED:
      return {
        fillColor: FlightStatusColorCode.Activated,
        strokeColor: FlightStatusColorCode.Activated,
        fillOpacity: shapeOptions.fillOpacity,
        strokeOpacity: shapeOptions.strokeOpacity,
        strokeWeight: shapeOptions.strokeWeight,
      };
    case SegmentStatusCode.IDLE: {
      return showIdleStatus(showDetails, clearanceStatus);
    }
    case SegmentStatusCode.TERMINATED:
    default:
      return {
        fillColor: FlightStatusColorCode.Terminated,
        strokeColor: FlightStatusColorCode.Terminated,
        fillOpacity: 0,
        strokeOpacity: showDetails ? shapeOptions.strokeOpacity : 0,
        strokeWeight: showDetails ? shapeOptions.strokeWeight : 0,
      };
  }
};
