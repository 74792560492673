import { useContext, useEffect, useRef } from 'react';
import { LoginContext } from '../../../src/context/LoginState';

enum NOA_LAYER {
  Primary = 'primary-noa',
  Secondary = 'secondary-noa',
}

export function useNoaLayer(google: any, map: google.maps.Map | null) {
  const { showSecondaryNOA } = useContext(LoginContext);
  const dataLayersCache =
    useRef<{ type: NOA_LAYER; dataLayer: google.maps.Data }[]>();

  useEffect(() => {
    dataLayersCache.current = [];
  }, []);

  useEffect(() => {
    if (map) {
      const url = `${window.env.ARGUS_CONTENT_API_URL}/approval-zones/${NOA_LAYER.Primary}.geojson`;
      const primaryLayer: google.maps.Data = new google.maps.Data({});

      primaryLayer.loadGeoJson(url, {}, (_: google.maps.Data.Feature[]) => {
        primaryLayer.setStyle((feature: google.maps.Data.Feature) => ({
          title: feature.getProperty('name'),
          zIndex: feature.getProperty('zIndex'),
          strokeColor: feature.getProperty('strokeColor'),
          strokeOpacity: feature.getProperty('strokeOpacity'),
          strokeWidth: feature.getProperty('strokeWidth'),
          strokeWeight: feature.getProperty('strokeWeight'),
          fillOpacity: feature.getProperty('fillOpacity'),
        }));

        primaryLayer.setMap(map);
      });
    }
  }, [google, map]);

  useEffect(() => {
    if (map) {
      if (showSecondaryNOA) {
        const cachedLayer = dataLayersCache.current.find(
          (x) => x.type === NOA_LAYER.Secondary
        );

        if (cachedLayer) {
          cachedLayer.dataLayer.setMap(map);
        } else {
          const url = `${window.env.ARGUS_CONTENT_API_URL}/approval-zones/${NOA_LAYER.Secondary}.geojson`;
          const secondaryLayer: google.maps.Data = new google.maps.Data({});

          secondaryLayer.loadGeoJson(
            url,
            {},
            (_: google.maps.Data.Feature[]) => {
              secondaryLayer.setStyle((feature: google.maps.Data.Feature) => ({
                title: feature.getProperty('name'),
                zIndex: feature.getProperty('zIndex'),
                strokeColor: feature.getProperty('strokeColor'),
                strokeOpacity: feature.getProperty('strokeOpacity'),
                strokeWidth: feature.getProperty('strokeWidth'),
                strokeWeight: feature.getProperty('strokeWeight'),
                fillOpacity: feature.getProperty('fillOpacity'),
              }));

              secondaryLayer.setMap(map);

              dataLayersCache.current.push({
                type: NOA_LAYER.Secondary,
                dataLayer: secondaryLayer,
              });
            }
          );
        }
      } else {
        dataLayersCache.current.forEach(({ dataLayer, type }) => {
          if (type === NOA_LAYER.Secondary) {
            dataLayer.setMap(null);
          }
        });
      }
    }
  }, [google, map, showSecondaryNOA]);
}
