import React, { useContext } from 'react';
import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { LoginContext } from '../../../../../context/LoginState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      alignSelf: 'start',
      position: 'relative',
      flexGrow: 3,
      order: 2,
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      minHeight: '28.5px',
      textAlign: 'center',
      borderRadius: '4px',
      backgroundColor: 'red',
      color: theme.palette.primary.contrastText,
    },
  })
);

export default function TopBanner() {
  const { airspaceIsClosed } = useContext(LoginContext);

  const classes = useStyles();

  const message =
    'All drone flights currently prohibited until midnight tonight';

  if (!airspaceIsClosed) return null;

  return (
    <Typography className={classes.banner} data-testid="map-top-banner">
      {message}
    </Typography>
  );
}
