import { createSelector } from 'reselect';
import { AppState } from '../store';

const getLocalState = (state: AppState) => state.session;

export const getError = createSelector([getLocalState], (state) => state.error);

export const getProfile = createSelector(
  [getLocalState],
  (state) => state.profile
);

export const getToken = createSelector([getProfile], (state) => state.token);

export const getLoginStatus = createSelector(
  [getLocalState],
  (state) => state.loginStatus
);

export const getResetPasswordStatus = createSelector(
  [getLocalState],
  (state) => state.resetPasswordStatus
);

export const getChangePasswordStatus = createSelector(
  [getLocalState],
  (state) => state.changePasswordStatus
);
