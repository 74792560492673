import { SegmentClearanceStatusCode, SegmentStatusCode } from '.';

export const formatSegmentStatus = (status: SegmentStatusCode) => {
  switch (status) {
    case SegmentStatusCode.ACTIVATED:
      return SegmentStatusCode.ACTIVATED;
    case SegmentStatusCode.TERMINATED:
      return SegmentStatusCode.TERMINATED;
    case SegmentStatusCode.IDLE:
    default:
      return SegmentStatusCode.IDLE;
  }
};

export const formatSegmentClearanceStatus = (
  clearance: SegmentClearanceStatusCode
) => {
  switch (clearance) {
    case SegmentClearanceStatusCode.APPROVED:
      return SegmentClearanceStatusCode.APPROVED;
    case SegmentClearanceStatusCode.REQUESTED:
      return SegmentClearanceStatusCode.REQUESTED;
    case SegmentClearanceStatusCode.REQUIRED:
      return 'Approval Required';
    case SegmentClearanceStatusCode.NOTREQUIRED:
      return 'Not Required';
    default:
      return '';
  }
};

export const formatCombinedSegmentStatus = (
  status: SegmentStatusCode,
  clearance: SegmentClearanceStatusCode
) => {
  if (
    clearance === SegmentClearanceStatusCode.REQUESTED ||
    status === SegmentStatusCode.IDLE
  ) {
    return formatSegmentClearanceStatus(clearance);
  }
  return formatSegmentStatus(status);
};
