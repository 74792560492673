import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { TypographyProps } from '@material-ui/core';

import { Scope } from 'argus-common/flight-requests.lib';

import { colorFromType } from '../../../../components/helpers';

interface Props {
  pathname?: string;
  scope?: Scope;
  type: { text: string };
  value: number;
  testId: string;
}

export const CountBadge = ({
  pathname,
  scope,
  type,
  testId,
  value,
}: Props): JSX.Element => (
  <ListItem
    component={Link}
    style={{ color: 'var(--color-mui-light-black)' }}
    dense
    key={testId}
    to={{ pathname, search: scope ? `scope=${scope}` : undefined }}
    data-testid={testId}
  >
    <ListItemText
      primary={type.text}
      secondary={value}
      secondaryTypographyProps={secondaryTypographyProps(type.text)}
      data-testid={`${testId}-text`}
    />
  </ListItem>
);

function secondaryTypographyProps(text: string): TypographyProps {
  return {
    style: {
      backgroundColor: colorFromType(text),
      padding: 10,
      borderRadius: 5,
      color: 'white',
      fontSize: 36,
      fontWeight: 600,
      textAlign: 'center',
    },
  };
}
