export const listItemStyle = {
  borderColor: 'darkgray',
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: 'lightgray',
};

export const subListItemStyle = {
  backgroundColor: listItemStyle.backgroundColor,
};

export const warningItemStyle = {
  backgroundColor: '#F57C00',
};
