import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from '@material-ui/core';
import EmergencyAirspaceClosureEnabled from './emergency-airspace-closure-enabled-icon';
import EmergencyAirspaceClosureDisabled from './emergency-airspace-closure-disabled-icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { LoginContext } from '../../../../src/context/LoginState';

import { useInterval } from '~/state/custom-hooks/useInterval';
import useEmergencyAirspaceClosure from '../../../hooks/emergency-airspace-closure.hook';

interface LoadAllDronesModalProps {
  closeModal: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  const content = {
    height: 250,
    width: 550,
    color: 'rgb(105, 105, 105)',
  };

  return createStyles({
    content,
    group: {
      flex: 1,
      justifyContent: 'space-between',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    headerText: {
      paddingLeft: theme.spacing(1),
    },
    inlineItem: {
      paddingTop: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    buttons: {
      minHeight: '36.5px',
    },
    loading: {
      ...content,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  });
});

function EmergencyAirspaceClosureModal({
  closeModal,
  open,
}: Readonly<LoadAllDronesModalProps>) {
  const classes = useStyles();

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateResponseTitle, setUpdateResponseTitle] = useState<string>('');
  const [updateResponseMessage, setUpdateResponseMessage] =
    useState<string>('');

  const { airspaceIsClosed, setForceReloadMap } = useContext(LoginContext);

  const {
    toggleEmergencyAirspaceClosure,
    handleEmergencyAirspaceClosureStatusRequest,
  } = useEmergencyAirspaceClosure();

  useEffect(() => {
    if (open) {
      setUpdateResponseTitle('');
      setUpdateResponseMessage('');
    }
  }, [open]);

  useEffect(() => {
    handleEmergencyAirspaceClosureStatusRequest();
  });

  useInterval(async () => {
    await handleEmergencyAirspaceClosureStatusRequest();
  }, 5000);

  const handleConfirm = async () => {
    setIsUpdating(true);

    try {
      const newAirspaceIsClosed = await toggleEmergencyAirspaceClosure();
      setUpdateResponseTitle('Success');
      setUpdateResponseMessage(
        newAirspaceIsClosed
          ? 'All active flights have been notified to land.'
          : 'All flights for today have been reprocessed.'
      );
    } catch (err) {
      setUpdateResponseTitle('Error');
      setUpdateResponseMessage('There was an error. Please try again later.');
      console.log(err);
    }

    setIsUpdating(false);
    setForceReloadMap(true);
  };

  const handleOnClose = (_event: object, _reason: string) => {
    if (!isUpdating) {
      return closeModal();
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      {isUpdating ? (
        <IsUpdatingContent classes={classes} />
      ) : updateResponseTitle ? (
        <UpdateResponseTitleContent
          classes={classes}
          updateResponseTitle={updateResponseTitle}
          updateResponseMessage={updateResponseMessage}
          closeModal={closeModal}
        />
      ) : (
        <Content
          classes={classes}
          airspaceIsClosed={airspaceIsClosed}
          closeModal={closeModal}
          handleConfirm={handleConfirm}
        />
      )}
    </Dialog>
  );
}

const IsUpdatingContent = ({
  classes,
}: {
  classes: ReturnType<typeof useStyles>;
}) => {
  return (
    <>
      <DialogContent className={classes.content}>
        <Box className={classes.loading} sx={{ display: 'flex' }}>
          <CircularProgress
            size={64}
            data-testid="emergency-airspace-closure-modal-spinner"
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttons} />
    </>
  );
};

const UpdateResponseTitleContent = ({
  classes,
  updateResponseTitle,
  updateResponseMessage,
  closeModal,
}: {
  classes: ReturnType<typeof useStyles>;
  updateResponseTitle: string;
  updateResponseMessage: string;
  closeModal: LoadAllDronesModalProps['closeModal'];
}) => {
  const handleClose = () => closeModal();

  return (
    <>
      <DialogContent className={classes.content}>
        <div className={classes.section}>
          <div className={classes.group}>
            <Typography
              variant="h6"
              data-testid="emergency-airspace-closure-modal-response-heading"
            >
              {updateResponseTitle}
            </Typography>
            <div className={classes.inlineItem}>{updateResponseMessage}</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          variant={'contained'}
          color={'primary'}
          style={{ marginLeft: '16px' }}
          onClick={handleClose}
          data-testid="emergency-airspace-closure-modal-close-button"
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

const dialogMessage = (airspaceIsClosed: boolean) => {
  if (airspaceIsClosed) {
    return `Remove temporary drone flight restrictions.
    
    Any existing flight requests for the day will be reprocessed using the regular airspace rules for the area.`;
  }
  return (
    <>
      {`Temporarily restrict drone flight operations for the entire airspace.
  
  This restriction will:`}
      <ul>
        <li>
          Send a message to all drone operators with active flights in your
          airspace advising that their flight authorisation is cancelled
        </li>
        <li>
          No new flight requests will be processed until restriction is switched
          off
        </li>
        <li>The temporary restriction can be removed at any time.</li>
      </ul>
    </>
  );
};

const Content = ({
  classes,
  airspaceIsClosed,
  closeModal,
  handleConfirm,
}: {
  classes: ReturnType<typeof useStyles>;
  airspaceIsClosed: boolean;
  closeModal: LoadAllDronesModalProps['closeModal'];
  handleConfirm: () => Promise<void>;
}) => {
  const title = airspaceIsClosed
    ? 'Allow drone flights'
    : 'Restrict drone flights';
  const message = dialogMessage(airspaceIsClosed);

  const handleCancel = () => closeModal();

  return (
    <>
      <DialogContent className={classes.content}>
        <div className={classes.section}>
          <div className={classes.group}>
            <div className={classes.header}>
              {airspaceIsClosed ? (
                <EmergencyAirspaceClosureDisabled data-testid="emergency-airspace-closure-modal-closure-disabled-icon" />
              ) : (
                <EmergencyAirspaceClosureEnabled data-testid="emergency-airspace-closure-modal-closure-enabled-icon" />
              )}
              <Typography
                variant="h6"
                data-testid="emergency-airspace-closure-modal-heading"
                className={classes.headerText}
              >
                {title}
              </Typography>
            </div>
            <div className={classes.inlineItem}>{message}</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleCancel}
          data-testid="emergency-airspace-closure-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          style={{ marginLeft: '16px' }}
          onClick={handleConfirm}
          data-testid="emergency-airspace-closure-modal-confirm-button"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default EmergencyAirspaceClosureModal;
