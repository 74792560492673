import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import { UavManufacturersViewModel } from 'fims-api-types';
import { UserRole } from 'argus-common/enums';
import { useProfile } from '../../../state/session';
import ManufacturerListItem from './ManufacturerListItem';
import EditManufacturersModal from './EditManufacturersModal';
import { getUavManufacturers } from '../../../clients/fims-api-client';

import './UavManufacturers.scss';

export default function UavManufacturers() {
  const profile = useProfile();

  const [search, setSearch] = useState('');
  const [editManufacturer, setEditManufacturer] =
    useState<UavManufacturersViewModel>(undefined);
  const [manufacturers, setManufacturers] = useState<
    UavManufacturersViewModel[]
  >([]);

  const retreiveUavManufacturersData = async () => {
    const res = await getUavManufacturers();
    setManufacturers(res);
  };

  useEffect(() => {
    retreiveUavManufacturersData();
  }, [profile.roles, editManufacturer]);

  const isAdmin = [UserRole.ADMIN, UserRole.SUPER_ADMIN].some((role) =>
    profile.roles.includes(role)
  );

  return (
    <>
      <div className="uav-manufacturers">
        <div className="manufacturers-header">
          <Typography
            className="uav-manufacturers__heading"
            variant="h3"
            data-testid="uav-manufacturer-heading"
          >
            UAV Manufacturers
          </Typography>
          {isAdmin ? (
            <Button
              className="manufacturers-header-trigger is-add-user"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() =>
                setEditManufacturer({
                  name: '',
                  isActive: true,
                  models: [],
                })
              }
              data-testid="add-manufacturer-button"
            >
              Add Manufacturer
            </Button>
          ) : null}
        </div>
        <Paper>
          <div className="paper-header">
            <TextField
              id="standard-start-adornment"
              data-testid="search"
              className="search-box"
              placeholder="Search..."
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="manufacturers-list">
            <List
              component="nav"
              aria-label="uav manufacturers list"
              data-testid="uav-manufacturer-list"
            >
              {manufacturers
                .filter(
                  (x) =>
                    x.name.toLowerCase().includes(search) ||
                    x.models.find((m) => m.name.toLowerCase().includes(search))
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((m) => (
                  <ManufacturerListItem
                    key={m.name}
                    manufacturer={m}
                    isAdmin={isAdmin}
                    editManufacturer={() =>
                      isAdmin ? setEditManufacturer(m) : () => {}
                    }
                  />
                ))}
            </List>
          </div>
        </Paper>
      </div>
      {editManufacturer && (
        <EditManufacturersModal
          closeModal={() => setEditManufacturer(undefined)}
          manufacturer={editManufacturer}
        />
      )}
    </>
  );
}
