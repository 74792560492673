import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { Profile } from 'argus-common/interfaces';
import { authFormsReducer } from './auth-forms/reducers';
import {
  SessionActionType,
  SessionSetProfileAction,
  SessionSetErrorAction,
  SessionSetLoginStatusAction,
  SessionSetResetPasswordStatusAction,
  SessionSetChangePasswordStatusAction,
} from './actions';
import {
  LogInStatus,
  ResetPasswordStatus,
  ChangePasswordStatus,
} from './constants';

export const REDUCER_KEY = 'session';

export interface SessionState {
  authForms: string;
  profile: Profile;
  error: string;
  loginStatus: LogInStatus;
  resetPasswordStatus: ResetPasswordStatus;
  changePasswordStatus: ChangePasswordStatus;
}

export const profile = createReducer({
  [SessionActionType.SET_PROFILE]: (
    _: Profile,
    { payload }: SessionSetProfileAction
  ) => payload,
});

export const error = createReducer({
  [SessionActionType.SET_ERROR]: (
    _: string,
    { payload }: SessionSetErrorAction
  ) => payload,
});

export const loginStatus = createReducer(
  {
    [SessionActionType.SET_LOGIN_STATUS]: (
      _: LogInStatus,
      { payload }: SessionSetLoginStatusAction
    ) => payload,
  },
  LogInStatus.IDLE
);

export const resetPasswordStatus = createReducer(
  {
    [SessionActionType.SET_RESET_PASSWORD_STATUS]: (
      _: ResetPasswordStatus,
      { payload }: SessionSetResetPasswordStatusAction
    ) => payload,
  },
  ResetPasswordStatus.IDLE
);

export const changePasswordStatus = createReducer(
  {
    [SessionActionType.SET_CHANGE_PASSWORD_STATUS]: (
      _: ChangePasswordStatus,
      { payload }: SessionSetChangePasswordStatusAction
    ) => payload,
  },
  ChangePasswordStatus.IDLE
);

export default combineReducers({
  authForms: authFormsReducer,
  profile,
  error,
  loginStatus,
  resetPasswordStatus,
  changePasswordStatus,
});
