import type { Point, Feature } from 'geojson'; // As per RFC 7946 https://geojson.org/
import type { Area } from '../misc';

export type AreaFeature = Feature<
  Area,
  {
    fillColor: string;
    fillOpacity: number;
    strokeColor: string;
    strokeOpacity: number;
    strokeWeight: number;
  }
>;

// Advisories can be points for hang-gliding or parachuting sites, or an area type.
export type AdvisoryGeoJSONTypes = Area | Point;

export const AreaTypes = ['Polygon', 'MultiPolygon'];
export const AdvisoryTypes = AreaTypes.concat('Point');

export enum FlightPathModeEnum {
  CIRCLE = 'Circle',
  POLYGON = 'Polygon',
  MULTIPOLYGON = 'MultiPolygon',
  SEGMENTED = 'Segmented',
}

export enum GeoJsonFeatureTypeEnum {
  CIRCLE = 'Circle',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  POINT = 'Point',
  MULTIPOLYGON = 'MultiPolygon',
}
