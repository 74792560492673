import React from 'react';
import { SplashPage } from './splash-page';

export function MobileNotSupported() {
  return (
    <SplashPage
      primaryText="This website is optimised for tablet, laptop or desktop"
      secondaryText="If using a tablet please rotate to landscape mode"
    />
  );
}
