import * as Sentry from '@sentry/browser';

const { ENVIRONMENT, SENTRY_DSN } = window.env;

export function initSentry(context = {}) {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
    });

    Sentry.configureScope((scope) => {
      Object.keys(context).forEach((key) => {
        scope.setTag(key, context[key]);
      });
    });
  }

  return Sentry;
}
