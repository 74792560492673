import React, { useState } from 'react';
import List from '@material-ui/core/List';

import type { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import AuthorizationsRequired from './AuthorizationsRequired';
import Conflicts from './Conflicts';
import Where from './Where';
import When from './When';
import Who from './Who';
import How from './How';
import What from './What';
import Segments from './Segments';
import AdditionalInfo from './AdditionalInfo';
import AuditLogs from './AuditLogs';
import RequiredAuthChanges from './RequiredAuthChanges';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  flightId: number;
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  setFocussedFlightRequest: (
    flightRequest: DetailedFlightRequestViewModelV2
  ) => void;
  setErrorMessage: (message: string) => void;
  segmentsOpen: string[];
  setSegmentsOpen: (segmentsOpen: string[]) => void;
}

const conditionalAuthEnabled =
  window.env.CONDITIONAL_AUTHORISATION_ENABLED?.toLowerCase() === 'true';

const FlightInformation = ({
  flightId,
  focussedFlightRequest,
  setFocussedFlightRequest,
  setErrorMessage,
  segmentsOpen,
  setSegmentsOpen,
}: Props) => {
  const [auditLogItemsChanged, setAuditLogItemsChanged] = useState(false);

  return (
    <div className="flight-info" data-testid="flight-info">
      <List style={{ width: '100%' }}>
        <Conflicts
          flightId={flightId}
          focussedFlightRequest={focussedFlightRequest}
          setFocussedFlightRequest={setFocussedFlightRequest}
          setErrorMessage={setErrorMessage}
        />
        <Where focussedFlightRequest={focussedFlightRequest} />
        <When focussedFlightRequest={focussedFlightRequest} />
        <Who focussedFlightRequest={focussedFlightRequest} />
        <How focussedFlightRequest={focussedFlightRequest} />
        <What focussedFlightRequest={focussedFlightRequest} />
        <AdditionalInfo focussedFlightRequest={focussedFlightRequest} />

        {focussedFlightRequest?.status?.length > 1 && (
          <AuthorizationsRequired
            listItemStyle={listItemStyle}
            focussedFlightRequest={focussedFlightRequest}
          />
        )}

        {conditionalAuthEnabled &&
        focussedFlightRequest?.requiredAuthChanges?.length > 0 ? (
          <RequiredAuthChanges
            focussedFlightRequest={focussedFlightRequest}
            auditLogItemsChanged={auditLogItemsChanged}
            setAuditLogItemsChanged={setAuditLogItemsChanged}
          />
        ) : null}

        <Segments
          focussedFlightRequest={focussedFlightRequest}
          segmentsOpen={segmentsOpen}
          setSegmentsOpen={setSegmentsOpen}
        />

        <AuditLogs
          focussedFlightRequest={focussedFlightRequest}
          auditLogItemsChanged={auditLogItemsChanged}
          setAuditLogItemsChanged={setAuditLogItemsChanged}
        />
      </List>
    </div>
  );
};

export default FlightInformation;
