import type { ObjectId } from 'mongodb';
import { PagedResponse } from './responses';
import { Uav } from './database';

export interface UavInfo extends Required<Uav> {
  id: string;
  serialNumber: string;
  user?: ObjectId;
  image?: string;
}

export interface UavQueryResponsePaged extends PagedResponse {
  data: UavInfo[];
}

export enum AircraftType {
  Airplane = 'AIRPLANE',
  Drone = 'DRONE',
}

export enum DeviceIdentifier {
  NotSpecific = 'NotSpecific',
}

export enum DroneType {
  Known = 'KNOWN',
  Unknown = 'UNKNOWN',
}

export enum DroneTypeColour {
  Unknown = '#F00',
}

// NOTE: UAV weight
// - 'min' is inclusive
// - 'max' is exclusive.
export const UAVWeights = [
  {
    label: 'Between 15 and 25 kg',
    code: '15 to 25',
    min: 15,
    max: 25,
  },
  {
    label: 'Above 25 kg',
    code: '>25',
    min: 25,
    max: null,
  },
  {
    label: 'Less Than 15 kg',
    code: '<15',
    min: 0,
    max: 15,
  },
];
