import { createSelector } from 'reselect';
import { AppState } from '../store';

const getLocalState = (state: AppState) => state.admin;

export const getUsers = createSelector([getLocalState], (state) => state.users);

export const getFetchStatus = createSelector(
  [getLocalState],
  (state) => state.fetchStatus
);

export const getUserError = createSelector(
  [getLocalState],
  (state) => state.userError
);

export const getError = createSelector([getLocalState], (state) => state.error);

export const getAdminDefaults = createSelector(
  [getLocalState],
  (state) => state.adminDefaults
);
