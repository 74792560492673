import type { Geometry, Polygon } from '@turf/helpers';
import center from '@turf/center';
import centroid from '@turf/centroid';
import type { LatLng, MapDefault } from 'argus-common/interfaces';
import { getPolygonFromGeojsonFile } from 'airshare-web-utils/file-parser';
import * as fimsClient from '../../../../clients/fims-api-client';
import { AreasOfRespCoords } from './map';

export function getCenter({ geometry }: { geometry?: Geometry | null }): {
  center?: LatLng;
} {
  if (geometry) {
    const point = center(geometry);

    if (point) {
      return {
        center: {
          lat: point.geometry.coordinates[1],
          lng: point.geometry.coordinates[0],
        },
      };
    }
  }
  return {};
}

export function getCenterAndZoom({
  history,
  orgDefault,
  appConfig,
}: {
  history?: Partial<MapDefault>;
  orgDefault?: Partial<MapDefault>;
  appConfig?: Partial<MapDefault> | null;
}): { center?: LatLng; zoom?: number } {
  const mapDefault = isValid(history)
    ? history
    : isValid(orgDefault)
      ? orgDefault
      : isValid(appConfig)
        ? appConfig
        : undefined;

  return {
    center: mapDefault
      ? { lat: mapDefault.lat, lng: mapDefault.lng }
      : undefined,
    zoom: mapDefault?.zoom,
  };
}

function isValid(
  mapDefault?: Partial<MapDefault> | null
): mapDefault is MapDefault {
  const isValidLat = typeof mapDefault?.lat === 'number';
  const isValidLng = typeof mapDefault?.lng === 'number';
  return Boolean(isValidLat && isValidLng && mapDefault?.zoom);
}

export const handleGeojsonUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  onSuccess: (polygon: Polygon | null, center: LatLng) => void,
  onFail: (message?: string) => void
) => {
  if (!event.target.files.length) return;
  try {
    const polygon = await getPolygonFromGeojsonFile(event.target.files[0]);
    const { lng, lat } = await getValidatedCenter(polygon);
    onSuccess(polygon, { lat, lng });
  } catch (err) {
    onFail(err.message);
  }
};

export const selectAORs = async (
  areasOfResponsibilityCoords: AreasOfRespCoords,
  onSuccess: (polygon: Polygon | null, center: LatLng) => void,
  onFail: (message?: string) => void
) => {
  try {
    const selectedAorPolygons: Polygon[] = areasOfResponsibilityCoords.map(
      (aor) => ({
        type: 'Polygon',
        coordinates: [
          aor.map((polygon: { lat: number; lng: number }) => [
            polygon.lng,
            polygon.lat,
          ]),
        ],
      })
    );
    const polygon = selectedAorPolygons[0];
    const { lng, lat } = await getValidatedCenter(polygon);
    onSuccess(polygon, { lat, lng });
  } catch (err) {
    onFail(err.message);
  }
};

export const getValidatedCenter = async (
  polygon: Polygon
): Promise<{ lat: number; lng: number }> => {
  try {
    const isWithinAreaOfResponsibility = (
      await fimsClient.validateManagedAreaGeometry(polygon)
    ).valid;
    if (!isWithinAreaOfResponsibility) {
      throw new Error('Uploaded area is not within area of responsibility');
    }
    const [lng, lat] = centroid(polygon).geometry.coordinates;
    return { lng, lat };
  } catch (err) {
    throw new Error(
      `Error while validating area of responsibility: ${err.message}`
    );
  }
};

export const closeActiveInfoWindows = () => {
  if (window.activeMarker) {
    window.activeMarker.infowindow.close();
  }
  if (window.activeClusterWindow) {
    window.activeClusterWindow.close();
  }
};
