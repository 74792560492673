import * as yup from 'yup';
import { ValidationError } from 'yup';

const resetKeySchema = yup
  .string()
  .length(6)
  .matches(/^\d{6}$/, 'A valid 6 digit code is required')
  .strict()
  .required();
const emailSchema = yup
  .string()
  .email('Must be a valid email address')
  .strict()
  .required();
const passwordSchema = yup
  .string()
  .min(8, 'A minimum of 8 characters is required')
  .strict()
  .required();

export const resetKeyValidationHint = (text: string) => {
  try {
    resetKeySchema.validateSync(text);
    return '';
  } catch (e) {
    if (e instanceof ValidationError) return e.errors[0];
    return '';
  }
};

export const emailValidationHint = (text: string) => {
  try {
    emailSchema.validateSync(text);
    return '';
  } catch (e) {
    if (e instanceof ValidationError) return e.errors[0];
    return '';
  }
};
export const passwordValidationHint = (text: string) => {
  try {
    passwordSchema.validateSync(text);
    return '';
  } catch (e) {
    if (e instanceof ValidationError) return e.errors[0];
    return '';
  }
};
