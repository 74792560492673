import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';

import cx from 'classnames';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import type { DetailedFlightRequestViewModelV2 } from 'fims-api-types';
import { ATC_AUTH_DEFAULT_NAME } from 'argus-api-shared/lib/constants';
import { getStatusClassnames } from '../../../helpers';
import { getStatusText } from 'airspace-manager-common/status/multi-status-check';

import '../flight.scss';

interface Props {
  listItemStyle: any;
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const AuthorizationsRequired: React.FC<Props> = ({
  listItemStyle,
  focussedFlightRequest,
}) => {
  const [authorisationsRequiredOpen, setAuthorisationsRequiredOpen] =
    useState(false);

  const atcAuthName =
    window.env.AIR_TRAFFIC_CONTROL_DISPLAY_NAME ?? 'Airways ATC';
  const getAuthName = (auth: string) =>
    auth === ATC_AUTH_DEFAULT_NAME ? atcAuthName : auth;

  return (
    <div>
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() =>
          setAuthorisationsRequiredOpen(!authorisationsRequiredOpen)
        }
        data-testid="flight-authorisation-required-heading"
      >
        <ListItemText primary="Authorisations Required" />
        {authorisationsRequiredOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={authorisationsRequiredOpen} timeout="auto" unmountOnExit>
        <table
          className="flight-info-table"
          data-testid="flight-authorisation-required-table"
        >
          <tbody>
            {focussedFlightRequest.status.map((status) => (
              <tr key={`${status.authorizer}`} className="authorizer-item">
                <th data-testid="flight-authorisation-required-authoriser-status-title">
                  {' '}
                  {getAuthName(status.authorizer)}{' '}
                </th>
                <td data-testid="flight-authorisation-required-authoriser-status-value">
                  <span
                    className={cx(
                      'flight-heading-status flight-heading-status-fixed-width',
                      ...getStatusClassnames(status.code)
                    )}
                  >
                    {getStatusText(status.code, true)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default AuthorizationsRequired;
