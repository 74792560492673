import type * as core from 'express-serve-static-core';
import type { Request } from 'express';
import SocketIO from 'socket.io';
import type { Authorizer } from 'argus-data-model/db/schemas/authorizers';
import {
  DecisionStatusEnum,
  FRStatusCode,
  type FlightRequestAudit,
  ManagedArea,
  ManagedAreaExternalResponse,
  StyledAreaResponse,
  UserRole,
  FlightRequestAuditAction,
  RulesEngineResponse,
  LatLng,
} from '@airshare/external-api-types';

import type { ObjectId } from 'mongodb';
import type { AlertStatus } from '@airshare/messaging-types';
export {
  Alert,
  ConformanceAlert,
  OutsideBoundaryTime,
} from '@airshare/messaging-types';
export { RulesEngineResponse, LatLng, StyledAreaResponse };

export interface SurveillanceAuditLog extends FlightRequestAudit {
  action:
    | FlightRequestAuditAction.CONFORMANCE_ALERT
    | FlightRequestAuditAction.CONFORMANCE_SAFE
    | FlightRequestAuditAction.CONFORMANCE_BREACH;
  context: {
    status: AlertStatus;
  };
}
export interface AuditLog extends FlightRequestAudit {
  action: FlightRequestAuditAction;
  user: string;
  userId?: ObjectId;
  context: {
    statusCode: FRStatusCode;
    actionRequired: DecisionStatusEnum;
    decisionMakerName: string;
    atcNote: string;
    operatorNote: string;
    message?: string;
    phone?: string;
    action?: string;
    pilotName?: string;
    pilot?: string;
    status?: string;
    changeLog?: any[];
    statuses?: { code: FRStatusCode }[];
    authorizerChanges?: string[];
  };
}

export interface MapBounds {
  ne: string;
  sw: string;
  zoom?: number;
}

export interface MapDefault {
  lat: number;
  lng: number;
  zoom: number;
}

export interface OrganisationMapDefault extends MapDefault {
  focusedZoom: number; // Inital max zoom when focusing in on a flight request or managed area
  mapType: string; // google.maps.MapTypeId
}

export interface PagedRequestQuery {
  pageindex: number;
  pagesize: number;
  sortby: string;
  search?: string;
}

export interface PagedRequest {
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  order: string;
  search: string;
}

export interface PagedFlightRequest extends PagedRequest {
  filter: string;
  scope: string;
}

export interface PagedResponse {
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
}

// Should only be used ASM.
export interface Profile {
  token: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  controlZones: string[];
  authorizer: Authorizer;
  mapDefaults?: OrganisationMapDefault;
  digitalAuthorization?: boolean;
  organisation: string;
  tower?: {
    watchEnabled: boolean;
    onWatch: boolean;
    nextOnWatch: string;
    nextOffWatch: string;
    todayOnWatch: string;
    todayOffWatch: string;
  };
}

export interface SessionDetails {
  socketId?: string;
  socket?: SocketIO.Socket;
  ctrZones?: string[];
  aerodromes?: string[];
  userId: string;
  userName: string;
  organisationId: string | null;
  email: string;
  roles: UserRole[];
  iat?: number;
}

export interface RequestWithSession<
  P extends core.Params = core.ParamsDictionary,
  ResBody = any,
  ReqBody = any,
  ReqQuery = core.Query,
> extends Request<P, ResBody, ReqBody, ReqQuery> {
  session: SessionDetails;
}

export type RequestParams<T> = core.Params & T;

export interface ErrorResponseBody {
  message: string;
}

export function formatStyledResponse(area: ManagedArea): StyledAreaResponse {
  const areaResponse = formatExternalResponse(area);
  return {
    ...areaResponse,
    strokeColor: area.properties.strokeColor,
    strokeOpacity: area.properties.strokeOpacity,
    strokeWeight: area.properties.strokeWeight,
    fillColor: area.properties.fillColor,
    fillOpacity: area.properties.fillOpacity,
  };
}

export function formatExternalResponse(
  area: ManagedArea
): ManagedAreaExternalResponse {
  return {
    id: `${area.id}`,
    type: area.properties.code,
    startDateTime: area.properties.startDateTime,
    endDateTime: area.properties.endDateTime,
    minAltitudeFeet: area.properties.lowerLimitFeet,
    maxAltitudeFeet: area.properties.upperLimitFeet,
    name: area.properties.name,
    description: area.properties.description,
    phone: area.properties.phone || undefined,
    email: area.properties.email,
    isActive: area.properties.isActive,
    geometry: {
      type: area.geometry.type,
      coordinates: area.geometry.coordinates,
    },
    auditLog: area.auditLog,
    authorizedPilots: area.properties.authorizedPilots,
  };
}
