import { useEffect, useState } from 'react';
import { fimsAPI } from './../clients/api';

export function useIsBackendOffline(backendApi: typeof fimsAPI) {
  const [isBackendOffline, setIsBackendOffline] = useState<boolean>(false);

  useEffect(() => {
    const interceptor = backendApi.interceptors.response.use(
      function (response) {
        if (isBackendOffline) {
          setIsBackendOffline(false);
        }
        return response;
      },
      function (error) {
        if (error?.message === 'Network Error' && !isBackendOffline) {
          setIsBackendOffline(true);
        }
        return Promise.reject(new Error(error));
      }
    );
    return () => {
      backendApi.interceptors.request.eject(interceptor);
    };
  }, [
    backendApi.interceptors.request,
    backendApi.interceptors.response,
    isBackendOffline,
    setIsBackendOffline,
  ]);

  return isBackendOffline;
}
