import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'grid',
      width: 400,
    },
    input: {
      margin: `${theme.spacing(1)}px 0`,
    },
    saveButton: {
      minWidth: 146,
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      width: `calc(100 % - ${theme.spacing(2)} * 2)`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);
interface DeleteGenericModalProps {
  closeModal: () => void;
  title: string;
  text: string;
  onSubmit: () => Promise<void>;
}
export default function DeleteGenericModal({
  closeModal,
  title,
  text,
  onSubmit,
}: DeleteGenericModalProps) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (_event: any): Promise<void> => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
    closeModal();
  };

  return (
    <Dialog onClose={closeModal} open>
      <DialogTitle data-testid="delete-modal-title">{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography
          className={classnames(classes.item, classes.error)}
          data-testid="delete-modal-text"
        >
          {text}
        </Typography>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
          data-testid="delete-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isSubmitting ? () => {} : handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={classes.saveButton}
          data-testid="delete-modal-save-button"
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Delete'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
