import React from 'react';
import { SplashPage } from './splash-page';

import './landscape-only.scss';

export function LandscapeOnly() {
  return (
    <SplashPage
      className="landscape-only-page"
      primaryText="This website is optimised for landscape mode"
      secondaryText="Please rotate your device"
    />
  );
}
