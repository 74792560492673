import React, { useState } from 'react';
import type { Polygon, MultiPolygon } from 'geojson';
import { Routes, Route, Navigate } from 'react-router-dom';
import type { Socket } from 'socket.io-client';

import { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';

import ApplicationBar from './application-bar/application-bar';
import HomeScreen from './home-screen/home-screen';
import Admin from './admin/admin';
import FlightReport from './flight-reports/flight-report';
import ConflictedFlights from './conflicted-flights/conflicted-flights';
import Flight from './flight/flight';
import About from './about/about';
import Uavs from './uavs/uavs';
import UavManufacturers from './uav-manufacturers/UavManufacturers';
import ExternalOrganisations from './external-organisations/external-organisations';
import RulesEngineFlowchart from './rules-engine-flowchart/rules-engine-flowchart';
import Organisations from './organisations/organisations';
import Reports from './reports/reports';
import ManagedAirspaces from './managed-airspace/managed-airspace';
import EditAirspace from './managed-airspace/edit-airspace-page';
import DigitalAuthorizationScreen from './digital-authorizations/digital-authorizations';
import { useIsAuthenticated } from '../../state/session/hooks';
import { LoginContext, MapView24Hour } from '../../context/LoginState';
import { useProfile } from '../../state/session';
import SubscriptionsDemo from './demo/subscriptions/demo-subscripions';
import { MapStyleCode } from '../../state/map-styles';

import './protected-layout.scss';
import { UserRole } from 'argus-common/enums';
function ProtectedLayout() {
  const isAuthenticated = useIsAuthenticated();
  const { authorizer, mapDefaults, roles } = useProfile();

  const [mapView24hour, setMapView24hour] = useState(MapView24Hour.TODAY);

  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);
  const [mapViewDateTimeRange, setMapViewDateTimeRange] = useState([
    yesterday,
    yesterday,
  ] as [Date, Date]);

  const [showSecondaryNOA, setShowSecondaryNOA] = useState(false);
  const [isCreatingAirspace, setIsCreatingAirspace] = useState(false);
  const [selectedAirspace, setSelectedAirspace] = useState<
    Polygon | MultiPolygon | null
  >(null);
  const [airspaceIsClosed, setAirspaceIsClosed] = useState(false);
  const [
    userCanUpdateEmergencyAirspaceClosure,
    setUserCanUpdateEmergencyAirspaceClosure,
  ] = useState(false);
  const [forceReloadMap, setForceReloadMap] = useState(false);
  const [mapHelpers, setMapHelpers] = useState(null);
  const [socket, setSocket] = useState<Socket | null>(null);

  const startingMapStyleCode =
    mapDefaults?.mapType === 'roadway'
      ? MapStyleCode.Dark
      : (mapDefaults?.mapType as MapStyleCode) ?? MapStyleCode.Dark;

  const [mapStyleCode, setMapStyleCode] = useState(startingMapStyleCode);

  const toggleShowSecondaryNOA = () => setShowSecondaryNOA(!showSecondaryNOA);
  const canAccessDigitalAuthorisation =
    authorizer?.type === AuthorizerType.ControlZone &&
    roles.some((r) =>
      [UserRole.AUTHORISER, UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(r)
    );

  if (!isAuthenticated) {
    return (
      <Route>
        <Navigate to={{ pathname: '/login' }} />
      </Route>
    );
  }

  return (
    <LoginContext.Provider
      value={{
        mapHelpers,
        setMapHelpers,
        mapStyleCode,
        setMapStyleCode,
        mapView24hour,
        setMapView24hour,
        mapViewDateTimeRange,
        setMapViewDateTimeRange,
        showSecondaryNOA,
        toggleShowSecondaryNOA,
        isCreatingAirspace,
        setIsCreatingAirspace,
        selectedAirspace,
        setSelectedAirspace,
        airspaceIsClosed,
        setAirspaceIsClosed,
        userCanUpdateEmergencyAirspaceClosure,
        setUserCanUpdateEmergencyAirspaceClosure,
        forceReloadMap,
        setForceReloadMap,
        socket,
        setSocket,
      }}
    >
      <ApplicationBar />

      {canAccessDigitalAuthorisation ? <DigitalAuthorizationScreen /> : null}
      <div className="screen-container">
        <Routes>
          {/* HOME */}
          <Route path="/" element={<HomeScreen />} />

          <Route path="/admin" element={<Admin />} />

          {/* CONFLICTED_FLIGHTS */}
          <Route path="/conflicted-flights" element={<ConflictedFlights />} />

          {/* FLIGHT-SEARCH */}
          <Route path="/flights" element={<FlightReport />} />

          {/* FLIGHT */}
          <Route path="/flight/:flightId" element={<Flight />} />

          {/* UAVs */}
          <Route path="/uavs" element={<Uavs />} />

          {/* UAV MANUFACTURERS */}
          <Route path="/uav-manufacturers" element={<UavManufacturers />} />

          {/* EXTERNAL ORGANISATIONS */}
          <Route
            path="/external-organisations"
            element={<ExternalOrganisations />}
          />

          {/* RULES ENGINE FLOWCHART */}
          <Route
            path="/rules-engine-flowchart"
            element={<RulesEngineFlowchart />}
          />

          {/* ORGANISATIONS */}
          <Route path="/organisations" element={<Organisations />} />

          {/* REPORTS */}
          <Route path="/reports" element={<Reports />} />

          {/* REPORTS */}
          <Route path="/about" element={<About />} />

          {/* MANAGED AIRSPACES */}
          <Route path="/managed-airspaces" element={<ManagedAirspaces />} />

          <Route path="/managed-airspaces/add" element={<EditAirspace add />} />

          <Route
            path="/managed-airspaces/:areaId"
            element={<EditAirspace add={false} />}
          />

          {window.env.EXTERNAL_API_ENABLE_GEOFENCE_SUBSCRIPTION_API ===
            'true' && (
            <Route path="/demo/subscriptions" element={<SubscriptionsDemo />} />
          )}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </LoginContext.Provider>
  );
}

export default ProtectedLayout;
