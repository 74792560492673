import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = (isHeader: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      gridItem: {
        position: 'relative',
        gridColumnEnd: 'auto',
        gridColumnStart: 'auto',
        minHeight: '1.8rem',
        padding: 'auto',

        textAlign: 'center',
        verticalAlign: 'middle',

        backgroundColor: isHeader
          ? theme.palette.primary.light
          : theme.palette.common.white,
        borderColor: theme.palette.common.black,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    })
  );

const GridTextItem = ({
  text,
  isHeader = false,
}: {
  text: string;
  isHeader?: boolean;
}): JSX.Element => {
  const classes = useStyles(isHeader)({});
  return (
    <div className={classes.gridItem}>
      <Typography variant={isHeader ? 'subtitle1' : 'body2'}>{text}</Typography>
    </div>
  );
};

export default GridTextItem;
