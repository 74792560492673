import { DateTime } from 'luxon';
import { ACTIVATABLE_TIME_PERIOD_MINS } from '@airshare/external-api-types';

import type { FlightDateTime } from 'argus-data-model/db/schemas/flight-request/_flight-date-time';

export const isEligibileForNotifiation = <
  P extends Pick<FlightDateTime, 'startDateTime' | 'timezone'>,
>(
  flightDateTime: P
): Date =>
  DateTime.fromJSDate(flightDateTime.startDateTime, {
    zone: flightDateTime.timezone,
  })
    .minus({
      minutes: ACTIVATABLE_TIME_PERIOD_MINS,
    })
    .toJSDate();

/**
 * A flight strip should have been created,
 * the flight request is eligible for activation
 */
export const isNotifiedFlight = <
  P extends Pick<FlightDateTime, 'startDateTime' | 'timezone'>,
>(
  flightDateTime: P
): boolean =>
  DateTime.fromJSDate(flightDateTime.startDateTime, {
    zone: flightDateTime.timezone,
  }).minus({
    minutes: ACTIVATABLE_TIME_PERIOD_MINS,
  }) < DateTime.now();

export const isNotifiedFlightISO = (isoStartDateTime?: string) =>
  !!isoStartDateTime &&
  DateTime.fromISO(isoStartDateTime).minus({
    minutes: ACTIVATABLE_TIME_PERIOD_MINS,
  }) < DateTime.now();
