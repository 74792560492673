import { useEffect } from 'react';

export function useTimeoutEffect(
  createFn: () => void,
  delay: number,
  condition: boolean
) {
  useEffect(() => {
    if (condition) {
      const id = setTimeout(() => {
        createFn();
      }, delay);

      return () => {
        clearTimeout(id);
      };
    }

    return () => {};
  }, [condition, createFn, delay]);
}
