import React from 'react';
import { DateTime } from 'luxon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AtcImpact } from '../../../clients/fims-api-client';

const AtcImpactTable = ({
  className,
  data,
}: {
  className: string;
  data: AtcImpact;
}) => (
  <Table
    className={className}
    data-testid="airshare-flights-requests-atc-impact-table"
  >
    <TableHead>
      <TableRow>
        <TableCell align="left">Week Beginning</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        {data.weeks.map(({ startDate }) => (
          <TableCell
            align="right"
            style={{ width: '20%' }}
            key={DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          >
            {DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          </TableCell>
        ))}
        <TableCell align="right" style={{ width: '20%' }}>
          Total
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        {data.weeks.map(({ value, startDate }) => (
          <TableCell
            key={`controlled-${DateTime.fromISO(startDate).toFormat(
              'dd-MMM-yyyy'
            )}`}
            align="right"
          >
            {value}
          </TableCell>
        ))}
        <TableCell
          align="right"
          data-testid="airshare-flights-requests-atc-impact-total-count"
        >
          {data.total}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default AtcImpactTable;
