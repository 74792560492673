export const LOG_OUT = 'session/LOG_OUT';

export const createReducer = <State extends Object>(
  handlers: any,
  defaultState: State = null,
  disableStateReset = false
) => {
  const allHandlers = {
    ...handlers,
  };

  if (!disableStateReset) {
    allHandlers[LOG_OUT] = () => defaultState;
  }

  return (state = defaultState, action: any) => {
    const handler = allHandlers[action.type];
    return handler ? handler(state, action) : state;
  };
};

export function createAction<PayloadType, B>(actionType: B) {
  return (payload?: PayloadType) => ({
    type: actionType,
    payload,
  });
}
