import React, { useState, useCallback, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { Badge, Button, Drawer, Tooltip, Typography } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import type { DigitalAuthRequest } from 'fims-api-types';
import { SoundPlayer, Loading } from 'asm-web-components';

import ActivationRequest from '../home-screen/notifications/activation-request';

import { useInterval } from '../../../state/custom-hooks/useInterval';
import * as fimsClient from '../../../clients/fims-api-client';
import { useProfile } from '~/state/session';
import { LoginContext } from '~/context/LoginState';
import { usePushNotification } from '../../../hooks/push-notification.hook';

import Logo from './logo.png';

const DIGITAL_ACTIVATIONS_AUTO_DRAWER_OPEN_ENABLED =
  !window.env.DIGITAL_ACTIVATIONS_AUTO_DRAWER_OPEN_ENABLED ||
  window.env.DIGITAL_ACTIVATIONS_AUTO_DRAWER_OPEN_ENABLED?.toLowerCase() ===
    'true';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    digitalAuthorizationContainer: {
      backgroundColor: '#81aada',
      color: '#fff',
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: theme.zIndex.snackbar,
    },
    digitalAuthorizationDrawerWrapper: {
      backgroundColor: 'transparent',
      paddingLeft: '35px',
    },
    digitalAuthorizationDrawer: {
      width: '580px',
      height: '100vh',
      backgroundColor: '#fff',
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
    digitalAuthorizationDrawerFooter: {
      display: 'flex',
      justifyContent: 'center',
      padding: '30px 10px',
    },
    tabPullOuter: {
      cursor: 'pointer',
      position: 'absolute',
      top: '240px',
      right: '0',
    },
    authorizationList: {
      paddingTop: '20px',
    },
    noAuthorizations: {
      width: '100%',
      textAlign: 'center',
    },
    li: {
      float: 'right',
      width: '95%',
    },
    tabPullBase: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: '#fff !important',
      overflow: 'hidden',
      textAlign: 'right',
      padding: '0p',
      cursor: 'pointer',
      height: '3rem',
      position: 'fixed',
    },
    tabPullBaseCollapsed: {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    },
    tabPullInner: {
      position: 'absolute',
      top: '240px',
      left: '2px',
      zIndex: 100,
    },
    drawer: {
      '& .MuiDrawer-paperAnchorDockedRight': {
        borderLeft: 'none !important',
        backgroundColor: 'transparent !important',
      },
    },
    logo: {
      maxHeight: 24,
    },
  })
);

const textActivation = 'activations';
const webPushNotificationsChannel = new BroadcastChannel(
  'web-push-notifications-channel'
);

export default function DigitalAuthorizationScreen() {
  const [digitalAuthRequests, setDigitalAuthRequests] = useState<
    DigitalAuthRequest[]
  >([]);
  const {
    isPushNotificationDisabled,
    subscribe,
    hasLocalSubscription,
    setHasLocalSubscription,
  } = usePushNotification();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSubscriptionButtonDisabled, setIsSubscriptionButtonDisabled] =
    useState(false);

  const [expanded, setExpanded] = useState<string | false>(
    digitalAuthRequests[0]?.key ?? false
  );
  const [beepFrequency, setBeepFrequency] = useState(180);

  const profile = useProfile();

  const { airspaceIsClosed } = useContext(LoginContext);

  const [loadingDigitalAuthRequests, setLoadingDigitalAuthRequests] =
    useState<boolean>(false);

  webPushNotificationsChannel.onmessage = async (event) => {
    setLoadingDigitalAuthRequests(true);
    const incomingDigitalAuthRequests =
      await fimsClient.getActivationRequests();
    setDigitalAuthRequests(incomingDigitalAuthRequests);
    if (event.data.flightId === '') {
      openDrawer(incomingDigitalAuthRequests[0]?.key);
    } else {
      openDrawer(event.data.key);
    }
    setLoadingDigitalAuthRequests(false);
  };

  useEffect(() => {
    const updateBeepFrequency = async () => {
      const organisationSettings = await fimsClient.getOrganisationSettings(
        profile.organisation
      );
      if (organisationSettings?.beepFrequency) {
        setBeepFrequency(organisationSettings?.beepFrequency);
      }
    };

    updateBeepFrequency();
  }, [profile]);

  const FLIGHT_REQUEST_REFRESH_INTERVAL = 10000;
  const classes = useStyles();

  const [lastPlayed, setLastPlayed] = useState(
    DateTime.now().toUTC().minus({ seconds: beepFrequency })
  );
  const playAudio = SoundPlayer();

  const toggleDrawer = useCallback(
    (requestId: string | false) => {
      setIsDrawerOpen(requestId ? true : !isDrawerOpen);
      setExpanded(requestId);
    },
    [isDrawerOpen]
  );

  const openDrawer = useCallback((requestId: string) => {
    setIsDrawerOpen(true);
    setExpanded(requestId);
  }, []);

  const updateDigitalAuthRequests = useCallback(
    async () => {
      const incomingDigitalAuthRequests =
        await fimsClient.getActivationRequests();

      const currentTime = DateTime.now().set({ millisecond: 0 }).toUTC();
      const hasNewDigitalAuth = !!incomingDigitalAuthRequests.find(
        (ida) => !digitalAuthRequests.find((da) => da.key === ida.key)
      );
      setDigitalAuthRequests(incomingDigitalAuthRequests);
      if (
        (!isDrawerOpen &&
          incomingDigitalAuthRequests.length > 0 &&
          lastPlayed &&
          lastPlayed.plus({ seconds: beepFrequency }) < currentTime) ||
        hasNewDigitalAuth
      ) {
        if (isPushNotificationDisabled()) {
          await playAudio();
          setLastPlayed(currentTime);
        }

        if (DIGITAL_ACTIVATIONS_AUTO_DRAWER_OPEN_ENABLED) {
          openDrawer(incomingDigitalAuthRequests[0]?.key);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      beepFrequency,
      digitalAuthRequests,
      isDrawerOpen,
      lastPlayed,
      playAudio,
      openDrawer,
    ]
  );

  useInterval(updateDigitalAuthRequests, FLIGHT_REQUEST_REFRESH_INTERVAL);

  const playAlert = useCallback(
    async () => {
      const currentTime = DateTime.now().set({ millisecond: 0 }).toUTC();
      if (
        lastPlayed &&
        lastPlayed.plus({ seconds: beepFrequency }) <= currentTime &&
        digitalAuthRequests.length &&
        isPushNotificationDisabled()
      ) {
        await playAudio();
        setLastPlayed(currentTime);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [digitalAuthRequests, lastPlayed, beepFrequency, playAudio]
  );

  useInterval(playAlert, beepFrequency * 1000);

  const canDisplaySubscriptionButton = () => {
    const isWebpushNotificationEnabled =
      window.env.WEB_PUSH_NOTIFICATION_ENABLED?.toLowerCase() === 'true';
    const isPWA =
      window.matchMedia('(display-mode: standalone)').matches ||
      // @ts-ignore
      window.navigator.standalone;
    return (
      isWebpushNotificationEnabled &&
      (('serviceWorker' in navigator &&
        isPWA &&
        window.Notification?.permission === 'default') ||
        (window.Notification?.permission === 'granted' &&
          !hasLocalSubscription))
    );
  };

  useEffect(
    () => {
      if (!('serviceWorker' in navigator)) {
        setHasLocalSubscription(false);
        return;
      }
      navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager
          .getSubscription()
          ?.then((subscription) => {
            setHasLocalSubscription(!!subscription);
          })
          .catch(() => {
            setHasLocalSubscription(false);
          });
      });
    },
    // forces periodic resync of service worker subscription
    [digitalAuthRequests, setHasLocalSubscription]
  );

  const sideList = () => (
    <div
      className={classes.authorizationList}
      data-testid="digital-authorisation-list"
    >
      {airspaceIsClosed ? (
        <Typography
          className={classes.noAuthorizations}
          data-testid="digitial-auth-airspace-is-closed"
        >
          {`Airspace is closed due to an emergency. Digitial ${textActivation} are
          not available.`}
        </Typography>
      ) : (
        <>
          {loadingDigitalAuthRequests ? (
            <div className="flight__loading-container">
              <Loading className="loading" />
            </div>
          ) : (
            <>
              {digitalAuthRequests.length === 0 ? (
                <Typography
                  className={classes.noAuthorizations}
                  data-testid="no-authorisations"
                >
                  {`No ${textActivation} to view`}
                </Typography>
              ) : (
                digitalAuthRequests.map((daRequest, ind) => (
                  <ActivationRequest
                    key={daRequest.key}
                    expanded={
                      expanded === daRequest.key ||
                      (ind === 0 && digitalAuthRequests.length === 1)
                    }
                    setExpanded={setExpanded}
                    digitalAuthRequest={daRequest}
                    updateDigitalAuthRequests={updateDigitalAuthRequests}
                    closeDrawer={() => toggleDrawer(false)}
                  />
                ))
              )}
            </>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={classes.digitalAuthorizationContainer}>
      <Button
        className={classnames(
          classes.tabPullBase,
          classes.tabPullOuter,
          !expanded ? classes.tabPullBaseCollapsed : ''
        )}
        onClick={() =>
          toggleDrawer(isDrawerOpen ? false : digitalAuthRequests[0]?.key)
        }
        data-testid="digital-authorisation-container-button"
      >
        <Badge
          overlap="rectangular"
          max={9}
          color="secondary"
          badgeContent={digitalAuthRequests.length}
        >
          <img src={Logo} alt="logo" className={classes.logo} />
        </Badge>
      </Button>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() =>
          toggleDrawer(isDrawerOpen ? false : digitalAuthRequests[0]?.key)
        }
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className={classes.drawer}
        data-testid="digital-authorisation-drawer"
      >
        <div className={classes.digitalAuthorizationDrawerWrapper}>
          <div className={classes.digitalAuthorizationDrawer}>
            <Button
              className={classnames(classes.tabPullBase, classes.tabPullInner)}
              onClick={() =>
                toggleDrawer(isDrawerOpen ? false : digitalAuthRequests[0]?.key)
              }
              data-testid="digital-authorisation-button"
            >
              <img src={Logo} alt="logo" className={classes.logo} />
            </Button>
            <div>{sideList()}</div>
            {canDisplaySubscriptionButton() ? (
              <div className={classes.digitalAuthorizationDrawerFooter}>
                <Tooltip
                  title={
                    <span style={{ fontSize: '16px' }}>
                      Subscribe to recieve push notifications for digital
                      activation in your device.
                    </span>
                  }
                >
                  <span>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={isSubscriptionButtonDisabled}
                      onClick={() => {
                        setIsSubscriptionButtonDisabled(true);
                        subscribe(true);
                      }}
                      data-testid="webpush-notifications-subscribe-button"
                      startIcon={<Notifications />}
                    >
                      Subscribe to desktop notifications
                    </Button>
                  </span>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
