import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const Who = ({ focussedFlightRequest }: Props) => {
  const [whoOpen, setWhoOpen] = useState(true);

  return (
    <div data-testid="flight-info-who">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setWhoOpen(!whoOpen)}
        data-testid="flight-who-heading"
      >
        <ListItemText primary="Who" />
        {whoOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={whoOpen} timeout="auto" unmountOnExit>
        <table className="flight-info-table" data-testid="flight-who-table">
          <tbody>
            <tr>
              <th data-testid="flight-who-business-name-title">
                Business Name
              </th>
              <td data-testid="flight-who-business-name-value">
                {focussedFlightRequest.company}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-who-account-contact-title">
                Account Contact
              </th>
              <td data-testid="flight-who-account-contact-value">
                {[
                  focussedFlightRequest.accountContact.givenName,
                  focussedFlightRequest.accountContact.familyName,
                ].join(' ')}
                , {focussedFlightRequest.accountContact.mobileNo}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-who-pilot-title">Pilot</th>
              <td data-testid="flight-who-pilot-value">
                {[
                  focussedFlightRequest.pilot.givenName,
                  focussedFlightRequest.pilot.familyName,
                ].join(' ')}
                , {focussedFlightRequest.pilot.mobileNo}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default Who;
