import { useState } from 'react';
import type { LatLng } from '@airshare/external-api-types';

import type { PlaceSelection } from 'asm-web-components/src/components/search/search';
import { MapHelpers } from '../../components/protected-layout/home-screen/map/hooks/helpers';

interface MapState {
  isLayerLegendOpen: boolean;
  userMarker: LatLng;
  intialMapInteractionDetected: boolean;
  mapHelpers: unknown;
  placesServiceInstance: unknown;
}

interface MapHandlers {
  handleCenterPositionClick: (callback?: () => void) => void;
  handlePlaceSearchSelection: (
    selection: PlaceSelection,
    callback?: () => void
  ) => void;
  handleInitialInteraction: (
    initialZoom: number,
    callback?: () => void
  ) => void;
  setIsLayerLegendOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPlacesServiceInstance: React.Dispatch<any>;
  setUserMarker: React.Dispatch<React.SetStateAction<LatLng>>;
  setIdleListener: (event: any) => void;
  animationPrep: () => void;
}

export function useMapHandlers(
  map: google.maps.Map,
  mapHelpers: MapHelpers | null
): [MapState, MapHandlers] {
  const [isLayerLegendOpen, setIsLayerLegendOpen] = useState(false);
  const [userMarker, setUserMarker] = useState<LatLng>(null);
  const [intialMapInteractionDetected, setIntialMapInteractionDetected] =
    useState(false);
  const [placesServiceInstance, setPlacesServiceInstance] = useState(null);
  const [idleListener, setIdleListener] = useState(null);

  let lastEvent: number;
  const duration = 1000;
  function eventStoppedFiring(callback: () => void) {
    if (lastEvent + duration <= new Date().getTime()) {
      setIntialMapInteractionDetected(true);
      if (callback) {
        callback();
      }
    }
  }

  function delayCallback(callback: () => void) {
    lastEvent = new Date().getTime();
    setTimeout(() => {
      eventStoppedFiring(callback);
    }, duration);
  }

  function handleInitialInteraction(
    initialZoom: number,
    callback?: () => void
  ) {
    if (google && map) {
      google.maps.event.addListenerOnce(map, 'idle', () => {
        google.maps.event.addListenerOnce(map, 'click', () => {
          setIntialMapInteractionDetected(true);
          if (callback) {
            delayCallback(callback);
          }
        });

        google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
          map.setZoom(Math.min(map.getZoom(), initialZoom));
          delayCallback(callback);
        });
      });
    }
  }

  function handleCenterPositionClick(callback?: () => void) {
    if (userMarker) {
      animationPrep();
      mapHelpers?.smoothlyAnimatePanTo(
        new google.maps.LatLng(userMarker),
        () => {
          mapHelpers.animateMapZoomTo(16, callback);
        }
      );
    }
  }

  function handlePlaceSearchSelection(
    selection: PlaceSelection,
    callback?: () => void
  ) {
    if (selection?.geometry) {
      animationPrep();

      if (callback) {
        callback();
      }

      mapHelpers?.smoothlyAnimatePanTo(selection.geometry.location, () => {
        mapHelpers.animateMapZoomTo(16);
      });
    }
  }

  function animationPrep() {
    if (idleListener) {
      google.maps.event.removeListener(idleListener);
      setIdleListener(null);
    }
  }

  const mapState = {
    isLayerLegendOpen,
    userMarker,
    intialMapInteractionDetected,
    mapHelpers,
    placesServiceInstance,
  };

  const mapHandlers = {
    handleCenterPositionClick,
    handlePlaceSearchSelection,
    handleInitialInteraction,
    setIsLayerLegendOpen,
    setPlacesServiceInstance,
    setUserMarker,
    setIdleListener,
    animationPrep,
  };

  return [mapState, mapHandlers];
}
