import type { FlightRequest } from '../flight-requests/database';
import {
  FRStatusCode,
  FRStatusCodeUnknown,
  FlightAdvisoryStatusCode,
  isRequestingDA,
} from '../flight-requests';

export const styleKeys = [
  'fillColor',
  'fillOpacity',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'clickable',
  'editable',
  'zIndex',
];

export interface FeatureStyle {
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  clickable?: boolean;
  editable?: boolean;
  zIndex?: number;
}

export enum FlightStatusColorCode {
  Activated = '#388e3c',
  Authorized = '#1976d2',
  Cancelled = '#d32f2f',
  Conflicted = '#ae7071',
  Declared = '#2f2f2f',
  Declined = '#d32f2f',
  Pending = '#ffa500',
  ProvAuth = '#0cb4fe',
  RNZAFAuthReq = '#ffa500',
  Terminated = '#9da7c0',

  Requesting = '#0000ff',
}

const DEFAULT_FLIGHT_STYLE: FeatureStyle = {
  fillColor: '#000000',
  fillOpacity: 0.3,
  strokeColor: '#0000FF',
  strokeOpacity: 0.8,
  strokeWeight: 3,
  clickable: false,
  editable: false,
  zIndex: 1,
};

export const getColorForStatus = (
  status: FlightAdvisoryStatusCode,
  digitalClearance?: FlightRequest['digitalClearance']
): FlightStatusColorCode => {
  if (isRequestingDA({ digitalClearance })) {
    return FlightStatusColorCode.Requesting;
  }

  switch (status) {
    case FRStatusCode.Activated:
      return FlightStatusColorCode.Activated;
    case FRStatusCode.Pending:
      return FlightStatusColorCode.Pending;
    case FRStatusCode.RNZAFAuthReq:
      return FlightStatusColorCode.RNZAFAuthReq;
    case FRStatusCode.Authorized:
      return FlightStatusColorCode.Authorized;
    case FRStatusCode.ProvAuth:
      return FlightStatusColorCode.ProvAuth;
    case FRStatusCode.Declined:
      return FlightStatusColorCode.Declined;
    case FRStatusCode.Cancelled:
      return FlightStatusColorCode.Cancelled;
    case FRStatusCode.Conflicted:
      return FlightStatusColorCode.Conflicted;
    case FRStatusCode.Declared:
      return FlightStatusColorCode.Declared;
    case FRStatusCode.Terminated:
      return FlightStatusColorCode.Terminated;
    case FRStatusCodeUnknown.Unknown:
    default:
      return FlightStatusColorCode.Declared;
  }
};

export const getColorPropsFaded = (color: string): FeatureStyle => {
  return {
    ...DEFAULT_FLIGHT_STYLE,
    strokeColor: color,
    strokeOpacity: 0.6,
    strokeWeight: 1,
    fillColor: color,
    fillOpacity: 0.2,
    zIndex: 1,
  };
};

export const getColorProps = (color: string): FeatureStyle => {
  return {
    ...DEFAULT_FLIGHT_STYLE,
    strokeColor: color,
    fillColor: color,
    zIndex: 2,
  };
};
