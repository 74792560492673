import { Schema, Document } from 'mongoose';
import {
  type ManagedAreaAudit,
  ManagedAreaAuditAction,
} from '@airshare/external-api-types';
export { ManagedAreaAuditAction, ManagedAreaAudit };

export const trackedManagedAreaFields = [
  'geometry',
  'properties.code',
  'properties.description',
  'properties.isActive',
  'properties.email',
  'properties.lowerLimitFeet',
  'properties.name',
  'properties.phone',
  'properties.pilotVisible',
  'properties.tileDisplay',
  'properties.startDateTime',
  'properties.endDateTime',
  'properties.upperLimitFeet',
  'properties.authorizedPilots',
  'properties.icaoCode',
];

export interface ManagedAreaAuditSchema extends ManagedAreaAudit, Document {}

const managedAreaAuditSchema = new Schema<ManagedAreaAuditSchema>(
  {
    action: {
      type: String,
      enum: Object.values(ManagedAreaAuditAction),
      required: true,
    },
    userId: {
      type: String,
      ref: 'User',
      required: false,
    },
    userName: {
      type: String,
      required: false,
    },
    authorizerId: {
      type: String,
      ref: 'Authorizer',
      required: true,
    },
    message: String,
    changes: {
      type: [String],
      required: true,
    },
    createdAt: {
      type: Schema.Types.Date,
      required: false,
    },
    updatedAt: {
      type: Schema.Types.Date,
      required: false,
    },
  },
  { timestamps: true, _id: false, id: false }
);

export default managedAreaAuditSchema;
