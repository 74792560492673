import React from 'react';
import {
  Button,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { useProfile } from '~/state/session';
import { UserRole } from 'fims-api-types';

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      zIndex: 1,
      right: '5px',
      backgroundColor: 'rgba(211,47,47,0.8)',
      color: 'var(--color-true-white)',
      borderRadius: '8px',
      position: 'fixed',
      left: '122px',
      top: '61px',
    },
    button: {
      backgroundColor: 'var(--color-true-white)',
      color: 'var(--color-highlight-blue)',
      '&.Mui-disabled, &.Mui-disabled:hover': {
        backgroundColor: 'var(--color-true-white)',
        color: 'var(--color-shade-primary)',
      },
    },
    legend: {
      marginRight: '5px',
      display: 'inline',
    },
    legend2: {
      marginRight: '5px',
      display: 'inline',
      textTransform: 'uppercase',
    },
  })
);

export function TowerOffWatchBanner({
  actionHandler,
}: {
  actionHandler: () => void;
}) {
  const profile = useProfile();

  const classes = useStyles();

  const roles = profile.roles;
  const nextOnWatch = profile.tower?.nextOnWatch;
  const showButton =
    roles.includes(UserRole.ADMIN) || roles.includes(UserRole.AUTHORISER);

  async function handleClick() {
    actionHandler();
  }

  return (
    <Alert
      variant="filled"
      className={classes.alert}
      icon={false}
      action={
        <>
          {nextOnWatch && (
            <div className={classes.legend}>
              <Typography variant="body2" className={classes.legend}>
                The tower will start automatically next watch on
              </Typography>
              <Typography variant="body2" className={classes.legend2}>
                {DateTime.fromISO(nextOnWatch).toLocaleString({
                  weekday: 'short',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })}
                &nbsp;
              </Typography>
            </div>
          )}
          {showButton && (
            <Button
              variant="contained"
              className={classes.button}
              size="small"
              onClick={handleClick}
            >
              START WATCH NOW
            </Button>
          )}
        </>
      }
    >
      <Typography variant="body2"> OFF WATCH </Typography>
    </Alert>
  );
}
