import type { Feature } from 'geojson';
import type { ObjectId } from 'mongodb';
import { Area, Timestamps } from '../misc';
import { AmendExistingFlightsResult } from '../flight-requests/amend-flight-status';

export enum ManagedAreaCode {
  NFZ = 'NFZ',
  Advisory = 'Advisory',
  ExclusiveZone = 'Exclusive Zone',
  FlightPath = 'Flight Path',
  Aerodrome = 'Aerodrome',
  EmergencyAirspaceClosure = 'Emergency Airspace Closure',
}

export enum UnfinishedManagedAreaCode {
  CTR = 'Control Zone',
  DangerZone = 'Danger Area',
  LFZ = 'Low Flying Zone',
  MOA = 'Military Operating Area',
  TL = 'Transit Lane',
}

export enum ManagedAreaAuditAction {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  AMEND_FLIGHTS = 'AMEND_FLIGHTS',
  UPDATED_FLIGHT_REQUESTS_SMS_SENT = 'UPDATED_FLIGHT_REQUESTS_SMS_SENT',
}

/* Airspace Identifiers

ICAO code format, e.g. NZAA - used for Aerodromes

Other AIP codes - not ICAO?, third letter denotes type - examples:
NZA153 - Control towers
NZD332 - Danger Areas
NZM206 - NZ Defence force areas
NZL267 - Low Flying

*/
interface AerodromeProps {
  icaoCode: string;
}

interface FlightPathProps {
  authorizedPilots?: string[];
}

interface AdvisoryProps {
  councilName?: string;
}

interface ManagedAreaCommonProps {
  code: ManagedAreaCode;
  startDateTime?: Date;
  endDateTime?: Date;
}
export type AllExtraSchemaProps = ManagedAreaCommonProps &
  Partial<AerodromeProps> &
  Partial<FlightPathProps> &
  Partial<AdvisoryProps>;

export interface ManagedAreaAudit extends Timestamps {
  action: ManagedAreaAuditAction;
  amendFlights?: AmendExistingFlightsResult;
  authorizerId: string;
  changes: string[];
  userId?: string;
  userName?: string;
  message?: string;
}

export interface LogoData {
  category: string;
  code: string;
}

// Advisory, Aerodrome, Helipad
type AdvisoryType = 'AD' | 'AH' | 'HP';
export type MapCodeType =
  | 'ad'
  | 'ctr'
  | 'tl'
  | 'd'
  | 'lfz'
  | 'moa'
  | 'nfz'
  | 'r'
  | 'parachuting'
  | 'hang-gliding'
  | 'tl'
  | 'unknown';

export interface MapProperties {
  activity?: string;
  lowerLimitFeet?: number;
  upperLimitFeet?: number;
  elevationFeet?: number;
  workinghours?: string;
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity: number;
  name: string;
  description?: string;
  icaoCode?: string;
  website?: string;
  policies?: string[];
  category?: string;
  type?: AdvisoryType;
  mapCode?: MapCodeType;
  mapZoom?: number;
  minZoom?: number;
  lat?: number;
  lng?: number;
  pilotVisible: boolean;
  tileDisplay?: boolean;
  atcVisible: boolean;
  isMapLayer: boolean;
  geoQueryInclude: boolean;
  isActive: boolean;
  logo?: LogoData;
  siteName?: string; // Military types
  siteDescription?: string | null; // Councils
  address?: string | null; // Military types
  phone?: string | null; // Military types
  email?: string;
  notamEmailAddress?: string | null;
  deviationThresholdHorizontalMetres?: number;
  deviationThresholdVerticalFeet?: number;
}

export type ManagedAreaProperties = MapProperties & AllExtraSchemaProps;

export interface ManagedArea extends Feature<Area, ManagedAreaProperties> {
  authorizer?: ObjectId;
  auditLog?: ManagedAreaAudit[];
}
