import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import { Loading } from 'asm-web-components';
import { FRStatusCode } from 'argus-common/flight-requests.lib';

import {
  LoginContext,
  MapView24Hour,
} from '../../../../../src/context/LoginState';
import { useSurveillanceWithLatency } from '~/websocket/use-surveillance';
import { getCounterData, getupcomingData } from '../home-screen.lib';
import { FlightCountByStatus } from '../types';
import { CountBadge } from './count-badge';

const renderFlightCountsByStatus = (
  dashboardStats: FlightCountByStatus,
  mapView24hour: MapView24Hour,
  isCtrZoneAuthorizer: boolean
): JSX.Element => {
  const upcoming = getupcomingData(dashboardStats, true);
  const flightStatsByMapView = {
    [MapView24Hour.NEXT24]: [
      'activated',
      isCtrZoneAuthorizer ? 'provisionallyAuthorized' : 'authorized',
      'pending',
    ],
    [MapView24Hour.CURRENT]: [
      'nowActivated',
      isCtrZoneAuthorizer ? 'nowProvisionallyAuthorized' : 'nowAuthorized',
    ],
    [MapView24Hour.TODAY]: [
      'todayActivated',
      isCtrZoneAuthorizer ? 'todayProvisionallyAuthorized' : 'todayAuthorized',
      'todayPending',
    ],
    [MapView24Hour.DATE]: [
      'nowActivated',
      isCtrZoneAuthorizer ? 'todayProvisionallyAuthorized' : 'todayAuthorized',
      'todayPending',
    ],
  };

  const testIdSuffix = (status: string) =>
    status === FRStatusCode.ProvAuth ? 'prov-auth' : status.toLowerCase();

  return (
    <>
      {upcoming
        .filter((f) => flightStatsByMapView[mapView24hour].includes(f.type?.id))
        .map((x) => (
          <CountBadge
            key={x.type?.text}
            {...x}
            testId={`upcoming-flights-data-${testIdSuffix(x.type?.text)}`}
          />
        ))}
    </>
  );
};

export default function HomeList({
  dashboardStats,
  isCtrZoneAuthorizer,
}: {
  dashboardStats: FlightCountByStatus;
  isCtrZoneAuthorizer: boolean;
}) {
  const [surveillanceData] = useSurveillanceWithLatency();
  const { mapView24hour, mapViewDateTimeRange } = useContext(LoginContext);
  const showLiveFlights =
    window.env.SHOW_LIVE_FLIGHTS_PANEL?.toLowerCase() === 'true';

  const titleLabel = {
    [MapView24Hour.NEXT24]: 'Next 24H',
    [MapView24Hour.CURRENT]: 'Current',
    [MapView24Hour.TODAY]: 'Today',
    [MapView24Hour.DATE]: (
      <small>{DateTime.fromJSDate(mapViewDateTimeRange[0]).toISODate()}</small>
    ),
  };

  return (
    <List
      className={`flights-info ${mapView24hour}`}
      style={{
        display: mapView24hour?.toLowerCase() !== 'date' ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
        fontSize: 'medium',
        padding: '10px 0px',
        whiteSpace: 'nowrap',
        ...(mapView24hour?.toLowerCase() === 'date' && {
          filter: 'grayscale(100%)',
          pointerEvents: 'none',
        }),
      }}
    >
      {showLiveFlights && (
        <List
          component="nav"
          aria-labelledby="flight-info-list"
          subheader={
            <ListSubheader
              component="div"
              data-testid="flight-info-list"
              style={SUB_HEADER_STYLE}
            >
              Live Flights
            </ListSubheader>
          }
        >
          {getCounterData(surveillanceData).map((item) => (
            <CountBadge
              key={item.type.text}
              {...item}
              testId={`flight-info-list-item-${item.type.text}`}
            />
          ))}
        </List>
      )}
      <List
        component="nav"
        aria-labelledby="flights-24-list"
        subheader={
          showLiveFlights ? (
            <ListSubheader
              component="div"
              data-testid="flights-24-list"
              style={SUB_HEADER_STYLE}
            >
              {titleLabel[mapView24hour]}
            </ListSubheader>
          ) : undefined
        }
        style={showLiveFlights ? {} : { marginTop: '-10px' }}
      >
        {dashboardStats.loaded ? (
          renderFlightCountsByStatus(
            dashboardStats,
            mapView24hour,
            isCtrZoneAuthorizer
          )
        ) : (
          <Loading className="loading" />
        )}
      </List>
    </List>
  );
}

const SUB_HEADER_STYLE = {
  fontFamily: 'GothamMedium',
  textAlign: 'center' as const,
  lineHeight: 'normal',
  paddingTop: '10px',
};
