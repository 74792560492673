import * as React from 'react';
import { useSurveillanceWithLatency } from '~/websocket/use-surveillance';

export default function SurveillanceLatencyDisplay() {
  const [_surveillanceData, avgLatencyMs] = useSurveillanceWithLatency();

  const inSecs = avgLatencyMs / 1000;
  const { SURVEILLANCE_LATENCY_WARNING_TIME_IN_SECS } = window.env;
  const configValue = Number.parseInt(
    SURVEILLANCE_LATENCY_WARNING_TIME_IN_SECS
  );
  const latencyThreshold = isNaN(configValue) ? 3 : configValue;
  let isWarningState = inSecs > latencyThreshold;
  let displayText = `Latency: ${inSecs.toFixed(1)}s`;

  if (avgLatencyMs === 0) {
    isWarningState = true;
    displayText = 'ATM Feed Unavailable';
  }

  return (
    <div
      data-testid="surveillance-latency-display"
      style={{
        fontSize: '12px',
        position: 'absolute',
        left: '11px',
        bottom: '28px',
        color: isWarningState ? 'red' : 'white',
      }}
    >
      {displayText}
    </div>
  );
}
