import * as yup from 'yup';
import { createFormReducer } from 'airshare-web-utils/redux-forms';
import { actions, RESET_PASSWORD_FORM_KEY } from './actions';

export const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Must be a valid email address')
      .required('Required'),
    resetKey: yup
      .number()
      .typeError('A valid 6 digit code is required')
      .required('Required'),
    newPassword: yup
      .string()
      .min(8, 'A minimum of 8 characters is required')
      .required('Required'),
    repeatNewPassword: yup
      .string()
      .min(8, 'A minimum of 8 characters is required')
      .required('Required'),
  })
  .test({
    name: 'passwordsMatch',
    message: 'Passwords must match',
    test: (value) => value.newPassword === value.repeatNewPassword,
  });

const defaultState = {
  email: '',
  resetKey: '',
  newPassword: '',
  repeatNewPassword: '',
};

export const resetPasswordFormReducer = {
  [RESET_PASSWORD_FORM_KEY]: createFormReducer(actions, defaultState, schema),
};
