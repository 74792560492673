import { useEffect, useRef, MutableRefObject, useCallback } from 'react';
import ReactDomServer from 'react-dom/server';
import type { StyledFeature } from 'fims-api-types';
import MarkerInfo, { markerInfoId } from '../../components/shared/marker-info';
import {
  getPinOptions,
  getLabeledPin,
  type FlightRequestMarkerElement,
} from '../../lib/styled-features';

const FLIGHT_AREA_Z_INDEX = 1;

export function useFlightAreaLayer(
  map: google.maps.Map,
  flightAreas: StyledFeature[],
  flightMarkerAction: (id: string) => void
) {
  const flightAreasRef: MutableRefObject<any[]> = useRef([]);

  const addAreaToMap = useCallback(
    (feature: StyledFeature, index: number) => {
      if (flightAreasRef.current[index]) {
        flightAreasRef.current[index].dataLayer.setMap(null);
      }

      flightAreasRef.current[index] = {
        flightId: feature.properties?.name,
        dataLayer: new google.maps.Data({
          map,
        }),
      };

      flightAreasRef.current[index].dataLayer.addGeoJson(feature);

      flightAreasRef.current[index].dataLayer.setStyle(
        (styleProps: any): any => ({
          ...styleProps,
          zIndex: FLIGHT_AREA_Z_INDEX,
          name: feature.properties?.name,
          title: feature.properties?.title,
          ...feature.properties?.areaStyling,
        })
      );
    },
    [map]
  );

  const addMarkersToMap = useCallback(
    (feature: StyledFeature) => {
      if (feature.properties?.segmentId) {
        const { pinOptions } = getPinOptions(feature);

        const areaPin = new google.maps.marker.PinElement(pinOptions);

        const markerContent = getLabeledPin(
          feature.properties.name,
          areaPin.element
        );

        const position = feature.properties.markerStyling.center;

        const marker = new google.maps.marker.AdvancedMarkerElement({
          map,
          position,
          title: feature.properties.title,
          content: markerContent,
        });

        addSegmentMarkerListeners(map, marker, feature, flightMarkerAction);
      }
    },
    [map, flightMarkerAction]
  );

  useEffect(() => {
    if (map && flightAreas && flightAreasRef.current) {
      flightAreas.forEach((flightArea, index) => {
        addAreaToMap(flightArea, index);
        addMarkersToMap(flightArea);
      });
    }
  }, [map, flightAreas, addAreaToMap, addMarkersToMap]);
}

function addSegmentMarkerListeners(
  map: google.maps.Map,
  marker: FlightRequestMarkerElement,
  feature: StyledFeature,
  openSegment: (segmentId: string) => void
): () => void {
  marker.infowindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: true,
  });

  const id = markerInfoId(feature, true);

  const listener = google.maps.event.addListener(
    marker.infowindow,
    'domready',
    () => {
      const markerInfoEl = document.getElementById(id);
      if (markerInfoEl) {
        markerInfoEl.addEventListener(
          'click',
          () => openSegment(feature.properties.segmentId),
          { passive: true }
        );
      }
    }
  );

  const clickListener = marker.addListener('click', () => {
    window.activeMarker?.infowindow?.close();
    window.activeMarker?.infowindow?.setContent(null);

    const content = ReactDomServer.renderToStaticMarkup(
      MarkerInfo({ feature, timezone: 'Pacific/Auckland', details: true })
    );
    marker.infowindow.setContent(content);
    marker.infowindow.open({ map, anchor: marker, shouldFocus: false });
    marker.infowindow.addListener('closeclick', () => {
      window.activeMarker = null;
    });
    window.activeMarker = marker;
  });

  return () => [listener, clickListener].map((l) => l.remove());
}
