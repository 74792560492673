import React from 'react';
import Typography from '@material-ui/core/Typography';

import './error-message.scss';

interface Props {
  main?: string;
  sub?: string;
}

const ErrorMessage: React.FC<Props> = ({ main, sub }) => (
  <div className="error-message" data-testid="error-message">
    <Typography className="error-message__text--main" variant="body1">
      {main}
    </Typography>
    <Typography variant="body2">{sub}</Typography>
  </div>
);

export default ErrorMessage;
