import type { Schema } from 'mongoose';
import type { ObjectId } from 'mongodb';
import type {
  Position,
  LineString,
  FeatureCollection,
  Polygon,
  Feature,
} from 'geojson';
import {
  Area,
  CertificationType,
  LatLng,
  Timestamps,
  UasOperationCategory,
} from '../misc';
import { Emergency } from './responses';
import {
  AltitudeType,
  ControlZone,
  DecisionStatusEnum,
  DigitalClearanceStatus,
  FRStatusCode,
  FlightDataSource,
  FlightPathModeEnum,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
} from '../';
import { FlightRequestAuditContext } from './audit-record-context-types';

export enum RequestToPilotType {
  RequestToLand = 'Flight authorisation is cancelled',
  ConformanceAlert = 'ConformanceAlert',
}
export enum RequestToPilotStatus {
  Requested = 'Requested',
  Acknowledged = 'Acknowledged',
}

export interface NoteDb {
  authorId: Schema.Types.ObjectId | string | null;
  authorName: string;
  text: string;
  createdAt?: Date;
  updatedAt?: Date;
  relatedAction: string;
}

export interface FlightDateTime extends Required<Timestamps> {
  startDateTime: Date;
  endDateTime: Date;
  durationMinutes: number;
  leadTimeMinutes: number;
  timezone: string;
}

export interface AccountContact {
  givenName: string;
  familyName: string;
  mobileNo: string;
  email: string;
  certificationNumber?: string;
}

export interface PilotAccountContact extends AccountContact {
  id?: ObjectId;
}

export interface RequiredAuthChangeDb {
  field: string;
  previousValue: number | Date;
  isAcknowledged: boolean;
  authorizerName: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface FlightAltitudeDb {
  launchElevationFeet: number;
  minAltitudeFeet: number;
  maxAltitudeFeet: number;
}

export interface DigitalClearanceFlightRequestDb {
  status?: DigitalClearanceStatus;
  reason?: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface FlightLocation {
  streetAddress: string;
  pathMode: FlightPathModeEnum;
  coordinates: Position[] | Position[][] | Position[][][];
  deadCenter: number[]; // [longitude, latitude]
  radiusMeters: number | null;
  waypoints?: LineString;
  managedAreaId?: ObjectId;
}

export interface RequestToPilot {
  updatedAt: Date;
  createdAt: Date;
  type: RequestToPilotType;
  status: RequestToPilotStatus;
  message: string;
}

export interface AdditionalInfoDb {
  nickname?: string;
  isFavourite?: boolean;
  favouriteOwner?: string;
  organisationId?: ObjectId;
  businessName?: string;
  descriptionOfOperatingArea?: string;
  flightPurpose: string;
  operatingRule: CertificationType;
  isShielded: boolean;
  isCameraInUse: boolean;
  isPriorityFlight?: boolean;
  uasOperationCategory?: UasOperationCategory;
  procedureToMeasureAltitude?: string;
  hasCertifiedTransmitter: boolean;
  hasVhfRadioContact: boolean;
  emergencyProcedure?: string;
  otherInformation?: string;
  requestToLand?: boolean;
  requestsToPilot?: RequestToPilot[];
  dataSource?: FlightDataSource;
}

export interface ExponentUser {
  ID: number;
  FullName: string;
  EmailAddress: string;
  ProfileImage: string;
  Mobile: string;
  CompanyName: string;
  PermitName: string;
  PermitExpiryDate: string;
  PermitIssueDate: Date;
  DroneIDs: number[];
}

export interface StatusUpdateUser {
  userId: string;
  userName: string;
}

export enum FlightRequestAuditAction {
  ACTIVATE_FLIGHT = 'ACTIVATE_FLIGHT',
  AMENDMENTS_ACKNOWLEDGED = 'AMENDMENTS_ACKNOWLEDGED',
  ADHOC_SMS = 'ADHOC_SMS',
  ALERT_ACKNOWLEDGED = 'ALERT_ACKNOWLEDGED',
  ALCHEMY_CREATED = 'ALCHEMY_CREATED',
  AUTHORIZER_CHANGES_REQUIRED = 'AUTHORIZER_CHANGES_REQUIRED',
  CONFORMANCE_ALERT = 'CONFORMANCE_ALERT',
  CONFORMANCE_SAFE = 'CONFORMANCE_SAFE',
  CONFORMANCE_BREACH = 'CONFORMANCE_BREACH',
  EMERGENCY = 'EMERGENCY_START',
  EMERGENCY_EXTEND = 'EMERGENCY_EXTEND',
  EMERGENCY_END = 'EMERGENCY_END',
  FLIGHT_REQUEST_CREATED = 'FLIGHT_REQUEST_CREATED',
  FLIGHT_REQUEST_AMENDED = 'FLIGHT_REQUEST_AMENDED',
  FLIGHT_REQUEST_DECLINED_SMS = 'FLIGHT_REQUEST_DECLINED_SMS',
  AUTHORISATION_CANCELLATION = 'AUTHORISATION_CANCELLATION',
  MIGRATED_FROM_EXPONENT = 'Migrated from Exponent',
  NON_CONFORMANT_ACTIVE_FLIGHT_SMS = 'NON_CONFORMANT_ACTIVE_FLIGHT_SMS',
  NOTAM_EMAIL_SENT = 'NOTAM_EMAIL_SENT',
  NOTAM_CANCELLATION_EMAIL_SENT = 'NOTAM_CANCELLATION_EMAIL_SENT',
  NOTIFICATION_EMAIL_SENT = 'NOTIFICATION_EMAIL_SENT',
  AUTHORIZER_NEW_FLIGHT_REQUEST_EMAIL_SENT = 'AUTHORIZER_NEW_FLIGHT_REQUEST_EMAIL_SENT',
  NOTIFICATION_EMAIL_FAILUTE = 'NOTIFICATION_EMAIL_FAILUTE',
  SEGMENT_ACTIVATION = 'SEGMENT_ACTIVATION',
  SEGMENT_UPDATE_APPROVAL = 'SEGMENT_UPDATE_APPROVAL',
  SMS_RECEIVED = 'SMS_RECEIVED',
  STATUS_UPDATE = 'STATUS_UPDATE',
  UPDATE_EMAIL_SENT = 'UPDATE_EMAIL_SENT',
  UPDATE_DIGITAL_CLEARANCE_STATUS = 'UPDATE_DIGITAL_CLEARANCE_STATUS',
}

export interface FlightRequestAudit extends Timestamps {
  action: FlightRequestAuditAction;
  user?: string | ObjectId | StatusUpdateUser | ExponentUser;
  userId?: ObjectId;
  pilot?: string; // email
  organisation?: ObjectId; // organisationId (external access, not airshare org)
  context?: FlightRequestAuditContext;
  message?: string;
}

export interface Status extends Timestamps {
  authorizer: string;
  code: FRStatusCode;
  actionRequired: DecisionStatusEnum;
  decisionMakerName: string;
  decisionMakerId?: ObjectId;
  decisionMakerControlZone?: string;
  decisionMakerPhone?: string;
  canAuthorize: boolean;
  warningMessage?: string;
}

export interface Tracker extends Timestamps {
  imeiNumber: number;
  iccIdNumber: number;
}

export interface Uav {
  uavId?: ObjectId;
  name?: string;
  manufacturer?: string;
  model?: string;
  uavType?: string;
  weight: string;
  description?: string;
  cClass?: string;
  enduranceMinutes?: number;
  remoteId?: string;
  acType?: string;
}

export type FlightSegmentFeature = Feature<Polygon, FlightSegmentProperties>;

export interface FlightSegmentProperties {
  id: string;
  startDateTime: Date;
  endDateTime: Date;
  maxAltitudeFeet: number;
  minAltitudeFeet: number;
  altitudeType: AltitudeType;
  launchElevationFeet: number;
  segmentStatus: SegmentStatusCode;
  segmentClearanceStatus: SegmentClearanceStatusCode;
  center: LatLng;
  segmentStreetAddress: string;
}

export type FlightSegmentFeatureCollection = FeatureCollection<
  Polygon,
  FlightSegmentProperties
>;

export interface FlightRequest {
  flightId: number;
  status: Status[];
  emergency?: Emergency;
  digitalClearance?: DigitalClearanceFlightRequestDb;
  conflictIds?: number[];
  faConflictIds?: number[];
  pilot: PilotAccountContact;
  accountContact?: AccountContact;
  allocatedControlZone?: ControlZone;
  aerodromes?: string[];
  managedAreaIds?: string[];
  flightLocation: FlightLocation;
  flightAltitude: FlightAltitudeDb;
  flightDateTime: FlightDateTime;
  geometry: Area;
  uav: Uav;
  additionalInfo: AdditionalInfoDb;
  atcNotes: NoteDb[];
  operatorNotes: NoteDb[];
  auditLog: FlightRequestAudit[];
  tracker?: Tracker;
  rawData?: any;
  externalAccessOrgId?: ObjectId;
  requiredAuthChanges?: RequiredAuthChangeDb[];
  featureCollection?: FlightSegmentFeatureCollection;
}
