import React from 'react';
import cn from 'classnames';
import './status-pill.scss';
import {
  getStatusText,
  resolveSingleStatus,
} from 'airspace-manager-common/status/multi-status-check';
import { Status } from 'fims-api-types';

interface Props {
  id: string;
  status: string | Status[];
  className?: string;
  variant?: string;
}

const StatusPill: React.FC<Props> = ({
  id,
  status,
  className,
  variant = 'contained',
}) => (
  <div
    id={id}
    className={cn('status-pill', {
      [className as string]: className,
      [variant]: variant,
    })}
    data-status={
      typeof status === 'string'
        ? status.toLowerCase()
        : resolveSingleStatus(status)?.toLowerCase()
    }
  >
    {getStatusText(status, true)}
  </div>
);

export default StatusPill;
