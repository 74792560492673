import React, { useEffect, useState } from 'react';
import { useIsOffline, useIsBackendOffline } from '../hooks';
import { fimsAPI } from './../clients/api';

const BACKEND_OFFLINE_TIMEOUT = 60000;
const CONNECTION_OFFLINE_TIMEOUT = 60000;

export function useOfflineListeners() {
  const isOffline = useIsOffline();
  const isBackendOffline = useIsBackendOffline(fimsAPI);

  const timeoutOfflineRef = React.useRef<NodeJS.Timeout | null>(null);
  const timeoutBackendRef = React.useRef<NodeJS.Timeout | null>(null);
  const [isBackendOfflineMode, setIsBackendOfflineMode] =
    useState<boolean>(false);
  const [isOfflineMode, setIsOfflineMode] = useState<boolean>(false);
  const [isFirstCall, setIsFirstCall] = useState<boolean>(true);

  useEffect(() => {
    function handleBackendOnline() {
      if (timeoutBackendRef.current) {
        clearTimeout(timeoutBackendRef.current);
      }
      if (isBackendOfflineMode) {
        setIsBackendOfflineMode(false);
      }
    }
    function handleBackendOffline() {
      timeoutBackendRef.current = setTimeout(
        () => {
          if (isFirstCall) {
            setIsFirstCall(false);
          }
          setIsBackendOfflineMode(true);
        },
        isFirstCall ? 0 : BACKEND_OFFLINE_TIMEOUT
      );
    }
    if (isBackendOffline) {
      handleBackendOffline();
    } else {
      handleBackendOnline();
    }
    return () => {
      if (timeoutBackendRef.current) {
        clearTimeout(timeoutBackendRef.current);
      }
    };
  }, [
    isBackendOffline,
    isBackendOfflineMode,
    isFirstCall,
    setIsBackendOfflineMode,
  ]);

  useEffect(() => {
    if (isOffline) {
      timeoutOfflineRef.current = setTimeout(() => {
        setIsOfflineMode(true);
        if (isBackendOfflineMode) {
          setIsBackendOfflineMode(false);
        }
        if (timeoutBackendRef.current) {
          clearTimeout(timeoutBackendRef.current);
        }
      }, CONNECTION_OFFLINE_TIMEOUT);
    } else {
      if (timeoutOfflineRef.current) {
        clearTimeout(timeoutOfflineRef.current);
      }
      if (isOfflineMode) {
        setIsOfflineMode(false);
      }
    }
    return () => {
      if (timeoutOfflineRef.current) {
        clearTimeout(timeoutOfflineRef.current);
      }
    };
  }, [isBackendOfflineMode, isOffline, isOfflineMode, setIsOfflineMode]);

  return { isOfflineMode, isBackendOfflineMode };
}
