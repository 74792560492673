import {
  Authorizer,
  AuthorizerType,
} from 'argus-data-model/db/schemas/authorizers';

export enum FRStatusCode {
  Activated = 'Activated',
  Pending = 'Pending',
  Declared = 'Declared',
  Authorized = 'Authorized',
  Conflicted = 'Conflicted',
  ProvAuth = 'Prov. Auth.',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  RNZAFAuthReq = 'RNZAF Auth. Req.',
  Terminated = 'Terminated',
}

export enum Scope {
  all = 'all',
  activated = 'activated',
  historical = 'historical',
  upcoming = 'upcoming',
  unapproved = 'unapproved',
}

export enum TimeFrame {
  all = 'All',
  past = 'Past',
  future = 'Future',
  today = 'Today',
}

export const scopeToStatus = (
  scope: string,
  authorizer?: Authorizer
): FRStatusCode[] => {
  switch (scope) {
    case Scope.activated:
      return [FRStatusCode.Activated];
    case Scope.unapproved:
      return [FRStatusCode.Pending];
    case Scope.upcoming:
      return [
        authorizer?.type === AuthorizerType.ControlZone
          ? FRStatusCode.ProvAuth
          : FRStatusCode.Authorized,
      ];
    case Scope.historical:
      return [
        FRStatusCode.Terminated,
        FRStatusCode.Declined,
        FRStatusCode.Cancelled,
      ];
    case Scope.all:
    default:
      return [];
  }
};

export const scopeToTimeFrame = (scope: string): TimeFrame => {
  switch (scope) {
    case Scope.unapproved:
      return TimeFrame.future;
    case Scope.upcoming:
      return TimeFrame.future;
    case Scope.historical:
      return TimeFrame.past;
    case Scope.all:
    case Scope.activated:
    default:
      return TimeFrame.all;
  }
};

export enum DigitalClearanceStatus {
  Requested = 'Requested',
  CallTower = 'Call Tower',
  Authorized = 'Authorized',
  Declined = 'Declined',
}
