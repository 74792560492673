import React, { useState } from 'react';
import classnames from 'classnames';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker } from '@material-ui/pickers';
import { Collapse, List } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ManagedAreaAudit } from 'argus-data-model/db/schemas/managed-areas/audit';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import { ManagedAreaCodeLabels } from './managed-airspace-constants';

import AuditLog from './audit-log';
import { getPilotEmailAutocomplete } from '../../../clients/fims-api-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      paddingRight: theme.spacing(2),
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      width: `calc(100% - (${theme.spacing(2)} * 2)px)`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    pilotInputControl: {
      width: `calc(100% - 32px)`,
      margin: '16px 16px 0px 16px',
    },
    pilotList: {
      width: `calc(100% - 32px)`,
      padding: '0px 16px',
    },
    pilotListTitle: {
      width: '100%',
      marginBottom: '-8px',
      padding: '8px 0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    pilotsTitle: {
      display: 'inline-block',
      verticalAlign: 'super',
      margin: '0px',
    },
    textField: {
      width: `calc(100% - (${theme.spacing(2)} * 2)px)`,
    },
    viewOnlyListItem: {
      cursor: 'pointer',
      padding: '3px',
    },
    removableListItem: {
      cursor: 'pointer',
      padding: '3px',
      '&:hover': {
        textDecoration: 'line-through',
      },
    },
    visiblitySwitch: {
      display: 'flex',
      justifyContent: 'space-between',
      width: `calc(100 % - ${theme.spacing(2)} * 2)`,
      margin: '1rem',
    },
    visibilityLabelTxt: {
      color: theme.palette.text.secondary,
    },
  })
);

const EditAirspaceForm = (props: {
  editingWithoutPermission: boolean;
  type: ManagedAreaCode;
  setType: (type: ManagedAreaCode) => void;
  enabledAreaTypes: { label: string; value: ManagedAreaCode }[];
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  disabled: boolean;
  pilotVisibility: string;
  setPilotVisibility: (pilotVisibility: string) => void;
  pilotVisibilityOptions: {
    label: string;
    options: { isVisible: boolean; isTileDisplay: boolean };
  }[];
  setIsTileDisplay: (isTileDisplay: boolean) => void;
  name: string;
  setName: (name?: string) => void;
  showICAOCode: boolean;
  icaoCode: string;
  setICAOCode: (ICAOCode: string) => void;
  description: string;
  setDescription: (description: string) => void;
  startDateTime: Date;
  setStartDateTime: (startDateTime: Date) => void;
  endDateTime: Date;
  setEndDateTime: (endDateTime: Date) => void;
  email: string;
  setEmail: (email: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  minAltitude: number;
  setMinAltitude: (minAltitude: number) => void;
  maxAltitude: number;
  setMaxAltitude: (maxAltitude: number) => void;
  authorizedPilots: string[];
  setAuthorizedPilots: (authorizedPilots: string[]) => void;
  authorizedPilotsOpen: boolean;
  setAuthorizedPilotsOpen: (isOpen: boolean) => void;
  auditLog: ManagedAreaAudit[];
}) => {
  const {
    editingWithoutPermission,
    type,
    setType,
    enabledAreaTypes,
    isVisible,
    setIsVisible,
    disabled,
    pilotVisibility,
    setPilotVisibility,
    pilotVisibilityOptions,
    setIsTileDisplay,
    name,
    setName,
    showICAOCode,
    icaoCode,
    setICAOCode,
    description,
    setDescription,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    email,
    setEmail,
    phone,
    setPhone,
    minAltitude,
    setMinAltitude,
    maxAltitude,
    setMaxAltitude,
    authorizedPilots,
    setAuthorizedPilots,
    authorizedPilotsOpen,
    setAuthorizedPilotsOpen,
    auditLog,
  } = props;
  const classes = useStyles();

  const [pilotEmailInput, setPilotEmailInput] = useState('');
  const [pilotEmail, setPilotEmail] = useState('');
  const [pilotOptions, setPilotOptions] = useState<string[]>([]);

  const onAutocompletePilotEmails = async (
    _event: any,
    newInputValue: string
  ) => {
    setPilotEmailInput(newInputValue);
    if (newInputValue?.length > 2) {
      const emails = await getPilotEmailAutocomplete(newInputValue);
      setPilotOptions(emails);
    }
  };

  const removePilotEmail = (emailToRemove: string) => {
    const pilots = authorizedPilots.filter(function (pilot) {
      return pilot !== emailToRemove;
    });
    setAuthorizedPilots(pilots);
  };

  return (
    <div className={classes.formControl}>
      <FormControl className={classes.item}>
        <InputLabel data-testid="type-select-label" shrink>
          Area Type *
        </InputLabel>
        <Select
          labelId="type-select-label"
          data-testid="type-select"
          inputProps={{
            'data-testid': 'type-select',
          }}
          value={type || ''}
          label="Area Type"
          disabled={editingWithoutPermission}
          onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
            setType(e.target.value as ManagedAreaCode)
          }
        >
          {editingWithoutPermission && type ? (
            <MenuItem disabled key={ManagedAreaCodeLabels[type]} value={type}>
              {ManagedAreaCodeLabels[type]}
            </MenuItem>
          ) : (
            enabledAreaTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      {[ManagedAreaCode.FlightPath].includes(type) ? (
        <FormControlLabel
          label={
            <Typography
              variant="subtitle1"
              className={classes.visibilityLabelTxt}
              data-testid="make-visible-to-pilots-text"
            >
              Make visible to pilots
            </Typography>
          }
          labelPlacement="start"
          className={classes.visiblitySwitch}
          control={
            <Switch
              name="isVisible"
              size="small"
              color="primary"
              checked={isVisible}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIsVisible(!!e.target?.checked)
              }
              data-testid="make-visible-to-pilots-switch"
            />
          }
        />
      ) : null}

      {[ManagedAreaCode.Advisory].includes(type) ? (
        <FormControl className={classes.item}>
          <InputLabel data-testid="pilot-visibility-select-label" shrink>
            Pilot Visibility
          </InputLabel>
          <Select
            labelId="pilot-visibility-select-label"
            data-testid="pilot-visibility-select"
            inputProps={{
              'data-testid': 'pilot-visibility-select',
            }}
            value={pilotVisibility || ''}
            label="Pilot Visibility"
            disabled={editingWithoutPermission}
            onChange={(
              e: React.ChangeEvent<{ name?: string; value: unknown }>
            ) => {
              setPilotVisibility(e.target.value as string);
              const pilotVisibilityOption = pilotVisibilityOptions.find(
                ({ label }) => label === e.target.value
              );
              if (pilotVisibilityOption) {
                setIsVisible(pilotVisibilityOption.options.isVisible);
                setIsTileDisplay(pilotVisibilityOption.options.isTileDisplay);
              }
            }}
          >
            {pilotVisibilityOptions.map(({ label }, index) => (
              <MenuItem key={index} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      <FormControl className={classes.item}>
        <TextField
          type="text"
          label="Name"
          name="name"
          className={classes.textField}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={disabled}
          required
          data-testid="name"
        />
      </FormControl>
      {showICAOCode && (
        <FormControl className={classes.item}>
          <TextField
            type="text"
            label="ICAO code"
            name="ICAOCode"
            className={classes.textField}
            value={icaoCode}
            disabled={disabled || !showICAOCode}
            inputProps={{
              maxLength: 4,
              style: { textTransform: 'uppercase' },
            }}
            required
            onChange={(e) => setICAOCode(e.target.value?.toUpperCase())}
            data-testid="icao-code"
          />
        </FormControl>
      )}
      <FormControl className={classes.item}>
        <TextField
          type="text"
          name="description"
          label="Description"
          className={classes.textField}
          value={description}
          disabled={disabled}
          required={type !== ManagedAreaCode.Aerodrome}
          onChange={(e) => setDescription(e.target.value)}
          data-testid="description"
        />
      </FormControl>
      <DateTimePicker
        label="Start date-time"
        value={startDateTime}
        onChange={(s) => setStartDateTime(s ? s.toJSDate() : null)}
        disabled={disabled}
        className={classes.item}
        minDate={disabled ? undefined : new Date()}
        minDateMessage="Start date must be in future"
        clearable
        data-testid="start-date-time"
      />
      <DateTimePicker
        label="End date-time"
        value={endDateTime}
        onChange={(s) => setEndDateTime(s ? s.toJSDate() : null)}
        disabled={disabled}
        disablePast
        className={classes.item}
        minDate={disabled ? undefined : startDateTime}
        minDateMessage="End must be after start"
        clearable
        data-testid="end-date-time"
      />
      <FormControl className={classes.item}>
        <TextField
          type="text"
          label="Email"
          name="email"
          className={classes.textField}
          value={email}
          disabled={disabled}
          onChange={(e) => setEmail(e.target.value)}
          data-testid="email"
        />
      </FormControl>
      <FormControl className={classes.item}>
        <TextField
          type="text"
          label="Phone"
          name="phone"
          className={classes.textField}
          value={phone}
          disabled={disabled}
          onChange={(e) => setPhone(e.target.value.toString())}
          data-testid="phone"
        />
      </FormControl>
      {type !== ManagedAreaCode.Aerodrome && (
        <FormControl className={classes.item}>
          <TextField
            type="number"
            label="Minimum altitude (ft)"
            name="minAltitude"
            className={classes.textField}
            value={minAltitude}
            onChange={(e) => setMinAltitude(parseInt(e.target.value, 10))}
            disabled={disabled}
            required
            data-testid="minimum-altitude"
          />
        </FormControl>
      )}
      {type !== ManagedAreaCode.Aerodrome && (
        <FormControl className={classes.item}>
          <TextField
            type="number"
            label="Maximum altitude (ft)"
            name="maxAltitude"
            className={classes.textField}
            value={maxAltitude}
            onChange={(e) => setMaxAltitude(parseInt(e.target.value, 10))}
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            data-testid="maximum-altitude"
          />
        </FormControl>
      )}

      {[ManagedAreaCode.ExclusiveZone, ManagedAreaCode.FlightPath].includes(
        type
      ) ? (
        <>
          {!disabled && (
            <FormControl
              className={classnames(classes.item, classes.pilotInputControl)}
            >
              <Autocomplete
                value={pilotEmail}
                onChange={(_event, newValue) => {
                  if (
                    !!newValue &&
                    !authorizedPilots?.includes(newValue) &&
                    pilotOptions.includes(newValue)
                  ) {
                    setAuthorizedPilots([...authorizedPilots, newValue]);
                    setAuthorizedPilotsOpen(true);
                  }
                  setPilotEmail('');
                  setPilotEmailInput('');
                  setPilotOptions([]);
                }}
                freeSolo={true}
                inputValue={pilotEmailInput}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'input') {
                    onAutocompletePilotEmails(event, newInputValue);
                  }
                }}
                data-testid="autocomplete-pilots"
                options={pilotOptions}
                className={classes.pilotInputControl}
                renderInput={(pilotParams) => (
                  <TextField
                    {...pilotParams}
                    label="Add Pilot"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          )}
          <div className={classes.pilotList}>
            <div
              className={classes.pilotListTitle}
              onClick={() => setAuthorizedPilotsOpen(!authorizedPilotsOpen)}
            >
              <h4 className={classes.pilotsTitle}>Allowed Pilots</h4>
              {authorizedPilotsOpen ? <ExpandLess /> : <ExpandMore />}
            </div>
            <Collapse in={authorizedPilotsOpen} timeout="auto" unmountOnExit>
              <List
                component="nav"
                aria-label="organisations list"
                data-testid="pilot-list"
              >
                {authorizedPilots.map((pilot) => {
                  return (
                    <div
                      className={
                        disabled
                          ? classes.viewOnlyListItem
                          : classes.removableListItem
                      }
                      key={`authed-pilot-${pilot}`}
                      onClick={() => !disabled && removePilotEmail(pilot)}
                      data-testid="pilot-name"
                    >
                      {pilot}
                    </div>
                  );
                })}
              </List>
            </Collapse>
          </div>
        </>
      ) : null}
      <AuditLog auditLog={auditLog} />
    </div>
  );
};

export default EditAirspaceForm;
