export enum NotificationEvent {
  // ASM events
  Error = 'ERROR',
  FimsSurveillanceEvent = 'FIMS_SURVEILLANCE_EVENT',
  FlightpathCreatedEvent = 'FLIGHTPATH_CREATED_EVENT',
  FlightpathEvent = 'FLIGHTPATH_EVENT',
  ConformanceAlertEvent = 'CONFORMANCE_ALERT_EVENT',
  ProximityAlertEvent = 'PROXIMITY_ALERT_EVENT',
  SuperAdminConfigurationEvent = 'SUPER_ADMIN_CONFIGURATION_EVENT',
  FlightReportEvent = 'FLIGHT_REPORT_EVENT',

  // External System Events
  SurveillanceEvent = 'SURVEILLANCE_EVENT',

  // Pilot Events
  PilotSurveillanceEvent = 'PILOT_SURVEILLANCE_EVENT',
  NearbyActivatedFlight = 'NEARBY_ACTIVATED_FLIGHT',
}

type Keys = keyof typeof NotificationEvent;
type Values = (typeof NotificationEvent)[Keys];

export type EmitNotificationEvents = {
  [key in Values]: (data: any) => void;
};
