import { useEffect, useMemo, useState } from 'react';
import type { MultiPolygon, Polygon } from '@turf/helpers';
import bbox from '@turf/bbox';

export default function useZoomOnFeature(
  zoom: number,
  maximumZoom: number | null,
  manualSetZoom: (_: number) => void,
  map: google.maps.Map | null,
  {
    airspace,
  }: {
    airspace?: Polygon | MultiPolygon | null;
  }
) {
  const [maxZoom, setMaxZoom] = useState<number | null>(null);

  const boundingBox = useMemo(() => {
    if (airspace) {
      return bbox(airspace);
    }

    return undefined;
  }, [airspace]);

  return useEffect(() => {
    if (map && maxZoom !== maximumZoom) {
      if (boundingBox) {
        map.fitBounds({
          east: boundingBox[2],
          north: boundingBox[3],
          south: boundingBox[1],
          west: boundingBox[0],
        });
      }
      manualSetZoom(Math.min(zoom, maximumZoom));
      setMaxZoom(maximumZoom);
    }
  }, [map, manualSetZoom, maxZoom, maximumZoom, zoom, setMaxZoom, boundingBox]);
}
