import { StyledFeature, IconType } from 'fims-api-types';
import {
  AlarmClockSVG,
  AlertSVG,
  ArrowDropUpIconSVG,
  CallSVG,
  HourglassAnimatedSVG,
  PriorityFlightSVG,
} from './icons';

export interface FlightRequestMarkerElement
  extends google.maps.marker.AdvancedMarkerElement {
  infowindow?: google.maps.InfoWindow;
}

const toGlyph = (
  svg: string
): google.maps.marker.PinElementOptions['glyph'] => {
  const glyphImg = document.createElement('img');
  glyphImg.src = `data:image/svg+xml;charset=utf-8,${svg}`;
  return glyphImg;
};

const iconToSvg: Record<IconType, string> = {
  [IconType.Alert]: AlertSVG,
  [IconType.RequestToLand]: HourglassAnimatedSVG,
  [IconType.IsPriorityFlight]: PriorityFlightSVG,
  [IconType.RequestingClearance]: ArrowDropUpIconSVG,
  [IconType.CallTower]: CallSVG,
  [IconType.ExceedAllocatedTime]: AlarmClockSVG,
  [IconType.None]: '',
};

const glyphImage = ({
  markerStyling,
}: StyledFeature['properties']): //@ts-ignore
google.maps.marker.PinElementOptions['glyph'] =>
  iconToSvg[markerStyling.icon] ? toGlyph(iconToSvg[markerStyling.icon]) : '';

const glyphImages = ({
  markerStyling,
}: StyledFeature['properties']): //@ts-ignore
google.maps.marker.PinElementOptions['glyph'] => {
  const icons = getIconsFiltered(markerStyling);

  if (icons.length === 0) {
    return '';
  }
  if (icons.length === 1) {
    return toGlyph(icons[0]);
  }

  return toGlyph(iconsToAnimatedSVG(icons));
};

export const getPinOptions = (
  flightArea: StyledFeature
): {
  pinOptions: google.maps.marker.PinElementOptions;
  appearanceTracker: string;
} => {
  const { properties } = flightArea;
  const background = properties.markerStyling.color;

  const glyph = window.env.MULTI_ICON_ENABLED
    ? glyphImages(properties)
    : glyphImage(properties);

  const pinOptions: google.maps.marker.PinElementOptions = {
    background,
    borderColor: 'black',
    scale: 0.75,
    glyph,
  };

  return {
    pinOptions,
    appearanceTracker: `${background}-${(glyph as HTMLImageElement)?.src || ''}`,
  };
};

function iconsToAnimatedSVG(icons: string[]) {
  const FIXED_DIMENSION = 14;
  const FRAME_DURATION_SECONDS = 3;
  const duration = icons.length * FRAME_DURATION_SECONDS;
  const frames = icons.map((_) => 0);
  let images = icons
    .map((icon, index) => {
      const position = icon.lastIndexOf('</svg>');
      frames[index] = 1;
      icon = icon.replace(/height="[^"]+"/g, `height="${FIXED_DIMENSION}"`);
      icon = icon.replace(/width="[^"]+"/g, `width="${FIXED_DIMENSION}"`);
      icon = `${icon.substring(0, position)}<animate attributeName="opacity" calcMode="discrete" values="${frames.join(';')}" dur="${duration}s" repeatCount="indefinite" />  ${icon.substring(position)}`;
      frames[index] = 0;
      return icon;
    })
    .join('');
  images = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 120 120" height="${FIXED_DIMENSION}" viewBox="0 0 ${FIXED_DIMENSION} ${FIXED_DIMENSION}" width="${FIXED_DIMENSION}">${images}</svg>`;

  return images;
}

function getIconsFiltered(
  markerStyling: StyledFeature['properties']['markerStyling']
) {
  return (
    markerStyling.icons
      ?.map((icon) => iconToSvg[icon] || '')
      .filter((icon) => icon.length > 0) || []
  );
}

export function getLabeledPin(flightLabel: string, pin: HTMLElement) {
  const label = document.createElement('div');
  label.classList.add('drone-flight-request-label');
  label.innerHTML = flightLabel;

  const content = document.createElement('div');
  content.classList.add('drone-flight-request-wrapper');
  content.innerHTML = label.outerHTML + pin.outerHTML;

  return content;
}
