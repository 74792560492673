import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  formatCombinedSegmentStatus,
  type StyledFeature,
} from 'fims-api-types';

const useStyles = (backgroundColor: string, borderColor: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      gridItem: {
        position: 'relative',
        gridColumnEnd: 'auto',
        gridColumnStart: 'auto',
        minHeight: '1.8rem',
        padding: 'auto',

        textAlign: 'center',
        verticalAlign: 'middle',

        backgroundColor,
        borderColor,
        borderStyle: 'solid',
        borderWidth: '2px',
      },
      text: {
        color: theme.palette.common.white,
      },
    })
  );

const GridSegmentStatusItem = ({
  properties,
}: {
  properties?: StyledFeature['properties'];
}): JSX.Element => {
  const classes = useStyles(
    properties?.areaStyling?.fillColor,
    properties?.areaStyling?.strokeColor
  )();
  return (
    <div className={classes.gridItem}>
      <Typography variant="body2" className={classes.text}>
        {formatCombinedSegmentStatus(properties.status, properties.clearance)}
      </Typography>
    </div>
  );
};

export default GridSegmentStatusItem;
