import React from 'react';
import { DateTime } from 'luxon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NewRegistrations } from '../../../clients/fims-api-client';

const NewRegistrationsTable = ({
  className,
  data,
}: {
  className: string;
  data: { commercial: NewRegistrations; recreational: NewRegistrations };
}) => (
  <Table className={className} data-testid="new-pilot-registration-table">
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell align="right">Week Beginning</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell data-testid="new-pilot-registration-type">
          Registration Type
        </TableCell>
        {data.commercial?.registrations.map(({ startDate }) => (
          <TableCell
            align="right"
            key={DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          >
            {DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          </TableCell>
        ))}
        <TableCell align="right">Total</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell data-testid="new-pilot-registration-type-commercial">
          Commercial
        </TableCell>
        {data.commercial?.registrations.map(({ value, startDate }) => (
          <TableCell
            key={`commercial-${DateTime.fromISO(startDate).toFormat(
              'dd-MMM-yyyy'
            )}`}
            align="right"
          >
            {value}
          </TableCell>
        ))}
        <TableCell
          align="right"
          data-testid="new-pilot-registration-type-commercial-total-count"
        >
          {data.commercial?.total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell data-testid="new-pilot-registration-type-recreational">
          Recreational
        </TableCell>
        {data.recreational?.registrations.map(({ value, startDate }) => (
          <TableCell
            key={`recreational-${DateTime.fromISO(startDate).toFormat(
              'dd-MMM-yyyy'
            )}`}
            align="right"
          >
            {value}
          </TableCell>
        ))}
        <TableCell
          align="right"
          data-testid="new-pilot-registration-type-recreational-total-count"
        >
          {data.recreational?.total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell data-testid="new-pilot-registration-type-total">
          Total
        </TableCell>
        {data.commercial?.registrations.map(({ startDate, value }, i) => (
          <TableCell
            align="right"
            key={`total-${DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}`}
          >
            {value + (data.recreational?.registrations[i].value || 0)}
          </TableCell>
        ))}
        <TableCell
          align="right"
          data-testid="new-pilot-registration-type-total-count"
        >
          {(data.commercial?.total || 0) + (data.recreational?.total || 0)}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default NewRegistrationsTable;
