import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px !important`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    addButton: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
    dialogTitle: {
      padding: '24px 24px 0 24px',
    },
    organisations: {
      padding: '0 16px 16px 16px',
    },
    editDialog: {
      position: 'relative',
      width: 400,
      padding: 24,
      paddingTop: 12,
      alignItems: 'center',
    },
    editSelect: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    pilotInfo: {
      marginBottom: 16,
    },
    searchBox: {
      float: 'right',
    },
    paperHeader: {
      padding: theme.spacing(1),
    },
    orgList: {
      marginTop: theme.spacing(4),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textField: {
      width: 400,
    },
    content: {
      display: 'grid',
      width: 400,
    },
    input: {
      margin: `${theme.spacing(1)}px 0`,
    },
    saveButton: {
      minWidth: 146,
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      width: `calc(100 % - ${theme.spacing(2)} * 2)`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    capitalize: {
      textTransform: 'capitalize',
    },

    error: {
      color: theme.palette.error.main,
    },
  })
);
