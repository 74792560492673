import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';

interface Props {
  title: string;
}

const DetailPageTitle: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function navigateToPrevious() {
    const mapState = location?.state;

    // If we are going back to the map then pass the state back so the map goes back to the correct
    // position
    // There isn't an easy way to determine if we are going back to the map. navigate(-1) does not support
    // passing state - which is just stupid.
    // So instead if we were given map state from our caller then we can assume we are going back to the map.
    // Not ideal but it works.
    if (mapState?.lat && mapState.lng && mapState.zoom) {
      navigate('/', { state: mapState });
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      <IconButton
        aria-label="back"
        component="span"
        onClick={() => navigateToPrevious()}
        data-testid="detail-page-back-button"
      >
        <ArrowBack />
      </IconButton>
      <Typography
        className="flight-info__label"
        variant="h6"
        style={{ margin: '0 10px' }}
        data-testid="detail-page-heading-title"
      >
        {title}
      </Typography>
    </>
  );
};

export default DetailPageTitle;
