import { useDispatch, useSelector } from 'react-redux';
import { UserCreationForm, UserUpdateForm } from 'fims-api-types';
import {
  adminFetchUsersRequested,
  adminAddUserRequested,
  adminUpdateUserRequested,
  adminFetchAdminDefaultsRequested,
  adminClearError,
  adminClearUserError,
} from './actions';
import {
  getError,
  getUserError,
  getFetchStatus,
  getUsers,
  getAdminDefaults,
} from './selectors';

export function useAdmin() {
  const dispatch = useDispatch();
  const adminDefaults = useSelector(getAdminDefaults);
  const fetchStatus = useSelector(getFetchStatus);
  const users = useSelector(getUsers);

  const addUser = (user: UserCreationForm) =>
    dispatch(adminAddUserRequested(user));
  const updateUser = (user: UserUpdateForm) =>
    dispatch(adminUpdateUserRequested(user));

  const error = useSelector(getError);
  const userError = useSelector(getUserError);
  const fetchUsers = () => dispatch(adminFetchUsersRequested());
  const fetchAdminDefaults = () => dispatch(adminFetchAdminDefaultsRequested());
  const clearError = () => dispatch(adminClearError());
  const clearUserError = () => dispatch(adminClearUserError());

  return {
    addUser,
    adminDefaults,
    clearError,
    clearUserError,
    error,
    fetchAdminDefaults,
    fetchStatus,
    fetchUsers,
    updateUser,
    userError,
    users,
  };
}
