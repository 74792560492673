import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const AdditionalInfo = ({ focussedFlightRequest }: Props) => {
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);

  return (
    <div data-testid="flight-info-additional-info">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setAdditionalInfoOpen(!additionalInfoOpen)}
        data-testid="flight-additional-info-heading"
      >
        <ListItemText primary="Additional Info" />
        {additionalInfoOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={additionalInfoOpen} timeout="auto" unmountOnExit>
        <table
          className="flight-info-table"
          data-testid="flight-additional-info-table"
        >
          <tbody>
            <tr>
              <th data-testid="flight-additional-info-name-title">Name</th>
              <td data-testid="flight-additional-info-name-value">
                {focussedFlightRequest.nickname}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-additional-info-purpose-title">
                Purpose
              </th>
              <td data-testid="flight-additional-info-purpose-value">
                {focussedFlightRequest.flightPurpose}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-additional-info-filming-title">
                Filming
              </th>
              <td data-testid="flight-additional-info-filming-value">
                {focussedFlightRequest.isCameraInUse ? 'Y' : 'N'}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-additional-info-priority-title">
                Is Priority Flight
              </th>
              <td data-testid="flight-additional-info-priority-value">
                {focussedFlightRequest.isPriorityFlight ? 'Y' : 'N'}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-additional-info-emergency-procedure-title">
                Emergency Procedure
              </th>
              <td data-testid="flight-additional-info-emergency-procedure-value">
                {focussedFlightRequest.emergencyProcedure}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-additional-info-measure-altitude-title">
                Procedure to Measure Altitude
              </th>
              <td data-testid="flight-additional-info-measure-altitude-value">
                {focussedFlightRequest.procedureToMeasureAltitude}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default AdditionalInfo;
