import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from '~/components/shared/error-message/error-message';
import { UserViewModel, useAdmin } from '~/state/admin';
import { useTableStyles } from '~/lib/table';
import AdminModal from './admin-modal/admin-modal';

import { TABLE_HEADINGS } from './admin-table-constants';

import * as apiClient from '../../../clients/fims-api-client';

import './admin.scss';

const AddIcon = () => (
  <SvgIcon>
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </SvgIcon>
);

const UserManagement = () => {
  const classes = useTableStyles({});
  const { clearError, clearUserError, error, userError } = useAdmin();
  const [users, setUsers] = useState([]);
  const [adminDefaults, setAdminDefaults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');

  const getUsers = async () => {
    const defaults = await apiClient.getAdminDefaults();
    setAdminDefaults(defaults);
    const usersData = await apiClient.getUsers();
    setUsers(usersData);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (error) {
      setIsModalOpen(false);
      clearError();
    }
  }, [users, error, clearError]);

  const handleClick = (userId: string) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const handleModalClick = () => {
    setIsModalOpen(!isModalOpen);
    if (userError) {
      clearUserError();
    }
    getUsers();
  };

  return (
    <>
      <div className="admin">
        {error ? (
          <ErrorMessage main="Error" sub={error} />
        ) : (
          <>
            <div className="admin-header">
              <Typography
                className="admin-header__heading"
                variant="h3"
                data-testid="admin-header"
              >
                Admin
              </Typography>
              <Button
                className="admin-header-trigger is-add-user"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleClick('')}
                data-testid="admin-add-user-button"
              >
                Add User
              </Button>
            </div>
            <Paper className={classes.root}>
              <Table className="table" data-testid="admin-user-table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEADINGS.map((heading) => (
                      <TableCell align={heading.align} key={heading.sortKey}>
                        {heading.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: UserViewModel) => (
                    <TableRow
                      className="table__row"
                      key={user.id}
                      onClick={() => handleClick(user.id)}
                    >
                      <TableCell data-testid="admin-user-email">
                        {user.email}
                      </TableCell>
                      <TableCell data-testid="admin-user-name">
                        {[user.name.givenName, user.name.familyName].join(' ')}
                      </TableCell>
                      <TableCell data-testid="admin-user-mobile">
                        {user.mobileNo}
                      </TableCell>
                      <TableCell data-testid="admin-user-role">
                        {user.role}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{
                          root: `is-${user.status
                            .replace(/\s/g, '')
                            .toLowerCase()}`,
                        }}
                        data-testid="admin-user-status"
                      >
                        <span
                          className={cx(
                            'admin-table-row-status',
                            { 'is-active': user.status === 'ACTIVE' },
                            { 'is-disabled': user.status === 'DISABLED' }
                          )}
                        >
                          {user.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            {isModalOpen && (
              <AdminModal
                adminDefaults={adminDefaults}
                closeModal={handleModalClick}
                selectedUser={users.find((user) => user.id === selectedUserId)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserManagement;
