import React from 'react';
import {
  type AmmendmentsAcknowledgedContext,
  type AuditLogViewModelContext,
  type AuthorizerChangesRequiredContext,
  FlightRequestAuditAction,
} from 'fims-api-types';

import '../audit-logs.scss';

export const ContextOther = ({
  action,
  context,
}: {
  action: string;
  context: AuditLogViewModelContext;
}) => {
  return (
    <>
      {(action === FlightRequestAuditAction.CONFORMANCE_ALERT ||
        action === FlightRequestAuditAction.CONFORMANCE_BREACH ||
        action === FlightRequestAuditAction.CONFORMANCE_SAFE) && (
        <tr>
          <th data-testid="flight-audit-log-status-title">Status</th>
          <td data-testid="flight-audit-log-status-value">{context.message}</td>
        </tr>
      )}
      {action === FlightRequestAuditAction.AUTHORISATION_CANCELLATION && (
        <tr>
          <th className="flight-audit-log-reason-title">Reason</th>
          <td className="flight-audit-log-reason-value">{context?.message}</td>
        </tr>
      )}

      {action === FlightRequestAuditAction.AUTHORIZER_CHANGES_REQUIRED &&
        ((context as AuthorizerChangesRequiredContext)?.changeLog?.length ??
          -1) > 0 &&
        (context as AuthorizerChangesRequiredContext)?.changeLog?.map(
          (change) => (
            <tr key={`${change?.field}-${change?.message}`}>
              <th
                className="note-header"
                data-testid="flight-audit-log-changes-required-note-title"
              >
                {change?.field}
              </th>
              <td
                className="note"
                data-testid="flight-audit-log-changes-required-note-value"
              >
                {change?.message}
              </td>
            </tr>
          )
        )}
      {action === FlightRequestAuditAction.AMENDMENTS_ACKNOWLEDGED &&
        (context as AmmendmentsAcknowledgedContext)?.authorizerChanges?.map(
          (field) => (
            <tr key={field}>
              <th
                className="note-header"
                data-testid="flight-audit-log-acknowledged-amendment-title"
              >
                Field
              </th>
              <td
                className="note"
                data-testid="flight-audit-log-acknowledged-amendment-value"
              >
                {field}
              </td>
            </tr>
          )
        )}
    </>
  );
};
