import { INode } from 'react-flow-builder';
import { v4 as uuid } from 'uuid';

import {
  Rule,
  RulesConfig,
  ruleTypes,
} from 'argus-data-model/db/schemas/rules-config';

enum NodeTypes {
  START = 'start',
  END = 'end',
  ACTION = 'action',
  QUERY = 'query',
  QUERY_RESULT = 'query-result',
  BRANCH = 'branch',
}

const convertToNodes = (ruleConfig: RulesConfig) => {
  let nodes: INode[] = [
    {
      id: uuid(),
      type: NodeTypes.START,
      name: ruleConfig.code,
      path: ['0'],
    },
  ];

  ruleConfig.entry.forEach((rule, index) => {
    const { node, branchNode } = _mapRuleToNode(rule, index, nodes[0]);
    nodes.push(node);
    if (branchNode) nodes.push(branchNode);
  });

  return nodes;
};

const _mapRuleToNode = (rule: Rule, _index: number, parentNode: INode) => {
  let description = rule.params?.description || rule.params || '';
  let type = 'default';
  let name = _generateName(rule);
  let branchNode: INode | null = null;

  switch (rule.type) {
    case ruleTypes.CONDITION:
      type = NodeTypes.QUERY;
      break;
    case ruleTypes.ACTION:
      type = NodeTypes.ACTION;
      break;
    case ruleTypes.LIB:
      type = NodeTypes.ACTION;
      if (rule.func?.toLowerCase() === 'leadtimelte48hoursexit') {
        branchNode = {
          id: uuid(),
          type: NodeTypes.END,
          name: 'EXIT',
        };
      }
      break;
    case ruleTypes.EXIT:
      type = NodeTypes.END;
      break;
  }

  let node: INode = {
    id: uuid(),
    type: type,
    name: name,
    data: rule,
  };

  const isTrueChildren: INode[] =
    rule.isTrue?.length > 0
      ? _flatten2DArray(
          rule.isTrue.map((innerTrueRule, innerTrueindex) =>
            _mapRuleToNode(innerTrueRule, innerTrueindex, parentNode)
          )
        )
      : [];
  const isFalseChildren: INode[] =
    rule.isFalse?.length > 0
      ? _flatten2DArray(
          rule.isFalse.map((innerFalseRule, innerFalseIndex) =>
            _mapRuleToNode(innerFalseRule, innerFalseIndex, parentNode)
          )
        )
      : [];

  if (
    rule.type !== ruleTypes.LIB &&
    (isTrueChildren?.length > 0 || isFalseChildren?.length > 0)
  ) {
    branchNode = {
      id: uuid(),
      type: NodeTypes.BRANCH,
      name: `${rule.func}: ${description}: branch`,
      children: [],
    };

    branchNode.children?.push({
      id: uuid(),
      type: NodeTypes.QUERY_RESULT,
      name: 'IsTrue',
      children: isTrueChildren,
    });

    branchNode.children?.push({
      id: uuid(),
      type: NodeTypes.QUERY_RESULT,
      name: 'IsFalse',
      children: isFalseChildren,
    });
  }

  return { node, branchNode };
};

const _generateName = (rule: Rule) => {
  let description = rule.params?.description || rule.params || '';

  switch (rule.type) {
    case ruleTypes.CONDITION:
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (`${rule.func}`) {
        case 'isOutsideDaylightHours':
        case 'fullyWithinCtrZone':
        case 'UAVLargerThan25KG':
        case 'isShielded':
        case 'isControlledAerodrome':
        case 'ctrAutoApprovalEnabled':
          return `${rule.func}`;
        case 'altitudeCompare':
        case 'leadTimeCompare':
          return `${rule.func}: ${rule?.params?.operator} ${
            rule?.params?.compareVal
          } ${rule?.params?.unit || ''}`;
      }
      return `${rule.func}: ${description}`;
    case ruleTypes.ACTION:
      if (rule.func?.toLowerCase() === 'setdatavalue') {
        return `${rule.func}: {${rule.params.key}: ${rule.params.value}}`;
      }
      return `${rule.func}: ${description}`;
    case ruleTypes.LIB:
      return `${rule.func}`;
    case ruleTypes.EXIT:
      return 'EXIT';
  }
  return 'Unknown';
};

const _flatten2DArray = (
  array2d: { node: INode; branchNode: INode | null }[]
) =>
  array2d.flatMap(({ node, branchNode }) =>
    branchNode ? [node, branchNode] : [node]
  );

export default { convertToNodes, NodeTypes };
