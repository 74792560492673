import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VerificationCodeEntry from './verification-code-entry';
import PasswordChanged from './password-changed';
import VerificationCodeRequest from './verification-code-request';

import './reset-password.scss';

const FORM_STEP = {
  REQUEST_VERIFICATION_CODE: 'request-verification-code',
  ENTER_VERIFICATION_CODE_AND_NEW_PASSWORD:
    'enter-verification-code-and-new-password',
  COMPLETE: 'complete',
};

export const ResetPasswordForm = () => {
  const [formStep, updateFormStep] = useState(
    FORM_STEP.REQUEST_VERIFICATION_CODE
  );

  const [isPasswordValid, onPasswordValidityChange] = useState(false);
  const [email, updateEmail] = useState<string>('');

  return (
    <div className="reset-password" data-testid="reset-password-form">
      {formStep === FORM_STEP.REQUEST_VERIFICATION_CODE && (
        <VerificationCodeRequest
          updateResetPasswordStep={(e) => {
            updateEmail(e);
            updateFormStep(FORM_STEP.ENTER_VERIFICATION_CODE_AND_NEW_PASSWORD);
          }}
        />
      )}
      {formStep === FORM_STEP.ENTER_VERIFICATION_CODE_AND_NEW_PASSWORD && (
        <VerificationCodeEntry
          email={email}
          updateResetPasswordStep={() => updateFormStep(FORM_STEP.COMPLETE)}
          isPasswordValid={isPasswordValid}
          onPasswordValidityChange={onPasswordValidityChange}
        />
      )}
      {formStep === FORM_STEP.COMPLETE && <PasswordChanged />}
      <Link
        to={{
          pathname: '/signin',
        }}
        className="sub-link"
        style={{
          color: 'var(--color-mui-light-black)',
          textDecoration: 'none',
        }}
        data-testid="reset-password-sign-in-button"
      >
        Sign In
      </Link>
    </div>
  );
};

export default ResetPasswordForm;
