import { useEffect, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import booleanIntersects from '@turf/boolean-intersects';
import { point } from '@turf/helpers';

import {
  RequestToPilotStatus,
  RequestToPilotType,
  UserRole,
} from 'argus-common/enums';
import { DigitalClearanceStatus, FlightRequestViewModel } from 'fims-api-types';

import { LoginContext, MapView24Hour } from '../../../../../context/LoginState';
import { getFlightRoute } from '../../../../../lib/routes';
import {
  addMarkerListeners,
  FlightRequestMarker,
  getFlightRequestMarker,
  setLabelForMarker,
} from './drone-marker-helpers';
import {
  removedDroneRequestMarkers,
  newDroneRequestMarkers,
  sortByStatus,
  setMapHistoryHelper,
  latLngSamePlace,
  normalizeLatLng,
} from './marker-helpers';
import { isCurrent, isNext24, isToday } from './marker-filters';
import { buildClusterInfoWindow } from '../cluster-marker-info';

import { useProfile } from '../../../../../state/session';
import { MapStyleCode } from '../../../../../state/map-styles/actions';
import { closeActiveInfoWindows } from '../helpers';
import { ItemDataType, ListProps } from '../../../../shared/marker-info';

interface MapProps {
  map?: google.maps.Map | null;
  flightRequests: FlightRequestViewModel[];
  zoom: number;
  mapStyleCode: MapStyleCode;
  requestToLandHandler: (flightId: number) => void;
}

const clusterColor = '#222222';
const clusterSVG = window.btoa(`
<svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

export default function useMarkers({
  map,
  flightRequests,
  zoom,
  mapStyleCode,
  requestToLandHandler,
}: MapProps) {
  const navigate = useNavigate();
  const { mapView24hour } = useContext(LoginContext);
  const [_markerClusterer, setMarkerClusterer] =
    useState<MarkerClusterer | null>(null);
  const [flightRequestMarkers, setFlightRequestMarkers] = useState<
    FlightRequestMarker[]
  >([]);
  const { authorizer, roles } = useProfile();
  const isSuperAdmin = roles?.includes(UserRole.SUPER_ADMIN);
  const [flightIdToLand, setFlightIdToLand] = useState(null);

  const navigateToFlight = useCallback(
    (flightId: number) => {
      if (map) {
        navigate(getFlightRoute(flightId), {
          replace: false,
          state: setMapHistoryHelper(map),
        });
      }
    },
    [navigate, map]
  );

  const adjustOverlappingFlights = useCallback(
    (origMarkers: FlightRequestMarker[]) => {
      const matchingMarkerMap = origMarkers.map((requestMarkerA) => {
        const matching = origMarkers.filter(
          (requestMarkerB) =>
            requestMarkerA.flight.flightId !== requestMarkerB.flight.flightId &&
            latLngSamePlace(
              normalizeLatLng(requestMarkerA.marker.position),
              normalizeLatLng(requestMarkerB.marker.position)
            )
        );
        return { markerA: requestMarkerA, matching };
      });

      const overlayedMarkers = matchingMarkerMap.filter(
        (x) => x.matching.length > 0
      );

      overlayedMarkers.forEach((match) => {
        const incrAmount = 0.0002;
        const lat = normalizeLatLng(match.markerA.marker.position)?.lat();
        const lng = normalizeLatLng(match.markerA.marker.position)?.lng();

        if (lat && lng) {
          const newLatLng = new google.maps.LatLng({
            lat: lat + incrAmount,
            lng: lng + incrAmount,
          });

          const inside = booleanIntersects(
            point([newLatLng.lng(), newLatLng.lat()]),
            match.markerA.flight.geometry
          );

          if (!inside) {
            return;
          }

          const parted = !match.matching.every((x) =>
            latLngSamePlace(newLatLng, normalizeLatLng(x.marker.position))
          );

          if (parted) {
            const updatedMarker = match.markerA;
            updatedMarker.marker.position = newLatLng;
          }
        }
      });

      return matchingMarkerMap.map((x) => x.markerA);
    },
    []
  );

  useEffect(() => {
    if (map) {
      flightRequestMarkers.forEach((x) =>
        x.flightAreas.forEach((a) => a.setMap(map))
      );

      setMarkerClusterer((clusterer) => {
        if (clusterer) {
          clusterer.clearMarkers();
          clusterer.setMap(null);
        }
        return new MarkerClusterer({
          markers: flightRequestMarkers.map((x) => x.marker),
          map,
          onClusterClick: (
            event: google.maps.MapMouseEvent,
            cluster: Cluster,
            p_map: google.maps.Map
          ) => buildClusterInfoWindow(event, cluster, p_map, navigateToFlight),
          renderer: {
            render: (cluster: Cluster) => {
              const firstFlightId = cluster.markers
                .map((x: google.maps.marker.AdvancedMarkerElement) =>
                  x.title?.substring(7)
                )
                .sort()[0];
              return new google.maps.Marker({
                position: cluster.position,
                icon: {
                  url: `data:image/svg+xml;base64,${clusterSVG}`,
                  scaledSize: new google.maps.Size(45, 45),
                },
                label: {
                  text: String(cluster.count),
                  color: 'rgba(255,255,255,0.9)',
                  fontSize: '12px',
                },
                title: `Cluster of ${cluster.count} flights: (${firstFlightId}, ...)`,
              });
            },
          },
        });
      });
    }

    flightRequestMarkers.forEach((x) => {
      const additionalInfo: ListProps[] = [];
      if (mapView24hour === MapView24Hour.DATE) {
        additionalInfo.push({
          key: 'Status',
          value: x.flight.status.map((status) => status.code).join(', '),
        });
      }
      if (x.flight?.requestToLand) {
        const requestsToLandUnacknowledged = x.flight?.requestsToPilot?.filter(
          (request) =>
            request.type === RequestToPilotType.RequestToLand &&
            request.status !== RequestToPilotStatus.Acknowledged
        );
        const items = requestsToLandUnacknowledged.map(
          (requestToLandUnacknowledged, index) => ({
            key: `${requestsToLandUnacknowledged.length > 1 ? index + 1 : ''} Land Request`,
            value: requestToLandUnacknowledged.createdAt.toString(),
            itemDataType: ItemDataType.ElapsedTime,
          })
        );
        if (items.length > 0) {
          additionalInfo.push(...items);
        }
      }
      return addMarkerListeners(
        map,
        authorizer,
        x.marker,
        x.flight,
        navigateToFlight,
        roles,
        additionalInfo,
        mapView24hour !== MapView24Hour.DATE
          ? () => {
              setFlightIdToLand(x.flight.flightId);
            }
          : undefined
      );
    });

    return () => {};
  }, [
    map,
    setMarkerClusterer,
    flightRequestMarkers,
    navigateToFlight,
    authorizer,
    roles,
    mapView24hour,
  ]);

  useEffect(() => {
    if (flightIdToLand !== null) {
      requestToLandHandler(flightIdToLand);
      setFlightIdToLand(null);
    }
  }, [flightIdToLand, requestToLandHandler]);

  useEffect(() => {
    if (map) {
      const viewableFlightRequests = flightRequests
        .filter(
          (flight) =>
            (mapView24hour === MapView24Hour.CURRENT &&
              isCurrent(flight, authorizer)) ||
            (mapView24hour === MapView24Hour.TODAY &&
              isToday(flight, authorizer)) ||
            (mapView24hour === MapView24Hour.NEXT24 &&
              isNext24(flight, authorizer)) ||
            mapView24hour === MapView24Hour.DATE ||
            flight.digitalClearance?.status === DigitalClearanceStatus.Requested
        )
        .sort(sortByStatus(authorizer));

      const updatedRequestMarkers = viewableFlightRequests.map((flightReq) =>
        getFlightRequestMarker(flightReq, map.getZoom())
      );

      const removedMarkers = removedDroneRequestMarkers(
        flightRequestMarkers,
        updatedRequestMarkers
      );
      const newMarkers = newDroneRequestMarkers(
        flightRequestMarkers,
        updatedRequestMarkers
      );

      if (removedMarkers?.length !== 0 || newMarkers?.length !== 0) {
        flightRequestMarkers.forEach((x) =>
          x.flightAreas.forEach((a) => a.setMap(null))
        );
        setFlightRequestMarkers(
          adjustOverlappingFlights(updatedRequestMarkers)
        );
      }
    }
  }, [
    flightRequests,
    mapView24hour,
    map,
    flightRequestMarkers,
    navigateToFlight,
    authorizer,
    isSuperAdmin,
    adjustOverlappingFlights,
  ]);

  useEffect(() => {
    if (map) {
      const mapZoom = map.getZoom();
      flightRequestMarkers.forEach((markerObj) =>
        setLabelForMarker(markerObj.marker, markerObj.flight, mapZoom)
      );

      const clickListener = map.addListener('click', () => {
        closeActiveInfoWindows();
      });
      return () => {
        google.maps.event.removeListener(clickListener);
      };
    }
  }, [map, flightRequestMarkers, zoom, authorizer]);

  useEffect(() => {
    if (mapStyleCode) {
      flightRequestMarkers.map((marker) => {
        if (marker?.marker?.map) {
          marker.marker.map = null;
        }
        marker?.flightAreas.forEach((a) => a?.setMap(null));
      });
      setFlightRequestMarkers([]);
      setMarkerClusterer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyleCode]);
}
