import React from 'react';
import {
  DigitalClearanceStatus,
  type StatusUpdateContext,
  AuditLogViewModelContextDecision,
} from 'fims-api-types';

import { getStatusText } from 'airspace-manager-common/status/multi-status-check';

import { ATCNote } from './ATCNote';
import { OperatorNote } from './OperatorNote';

import '../audit-logs.scss';

export const ContextStatusUpdate = ({
  context,
}: {
  context: StatusUpdateContext & AuditLogViewModelContextDecision;
}) => {
  return (
    <>
      {context?.segmentId && (
        <tr>
          <th data-testid="flight-audit-log-segment-id-title">Segment Id</th>
          <td data-testid="flight-audit-log-segment-id-value">
            {context.segmentId || 'N/A'}
          </td>
        </tr>
      )}

      <tr>
        <th data-testid="flight-audit-log-decision-title">Decision</th>
        <td data-testid="flight-audit-log-decision-value">
          {getStatusText(context.decision, true) || 'N/A'}
        </td>
      </tr>

      <ATCNote atcNote={context.atcNote} />
      <OperatorNote operatorNote={context.operatorNote} />

      {context?.statusCode === DigitalClearanceStatus.Declined &&
        context?.message && (
          <tr>
            <th
              className="note-header"
              data-testid="flight-audit-log-reason-title"
            >
              Reason
            </th>
            <td className="note" data-testid="flight-audit-log-reason-value">
              {context.message}
            </td>
          </tr>
        )}
    </>
  );
};
