import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import { UserRole } from 'argus-common/enums';
import { OrganisationsResponseBody } from 'fims-api-types';
import * as fimsClient from '~/clients/fims-api-client';
import { useProfile } from '~/state/session';

import EditOrganisationModal from './EditOrganisationModal';
import EditMapDefaultModal from './EditMapDefaultModal';
import DeleteModal from './DeleteModal';
import OrgListItem from './OrgListItem';
import { useStyles } from './useStyles';

function Organisations() {
  const { roles } = useProfile();
  const [organisations, setOrganisations] = useState<OrganisationsResponseBody>(
    []
  );
  const [search, setSearch] = useState('');
  const [editingId, setEditingId] = useState('');
  const [editingSettingsId, setEditingSettingsId] = useState('');
  const [deletingId, setDeletingId] = useState('');

  const classes = useStyles();
  const isSuperAdmin = [UserRole.SUPER_ADMIN].some((role) =>
    roles.includes(role)
  );

  const navigate = useNavigate();

  if (!isSuperAdmin) {
    navigate('/');
  }

  async function getOrganisations() {
    const data = await fimsClient.getOrganisations();
    setOrganisations(data);
  }

  useEffect(() => {
    getOrganisations();
  }, [editingId, editingSettingsId, deletingId]);

  const filteredOrganizations = [...organisations]
    .filter((x) =>
      `${x.name.toLowerCase()} ${x.contactDetails?.email?.toLowerCase()}`.includes(
        search.toLowerCase()
      )
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <div className={classes.organisations}>
        <div className={classes.heading}>
          <Typography variant="h3" data-testid="organisations-header">
            Organisations
          </Typography>
          {isSuperAdmin ? (
            <Button
              className={classes.addButton}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setEditingId('new')}
              data-testid="add-organisations-button"
            >
              Add Organisation
            </Button>
          ) : null}
        </div>
        <Paper>
          <div className={classes.paperHeader}>
            <TextField
              data-testid="standard-start-adornment"
              className={classes.searchBox}
              placeholder="Search..."
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.orgList}>
            <List
              component="nav"
              aria-label="organisations list"
              data-testid="organisations-list"
            >
              <Divider />
              {filteredOrganizations.map((o) => (
                <OrgListItem
                  key={o.id}
                  organisation={o}
                  setEditingId={setEditingId}
                  setEditingSettingsId={setEditingSettingsId}
                  setDeletingId={setDeletingId}
                />
              ))}
            </List>
          </div>
        </Paper>
      </div>

      {deletingId && isSuperAdmin && (
        <DeleteModal
          closeModal={() => {
            setDeletingId('');
          }}
          organisation={organisations.find((x) => x.id === deletingId)}
        />
      )}
      {editingSettingsId && isSuperAdmin && (
        <EditMapDefaultModal
          closeModal={() => {
            setEditingSettingsId('');
          }}
          organisation={organisations.find((x) => x.id === editingSettingsId)}
        />
      )}
      {editingId && isSuperAdmin && (
        <EditOrganisationModal
          closeModal={() => {
            setEditingId('');
          }}
          organisation={
            organisations.find((x) => x.id === editingId) || {
              id: 'new',
              name: '',
              contactDetails: {
                email: '',
                address: '',
              },
              authorizer: null,
            }
          }
        />
      )}
    </>
  );
}

export default Organisations;
