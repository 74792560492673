import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  ListItemIcon,
  ListItemText,
  PopperPlacementType,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      zIndex: theme.zIndex.appBar,
    },
    menuItem: {
      zIndex: theme.zIndex.modal,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    menuItemButton: {
      minWidth: 30,
    },
    labelButton: {
      color: `${theme.palette.common.white} !important`,
      margin: `0 ${theme.spacing(2)}px`,
    },
  })
);

export interface MenuItemInterface {
  label: string;
  onClick: () => void;
  divider?: boolean;
  startIcon?: any;
}

export default function Menu({
  label,
  items,
  placement = 'bottom',
  startIcon = null,
  tooltip = null,
  contentTop = null,
}: {
  label: string | ReactNode;
  items: MenuItemInterface[];
  placement?: PopperPlacementType;
  startIcon?: any;
  tooltip?: ReactNode;
  contentTop?: JSX.Element;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const buttonDisplay =
    typeof label === 'string' ? (
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.labelButton}
        data-testid="app-menu-button"
        startIcon={startIcon}
      >
        {label}
      </Button>
    ) : (
      <Tooltip title={tooltip}>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="inherit"
          data-testid="icon-button"
        >
          {label}
        </IconButton>
      </Tooltip>
    );

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {buttonDisplay}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuList}
        placement={placement}
        data-testid="popper"
      >
        {({ TransitionProps }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  data-testid="menu-list-grow"
                  onKeyDown={() => handleListKeyDown}
                >
                  {contentTop}
                  {items.map((item) => [
                    item.divider ? <Divider /> : null,
                    <MenuItem
                      key={item.label}
                      className={classes.menuItem}
                      onClick={(e) => {
                        item.onClick();
                        handleClose(e);
                      }}
                      data-testid="menu-item"
                    >
                      {item.startIcon && (
                        <ListItemIcon className={classes.menuItemButton}>
                          {item.startIcon}
                        </ListItemIcon>
                      )}
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>,
                  ])}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
