import * as yup from 'yup';
import { polygonSchema } from 'airspace-manager-common/managed-areas/schemas';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import {
  CreateManagedAreaBody,
  EditManagedAreaBody,
} from '../../../../clients/fims-api-client';

export const editAirspaceSchema: yup.SchemaOf<EditManagedAreaBody> = yup.object(
  {
    id: yup.string().required(),
    type: yup.mixed().oneOf(Object.values(ManagedAreaCode)).optional(),
    name: yup.string().optional(),
    icaoCode: yup.string().when('type', {
      is: (type: ManagedAreaCode) => type === ManagedAreaCode.Aerodrome,
      then: yup.string().length(4).uppercase().required(),
      otherwise: yup.string().optional(),
    }),
    description: yup.string().optional(),
    email: yup.string().optional(),
    phone: yup.string().optional(),
    minAltitude: yup.number().optional(),
    maxAltitude: yup.number().optional(),
    startDateTime: yup.date().nullable().optional(),
    endDateTime: yup.date().nullable().optional(),
    isActive: yup.boolean().optional(),
    isVisible: yup.boolean().when('type', {
      is: (type: ManagedAreaCode) =>
        [ManagedAreaCode.Advisory, ManagedAreaCode.FlightPath].includes(type),
      then: yup.boolean().required(),
      otherwise: yup.boolean().optional(),
    }),
    authorizedPilots: yup.array().of(yup.string().email()).optional(),
    isTileDisplay: yup.boolean().optional(),
  }
);

export const addAirspaceSchema: yup.SchemaOf<CreateManagedAreaBody> =
  yup.object({
    type: yup.mixed().oneOf(Object.values(ManagedAreaCode)).required(),
    name: yup.string().required(),
    icaoCode: yup.string().when('type', {
      is: (type: ManagedAreaCode) => type === ManagedAreaCode.Aerodrome,
      then: yup.string().length(4).uppercase().required(),
      otherwise: yup.string().optional(),
    }),
    description: yup.string().when('type', {
      is: (type: ManagedAreaCode) => type === ManagedAreaCode.Aerodrome,
      then: yup.string().optional(),
      otherwise: yup.string().required(),
    }),
    email: yup.string().optional(),
    phone: yup.string().optional(),
    minAltitude: yup.number().when('type', {
      is: (type: ManagedAreaCode) => type !== ManagedAreaCode.Aerodrome,
      then: yup.number().required(),
      otherwise: yup.number().optional(),
    }),
    maxAltitude: yup.number().optional(),
    startDateTime: yup.date().optional(),
    endDateTime: yup.date().optional(),
    geometry: polygonSchema.required(),
    isVisible: yup.boolean().when('type', {
      is: (type: ManagedAreaCode) =>
        [ManagedAreaCode.Advisory, ManagedAreaCode.FlightPath].includes(type),
      then: yup.boolean().required(),
      otherwise: yup.boolean().optional(),
    }),
    authorizedPilots: yup.array().of(yup.string().email()).optional(),
    isTileDisplay: yup.boolean().optional(),
  });
