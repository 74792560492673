import React from 'react';
import { default as MuiToggleButtonGroup } from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import cx from 'classnames';

import './toggle-button-group.scss';

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  name?: string;
  selectedValue: string;
  className: string;
  onChange: (event: any) => void;
  disabled?: boolean;
  valuesDisabled?: string[];
  size?: 'small' | 'medium' | 'large';
}

export default function ToggleButtonGroup({
  options,
  name,
  selectedValue,
  className,
  onChange,
  disabled,
  valuesDisabled = [],
  size,
}: Props) {
  function handleClick(event: any) {
    event.preventDefault();

    onChange(event);
  }

  return (
    <MuiToggleButtonGroup
      exclusive
      value={selectedValue}
      className={cx('toggle-button-group', { [className]: Boolean(className) })}
      aria-label="small outlined button group"
    >
      {options.map(({ value, label }) => (
        <ToggleButton
          key={value}
          className="toggle-button"
          name={name}
          disabled={
            disabled ||
            valuesDisabled.includes(label) ||
            valuesDisabled.includes(value)
          }
          value={value}
          onClick={handleClick}
          size={size}
          data-testid="toggle-button"
        >
          {label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
}
