import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import type { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const What = ({ focussedFlightRequest }: Props) => {
  const [whatOpen, setWhatOpen] = useState(false);

  const formattedUAV =
    [focussedFlightRequest.uav.manufacturer, focussedFlightRequest.uav.model]
      .join(' ')
      .trim() || 'Unspecified';

  const isEuroRegulation =
    window.env.EURO_REGULATIONS?.toLowerCase() === 'true';

  return (
    <div data-testid="flight-info-what">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setWhatOpen(!whatOpen)}
        data-testid="flight-what-heading"
      >
        <ListItemText primary="What" />
        {whatOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={whatOpen} timeout="auto" unmountOnExit>
        <table className="flight-info-table" data-testid="flight-what-table">
          <tbody>
            {renderRow('Vehicle Type', formattedUAV, 'vehicle-type', true)}
            {renderRow(
              'Vehicle Weight (kg)',
              focussedFlightRequest.uav.weight,
              'vehicle-weight',
              true
            )}
            {renderRow(
              'C-Class',
              focussedFlightRequest?.uav?.cClass,
              'cClass',
              isEuroRegulation
            )}
            {renderRow(
              'Measure Altitude',
              focussedFlightRequest.procedureToMeasureAltitude,
              'measure-altitude',
              true
            )}
            {renderRow(
              'Transponder',
              focussedFlightRequest.hasCertifiedTransmitter ? 'Y' : 'N',
              'transponder',
              true
            )}
            {renderRow(
              'VHF Radio Contact',
              focussedFlightRequest.hasVhfRadioContact ? 'Y' : 'N',
              'vhf-radio-contact',
              true
            )}
            {renderRow(
              'RemoteID',
              focussedFlightRequest?.uav?.remoteId,
              'remoteid',
              isEuroRegulation
            )}
            {renderRow(
              'Endurance (minutes)',
              focussedFlightRequest?.uav?.enduranceMinutes?.toString(),
              'endurance-minutes',
              isEuroRegulation
            )}
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

function renderRow(
  title: string,
  value: string,
  testIdSubstring: string,
  alwaysShow: boolean = false
): JSX.Element | null {
  if (!alwaysShow && !value) {
    return null;
  }

  return (
    <tr>
      <th data-testid={`flight-what-${testIdSubstring}-title`}>{title}</th>
      <td data-testid={`flight-what-${testIdSubstring}-value`}>{value}</td>
    </tr>
  );
}

export default What;
