import React from 'react';
import { SplashPage } from './splash-page';

export function ErrorPage() {
  return (
    <SplashPage
      primaryText="We seem to have encountered a problem"
      secondaryText={
        <span>
          Please click <a href="/">here</a> to refresh
        </span>
      }
    />
  );
}
