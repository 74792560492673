import type { Polygon, MultiPolygon, FeatureCollection, Point } from 'geojson';
import type { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { ObjectId } from 'mongodb';
import {
  FlightRequestViewModel,
  SetCtrSettingsPayload,
  CtrSettings as CtrZoneSettings,
  UavManufacturersUpdateForm,
  UavManufacturersCreateForm,
  ManagedAirspaceViewModel,
  NotificationResponse,
  FlightAreaViewModel,
  DetailedFlightRequestViewModelV2,
  FlightRequestsViewModelPaged,
  SetCtrZoneVisibilityPayload,
  CtrZoneVisibliltySettingsResponseBody,
  OrganisationsResponseBody,
  OrganisationSettingsResponseBody,
  CreateOrgRequestBody,
  UpdateOrgRequestBody,
  OrganisationResponseBody,
  DeleteOrganisationReqParams,
  BackgroundJobStatus,
  UavViewModelPaged,
  ErrorResponseBody,
} from 'fims-api-types';
import type { UavManufacturer } from 'argus-data-model/db/schemas/uav-manufacturer';
import type { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas/index';
import type { RulesConfig } from 'argus-data-model/db/schemas/rules-config';
import type { WebPushSubscriptionResponseBody } from 'argus-data-model/db/schemas/webpush-subscriptions';
import type {
  AreasOfResponsibility,
  AuthorizerType,
} from 'argus-data-model/db/schemas/authorizers';

import { fimsAPI } from '../api';

export * from './activation';

export interface NewRegistrations {
  interval: string;
  total: number;
  registrations: {
    value: number;
    startDate: string;
  }[];
}

export interface FlightRequestsByZone {
  total: number;
  controlZones: {
    name: string;
    weeks: {
      startDate: string;
      value: number;
    }[];
    total: number;
  }[];
  weeklyTotals: {
    startDate: string;
    value: number;
  }[];
}

export interface FlightsControlled {
  controlled: {
    total: number;
    weeks: {
      startDate: string;
      value: number;
    }[];
  };
  uncontrolled: {
    total: number;
    weeks: {
      startDate: string;
      value: number;
    }[];
  };
}

export interface AtcImpact {
  total: number;
  weeks: {
    startDate: string;
    value: number;
  }[];
}
export interface FlightsPerMonth {
  total: number;
  month: string;
  year: number;
}
export interface ReportsData {
  totalFlights?: number;
  totalRegistrations?: number;
  newRegistrations?: {
    commercial: NewRegistrations;
    recreational: NewRegistrations;
  };
  flightRequestsByZone?: FlightRequestsByZone;
  flightsControlled?: FlightsControlled;
  flightsPerMonth?: FlightsPerMonth[];
  atcImpact?: AtcImpact;
}

export enum ReportFields {
  TotalFlights = 'totalFlights',
  TotalRegistrations = 'totalRegistrations',
  NewRegistrations = 'newRegistrations',
  FlightRequestsByZone = 'flightRequestsByZone',
  FlightsPerMonth = 'flightsPerMonth',
  AtcImpact = 'atcImpact',
}

export interface EditManagedAreaBody {
  id: string;
  type?: ManagedAreaCode;
  icaoCode?: string;
  startDateTime?: Date | null | string;
  endDateTime?: Date | null | string;
  minAltitude?: number;
  maxAltitude?: number;
  name?: string;
  description?: string;
  phone?: string;
  email?: string;
  isActive?: boolean;
  isVisible?: boolean;
  isTileDisplay?: boolean;
  authorizedPilots?: string[];
}

export interface CreateManagedAreaBody {
  type: string;
  icaoCode?: string;
  startDateTime?: Date | string;
  endDateTime?: Date | string;
  minAltitude?: number;
  maxAltitude?: number;
  name?: string;
  description?: string;
  phone?: string;
  email?: string;
  isVisible?: boolean;
  isTileDisplay?: boolean;
  geometry?: Polygon | MultiPolygon | null;
  authorizedPilots?: string[];
}

interface ManagedAreaResponse {
  success: string;
  managedAreas: ManagedAirspaceViewModel[];
}

export interface ManagedAreaByIdResponse {
  success: string;
  managedArea: ManagedAirspaceViewModel;
}

interface AirspaceIsClosedStatusResponse {
  userCanUpdateEmergencyAirspaceClosure: boolean;
  airspaceIsClosed: boolean;
}

export const getNotifications: () => Promise<
  NotificationResponse[]
> = async () =>
  fimsAPI
    .get<NotificationResponse[]>('/notifications')
    .then((resp) => (resp.status === 204 ? [] : resp.data))
    .catch(() => {
      throw new Error('Unable to fetch notifications');
    });

export function isErrorState(
  resp:
    | FlightRequestViewModel[]
    | {
        errors?: string[];
      }
): resp is { errors?: string[] } {
  return (resp as { errors?: string[] })?.errors?.length > 0;
}

export const getFlightRequestsToday = async (): Promise<
  FlightRequestViewModel[] | { errors: string[] }
> =>
  fimsAPI
    .get<FlightRequestViewModel[] | ErrorResponseBody>('/flight-requests-today')
    .then((resp) => {
      if (resp?.status === 200 || resp.status === 204) {
        return resp.data as FlightRequestViewModel[];
      }
      const data = resp?.data as ErrorResponseBody;
      return {
        errors: [data?.message],
      };
    })
    .catch(() => {
      return { errors: ['Unable to fetch flight requests'] };
    });

export const getFlightRequestsByDate = async (
  mapViewDateTimeRange: [Date, Date]
): Promise<FlightRequestViewModel[] | { errors: string[] }> =>
  fimsAPI
    .get<FlightRequestViewModel[] | ErrorResponseBody>(
      `/flight-requests-today/${DateTime.fromJSDate(mapViewDateTimeRange[0]).toUTC().toISO()}/${DateTime.fromJSDate(mapViewDateTimeRange[1]).toUTC().toISO()}`
    )
    .then((resp) => {
      if (resp?.status === 200 || resp.status === 204) {
        return resp.data as FlightRequestViewModel[];
      }
      const data = resp?.data as ErrorResponseBody;
      return {
        errors: [data?.message],
      };
    })
    .catch(() => {
      return { errors: ['Invalid flight information'] };
    });

export const getCtrSettings = async () =>
  fimsAPI
    .get<CtrZoneSettings[]>(`/ctr-settings`)
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error reading Global Settings for CTR');
    });

export const getCtrZoneVisibilitySettings = async () =>
  fimsAPI
    .get<CtrZoneVisibliltySettingsResponseBody>('/ctr-zone-visibility')
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error reading Control Zone Settings');
    });

export const updateCtrZoneVisibilitySettings = async (
  ctrObject: SetCtrZoneVisibilityPayload
) =>
  fimsAPI
    .post('/ctr-zone-visibility', ctrObject)
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error updating Ctr Visibility Settings');
    });

export const postCtrSettings = async (ctrObject: SetCtrSettingsPayload) =>
  fimsAPI
    .post<SetCtrSettingsPayload>(`/ctr-settings`, ctrObject)
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error updating Global Settings for CTR');
    });

export const getEmergencyAirspaceClosureStatus = async () =>
  fimsAPI
    .get<AirspaceIsClosedStatusResponse>('/emergency-airspace-closure/status')
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error(
        'Unable to get current status of emergency airspace closure'
      );
    });
export const activateEmergencyAirspaceClosure = async () =>
  fimsAPI
    .post<AirspaceIsClosedStatusResponse>(
      '/emergency-airspace-closure/activate',
      null,
      {
        timeout: 120000,
      }
    )
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to get activate emergency airspace closure');
    });
export const deactivateEmergencyAirspaceClosure = async () =>
  fimsAPI
    .post<AirspaceIsClosedStatusResponse>(
      '/emergency-airspace-closure/deactivate',
      null,
      {
        timeout: 120000,
      }
    )
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to get deactivate emergency airspace closure');
    });

export const postSMS = async ({
  message,
  flightId,
}: {
  message: string;
  flightId: number;
}) =>
  fimsAPI
    .post(`/flight-requests/${flightId}/sms`, {
      message,
    })
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error sending SMS');
    });

export const requestToLand = async ({
  reason,
  flightId,
}: {
  reason: string;
  flightId: number;
}) =>
  fimsAPI
    .post(`/flight-requests/${flightId}/request-to-land`, {
      reason,
    })
    .then((resp) => resp)
    .catch((error) => {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error('Error requesting to land');
    });

export const updateNotificationStatus: (
  id: string
) => Promise<{ errors?: string[]; success?: string }> = async (id) =>
  fimsAPI
    .put('/notifications', { id })
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to update notification read status');
    });

export const getUavManufacturers: () => Promise<UavManufacturer[]> = () =>
  fimsAPI
    .get<UavManufacturer[]>('/uav-manufacturers')
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to fetch Uav Manufacturers');
    });

export const updateUavManufacturer: (
  form: UavManufacturersUpdateForm
) => Promise<UavManufacturer[]> = (payload) =>
  fimsAPI
    .put('/uav-manufacturers', payload)
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to update Uav Manufacturer');
    });

export const createUavManufacturer: (
  form: UavManufacturersCreateForm
) => Promise<UavManufacturer> = (payload) =>
  fimsAPI
    .post('/uav-manufacturers', payload)
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to create Uav Manufacturer');
    });

export const getUavs: (
  pageIndex: number,
  pageSize: number,
  sortBy: string,
  order: string,
  search?: string
) => Promise<UavViewModelPaged> = async (
  pageIndex,
  pageSize,
  sortBy,
  order,
  search = ''
) =>
  fimsAPI
    .get<UavViewModelPaged>(
      `/uavs?pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`
    )
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to get UAVs');
    });

export interface PrivilegedOrganisation {
  id?: string;
  name: string;
  apiKey?: string | null;
  organisationId?: ObjectId;
  locked?: boolean;
  routeWhitelist?: string[];
  apiSubscriptionUser?: string | null;
  apiSubscriptionPassword?: string | null;
  apiSubscriptionActive?: boolean;
}

export const getOrganisations: () => Promise<OrganisationsResponseBody> =
  async () =>
    fimsAPI
      .get('/organisations')
      .then((res) => res.data)
      .catch(() => {
        throw new Error('Unable to fetch organisations');
      });

export const getPrivilegedOrganizations: () => Promise<
  PrivilegedOrganisation[]
> = async () =>
  fimsAPI
    .get('/privileged-organisations')
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to fetch privileged organisations');
    });

export const createPrivilegedOrganisation: (
  privilegedOrganisation: PrivilegedOrganisation
) => Promise<PrivilegedOrganisation[]> = async ({
  name,
  apiKey,
  apiSubscriptionUser,
  apiSubscriptionPassword,
}) =>
  fimsAPI
    .post('/privileged-organisations', {
      name,
      apiKey,
      apiSubscriptionUser,
      apiSubscriptionPassword,
    })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to create privileged organisation');
    });

export const updatePrivilegedOrganisation: (
  privilegedOrganisation: PrivilegedOrganisation & { id: string }
) => Promise<PrivilegedOrganisation[]> = async ({
  id,
  name,
  apiKey,
  apiSubscriptionUser,
  apiSubscriptionPassword,
  apiSubscriptionActive,
}) =>
  fimsAPI
    .put(`/privileged-organisations/${id}`, {
      id,
      name,
      apiKey,
      apiSubscriptionUser,
      apiSubscriptionPassword,
      apiSubscriptionActive,
    })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to update privileged organisation');
    });

export const createOrganisation: (
  organisation: CreateOrgRequestBody
) => Promise<OrganisationResponseBody> = async ({
  name,
  contactDetails,
  mapDefault,
  authorizer,
}) =>
  fimsAPI
    .post('/organisations', { name, contactDetails, mapDefault, authorizer })
    .then((res) => res.data)
    .catch((e) => {
      let response = e.response?.data?.errors;
      response = response || 'Unable to create organisation';
      throw new Error(response);
    });

export const updateOrganisation: (
  organisation: UpdateOrgRequestBody & { id: string }
) => Promise<OrganisationResponseBody> = async ({
  id,
  name,
  contactDetails,
  mapDefault,
  authorizer,
  settings,
  liveFlights,
}) =>
  fimsAPI
    .put(`/organisations/${id}`, {
      id,
      name,
      contactDetails,
      mapDefault,
      authorizer,
      settings,
      liveFlights,
    })
    .then((res) => res.data)
    .catch((e) => {
      let response = e.response?.data?.errors;
      response = response || 'Unable to update organisation';
      throw new Error(response);
    });

export const deleteOrganisation: (
  organisation: DeleteOrganisationReqParams
) => Promise<OrganisationResponseBody> = async ({ id }) =>
  fimsAPI
    .delete(`/organisations/${id}`)
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to delete organisation');
    });

export const getRulesConfigs: () => Promise<RulesConfig[]> = async () =>
  fimsAPI
    .get('/rulesconfigs')
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to fetch rulesconfigs');
    });

export interface AuthorizerResponse {
  id?: string;
  name: string;
  type: AuthorizerType;
  areasOfResponsibility?: AreasOfResponsibility;
}

export const getAuthorizers: () => Promise<AuthorizerResponse[]> = async () =>
  fimsAPI
    .get('/authorizers')
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to fetch authorizers');
    });

export const getAreasOfResponsibility: () => Promise<
  { lat: number; lng: number }[][]
> = async () =>
  fimsAPI
    .get(`/organisations/areas`)
    .then((res) => res.data)
    .catch(() => {
      throw new Error(
        'Unable to get areas of responsibility associated with organisation'
      );
    });

export const getManagedAirspace: (
  id: string
) => Promise<ManagedAreaByIdResponse> = async (id) =>
  fimsAPI
    .get(`/managed-areas/${id}`)
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to retrieve managed airspace');
    });

export const getManagedAirspaces: (
  isActive: boolean
) => Promise<ManagedAreaResponse> = async (isActive) =>
  fimsAPI
    .get(`/managed-areas`, {
      params: {
        isActive,
      },
    })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to retrieve managed airspaces');
    });

export const editManagedAirspace: (
  airspace: EditManagedAreaBody
) => Promise<{ success: string }> = async ({ id, ...body }) =>
  fimsAPI
    .put(`/managed-areas/${id}`, body)
    .then((res) => res.data)
    .catch((error: AxiosError | Error | any) => {
      throw new Error(
        `Unable to update airspace (${
          error?.response?.data.message || error.message
        })`
      );
    });

export const createManagedAirspace: (
  airspace: CreateManagedAreaBody
) => Promise<{ success: string }> = async (airspace) =>
  fimsAPI
    .post('/managed-areas', airspace)
    .then((res) => res.data)
    .catch((error: AxiosError | Error | any) => {
      throw new Error(
        `Unable to create managed area (${
          error?.response?.data.message || error.message
        })`
      );
    });

export const validateManagedAreaGeometry: (
  geometry: Polygon
) => Promise<{ success: string; valid: boolean }> = async (geometry) =>
  fimsAPI
    .post('/managed-areas/validate-geometry', { geometry })
    .then((res) => res.data)
    .catch((error: AxiosError | Error | any) => {
      throw new Error(
        `Unable to create managed area (${
          error?.response?.data.message || error.message
        })`
      );
    });

export const getReports: (
  fields?: ReportFields[]
) => Promise<{ data?: ReportsData; error?: string }> = async (
  fields = [
    ReportFields.TotalFlights,
    ReportFields.TotalRegistrations,
    ReportFields.NewRegistrations,
    ReportFields.FlightRequestsByZone,
    ReportFields.FlightsPerMonth,
    ReportFields.AtcImpact,
  ]
) =>
  fimsAPI
    .get(`/reports?fields=${fields.join(',')}`)
    .then((res) => ({ data: res.data }))
    .catch((error) => ({ error: error.message }));
export const getFlightArea: (
  flightId: number
) => Promise<FlightAreaViewModel> = async (flightId) =>
  fimsAPI
    .get(`flight-area/${flightId}`)
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Unable to retrieve flight area');
    });

export const getFlightRequest: (
  flightId: number
) => Promise<DetailedFlightRequestViewModelV2> = async (flightId) =>
  fimsAPI
    .get(`flight-requests/${flightId}`)
    .then((res) => res.data)
    .catch(() => {
      throw new Error(
        `Unable to retrieve flight request for flightId ${flightId}`
      );
    });

function buildUrl(
  pageIndex: string | number,
  pageSize: string | number,
  sortBy: string,
  order: string,
  filter: string,
  search = ''
) {
  let url = `/flight-requests?pageindex=${pageIndex}&pagesize=${pageSize}&search=${search}`;

  if (sortBy && order) {
    const formattedSortBy = sortBy.replace(/\s/g, '').toLowerCase();
    url = url.concat(`&sortby=${formattedSortBy}:${order}`);
  }

  if (filter) {
    url = url.concat(filter);
  }

  return url;
}

interface GetFlightRequestsParams {
  pageIndex: string | number;
  pageSize: string | number;
  sortBy: string;
  order: any;
  status?: string[];
  purpose?: string[];
  timeframe?: string;
  search: string;
}

export const getFlightRequests: (
  props: GetFlightRequestsParams
) => Promise<FlightRequestsViewModelPaged> = ({
  pageIndex,
  pageSize,
  sortBy,
  order,
  status,
  purpose,
  timeframe,
  search,
}) => {
  const statusFilter = status?.length ? `&status=${status.join('|')}` : '';
  const purposeFilter = purpose?.length ? `&purpose=${purpose.join('|')}` : '';
  const timeframeFilter = timeframe ? `&timeframe=${timeframe}` : '';
  const filter = `${statusFilter}${purposeFilter}${timeframeFilter}`;

  return fimsAPI
    .get(buildUrl(pageIndex, pageSize, sortBy, order, filter, search))
    .then((res) => res.data)
    .catch((_: any) => {
      throw new Error('Unable to retrieve flight requests');
    });
};

export const getUsers: () => Promise<any> = async () =>
  fimsAPI
    .get(`/user`)
    .then((res) => res.data)
    .catch((_: any) => {
      throw new Error('Unable to retrieve users');
    });

export const getAdminDefaults: () => Promise<any> = async () =>
  fimsAPI
    .get(`/admin-defaults`)
    .then((res) => res.data)
    .catch((_: any) => {
      throw new Error('Unable to retrieve admin defaults');
    });

export const getPilotEmailAutocomplete = async (search: string) =>
  fimsAPI
    .get(`/v2/pilot-emails?search=${search}`)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err }));

export const getMapPoints = async () =>
  fimsAPI
    .get<FeatureCollection<Point>>(`/map/points`)
    .then((res) => res.data)
    .catch((_: any) => {
      throw new Error('Unable to retrieve map points');
    });

export const getOrganisationSettings: (
  organisationId: string
) => Promise<OrganisationSettingsResponseBody> = async (organisationId) => {
  try {
    const organisationSettings = await fimsAPI.get(
      `/organisations/${organisationId}/settings`
    );
    return organisationSettings.data;
  } catch (error) {
    throw new Error('Unable to retrieve organisation settings');
  }
};

export const activateFlightRequest = async (flightId: number) =>
  fimsAPI
    .post(`/flight-requests/${flightId}/activate`)
    .then((res) => res.data)
    .catch((err) => err.response);

export const terminateFlightRequest = async (flightId: number) =>
  fimsAPI
    .post(`/flight-requests/${flightId}/terminate`)
    .then((res) => res.data)
    .catch((err) => err.response);

export const deconflictFlight = async (
  flightId: number,
  flightToDeconflict: number
) => {
  return fimsAPI
    .post(`/flight-requests/${flightId}/deconflict`, {
      flightToDeconflict,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const addPushNotification = async (dataSubscription: any) => {
  return fimsAPI
    .post('/notification-subscription', dataSubscription)
    .then((res) => res.data)
    .catch((error) => {
      return error.response;
    });
};

export const getPushNotification: () => Promise<WebPushSubscriptionResponseBody> =
  async () => {
    return fimsAPI
      .get(
        '/notification-subscription?browserId=' +
          localStorage.getItem('pushNotification-browserId')
      )
      .then((res) => res.data)
      .catch((error: AxiosError) => {
        return error.response;
      });
  };

export const getBackgroundJobs: () => Promise<
  BackgroundJobStatus[]
> = async () => {
  return fimsAPI
    .get('/background-jobs')
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return error.response;
    });
};

export const setTowerWatch = async (
  watch: 'on-watch' | 'off-watch',
  validFrom?: Date
) => {
  return fimsAPI
    .post(`/towers/watch/${watch}`, {
      validFrom,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return error.response;
    });
};
