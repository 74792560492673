import { createAction, LOG_OUT } from 'airshare-web-utils/redux-helpers';
import { Profile } from 'argus-common/interfaces';

import {
  LogInStatus,
  ResetPasswordStatus,
  ChangePasswordStatus,
} from './constants';

export enum SessionActionType {
  SET_PROFILE = 'session/SET_PROFILE',
  SET_ERROR = 'session/SET_ERROR',

  SET_LOGIN_STATUS = 'session/SET_LOGIN_STATUS',
  ATTEMPT_LOG_IN = 'session/ATTEMPT_LOG_IN',

  SET_RESET_PASSWORD_STATUS = 'session/SET_RESET_PASSWORD_STATUS',
  ATTEMPT_RESET_PASSWORD = 'session/ATTEMPT_RESET_PASSWORD',

  SET_CHANGE_PASSWORD_STATUS = 'session/SET_CHANGE_PASSWORD_STATUS',
  ATTEMPT_CHANGE_PASSWORD = 'session/ATTEMPT_CHANGE_PASSWORD',
}

export interface AttemptLoginPayload {
  email: string;
  password: string;
  recaptchaToken: string;
}

export interface AttemptResetPasswordPayload {
  email: string;
  newPassword: string;
  resetKey: string;
}

export interface AttemptChangePasswordPayload {
  email: string;
  newPassword: string;
  resetKey: string;
}

export interface SessionLogoutAction {
  type: 'session/LOG_OUT';
}
export interface SessionSetProfileAction {
  type: SessionActionType.SET_PROFILE;
  payload: Object;
}
export interface SessionSetErrorAction {
  type: SessionActionType.SET_ERROR;
  payload: string;
}
export interface SessionSetLoginStatusAction {
  type: SessionActionType.SET_LOGIN_STATUS;
  payload: LogInStatus;
}
export interface SessionAttemptLoginAction {
  type: SessionActionType.ATTEMPT_LOG_IN;
  payload: AttemptLoginPayload;
}
export interface SessionSetResetPasswordStatusAction {
  type: SessionActionType.SET_RESET_PASSWORD_STATUS;
  payload: ResetPasswordStatus;
}
export interface SessionAttemptResetPasswordAction {
  type: SessionActionType.ATTEMPT_RESET_PASSWORD;
  payload: AttemptResetPasswordPayload;
}
export interface SessionSetChangePasswordStatusAction {
  type: SessionActionType.SET_CHANGE_PASSWORD_STATUS;
  payload: ChangePasswordStatus;
}
export interface SessionAttemptChangePasswordAction {
  type: SessionActionType.ATTEMPT_CHANGE_PASSWORD;
  payload: AttemptChangePasswordPayload;
}

export const logOut: () => SessionLogoutAction = createAction(LOG_OUT);
export const setProfile = createAction<Profile, SessionActionType.SET_PROFILE>(
  SessionActionType.SET_PROFILE
);
export const setError: (payload: string) => SessionSetErrorAction =
  createAction(SessionActionType.SET_ERROR);

export const setLoginStatus: (
  payload: LogInStatus
) => SessionSetLoginStatusAction = createAction(
  SessionActionType.SET_LOGIN_STATUS
);
export const attemptLogin: (
  payload: AttemptLoginPayload
) => SessionAttemptLoginAction = createAction(SessionActionType.ATTEMPT_LOG_IN);

export const setResetPasswordStatus: (
  payload: ResetPasswordStatus
) => SessionSetResetPasswordStatusAction = createAction(
  SessionActionType.SET_RESET_PASSWORD_STATUS
);
export const attemptResetPassword: (
  payload: AttemptResetPasswordPayload
) => SessionAttemptResetPasswordAction = createAction(
  SessionActionType.ATTEMPT_RESET_PASSWORD
);

export const setChangePasswordStatus: (
  payload: ChangePasswordStatus
) => SessionSetChangePasswordStatusAction = createAction(
  SessionActionType.SET_CHANGE_PASSWORD_STATUS
);
export const attemptChangePassword: (
  payload: AttemptChangePasswordPayload
) => SessionAttemptChangePasswordAction = createAction(
  SessionActionType.ATTEMPT_CHANGE_PASSWORD
);
