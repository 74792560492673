import React, { Fragment, useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
  Divider,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { UavManufacturersViewModel } from 'fims-api-types';

export default function ManufacturerListItem({
  manufacturer,
  isAdmin,
  editManufacturer,
}: {
  manufacturer: UavManufacturersViewModel;
  isAdmin: boolean;
  editManufacturer: (manufacturer: UavManufacturersViewModel) => void;
}) {
  const { name, isActive, models } = manufacturer;

  const [expandedItem, setExpandedItem] = useState(false);

  return (
    <Fragment key={name}>
      <ListItem
        button
        onClick={
          models.length ? () => setExpandedItem(!expandedItem) : () => {}
        }
        data-testid="uav-manufacturer-list-item"
      >
        <ListItemText
          primary={name}
          secondary={isActive ? 'Active' : 'Inactive'}
          data-testid="uav-manufacturer-list-item-text"
        />
        {models.length ? (
          <ListItemIcon>
            {expandedItem ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        ) : null}
        <ListItemSecondaryAction>
          {isAdmin ? (
            <IconButton
              onClick={() => editManufacturer(manufacturer)}
              data-testid="uav-manufacturer-list-item-edit-button"
            >
              <Edit />
            </IconButton>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expandedItem} timeout="auto" unmountOnExit>
        <List>
          <Divider />
          {models.map((model) => (
            <ListItem
              className="nested-list"
              key={model.name}
              button
              data-testid="uav-manufacturer-list-item-expand-list"
            >
              <ListItemText
                primary={model.name}
                secondary={model.isActive ? 'Active' : 'Inactive'}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
    </Fragment>
  );
}
