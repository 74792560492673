import type { Polygon } from 'geojson';
import * as yup from 'yup';

enum GeometryTypes {
  CIRCLE = 'Circle',
  POLYGON = 'Polygon',
}

const geoCoord = yup.array(yup.number()).length(2).required();

export const geometrySchema = yup
  .object()
  .shape({
    type: yup
      .string()
      .required()
      .oneOf(
        [GeometryTypes.POLYGON, GeometryTypes.CIRCLE],
        'Geometry type is invalid'
      ),
    coordinates: yup.mixed().when('type', {
      is: (type: string) => type === GeometryTypes.CIRCLE,
      then: geoCoord.required(),
      otherwise: yup.array(yup.array(geoCoord.required()).min(4)).required(),
    }),
    radiusMetres: yup.mixed().when('type', {
      is: (type: string) => type === GeometryTypes.CIRCLE,
      then: yup.number().min(1).required(),
      otherwise: yup.number().optional(),
    }),
  })
  .default(undefined);

export const polygonSchema = yup.object().shape({
  type: yup
    .string()
    .required()
    .oneOf([GeometryTypes.POLYGON], 'Polygon type is invalid'),
  coordinates: yup.array(yup.array(geoCoord.required()).min(4)).required(),
  bbox: yup.array(yup.number()).length(4).optional(),
}) as yup.SchemaOf<Polygon>;

export const optionalPolygonSchema = yup.object().shape({
  type: yup.string().oneOf([GeometryTypes.POLYGON], 'Polygon type is invalid'),
  coordinates: yup.array(yup.array(geoCoord.required()).min(4)),
  bbox: yup.array(yup.number()).length(4).optional(),
}) as yup.SchemaOf<Polygon>;
