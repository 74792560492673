import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';

import { SuperAdminConsoleGlobalSettings } from 'fims-api-types';
import {
  SettingsActionType,
  SettingsFetchSettingsSuccessAction,
  SettingsUpdateSettingsAction,
} from './actions';
import { SettingsFetchStatus, SettingsUpdateStatus } from './constants';

export interface SettingsState {
  global: SuperAdminConsoleGlobalSettings;
  fetchStatus: SettingsFetchStatus;
  updateStatus: SettingsUpdateStatus;
}

const globalReducer = createReducer(
  {
    [SettingsActionType.FETCH_SETTINGS_SUCCESS]: (
      _: SuperAdminConsoleGlobalSettings,
      { payload }: SettingsFetchSettingsSuccessAction
    ) => payload,
    [SettingsActionType.UPDATE_SETTING]: (
      state: SuperAdminConsoleGlobalSettings,
      { payload: { section, setting, value } }: SettingsUpdateSettingsAction
    ) => ({ ...state, [section]: { ...state[section], [setting]: value } }),
  },
  null,
  false
);

const INITIAL_FETCH_STATUS = SettingsFetchStatus.IDLE;
const globalFetchStatusReducer = createReducer(
  {
    [SettingsActionType.FETCH_SETTINGS]: () =>
      SettingsFetchStatus.SETTINGS_FETCH_IN_PROGRESS,
    [SettingsActionType.FETCH_SETTINGS_SUCCESS]: () =>
      SettingsFetchStatus.SUCCESS,
    [SettingsActionType.FETCH_SETTINGS_FAILED]: () =>
      SettingsFetchStatus.FAILED,
  },
  INITIAL_FETCH_STATUS
);

const INITIAL_UPDATE_STATUS = SettingsUpdateStatus.IDLE;
const globalUpdateStatusReducer = createReducer(
  {
    [SettingsActionType.UPDATE_SETTING]: () =>
      SettingsUpdateStatus.SETTINGS_UPDATE_IN_PROGRESS,
    [SettingsActionType.UPDATE_SETTINGS_SUCCESS]: () =>
      SettingsUpdateStatus.SUCCESS,
    [SettingsActionType.UPDATE_SETTINGS_FAILED]: () =>
      SettingsUpdateStatus.FAILED,
  },
  INITIAL_UPDATE_STATUS
);

export default combineReducers({
  global: globalReducer,
  fetchStatus: globalFetchStatusReducer,
  updateStatus: globalUpdateStatusReducer,
});
