export enum AuthChangeField {
  ALTITUDE = 'flightAltitude.maxAltitudeFeet',
  FLIGHT_TIME = 'flightDateTime.startDateTime',
}

export enum AuthChangeFieldClient {
  ALTITUDE = 'altitudeFeet',
  FLIGHT_TIME = 'startDateTime',
}

export enum AuthChangeFieldUi {
  ALTITUDE = 'Altitude (ft)',
  FLIGHT_TIME = 'Start time',
}

export type AuthChangeValue = number | Date;

export interface RequiredAuthChange {
  field: AuthChangeField | AuthChangeFieldClient | AuthChangeFieldUi;
  previousValue: AuthChangeValue;
  isAcknowledged: boolean;
  authorizerName: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface RequiredAuthChangeSubmission
  extends Omit<RequiredAuthChange, 'authorizerName'> {
  field: AuthChangeField;
  newValue: AuthChangeValue;
  authorizerName?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AcknowledgeFlightRequestBody {
  authorizerChanges?: AuthChangeFieldClient[];
  alertType?: string;
}

export type AcknowledgeFlightRequestResponse =
  Partial<AcknowledgeFlightRequestBody> & { errors?: string[] };
