import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import type { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import { getFormattedDateTime, getFormattedDuration } from '../timehelpers';
import { listItemStyle } from './styles';

import { AlarmClockColoured } from '../../../../lib/icons';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const When = ({ focussedFlightRequest }: Props) => {
  const [whenOpen, setWhenOpen] = useState(true);

  return (
    <div data-testid="flight-info-when">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setWhenOpen(!whenOpen)}
        data-testid="flight-when-heading"
      >
        <ListItemText primary="When" />
        {whenOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={whenOpen} timeout="auto" unmountOnExit>
        <table className="flight-info-table" data-testid="flight-when-table">
          <tbody>
            <tr>
              <th data-testid="flight-when-start-title">Start</th>
              <td data-testid="flight-when-start-value">
                {getFormattedDateTime(
                  focussedFlightRequest.localStartDateTime,
                  focussedFlightRequest.timezone
                )}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-when-finish-title">Finish</th>
              <td data-testid="flight-when-finish-value">
                {focussedFlightRequest?.exceededAllocatedTime
                  ? AlarmClockColoured
                  : null}
                {getFormattedDateTime(
                  focussedFlightRequest.localEndDateTime,
                  focussedFlightRequest.timezone
                )}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-when-duration-title">Duration (hh:mm)</th>
              <td data-testid="flight-when-duration-value">
                {getFormattedDuration(focussedFlightRequest)}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-when-timezone-title">Timezone</th>
              <td data-testid="flight-when-timezone-value">
                {focussedFlightRequest.timezone}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default When;
