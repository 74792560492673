import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import DetailPageTitle from './detail-page-title';

interface Props {
  title: string;
  status?: JSX.Element;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 20px 10px 5px',
      maxHeight: '48px',
    },
  })
);

export default function DetailPageHeader({ title, status }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <DetailPageTitle title={title} />
      {status}
    </div>
  );
}
