import React, { useState } from 'react';
import * as yup from 'yup';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {
  DialogContentText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { OrganisationResponse, UpdateOrgRequestBody } from 'fims-api-types';

import { MapStyleCode } from '../../../state/map-styles';
import { updateOrganisation } from '../../../clients/fims-api-client';
import { useStyles } from './useStyles';
import {
  mapDefaultFormSchema,
  LAT_MIN,
  LAT_MAX,
  LNG_MIN,
  LNG_MAX,
  ZOOM_MIN,
  ZOOM_MAX,
} from './organisation.lib';

interface OrgModalProps {
  closeModal(): any;
  organisation: OrganisationResponse;
}

const LogoutRequiredWarning = (
  <DialogContentText variant="body2" color="error">
    A logout will be required to see these changes reflected.
  </DialogContentText>
);

const ZoomHelperText = (
  <DialogContentText variant="body2">
    Range: 0 (zoomed out) - 22 (zoomed in)
  </DialogContentText>
);

function EditMapDefaultModal({ closeModal, organisation }: OrgModalProps) {
  const classes = useStyles();

  const { id, mapDefault, liveFlights } = organisation;

  const [updatedLat, setUpdatedLat] = useState(
    toNumericString(mapDefault?.lat)
  );
  const [updatedLng, setUpdatedLng] = useState(
    toNumericString(mapDefault?.lng)
  );
  const [updatedZoom, setUpdatedZoom] = useState(
    toNumericString(mapDefault?.zoom)
  );
  const [updatedMapType, setUpdatedMapType] = useState(
    mapDefault?.mapType ? mapDefault?.mapType : ''
  );
  const [updatedFocusedZoom, setUpdatedFocusedZoom] = useState(
    toNumericString(mapDefault?.focusedZoom)
  );

  const [isRadarFeedEnabled, setIsRadarFeedEnabled] = useState<boolean>(
    !!liveFlights?.radarFeedEnabled
  );
  const [isLiveUavFeedEnabled, setIsLiveUavFeedEnabled] = useState<boolean>(
    !!liveFlights?.uavFeedEnabled
  );

  const mapTypes = Object.values(MapStyleCode);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleLiveFlightFeedChange = (liveFlightUpdate: {
    radarFeed?: boolean;
    liveUavFeed?: boolean;
  }) => {
    const { radarFeed, liveUavFeed } = liveFlightUpdate;
    if (radarFeed !== undefined) {
      setIsRadarFeedEnabled(radarFeed);
    }
    if (liveUavFeed !== undefined) {
      setIsLiveUavFeedEnabled(liveUavFeed);
    }
  };

  const handleSubmit = async (_: any): Promise<void> => {
    setIsSubmitting(true);
    const params: UpdateOrgRequestBody = {
      mapDefault: {
        lat: updatedLat ? parseFloat(updatedLat) : undefined,
        lng: updatedLng ? parseFloat(updatedLng) : undefined,
        zoom: updatedZoom ? parseInt(updatedZoom, 10) : undefined,
        mapType: updatedMapType.length > 0 ? updatedMapType : undefined,
        focusedZoom: updatedFocusedZoom
          ? parseInt(updatedFocusedZoom, 10)
          : undefined,
      },
      liveFlights: {
        radarFeedEnabled: isRadarFeedEnabled,
        uavFeedEnabled: isLiveUavFeedEnabled,
      },
    };

    mapDefaultFormSchema
      .validate(params.mapDefault, {
        strict: true,
        abortEarly: false,
      })
      .then(async () => {
        try {
          await updateOrganisation({ id, ...params });
          setIsSubmitting(false);
          setIsSubmitting(false);
          closeModal();
        } catch (e: any) {
          setError(e.toString());
        }
      })
      .catch((e: yup.ValidationError) => {
        setError(e.errors.join(', '));
        setIsSubmitting(false);
      });
  };

  function toNumericString(value: string | number) {
    return value === undefined ? '' : value.toString().replace(/[^0-9.-]+/, '');
  }

  return (
    <Dialog onClose={closeModal} open>
      <DialogTitle data-testid="update-organisation-map-settings-header">
        Update Default Settings
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText variant="subtitle1">
          {`Update the default map settings for "${organisation.name}".`}
        </DialogContentText>
        <br />
        <DialogContentText variant="body1">Map</DialogContentText>
        <FormControl>
          <TextField
            type="text"
            label="Latitude"
            InputProps={{ inputProps: { min: LAT_MIN, max: LAT_MAX } }}
            name="latitude"
            className={classes.textField}
            value={updatedLat}
            error={
              updatedLat.length > 0 &&
              parseFloat(updatedLat).toString() !== updatedLat
            }
            onChange={(e) => setUpdatedLat(toNumericString(e.target.value))}
            data-testid="update-organisation-map-settings-latitude"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="text"
            InputProps={{ inputProps: { min: LNG_MIN, max: LNG_MAX } }}
            label="Longitude"
            name="longitude"
            className={classes.textField}
            value={updatedLng}
            error={
              updatedLng.length > 0 &&
              parseFloat(updatedLng).toString() !== updatedLng
            }
            onChange={(e) => setUpdatedLng(toNumericString(e.target.value))}
            data-testid="update-organisation-map-settings-longitude"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="text"
            InputProps={{ inputProps: { min: ZOOM_MIN, max: ZOOM_MAX } }}
            label="Initial Zoom"
            name="zoom"
            className={classes.textField}
            placeholder="0 - 22"
            value={updatedZoom}
            error={
              updatedZoom.length > 0 &&
              parseInt(updatedZoom, 10).toString() !== updatedZoom
            }
            onChange={(e) => setUpdatedZoom(toNumericString(e.target.value))}
            data-testid="update-organisation-map-settings-zoom"
          />
          {ZoomHelperText}
        </FormControl>
        <FormControl>
          <InputLabel data-testid="label">Map mode</InputLabel>
          <Select
            className={classnames(classes.input, classes.capitalize)}
            displayEmpty
            data-testid="mapType"
            name="mapType"
            value={updatedMapType}
            onChange={(e) => {
              setUpdatedMapType(e.target.value.toString());
            }}
          >
            <MenuItem value="" />
            {mapTypes.map((key: MapStyleCode) => (
              <MenuItem
                value={key}
                key={key}
                className={classes.capitalize}
                data-testid="map-type-list-item"
              >
                {key}
              </MenuItem>
            ))}
          </Select>
          {LogoutRequiredWarning}
        </FormControl>
        <br />
        <DialogContentText variant="body1">Focused Map</DialogContentText>
        <FormControl>
          <TextField
            type="text"
            InputProps={{ inputProps: { min: ZOOM_MIN, max: ZOOM_MAX } }}
            label="Initial Zoom"
            name="zoom"
            placeholder="0 - 22"
            className={classes.textField}
            value={updatedFocusedZoom}
            error={
              updatedFocusedZoom.length > 0 &&
              parseInt(updatedFocusedZoom, 10).toString() !== updatedFocusedZoom
            }
            onChange={(e) =>
              setUpdatedFocusedZoom(toNumericString(e.target.value))
            }
            data-testid="update-organisation-detail-map-settings-zoom"
          />
          {ZoomHelperText}
        </FormControl>
        <Typography
          className={classnames(classes.item, classes.error)}
          data-testid="update-organisation-map-settings-error-message"
        >
          {error}
        </Typography>
        <br />
        <DialogContentText variant="body1">Live Flight Feed</DialogContentText>
        <Grid className={classes.row}>
          <Typography variant="body2" data-testid="settings-name">
            Enable ATM Feed
          </Typography>
          <Switch
            checked={isRadarFeedEnabled}
            name="radarFeedEnabled"
            onChange={(e) =>
              handleLiveFlightFeedChange({ radarFeed: e.target.checked })
            }
            color="primary"
            data-testid="radar-feed-switch"
          />
        </Grid>
        <Grid className={classes.row}>
          <Typography variant="body2" data-testid="settings-name">
            Enable UA Feed
          </Typography>
          <Switch
            checked={isLiveUavFeedEnabled}
            name="liveUavFeedEnabled"
            onChange={(e) =>
              handleLiveFlightFeedChange({ liveUavFeed: e.target.checked })
            }
            color="primary"
            data-testid="live-uav-feed-switch"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
          data-testid="update-organisation-map-settings-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isSubmitting ? () => {} : handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={classes.saveButton}
          data-testid="update-organisation-map-settings-save-button"
        >
          {isSubmitting ? (
            <CircularProgress
              data-testid="update-organisation-map-defaults-circular-progress"
              size={24}
              color="inherit"
            />
          ) : (
            'Save Changes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditMapDefaultModal;
