import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  AuthChangeFieldClient,
  type DetailedFlightRequestViewModelV2,
} from 'fims-api-types';

import AcknowledgeHelper from 'airspace-manager-common/flight-requests/lib/acknowledge-helpers';

import { getFormattedDateTime } from '../timehelpers';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  auditLogItemsChanged: boolean;
  setAuditLogItemsChanged: (value: boolean) => void;
}

const RequiredAuthChanges = ({
  focussedFlightRequest,
  auditLogItemsChanged,
  setAuditLogItemsChanged,
}: Props) => {
  const [requiredAuthChangesOpen, setRequiredAuthChangesOpen] = useState(false);
  const [requiredAuthChangesOpenItems, setRequiredAuthChangesOpenItems] =
    useState([]);

  const toggleRequiredAuthChangeItem = (idx: number) => {
    if (!requiredAuthChangesOpenItems[idx]) {
      requiredAuthChangesOpenItems[idx] = true;
    } else {
      requiredAuthChangesOpenItems[idx] = !requiredAuthChangesOpenItems[idx];
    }

    setRequiredAuthChangesOpenItems([...requiredAuthChangesOpenItems]);
    setAuditLogItemsChanged(!auditLogItemsChanged);
  };

  const formatPreviousValue = (
    previousValue: number | Date,
    fieldName: string
  ) => {
    if (fieldName === AuthChangeFieldClient.FLIGHT_TIME) {
      return getFormattedDateTime(
        previousValue.toString(),
        focussedFlightRequest.timezone
      );
    }
    return previousValue;
  };

  return (
    <div data-testid="flight-info-required-auth-changes">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setRequiredAuthChangesOpen(!requiredAuthChangesOpen)}
        data-testid="flight-required-auth-changes-header"
      >
        <ListItemText primary="Required Pilot Acknowledgements" />
        {requiredAuthChangesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={requiredAuthChangesOpen} timeout="auto" unmountOnExit>
        {focussedFlightRequest?.requiredAuthChanges?.length > 0 ? (
          focussedFlightRequest.requiredAuthChanges?.map(
            (requiredChange, index) => (
              <div
                className="flight-required-auth-change-group"
                key={`required-auth-change-${index}`}
                data-testid="flight-required-auth-change-group"
              >
                <table
                  className="flight-info-table"
                  data-testid="flight-required-auth-changes-table"
                >
                  <tbody>
                    <tr
                      className="required-auth-changes-header-row"
                      onClick={() => toggleRequiredAuthChangeItem(index)}
                    >
                      <th data-testid="flight-required-auth-change-title">
                        An Approver Changed A Value
                      </th>
                      <td data-testid="flight-required-auth-change-toggle-value">
                        <div>
                          {requiredAuthChangesOpenItems[index] && (
                            <ExpandLess />
                          )}
                          {!requiredAuthChangesOpenItems[index] && (
                            <ExpandMore />
                          )}
                        </div>
                      </td>
                    </tr>
                    {requiredAuthChangesOpenItems[index] && (
                      <>
                        <tr>
                          <th data-testid="flight-required-auth-change-field-title">
                            Field
                          </th>
                          <td data-testid="flight-required-auth-change-field-value">
                            {AcknowledgeHelper.fieldNameToUI(
                              requiredChange.field
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th data-testid="flight-required-auth-change-authorizer-name-title">
                            Authorizer Name
                          </th>
                          <td data-testid="flight-required-auth-change-authorizer-name-value">
                            {requiredChange.authorizerName}
                          </td>
                        </tr>
                        <tr>
                          <th data-testid="flight-required-auth-change-previous-value-title">
                            Previous Value
                          </th>
                          <td data-testid="flight-required-auth-change-previous-value-value">
                            {formatPreviousValue(
                              requiredChange.previousValue,
                              requiredChange.field
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th data-testid="flight-required-auth-change-acknowledged-title">
                            Pilot Has Acknowledged
                          </th>
                          <td data-testid="flight-required-auth-change-acknowledged-value">
                            {requiredChange.isAcknowledged ? 'Y' : 'N'}
                          </td>
                        </tr>
                        <tr>
                          <th data-testid="flight-required-auth-change-created-title">
                            Created
                          </th>
                          <td data-testid="flight-required-auth-change-created-value">
                            {getFormattedDateTime(
                              requiredChange.createdAtDateTime,
                              focussedFlightRequest.timezone
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th data-testid="flight-required-auth-change-updated-title">
                            Updated
                          </th>
                          <td data-testid="flight-required-auth-change-updated-value">
                            {getFormattedDateTime(
                              requiredChange.updatedAtDateTime,
                              focussedFlightRequest.timezone
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )
          )
        ) : (
          <table
            className="flight-info-table"
            data-testid="flight-required-auth-changes-table"
          >
            <tbody>
              <tr>
                <th data-testid="flight-required-auth-change-none-title">
                  None
                </th>
              </tr>
            </tbody>
          </table>
        )}
      </Collapse>
    </div>
  );
};

export default RequiredAuthChanges;
