import { Schema, Document } from 'mongoose';
import { generateMapAreaSchema } from './map-areas';
import { AreaTypes, Area } from '../geojson/geometry';
import { toJsonOptions } from '../../../lib/shared-schema-options';
import managedAreaAuditSchema from './audit';
import {
  AllExtraSchemaProps,
  ManagedArea,
  ManagedAreaAudit,
  ManagedAreaCode,
  ManagedAreaProperties,
  UnfinishedManagedAreaCode,
} from '@airshare/external-api-types';
export {
  AllExtraSchemaProps,
  ManagedArea,
  ManagedAreaAudit,
  ManagedAreaCode,
  ManagedAreaProperties,
  UnfinishedManagedAreaCode,
};

export type ManagedAreaSchema = ManagedArea & Document;

const featureSchema = generateMapAreaSchema<
  Area,
  AllExtraSchemaProps,
  ManagedAreaProperties
>(
  AreaTypes,
  new Schema<AllExtraSchemaProps>({
    code: {
      type: String,
      enum: Object.values(ManagedAreaCode),
      required: true,
    },
    icaoCode: {
      type: String,
      required: false,
    },
    councilName: {
      type: String,
      required: false,
    },
    startDateTime: {
      type: Date,
      required: false,
    },
    endDateTime: {
      type: Date,
      required: false,
    },
    authorizedPilots: {
      type: [String],
      default: [],
    },
  })
);

const managedAreasSchema = new Schema<ManagedAreaSchema>(
  // @ts-ignore
  {
    ...featureSchema.obj,
    authorizer: {
      type: Schema.Types.ObjectId,
      ref: 'Authorizer',
      default: undefined,
    },
    auditLog: {
      type: [managedAreaAuditSchema],
      default: [],
    },
  }
);

managedAreasSchema.set('toJSON', toJsonOptions);

managedAreasSchema.index({
  'properties.geoQueryInclude': 1,
  'properties.pilotVisible': 1,
  'properties.minZoom': 1,
});
managedAreasSchema.index({
  'properties.isActive': 1,
  'properties.startDateTime': 1,
});
managedAreasSchema.index({
  'properties.isActive': 1,
  'properties.endDateTime': 1,
  'properties.startDateTime': 1,
});
managedAreasSchema.index({
  'properties.isMapLayer': 1,
  'properties.pilotVisible': 1,
});

export default managedAreasSchema;
