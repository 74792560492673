import { Polygon } from '@turf/turf';
import togeojson from '@mapbox/togeojson';

const geojsonFileTypesRegExp = new RegExp(/^.*\.(geojson|json)$/i);
const kmlFileTypesRegExp = new RegExp(/^.*\.(kml)$/i);

export const getPolygonFromGeojsonOrKmlFile = (
  inputFile: File
): Promise<Polygon> => {
  if (geojsonFileTypesRegExp.test(inputFile.name)) {
    return getPolygonFromGeojsonFile(inputFile);
  }

  if (kmlFileTypesRegExp.test(inputFile.name)) {
    return getPolygonFromKmlFile(inputFile);
  }

  return Promise.reject(
    new Error(
      'Invalid file type: only .geojson .json and .kml files are accepted'
    )
  );
};

export const getPolygonFromGeojsonFile = (
  inputFile: File
): Promise<Polygon> => {
  if (!geojsonFileTypesRegExp.test(inputFile.name))
    return Promise.reject(
      new Error('Invalid file type: only .geojson and .json files are accepted')
    );
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Failed to parse file'));
    };
    fileReader.onload = () => {
      let parsedGeoJson;
      try {
        parsedGeoJson = JSON.parse(fileReader.result as string);
      } catch (err) {
        reject(new Error('Error while parsing JSON file'));
      }
      const isFeatureCollection = parsedGeoJson.type === 'FeatureCollection';
      if (isFeatureCollection && parsedGeoJson.features?.length > 1) {
        reject(
          new Error(
            'Multiple features are not currently supported in FeatureCollection'
          )
        );
      }
      const polygon = isFeatureCollection
        ? parsedGeoJson.features[0].geometry
        : parsedGeoJson.geometry;
      if (polygon?.type !== 'Polygon') {
        reject(
          new Error(
            'Only Polygons are currently supported. Please check your file.'
          )
        );
      }
      resolve(polygon);
    };
    fileReader.readAsText(inputFile, 'UTF-8');
  });
};

export const getPolygonFromKmlFile = (inputFile: File): Promise<Polygon> => {
  if (!kmlFileTypesRegExp.test(inputFile.name))
    return Promise.reject(
      new Error('Invalid file type: only .kml files are accepted')
    );
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Failed to parse file'));
    };
    fileReader.onload = () => {
      let parsedGeoJson;
      try {
        const fileText = fileReader.result as string;
        const kml = new DOMParser().parseFromString(fileText, 'text/xml');
        parsedGeoJson = togeojson.kml(kml);
      } catch (err) {
        reject(new Error(`Error while parsing kml: ${err}`));
      }
      if (!parsedGeoJson.type) {
        reject(new Error(`Missing required type from kml parsed into geojson`));
      }
      const isFeatureCollection = parsedGeoJson.type === 'FeatureCollection';
      if (isFeatureCollection && parsedGeoJson.features?.length > 1) {
        reject(
          new Error(
            'Multiple placemarks are not currently supported from kml files'
          )
        );
      }
      if (isFeatureCollection && parsedGeoJson.features?.length === 0) {
        reject(
          new Error(
            'Unable to parse the kml file into geometry features. Please check your kml file.'
          )
        );
      }
      const polygon = isFeatureCollection
        ? parsedGeoJson.features[0].geometry
        : parsedGeoJson.geometry;
      if (polygon?.type !== 'Polygon') {
        reject(
          new Error(
            'Only Polygons are currently supported. Please check your file.'
          )
        );
      }
      resolve(polygon);
    };
    fileReader.readAsText(inputFile, 'UTF-8');
  });
};
