import React from 'react';
import cn from 'classnames';

import './weather-icon.scss';

interface Props {
  icon: string;
  className: string;
  size?: 'small' | 'medium' | 'large';
}

const WeatherIcon: React.FC<Props> = ({ icon, className, size = 'medium' }) => (
  <div
    className={cn('weather-icon', { [className]: className })}
    data-weather-icon={icon}
    data-weather-icon-size={size}
  />
);

export default WeatherIcon;
