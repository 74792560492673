import { useState } from 'react';

interface MapState {
  zoom: number | null;
}

interface MapHandlers {
  handleMapZoom: (step: number) => void;
  manualSetZoom: (value: number) => void;
  setZoom: React.Dispatch<React.SetStateAction<number | null>>;
}

export function useZoom(
  map: google.maps.Map,
  initialZoom: number | null
): [MapState, MapHandlers] {
  const [zoom, setZoom] = useState<number | null>(initialZoom);

  function handleMapZoom(step: number) {
    const currentZoom = map.getZoom();
    if (currentZoom) {
      const nextZoom = currentZoom + step;

      map.setZoom(nextZoom);
      setZoom(nextZoom);
    }
  }

  function manualSetZoom(value: number) {
    map.setZoom(value);
    setZoom(value);
  }

  return [
    { zoom },
    {
      handleMapZoom,
      manualSetZoom,
      setZoom,
    },
  ];
}
