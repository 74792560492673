import { TableHeading, Order } from '../../../lib/table';

export * from '../../../lib/table';

export const DEFAULT_PAGE_OPTIONS = [10, 20];
export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_ROWS_COUNT = 0;
export const DEFAULT_SORT_KEY = 'startDate';
export const DEFAULT_SORT_ORDER = Order.ascending;

export const TABLE_HEADINGS: TableHeading[] = [
  {
    label: 'Flight Id',
    sortKey: 'flightId',
    align: 'left',
  },
  {
    label: 'Pilot/Org',
    sortKey: '',
    align: 'left',
  },
  {
    label: 'Created',
    sortKey: 'createdOn',
    align: 'left',
  },
  {
    label: 'Purpose',
    sortKey: 'flightPurpose',
    align: 'left',
  },
  {
    label: 'Operating Rule',
    sortKey: '',
    align: 'left',
  },
  {
    label: 'Start Date',
    sortKey: 'startDate',
    align: 'left',
  },
  {
    label: 'End Date',
    sortKey: 'endDate',
    align: 'left',
  },
  {
    label: 'ATC Notes',
    sortKey: '',
    align: 'left',
  },
  {
    label: 'Status',
    sortKey: 'status',
    align: 'center',
  },
];
