import { createSelector } from 'reselect';
import { AppState } from '../store';

const getLocalState = (state: AppState) => state.focussedFlightRequests;

export const getFocussedFlightRequest = createSelector(
  [getLocalState],
  (state) => state.data
);

export const getFocussedFetchStatus = createSelector(
  [getLocalState],
  (state) => state.focussedFetchStatus
);

export const getFocussedSubmitter = createSelector(
  [getLocalState],
  (state) => state.submitter
);

export const getFocussedError = createSelector(
  [getLocalState],
  (state) => state.focussedError
);
