import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
}

const Where = ({ focussedFlightRequest }: Props) => {
  const [whereOpen, setWhereOpen] = useState(true);

  return (
    <div data-testid="flight-info-where">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => setWhereOpen(!whereOpen)}
        data-testid="flight-where-heading"
      >
        <ListItemText primary="Where" />
        {whereOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={whereOpen} timeout="auto" unmountOnExit>
        <table className="flight-info-table" data-testid="flight-where-table">
          <tbody>
            <tr>
              <th data-testid="flight-where-control-zone-title">
                Control Zone
              </th>
              <td data-testid="flight-where-control-zone-value">
                {focussedFlightRequest.controlZone}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-where-street-address-title">
                Street Address
              </th>
              <td data-testid="flight-where-street-address-value">
                {focussedFlightRequest.address}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-where-operating-area-title">
                Operating Area
              </th>
              <td data-testid="flight-where-operating-area-value">
                {focussedFlightRequest.descriptionOfOperatingArea}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-where-max-altitude-title">
                Max Altitude AGL (ft)
              </th>
              <td data-testid="flight-where-max-altitude-value">
                {focussedFlightRequest.maxAltitudeFeet}
              </td>
            </tr>
            <tr>
              <th data-testid="flight-where-launch-elevation-title">
                Launch Elevation MSL (ft)
              </th>
              <td data-testid="flight-where-launch-elevation-value">
                {focussedFlightRequest.launchElevationFeet}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default Where;
