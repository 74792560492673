export const shapeOptions = {
  fillColor: '#000000',
  fillOpacity: 0.5,
  strokeColor: '#0000FF',
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  editable: false,
  zIndex: 1,
};
