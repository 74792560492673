import React from 'react';
import type { MessageMobileContext } from 'fims-api-types';

import '../audit-logs.scss';

export const ContextMessageMobile = ({
  context,
}: {
  context: MessageMobileContext;
}) => {
  return (
    <>
      <tr>
        <th data-testid="flight-audit-log-sent-by-title">SMS sent by</th>
        <td className="note" data-testid="flight-audit-log-sent-by-value">
          {context?.from?.organisationName || context?.from?.userName || 'N/A'}
        </td>
      </tr>

      <tr>
        <th data-testid="flight-audit-log-message-title">Message</th>
        <td className="note" data-testid="flight-audit-log-message-value">
          {context?.message || 'N/A'}
        </td>
      </tr>

      <tr>
        <th data-testid="flight-audit-log-mobile-title">Mobile</th>
        <td data-testid="flight-audit-log-mobile-value">
          {context?.mobileNo || 'N/A'}
        </td>
      </tr>

      <tr>
        <th data-testid="flight-audit-log-sms-response-title">Response</th>
        <td data-testid="flight-audit-log-sms-response-value">
          {context?.response?.success || 'N/A'}
        </td>
      </tr>
    </>
  );
};
