import React from 'react';
import type { ApprovalUpdateContext } from 'fims-api-types';

import { ATCNote } from './ATCNote';
import { OperatorNote } from './OperatorNote';

export const ContextApprovalUpdate = ({
  context,
}: {
  context: ApprovalUpdateContext;
}) => {
  return (
    <>
      <ATCNote atcNote={context.atcNote} />
      <OperatorNote operatorNote={context.operatorNote} />

      <tr>
        <th data-testid="flight-audit-log-approval-request">
          Approval Request
        </th>
        <td data-testid="flight-audit-log-approval-request-value">
          {context?.approvalRequest
            ?.map(
              (req) =>
                `Segment Id: ${req.segmentId}, approve: ${req.approve === true ? 'true' : req.approve === false ? 'false' : ''}`
            )
            .join(', ') || 'N/A'}
        </td>
      </tr>

      <tr>
        <th data-testid="flight-audit-log-updated-segments">
          Updated Segments
        </th>
        <td data-testid="flight-audit-log-updated-segments-value">
          {context?.updatedSegments?.map((seg) => (
            <div key={seg.properties.id}>
              {`Segment Id: ${seg.properties.id}, Status: ${seg.properties.segmentStatus}, Clearance: ${seg.properties.segmentClearanceStatus}`}
              <br />
            </div>
          )) || 'N/A'}
        </td>
      </tr>
    </>
  );
};
