import { Schema } from 'mongoose';
import type { Area } from '@airshare/external-api-types';

export { Area };
import type { Geometry, Point, Feature } from 'geojson'; // As per RFC 7946 https://geojson.org/

export const AreaTypes = ['Polygon', 'MultiPolygon'];

export type AdvisoryGeoJSONTypes = Area | Point; // Advisories can be points for hang-gliding or parachuting sites, or an area type.
export const AdvisoryTypes = AreaTypes.concat('Point');

// Any Geometry type
export const FlightAdvisorTypes = [
  'Polygon',
  'MultiPolygon',
  'Point',
  'MultiPoint',
  'LineString',
  'MultiLineString',
];

export function generateGeometrySchema<T extends Geometry>(types: string[]) {
  return new Schema<T>(
    {
      type: {
        type: String,
        enum: types,
        required: true,
      },
      coordinates: {
        type: [Schema.Types.Mixed],
        required: true,
      },
    },
    { _id: false }
  );
}

export const pointSchema = new Schema<Point>(
  {
    type: {
      type: String,
      enum: ['Point'],
      required: true,
    },
    coordinates: {
      type: [Number],
      required: true,
    },
  },
  { _id: false }
);

export const areaSchema: Schema<Area> = generateGeometrySchema<Area>(AreaTypes);

export function generateFeatureSchema<G extends Geometry, P>(
  geometry: Schema<G>,
  properties: Schema<P>
): Schema<Feature<G, P>> {
  return new Schema<Feature<G, P>>({
    type: {
      type: String,
      enum: ['Feature'],
      required: true,
    },
    geometry,
    properties,
  });
}
