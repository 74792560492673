import { FRStatusCode, type FlightRequestViewModel } from 'fims-api-types';
import type { Authorizer } from 'argus-data-model/db/schemas/authorizers';

import { FlightRequestMarker } from './drone-marker-helpers';

const statusViewOrder = {
  [FRStatusCode.Pending]: 5,
  [FRStatusCode.Conflicted]: 4,
  [FRStatusCode.Activated]: 3.5,
  [FRStatusCode.ProvAuth]: 3,
  [FRStatusCode.Authorized]: 2,
  [FRStatusCode.Declared]: 1,
  [FRStatusCode.RNZAFAuthReq]: 0,
  [FRStatusCode.Declined]: 0,
  [FRStatusCode.Terminated]: 0,
  [FRStatusCode.Cancelled]: 0,
} as const;

const statusCode = (flight: FlightRequestViewModel, authorizer?: Authorizer) =>
  flight.status.find((x) => x.authorizer === authorizer?.name)?.code;

export const sortByStatus =
  (authorizer?: Authorizer) =>
  (a: FlightRequestViewModel, b: FlightRequestViewModel) => {
    if (authorizer) {
      const codeA = statusCode(a, authorizer);
      const codeB = statusCode(b, authorizer);

      if (codeA && codeB) {
        return statusViewOrder[codeA] - statusViewOrder[codeB];
      }
    }
    return 0;
  };

export const setMapHistoryHelper: (map: any) => void = (map) => {
  if (map) {
    const { zoom, center, styleCode } = map;

    return {
      zoom,
      lat: center.lat(),
      lng: center.lng(),
      stylecode: styleCode,
    };
  }
};

const compareMarkers = (
  originalMarker: FlightRequestMarker,
  newMarker: FlightRequestMarker
): boolean => {
  if (originalMarker.flight.flightId === newMarker.flight.flightId) {
    const result =
      originalMarker.appearanceTracker.valueOf() ===
        newMarker.appearanceTracker.valueOf() &&
      originalMarker.lastZoom === newMarker.lastZoom &&
      originalMarker.flight.status.every((x) =>
        newMarker.flight.status.find((y) => x.code === y.code)
      ) &&
      originalMarker.flight.requestToLand === newMarker.flight.requestToLand &&
      originalMarker.flight.requestsToPilot?.length ===
        newMarker.flight.requestsToPilot?.length;
    return result;
  }
  return false;
};

export const removedDroneRequestMarkers = (
  originalMarkers: FlightRequestMarker[],
  newMarkers: FlightRequestMarker[]
) =>
  originalMarkers.filter((o) => !newMarkers.find((n) => compareMarkers(o, n)));

export const newDroneRequestMarkers = (
  originalMarkers: FlightRequestMarker[],
  newMarkers: FlightRequestMarker[]
) =>
  newMarkers.filter((n) => !originalMarkers.find((o) => compareMarkers(o, n)));

export const normalizeLatLng = (
  anyLayLng?: google.maps.LatLng | google.maps.LatLngLiteral | null
): google.maps.LatLng | null => {
  if (!anyLayLng) return null;
  if (anyLayLng instanceof google.maps.LatLng) {
    return anyLayLng;
  }
  return new google.maps.LatLng(anyLayLng.lat, anyLayLng.lng);
};

const ACCEPTABLE_DISTANCE_APART = 0.00005;

export const latLngSamePlace = (
  p1?: google.maps.LatLng | null,
  p2?: google.maps.LatLng | null
): boolean =>
  !!p1 &&
  !!p2 &&
  Math.abs(p1.lat() - p2.lat()) < ACCEPTABLE_DISTANCE_APART &&
  Math.abs(p1.lng() - p2.lng()) < ACCEPTABLE_DISTANCE_APART;
