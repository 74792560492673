// eslint-disable-next-line import/no-unresolved
import * as core from 'express-serve-static-core';
import type { ObjectId } from 'mongodb';
import type { FeatureCollection, Polygon } from 'geojson';

import {
  CertificationType,
  OrganisationRef,
  UserStatus,
  PilotResponse as ExternalApiPilot,
} from '@airshare/external-api-types';

export { UserStatus };

import { Pilot } from './pilot';

export enum AirshareUserRole {
  PILOT = 'PILOT',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ORG_ADMIN = 'ORG_ADMIN',
  UAM_OPERATOR = 'UAM_OPERATOR',
  PRIORITY_OPERATOR = 'PRIORITY_OPERATOR',
  PRIVILEGED_VIEWER = 'PRIVILEGED_VIEWER',
}
export interface AirshareUserReqParam extends core.ParamsDictionary {
  id: string;
}

export interface AirshareUser {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  fullName: string;
  mobileNo: string;
  roles: AirshareUserRole[];
  organisations: OrganisationRef[];
  externalAccessOrgs: ObjectId[];
  passwordChanged: Date;
  dateOfBirth?: string;
  status: UserStatus;
  pilot?: Pilot;
  pilotId?: ObjectId;
  orgAdmin?: null | { organisation: string };
  superAdmin?: any;
  uamOperator?: any;
  priorityOperator?: any;
  viewer?: {
    areaOfVisibility: FeatureCollection<Polygon>;
  };
  rawData?: any;
}

export interface AirshareUsersPaged {
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  data: AirshareUser[];
}

export interface ProfileUser {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  fullName: string;
  mobileNo: string;
  roles: AirshareUserRole[];
  externalAccessOrgs: ObjectId[];
  passwordChanged: Date;
  dateOfBirth?: string;
  status: UserStatus;
  pilot: {
    result?: ExternalApiPilot;
    errors?: string[];
  };
  organisations: {
    name: string;
    defaultUav?: ObjectId;
    allowPilotsShareContactInfo: boolean;
  }[];
  orgAdmin?: any;
  superAdmin?: any;
  uamOperator?: any;
  priorityOperator?: any;
  priorityOperationLevel?: number;
  rawData?: any;
  company?: string;
  businessName?: string;
  certificationType?: CertificationType;
  certificationNumber?: string;
  photoUrl: string | null;
  areaOfVisibility?: FeatureCollection<Polygon>;
  registrationNo?: string;
}

export interface EndUserPolicyToAccept {
  policyId: string;
  policyName: string;
  url: string;
  changeDescription: string | null;
  version: number;
}

export interface EndUserPolicyAccepted {
  policyId: string;
  acceptedVersion: number;
}

export const MobileRegex = /^\+\d{1,3}(?!\s0)\s[\d\s.\-()]{4,20}$/;
