import { useDispatch, useSelector } from 'react-redux';
import {
  flightRequestsFetchFocussedRequested,
  flightRequestsUpdateDecisionRequested,
  flightRequestsClearFocussedError,
  flightRequestsClearFocussedFlight,
  DecisionPayload,
} from './actions';
import {
  getFocussedFlightRequest,
  getFocussedFetchStatus,
  getFocussedError,
  getFocussedSubmitter,
} from './selectors';

export function useFocussedFlightRequest(flightId: number) {
  const dispatch = useDispatch();
  const fetchStatus = useSelector(getFocussedFetchStatus);
  const focussedFlightRequest = useSelector(getFocussedFlightRequest);
  const focussedSubmitter = useSelector(getFocussedSubmitter);
  const updateDecisionRequest = ({
    atcNote,
    operatorNote,
    submitter,
    statusCode,
    changes,
  }: Omit<DecisionPayload, 'flightId'>) =>
    dispatch(
      flightRequestsUpdateDecisionRequested({
        atcNote,
        flightId,
        operatorNote,
        submitter,
        statusCode,
        changes,
      })
    );

  const error = useSelector(getFocussedError);
  const fetchFlightRequest = () =>
    dispatch(flightRequestsFetchFocussedRequested({ flightId }));
  const clearError = () => dispatch(flightRequestsClearFocussedError());
  const clearFlight = () => dispatch(flightRequestsClearFocussedFlight());

  return {
    focussedFlightRequest,
    focussedSubmitter,
    fetchFlightRequest,
    fetchStatus,
    updateDecisionRequest,
    error,
    clearError,
    clearFlight,
  };
}
