import { useDispatch, useSelector } from 'react-redux';
import { SuperAdminConsoleGlobalSettings } from 'fims-api-types';
import { getGlobalSettings } from './selectors';
import {
  fetchSettings,
  updateSetting,
  SettingsFetchSettingsAction,
  SettingsUpdateSettingsAction,
} from './actions';
import { DEFAULT_SETTINGS } from './constants';

export function useGlobalSettings(): [
  SuperAdminConsoleGlobalSettings,
  () => SettingsFetchSettingsAction,
  (
    section: keyof SuperAdminConsoleGlobalSettings,
    setting: string,
    value: any
  ) => SettingsUpdateSettingsAction,
] {
  const dispatch = useDispatch();

  const fetchGlobalSettings = () => dispatch(fetchSettings());
  const updateGlobalSetting = (
    section: keyof SuperAdminConsoleGlobalSettings,
    setting: string,
    value: string | number
  ) => dispatch(updateSetting({ section, setting, value }));
  const globalSettings = useSelector(getGlobalSettings);

  return [
    globalSettings || DEFAULT_SETTINGS,
    fetchGlobalSettings,
    updateGlobalSetting,
  ];
}
