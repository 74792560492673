import { useState, useEffect } from 'react';
import { LatLng } from 'fims-api-types';
import isEqual from 'lodash/isEqual';

export default function useCenterMap(
  center: LatLng,
  google: any,
  map: google.maps.Map | null
) {
  const [currentCenter, setCurrentCenter] = useState<LatLng | null>(null);

  useEffect(() => {
    if (!isEqual(center, currentCenter)) {
      setCurrentCenter(center);
    }
  }, [center, currentCenter]);

  useEffect(() => {
    if (currentCenter && google && map) {
      map.panTo(new google.maps.LatLng(currentCenter.lat, currentCenter.lng));
    }
  }, [currentCenter, google, map]);
}
