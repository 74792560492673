import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, type Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import {
  type ApproveSegmentsRequest,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
  type StyledFeature,
} from 'fims-api-types';

import GridTextItem from '../../../../shared/GridTextItem';
import GridSegmentStatusItem from '../../../../shared/GridSegmentStatusItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      position: 'relative',

      gridColumnEnd: 'auto',
      gridColumnStart: 'auto',
      minHeight: '2.4rem',
      padding: 'auto',

      textAlign: 'center',
      verticalAlign: 'middle',

      borderColor: theme.palette.common.black,
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    checkbox: {
      padding: '10px',
    },
    disabledCheckbox: {
      width: '100%',
      height: '100%',

      backgroundColor: grey[200],
    },
  })
);

const SegmentDetailRow = ({
  flightArea,
  approveSegments,
  setApproveSegments,
}: {
  flightArea: StyledFeature;
  approveSegments: ApproveSegmentsRequest;
  setApproveSegments: React.Dispatch<
    React.SetStateAction<ApproveSegmentsRequest>
  >;
}): JSX.Element => {
  const classes = useStyles({});

  const { properties } = flightArea;

  if (!properties.segmentId) {
    return null;
  }

  const onCheckboxChange = (segmentId: string, isChecked: boolean) => {
    return setApproveSegments((prevApproveSegments) => {
      const approveSegReq = prevApproveSegments?.find(
        (approveSegment) => approveSegment.segmentId === segmentId
      );

      if (approveSegReq) {
        approveSegReq.approve = isChecked;
      } else {
        prevApproveSegments.push({
          segmentId,
          approve: isChecked,
        });
      }

      return [...prevApproveSegments];
    });
  };

  const approveButtonIsDisabled =
    [SegmentStatusCode.TERMINATED, SegmentStatusCode.ACTIVATED].includes(
      properties?.status
    ) || properties.clearance == SegmentClearanceStatusCode.NOTREQUIRED;

  const approveIsChecked =
    approveSegments.find((x) => x.segmentId === properties.segmentId)
      ?.approve ?? properties.clearance === SegmentClearanceStatusCode.APPROVED;

  return (
    <>
      <GridTextItem text={properties.segmentId} />
      <GridSegmentStatusItem properties={properties} />

      <div className={classes.gridItem}>
        {approveButtonIsDisabled ? (
          <div
            data-testid={`approve-segments-dialog-${properties.segmentId}-activate-checkbox-disabled`}
            className={classes.disabledCheckbox}
          ></div>
        ) : (
          <Checkbox
            data-testid={`approve-segments-dialog-${properties.segmentId}-activate-checkbox`}
            checked={approveIsChecked}
            style={{
              transform: 'scale(1.6)',
            }}
            onChange={(e) =>
              onCheckboxChange(properties.segmentId, e.target.checked)
            }
            name="activate"
            color="primary"
            disabled={approveButtonIsDisabled}
            className={classes.checkbox}
          />
        )}
      </div>
    </>
  );
};

export default SegmentDetailRow;
