import { Schema } from 'mongoose';
import type {
  Feature,
  FeatureCollection,
  Geometry,
  GeoJsonProperties,
} from 'geojson';

export function generateFeatureSchema<
  G extends Geometry,
  P extends GeoJsonProperties,
>(
  geometrySchema: Schema<G>,
  propertiesSchema: Schema<P>
): Schema<Feature<G, P>> {
  return new Schema<Feature<G, P>>(
    {
      type: {
        type: String,
        enum: ['Feature'],
        required: true,
      },
      geometry: geometrySchema,
      properties: propertiesSchema,
    },
    { _id: false }
  );
}

export function generateFeatureCollectionSchema<
  G extends Geometry,
  P extends GeoJsonProperties,
>(
  geometrySchema: Schema<G>,
  propertiesSchema: Schema<P>
): Schema<FeatureCollection<G, P>> {
  const featureSchema = generateFeatureSchema<G, P>(
    geometrySchema,
    propertiesSchema
  );

  return new Schema<FeatureCollection<G, P>>(
    {
      type: {
        type: String,
        enum: ['FeatureCollection'],
        required: true,
      },
      features: {
        type: [featureSchema],
        default: [],
        required: true,
      },
    },
    { _id: false }
  );
}
