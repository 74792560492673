import { UserRole } from 'argus-common/enums';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';

export const SUPER_ADMIN_AUTHORIZED_TYPES = [
  ManagedAreaCode.Advisory,
  ManagedAreaCode.Aerodrome,
  ManagedAreaCode.NFZ,
  ManagedAreaCode.FlightPath,
  ManagedAreaCode.ExclusiveZone,
];

export const ADMIN_AUTHORIZED_TYPES = [
  ManagedAreaCode.Advisory,
  ManagedAreaCode.NFZ,
  ManagedAreaCode.FlightPath,
  ManagedAreaCode.ExclusiveZone,
];

export const FIMS_ONLY_AUTHORIZED_TYPES = [
  ManagedAreaCode.EmergencyAirspaceClosure,
];

export function getAuthorizedAirspaceTypesForRoles(
  roles: UserRole[]
): ManagedAreaCode[] {
  if (roles?.includes(UserRole.SUPER_ADMIN)) {
    return SUPER_ADMIN_AUTHORIZED_TYPES;
  }

  if (roles?.includes(UserRole.ADMIN)) {
    return ADMIN_AUTHORIZED_TYPES.concat(FIMS_ONLY_AUTHORIZED_TYPES);
  }

  if (roles?.includes(UserRole.AUTHORISER)) {
    return [
      ManagedAreaCode.FlightPath,
      ManagedAreaCode.ExclusiveZone,
      ManagedAreaCode.EmergencyAirspaceClosure,
    ];
  }

  return [];
}

export const isAirspaceTypeAuthorizedForRole = (
  areaType: ManagedAreaCode,
  roles: UserRole[] = []
) =>
  Boolean(
    getAuthorizedAirspaceTypesForRoles(roles).find((x) => x === areaType)
  );
