import { Schema, Document } from 'mongoose';
import type { FeatureCollection } from 'geojson';

import { toJsonOptions, Timestamps } from '../../lib/shared-schema-options';
import { generateGeometrySchema, Area, AreaTypes } from './geojson/geometry';
import { generateFeatureCollectionSchema } from './geojson/feature';

const areaSchema = generateGeometrySchema<Area>(AreaTypes);
const anyPropetiesSchema = new Schema<Object>({
  type: Schema.Types.Mixed,
});
const areaOfResponsibilitySchema = generateFeatureCollectionSchema(
  areaSchema,
  anyPropetiesSchema
);

export enum AuthorizerType {
  ControlZone = 'Control Zone',
  Aerodrome = 'Aerodrome',
  AirspaceOwner = 'Airspace Owner',
}

export type AreasOfResponsibility = FeatureCollection<Area>;
export interface Authorizer {
  name: string;
  type: AuthorizerType;
  areasOfResponsibility?: AreasOfResponsibility;
}

export interface AuthorizerSchema extends Authorizer, Document, Timestamps {}

export const authorizerSchema = new Schema<AuthorizerSchema>(
  {
    name: {
      type: String,
      required: true,
      trim: true,
      unique: true,
    },
    type: {
      type: String,
      enum: Object.values(AuthorizerType),
      required: true,
    },
    areasOfResponsibility: {
      type: areaOfResponsibilitySchema,
      required() {
        return [
          AuthorizerType.Aerodrome,
          AuthorizerType.AirspaceOwner,
        ].includes(this.type as AuthorizerType);
      },
    },
    createdAt: {
      type: Schema.Types.Date,
      required: false,
    },
    updatedAt: {
      type: Schema.Types.Date,
      required: false,
    },
  },
  { timestamps: true }
);

authorizerSchema.set('toJSON', toJsonOptions);
