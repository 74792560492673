import { useDispatch, useSelector } from 'react-redux';
import { ConflictGroup } from 'fims-api-types';
import {
  ConflictedFlightsFetchRequestedAction,
  ConflictedFlightsStartedPollingAction,
  ConflictedFlightsStoppedPollingAction,
  conflictedFlightsFetchRequested,
  conflictedFlightsPollingStarted,
  conflictedFlightsPollingStopped,
} from './actions';
import { getConflictedFlights } from './selectors';

export function useConflictedFlights(): [
  ConflictGroup[],
  () => ConflictedFlightsFetchRequestedAction,
  () => ConflictedFlightsStartedPollingAction,
  () => ConflictedFlightsStoppedPollingAction,
] {
  const dispatch = useDispatch();

  const conflictedFlights = useSelector(getConflictedFlights);

  const fetchConflictedFlights = () =>
    dispatch(conflictedFlightsFetchRequested());

  const startPollingConflictedFlights = () =>
    dispatch(conflictedFlightsPollingStarted());
  const stopPollingConflictedFlights = () =>
    dispatch(conflictedFlightsPollingStopped());

  return [
    conflictedFlights,
    fetchConflictedFlights,
    startPollingConflictedFlights,
    stopPollingConflictedFlights,
  ];
}
