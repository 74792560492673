import { takeLatest, call, put } from 'redux-saga/effects';

import { UserViewModel } from 'fims-api-types';
import { fimsAPI } from '~/clients/api';
import {
  AdminActionType,
  AdminAddUserRequestedAction,
  AdminUpdateUserRequestedAction,
  adminFetchUsersSucceeded,
  adminFetchUsersFailed,
  adminAddUserSucceeded,
  adminAddUserFailed,
  adminUpdateUserSucceeded,
  adminUpdateUserFailed,
  adminFetchAdminDefaultsSucceeded,
  adminFetchAdminDefaultsFailed,
} from './actions';

interface UsersResponse {
  data: UserViewModel[];
}

function* fetchUsers() {
  try {
    const { data }: UsersResponse = yield call(fimsAPI.get, '/user');

    yield put(adminFetchUsersSucceeded(data));
  } catch (error) {
    const message =
      error.response.data.message || 'We could not retrieve users';
    yield put(adminFetchUsersFailed(message));
  }
}

function* addUser({ payload }: AdminAddUserRequestedAction) {
  try {
    const { data }: UsersResponse = yield call(fimsAPI.post, '/user', payload);

    yield put(adminAddUserSucceeded(data));
  } catch (error) {
    const message = error.response.data.message || 'We could not add user';
    yield put(adminAddUserFailed(message));
  }
}

function* updateUser({ payload }: AdminUpdateUserRequestedAction) {
  try {
    const { data }: UsersResponse = yield call(fimsAPI.put, '/user', payload);

    yield put(adminUpdateUserSucceeded(data));
  } catch (error) {
    const message = error.response.data.message || 'We could not update user';
    yield put(adminUpdateUserFailed(message));
  }
}

function* fetchAdminDefaults() {
  try {
    const { data } = yield call(fimsAPI.get, '/admin-defaults');

    yield put(adminFetchAdminDefaultsSucceeded(data));
  } catch (error) {
    const message =
      error.response.data.message || 'We could not retrieve admin defaults';
    yield put(adminFetchAdminDefaultsFailed(message));
  }
}

export function* adminSaga() {
  yield takeLatest(AdminActionType.ADD_USER_REQUESTED, addUser);
  yield takeLatest(
    AdminActionType.FETCH_ADMIN_DEFAULTS_REQUESTED,
    fetchAdminDefaults
  );
  yield takeLatest(AdminActionType.FETCH_USERS_REQUESTED, fetchUsers);
  yield takeLatest(AdminActionType.UPDATE_USER_REQUESTED, updateUser);
}
