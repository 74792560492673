import React from 'react';
import ReactNumericInput from 'react-numeric-input';

import './numeric-input.scss';

interface NumericInputProps {
  value: number;
  onChange: (
    value: number | null,
    stringValue: string,
    input: HTMLInputElement
  ) => void;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  min: number;
  max: number;
  disabled?: boolean;
}

function NumericInput({
  value,
  onChange,
  onBlur,
  min,
  max,
  disabled,
}: NumericInputProps) {
  return (
    <ReactNumericInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      min={min}
      max={max}
      disabled={disabled}
      strict
    />
  );
}

export default NumericInput;
