import { createAction } from 'airshare-web-utils/redux-helpers';
import { UserRole, UserStatus } from 'argus-common/enums';
import {
  UserCreationForm,
  UserUpdateForm,
  UserViewModel,
} from 'fims-api-types';

export enum AdminActionType {
  FETCH_USERS_REQUESTED = 'admin/FETCH_USERS_REQUESTED',
  FETCH_USERS_SUCCEEDED = 'admin/FETCH_USERS_SUCCEEDED',
  FETCH_USERS_FAILED = 'admin/FETCH_USERS_FAILED',

  ADD_USER_REQUESTED = 'admin/ADD_USER_REQUESTED',
  ADD_USER_SUCCEEDED = 'admin/ADD_USER_SUCCEEDED',
  ADD_USER_FAILED = 'admin/ADD_USER_FAILED',

  UPDATE_USER_REQUESTED = 'admin/UPDATE_USER_REQUESTED',
  UPDATE_USER_SUCCEEDED = 'admin/UPDATE_USER_SUCCEEDED',
  UPDATE_USER_FAILED = 'admin/UPDATE_USER_FAILED',

  FETCH_ADMIN_DEFAULTS_REQUESTED = 'admin/FETCH_ADMIN_DEFAULTS_REQUESTED',
  FETCH_ADMIN_DEFAULTS_SUCCEEDED = 'admin/FETCH_ADMIN_DEFAULTS_SUCCEEDED',
  FETCH_ADMIN_DEFAULTS_FAILED = 'admin/FETCH_ADMIN_DEFAULTS_FAILED',

  CLEAR_ERROR = 'admin/CLEAR_ERROR',
  CLEAR_USER_ERROR = 'admin/CLEAR_USER_ERROR',
}

export interface AdminFetchUsersRequestedAction {
  type: AdminActionType.FETCH_USERS_REQUESTED;
}
export interface AdminFetchUsersSucceededAction {
  type: AdminActionType.FETCH_USERS_SUCCEEDED;
  payload: UserViewModel;
}
export interface AdminFetchUsersFailedAction {
  type: AdminActionType.FETCH_USERS_FAILED;
  payload: string;
}
export interface AdminAddUserRequestedAction {
  type: AdminActionType.ADD_USER_REQUESTED;
  payload: UserCreationForm;
}
export interface AdminAddUserSucceededAction {
  type: AdminActionType.ADD_USER_SUCCEEDED;
  payload: UserViewModel;
}
export interface AdminAddUserFailedAction {
  type: AdminActionType.ADD_USER_FAILED;
  payload: string;
}
export interface AdminUpdateUserRequestedAction {
  type: AdminActionType.UPDATE_USER_REQUESTED;
  payload: UserUpdateForm;
}
export interface AdminUpdateUserSucceededAction {
  type: AdminActionType.UPDATE_USER_SUCCEEDED;
  payload: UserViewModel;
}
export interface AdminUpdateUserFailedAction {
  type: AdminActionType.UPDATE_USER_FAILED;
  payload: string;
}
export interface AdminFetchAdminDefaultsRequestedAction {
  type: AdminActionType.FETCH_ADMIN_DEFAULTS_REQUESTED;
}
export interface AdminFetchAdminDefaultsSucceededAction {
  type: AdminActionType.FETCH_ADMIN_DEFAULTS_SUCCEEDED;
  payload: {
    userRole: UserRole[];
    userStatus: UserStatus[];
  };
}
export interface AdminFetchAdminDefaultsFailedAction {
  type: AdminActionType.FETCH_ADMIN_DEFAULTS_FAILED;
  payload: string;
}

export interface AdminClearErrorAction {
  type: AdminActionType.CLEAR_ERROR;
}

export interface AdminClearUserErrorAction {
  type: AdminActionType.CLEAR_USER_ERROR;
}

export const adminFetchUsersRequested: () => AdminFetchUsersRequestedAction =
  createAction(AdminActionType.FETCH_USERS_REQUESTED);
export const adminFetchUsersSucceeded = createAction<
  UserViewModel[],
  AdminActionType.FETCH_USERS_SUCCEEDED
>(AdminActionType.FETCH_USERS_SUCCEEDED);
export const adminFetchUsersFailed: (
  payload: string
) => AdminFetchUsersFailedAction = createAction(
  AdminActionType.FETCH_USERS_FAILED
);

export const adminAddUserRequested: (
  user: UserCreationForm
) => AdminAddUserRequestedAction = createAction(
  AdminActionType.ADD_USER_REQUESTED
);
export const adminAddUserSucceeded = createAction<
  UserViewModel[],
  AdminActionType.ADD_USER_SUCCEEDED
>(AdminActionType.ADD_USER_SUCCEEDED);
export const adminAddUserFailed: (payload: string) => AdminAddUserFailedAction =
  createAction(AdminActionType.ADD_USER_FAILED);

export const adminUpdateUserRequested: (
  user: UserUpdateForm
) => AdminUpdateUserRequestedAction = createAction(
  AdminActionType.UPDATE_USER_REQUESTED
);
export const adminUpdateUserSucceeded = createAction<
  UserViewModel[],
  AdminActionType.UPDATE_USER_SUCCEEDED
>(AdminActionType.UPDATE_USER_SUCCEEDED);
export const adminUpdateUserFailed: (
  payload: string
) => AdminUpdateUserFailedAction = createAction(
  AdminActionType.UPDATE_USER_FAILED
);

export const adminFetchAdminDefaultsRequested: () => AdminFetchAdminDefaultsRequestedAction =
  createAction(AdminActionType.FETCH_ADMIN_DEFAULTS_REQUESTED);
export const adminFetchAdminDefaultsSucceeded: (payload: {
  userRole: UserRole[];
  userStatus: UserStatus[];
}) => AdminFetchAdminDefaultsSucceededAction = createAction(
  AdminActionType.FETCH_ADMIN_DEFAULTS_SUCCEEDED
);
export const adminFetchAdminDefaultsFailed: (
  payload: string
) => AdminFetchAdminDefaultsFailedAction = createAction(
  AdminActionType.FETCH_ADMIN_DEFAULTS_FAILED
);

export const adminClearError: () => AdminClearErrorAction = createAction(
  AdminActionType.CLEAR_ERROR
);
export const adminClearUserError: () => AdminClearUserErrorAction =
  createAction(AdminActionType.CLEAR_USER_ERROR);
