import { TableHeading } from '~/lib/table';

export const TABLE_HEADINGS: TableHeading[] = [
  {
    label: 'Ref',
    sortKey: 'flightId',
    align: 'left',
  },
  {
    label: 'Pilot',
    sortKey: 'givenName',
    align: 'left',
  },
  {
    label: 'Shielded',
    sortKey: 'isShielded',
    align: 'left',
  },
  {
    label: 'Control Zone',
    sortKey: 'controlZone',
    align: 'left',
  },
  {
    label: 'Start Date',
    sortKey: 'startDate',
    align: 'left',
  },
  {
    label: 'End Date',
    sortKey: 'endDate',
    align: 'left',
  },
  {
    label: 'Status',
    sortKey: 'status',
    align: 'center',
  },
];
