export type { SuperAdminConsoleGlobalSettings } from 'argus-data-model/db/schemas/super-admin-console-global-settings';
export type { AircraftTelemetryWithAlerts as SurveillanceViewModel } from 'argus-events/interfaces';
export * from './activation';
export * from './notifications';
export * from './interfaces';
export * from './organisation-interfaces';
export * from './status-color';
export * from './segment-status';
export * from './default-options';
export {
  Area,
  AuthChangeField,
  AuthChangeFieldClient,
  CertificationType,
  CtrSettings,
  ExponentUser,
  FlightSegmentProperties,
  FRStatusCode,
  AltitudeType,
  LatLng,
  SegmentStatusCode,
  SegmentClearanceStatusCode,
  Status,
  StatusUpdateUser,
  RequiredAuthChangeSubmission,
  RequiredAuthChange,
  UserRole,
  UNIVERSAL_TIME_FORMAT,
  UNIVERSAL_DATE_FORMAT,
} from '@airshare/external-api-types';
export * from '@airshare/external-api-types/flight-requests/audit-record-context-types';

import { formatStatus as formatStatusGeneric } from 'airspace-manager-common/status/multi-status-check';
export const formatStatus = (s: string) => formatStatusGeneric(s, true);

export {
  resolveSingleStatus,
  getConsolidatedStatus,
} from 'airspace-manager-common/status/multi-status-check';

export { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
export { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';
export {
  SessionDetails,
  RequestWithSession,
  ErrorResponseBody,
  Profile,
} from 'argus-common/interfaces';
export { AircraftType } from 'argus-common/enums';
