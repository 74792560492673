import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    onWatch: {
      color: 'inherit',
    },
    offWatch: {
      color: 'rgba(0,0,0,0.3)',
    },
    content: {
      padding: '0 0.8rem 0.4rem',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '0.5rem',
    },
    secondaryButton: {
      marginRight: '0.5rem',
    },
  })
);
