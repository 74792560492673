import React, { useEffect, useRef, useCallback, useContext } from 'react';
import type { Polygon, MultiPolygon } from '@turf/helpers';
import { type Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { MapControls } from 'asm-web-components';
import {
  DetailedFlightRequestViewModelV2,
  LatLng,
  ManagedAreaCode,
  OrganisationSettingsResponseBody,
  SurveillanceViewModel,
  StyledFeatures,
} from 'fims-api-types';

import {
  useAirspaceLayer,
  useMapHandlers,
  useMapLayers,
  useMapPoints,
  useNoaLayer,
  useFlightAreaLayer,
  useMapSurveillance,
  useMapStyleChange,
  useMap,
  useZoom,
  useConformanceMonitoring,
} from '../../../hooks';
import useShowCenterMarker from './hooks/show-center-marker.hook';
import useZoomOnFeature from './hooks/zoom-on-feature.hook';
import useCenterMap from './hooks/center-map.hook';

import { MapHelpers } from '../../../components/protected-layout/home-screen/map/hooks/helpers';
import { getMapStylesByCode } from '../../../state/map-styles';

import { LoginContext } from '../../../context/LoginState';

export interface Props {
  settings: OrganisationSettingsResponseBody;
  center?: LatLng;
  showCenter?: boolean;
  airspace?: Polygon | MultiPolygon | null;
  advisoryType?: ManagedAreaCode | null;
  initialZoom?: number;
  flightAreas: StyledFeatures;
  surveillanceData: SurveillanceViewModel[];
  flightRequest?: DetailedFlightRequestViewModelV2;
  flightMarkerAction?: (id: string) => void;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    detailMap: {
      position: 'relative',
      width: '100%',
      height: 'calc(100vh - 82px - var(--header-height))',
      minHeight: '240px',
    },
    container: {
      height: '100%',
    },
  })
);

export default function DetailMap({
  settings,
  center = {
    // Wellington Central
    lat: Number.parseFloat(window.env.INITIAL_LAT) || -41.2864603,
    lng: Number.parseFloat(window.env.INITIAL_LNG) || 174.77623600000004,
  },
  showCenter = false,
  airspace,
  advisoryType,
  flightAreas = [],
  surveillanceData,
  flightRequest,
  flightMarkerAction,
}: Props) {
  const google = window.google;

  const { mapHelpers, setMapHelpers, mapStyleCode } = useContext(LoginContext);

  const classes = useStyles();

  const initialZoom = settings?.mapDefaults?.focusedZoom ?? null;

  const defaultMapOptions = {
    center,
    onReady: handleMapReady,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    ...getMapStylesByCode(mapStyleCode),
  };

  const mapRef = useRef();
  const [map, rerenderMap] = useMap(google, mapRef, defaultMapOptions);

  useFlightAreaLayer(map, flightAreas, flightMarkerAction);

  const [, { handleInitialInteraction }] = useMapHandlers(map, mapHelpers);
  const [{ zoom }, { handleMapZoom, manualSetZoom, setZoom }] = useZoom(
    map,
    initialZoom
  );
  useZoomOnFeature(zoom, initialZoom, manualSetZoom, map, {
    airspace,
  });

  const [, handleMapStyleChange] = useMapStyleChange(
    map,
    initialZoom,
    handleMapReady,
    rerenderMap,
    mapStyleCode
  );

  useMapSurveillance(
    google,
    map,
    surveillanceData,
    zoom,
    {
      setup: () => {},
      supportedTypes: [],
    },
    useCallback(() => {}, [])
  );

  useConformanceMonitoring(
    surveillanceData,
    flightRequest
      ? [
          {
            flightId: flightRequest.flightId,
            flightAreas: flightRequest.flightAreas,
            geometry: flightRequest.geometry,
          },
        ]
      : [],
    map,
    google
  );

  useMapLayers(google, map, zoom);
  useNoaLayer(google, map);
  useMapPoints(google, map, zoom);
  useAirspaceLayer(google, map, advisoryType || null, airspace);
  useCenterMap(center, google, map);
  useShowCenterMarker(center, showCenter, google, map);
  useEffect(() => () => setMapHelpers(null), [setMapHelpers]);

  function handleMapReady() {
    setMapHelpers(new MapHelpers(google, map));

    handleInitialInteraction(initialZoom);

    google.maps.event.addListener(map, 'zoom_changed', () => {
      setZoom(map.getZoom());
    });
  }

  return (
    <div className={classes.detailMap} data-testid="detail-map">
      <div className={classes.container} ref={mapRef} />
      <MapControls
        handleMapStyleChange={handleMapStyleChange}
        handleMapZoomIn={() => handleMapZoom(1)}
        handleMapZoomOut={() => handleMapZoom(-1)}
      />
    </div>
  );
}
