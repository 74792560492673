import { useEffect, useState, useCallback } from 'react';
import { SurveillanceViewModel } from 'fims-api-types';
import { AlertStatus } from 'argus-common/enums';
import { getHighestAlertStatus } from 'argus-common/helpers';

export function useSurveillanceInfoWindow(
  mapInstance: any,
  surveillanceData: SurveillanceViewModel[]
) {
  const [infoWindow, setInfoWindow] = useState(null);
  const [focussedFlight, setFocussedFlight] = useState(null);
  const [previousFocussedFlight, setPreviousFocussedFlight] = useState(null);
  const [_updatedFlight, setUpdatedFlight] = useState(null);

  useEffect(() => {
    if (mapInstance) {
      const infoWindowInstance = new google.maps.InfoWindow();
      setInfoWindow(infoWindowInstance);

      mapInstance.addListener('click', () => {
        infoWindowInstance.close();
      });
    }
  }, [mapInstance]);

  const setUpdatedFlightCallback = useCallback(() => {
    if (focussedFlight) {
      setPreviousFocussedFlight(focussedFlight);

      const setUpdatedFunc = (flight: SurveillanceViewModel) => {
        setUpdatedFlight(flight);
      };
      const setFocussedFlightFunc = (
        flight: SurveillanceViewModel,
        status: AlertStatus
      ) => {
        setFocussedFlight({ ...flight, status });
      };
      const newUpdatedFlight = surveillanceData.find(
        ({ flightId }) => focussedFlight?.flightId === flightId
      );

      const status = getHighestAlertStatus([
        newUpdatedFlight.alertConformanceStatus,
        newUpdatedFlight.alertProximityStatus,
      ]);

      setUpdatedFunc(newUpdatedFlight);
      setFocussedFlightFunc(newUpdatedFlight, status);

      infoWindow.setContent(
        getInfoWindowContent({ ...newUpdatedFlight, status })
      );
    } else {
      setFocussedFlight(null);
      if (infoWindow) infoWindow.close();
    }
  }, [surveillanceData, focussedFlight, infoWindow]);

  useEffect(() => {
    if (
      infoWindow &&
      focussedFlight &&
      previousFocussedFlight?.flightId !== focussedFlight?.flightId
    ) {
      setUpdatedFlightCallback();
    }
  }, [
    infoWindow,
    setUpdatedFlightCallback,
    focussedFlight,
    previousFocussedFlight,
  ]);

  function setup(
    surveillanceItem: SurveillanceViewModel,
    marker: any,
    status: AlertStatus
  ) {
    google.maps.event.clearInstanceListeners(marker);
    marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.setContent(
        getInfoWindowContent({ ...surveillanceItem, status })
      );
      infoWindow.open(mapInstance, marker);

      setFocussedFlight({ ...surveillanceItem, status });
    });
  }

  function close() {
    if (infoWindow) infoWindow.close();
  }

  return { setup, close };
}

function getInfoWindowContent({
  flightId,
  lat,
  lng,
  altitude,
  heading,
  status,
}: SurveillanceViewModel & { status: AlertStatus }) {
  return `<div class="flight-info-window">
      <h1 class="flight-info-window__heading"><b>Flight: ${flightId}</b></h1>
      <div class="flight-info-window__content">
        <p>Lat: ${getFormattedInfoValue(lat)}</p>
        <p>Lng: ${getFormattedInfoValue(lng)}</p>
        <p>Altitude: ${getFormattedInfoValue(altitude)}</p>
        <p>Heading: ${getFormattedInfoValue(heading)}</p>
        <p>Status: ${status}</p>
      </div>
    </div>`;
}

function getFormattedInfoValue(value: number) {
  return value && Number(value) ? Number(value).toFixed(3) : 'N/A';
}
