import React, { useState, useEffect } from 'react';
import FlowBuilder, {
  INode,
  IRegisterNode,
  IZoomToolConfig,
} from 'react-flow-builder';

import ErrorMessage from '../../shared/error-message/error-message';
import * as fimsClient from '~/clients/fims-api-client';

import './rules-engine-flowchart.scss';

import ConverterLib from './rules-engine-flowchart.lib';
import Components from './rules-engine-flowchart.components';

const registerNodes: IRegisterNode[] = [
  {
    type: ConverterLib.NodeTypes.START,
    name: 'start node',
    displayComponent: Components.StartNodeDisplay,
    isStart: true,
  },
  {
    type: ConverterLib.NodeTypes.END,
    name: 'end node',
    displayComponent: Components.EndNodeDisplay,
    isEnd: true,
  },
  {
    type: ConverterLib.NodeTypes.QUERY,
    name: 'query node',
    displayComponent: Components.QueryNodeDisplay,
  },
  {
    type: ConverterLib.NodeTypes.ACTION,
    name: 'action node',
    displayComponent: Components.ActionNodeDisplay,
  },
  {
    type: ConverterLib.NodeTypes.QUERY_RESULT,
    name: 'condition node',
    displayComponent: Components.QueryResultNodeDisplay,
  },
  {
    type: ConverterLib.NodeTypes.BRANCH,
    name: 'branch node',
    conditionNodeType: ConverterLib.NodeTypes.QUERY_RESULT,
  },
];

const zoomProps: IZoomToolConfig = {
  hidden: false,
  initialValue: 80,
};

const RulesEngineFlowchart = () => {
  const [nodes, setNodes] = useState<INode[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  async function getRulesConfigs() {
    try {
      const data = await fimsClient.getRulesConfigs();
      const primaryRule = data.find(
        (rule) => rule.code === 'PROCESS_NEW_FLIGHT_REQUEST'
      );
      if (!primaryRule) {
        setErrorMessage('Could not find primary rule');
      } else {
        setNodes(ConverterLib.convertToNodes(primaryRule));
      }
    } catch (e) {
      setErrorMessage(JSON.stringify(e));
    }
  }

  useEffect(() => {
    getRulesConfigs();
  }, []);

  return errorMessage ? (
    <ErrorMessage main="Error" sub={errorMessage} />
  ) : (
    <FlowBuilder
      nodes={nodes}
      registerNodes={registerNodes}
      onChange={() => null}
      readonly={true}
      zoomTool={zoomProps}
    />
  );
};

export default RulesEngineFlowchart;
