import { MapPointCode } from '.';
import { MapCodeType } from '../managed-areas/map-areas';

const category = 'council';

export const POINT_MIN_ZOOM = 13;

const mapPointsInfo = {
  category,
  minZoom: POINT_MIN_ZOOM,
  mapZoom: POINT_MIN_ZOOM,
  isMapLayer: true,
  pilotVisible: true,
  atcVisible: false,
  geoQueryInclude: true,
  strokeColor: '#551A8B',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#551A8B',
  fillOpacity: 0.2,
};

type MapCodeStyling = {
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
  category: string;
  logo: {
    category: string;
    code: string;
  };
};

type MapCodeContact = {
  policies: string[];
};

type MapCodeBasicInfo = {
  mapCode: MapCodeType;
  isMapLayer: boolean;
  geoQueryInclude: boolean;
  pilotVisible: boolean;
  atcVisible: boolean;
  minZoom: number;
  mapZoom: number;
};

type MapCodeInfo = MapCodeStyling & MapCodeContact & MapCodeBasicInfo;

const hangGlidingInfo: MapCodeInfo = {
  ...mapPointsInfo,
  mapCode: MapPointCode.HangGliding,
  policies: [
    'All drone operators must follow Civil Aviation Authority safety guidelines.',
  ],
  logo: {
    category,
    code: MapPointCode.HangGliding,
  },
};

const parachutingInfo: MapCodeInfo = {
  ...mapPointsInfo,
  mapCode: MapPointCode.Parachuting,
  policies: [
    'Caution, this area is used for parachute landings. Maintain awareness of possible parachute operations and give way at all times.',
  ],
  logo: {
    category,
    code: MapPointCode.Parachuting,
  },
};

export function getMapPropertyInfo(type: MapPointCode): MapCodeInfo {
  switch (type) {
    case MapPointCode.HangGliding:
      return hangGlidingInfo;
    case MapPointCode.Parachuting:
      return parachutingInfo;
    default:
      return hangGlidingInfo;
  }
}
