import { FRStatusCode, FlightRequestViewModel } from 'fims-api-types';
import { Authorizer } from 'argus-data-model/db/schemas/authorizers';

import { MapView24Hour } from '../../../../../context/LoginState';
import { filterFlightStatusesTime } from '../../flights-today-filters';

export const isCurrent = (
  flight: FlightRequestViewModel,
  authorizer: Authorizer
): boolean => {
  const statuses = [
    FRStatusCode.ProvAuth,
    FRStatusCode.Authorized,
    FRStatusCode.Activated,
  ];

  return filterFlightStatusesTime(
    flight,
    authorizer,
    MapView24Hour.CURRENT,
    statuses
  );
};

export const isToday = (
  flight: FlightRequestViewModel,
  authorizer: Authorizer
): boolean => {
  const statuses = [
    FRStatusCode.Pending,
    FRStatusCode.ProvAuth,
    FRStatusCode.Authorized,
    FRStatusCode.Activated,
  ];
  return filterFlightStatusesTime(
    flight,
    authorizer,
    MapView24Hour.TODAY,
    statuses
  );
};

export const isNext24 = (
  flight: FlightRequestViewModel,
  authorizer: Authorizer
): boolean => {
  const statuses = [
    FRStatusCode.Pending,
    FRStatusCode.ProvAuth,
    FRStatusCode.Authorized,
    FRStatusCode.Activated,
  ];
  return filterFlightStatusesTime(
    flight,
    authorizer,
    MapView24Hour.NEXT24,
    statuses
  );
};
