import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import './map-controls.scss';

const useStyles = makeStyles((_: Theme) => {
  const button = {
    cursor: 'pointer',
    width: '3rem',
    height: '2.5rem',
    padding: 0,
    backgroundColor: '#FFFFFF',
    border: 0,
  };

  const icon = {
    color: '#9DA7C0',
    top: '-1px',

    width: '100%',
    height: '70%',
  };

  return createStyles({
    locateButton: {
      ...button,
      width: '2.5rem',
      height: '2.5rem',
      marginBottom: '0.5rem',
      padding: 0,
      border: 0,
      borderRadius: '1.25rem',
    },
    mapZoomInButton: {
      ...button,
      borderRadius: '0 1.25rem 1.25rem 0',
    },
    mapZoomOutButton: {
      ...button,
      borderRadius: '1.25rem 0 0 1.25rem',
    },
    icon,
    iconZoomIn: {
      ...icon,
      borderLeft: '2px solid',
    },
    iconZoomOut: {
      ...icon,
      borderRight: '2px solid',
    },
  });
});

interface MapControlsProps {
  handleMapStyleChange(): void;
  handleCenterPositionClick?(): void;
  handleMapZoomOut(): void;
  handleMapZoomIn(): void;
}

function MapControls({
  handleMapStyleChange,
  handleCenterPositionClick,
  handleMapZoomOut,
  handleMapZoomIn,
}: MapControlsProps) {
  const classes = useStyles();

  return (
    <div className="map-controls-container">
      <button
        className="map-style-button"
        type="button"
        onClick={handleMapStyleChange}
        data-testid="map-style-button"
      >
        <i className="icon globe" />
      </button>
      {handleCenterPositionClick && (
        <button
          className={classes.locateButton}
          type="button"
          onClick={handleCenterPositionClick}
          data-testid="map-current-location-button"
        >
          <MyLocationIcon className={classes.icon} />
        </button>
      )}
      <div className="map-zoom-button-container">
        <button
          className={classes.mapZoomOutButton}
          type="button"
          onClick={handleMapZoomOut}
          data-testid="map-zoom-minus-button"
        >
          <ZoomOutIcon className={classes.iconZoomOut} />
        </button>
        <button
          className={classes.mapZoomInButton}
          type="button"
          onClick={handleMapZoomIn}
          data-testid="map-zoom-plus-button"
        >
          <ZoomInIcon className={classes.iconZoomIn} />
        </button>
      </div>
    </div>
  );
}

export default MapControls;
