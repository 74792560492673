import { AlertStatus } from '@airshare/messaging-types';

export function getHighestAlertStatus(
  alertStatuses: AlertStatus[]
): AlertStatus {
  if (alertStatuses.includes(AlertStatus.Breach)) {
    return AlertStatus.Breach;
  }

  if (alertStatuses.includes(AlertStatus.Alert)) {
    return AlertStatus.Alert;
  }

  return AlertStatus.SafeOps;
}
