import React from 'react';
import { DateTime } from 'luxon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FlightsControlled as FlightsControlledType } from '../../../clients/fims-api-client';

const FlightsControlled = ({
  className,
  data,
}: {
  className: string;
  data: FlightsControlledType;
}) => (
  <Table
    className={className}
    data-testid="airshare-flights-requests-control-zone-vs-outside-table"
  >
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell align="right">Week Beginning</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell>Registration Type</TableCell>
        {data.controlled?.weeks.map(({ startDate }) => (
          <TableCell
            align="right"
            key={DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          >
            {DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          </TableCell>
        ))}
        <TableCell align="right">Total</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>Controlled</TableCell>
        {data.controlled?.weeks.map(({ value, startDate }) => (
          <TableCell
            key={`controlled-${DateTime.fromISO(startDate).toFormat(
              'dd-MMM-yyyy'
            )}`}
            align="right"
          >
            {value}
          </TableCell>
        ))}
        <TableCell align="right" data-testid="controlled-count">
          {data.controlled?.total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Uncontrolled</TableCell>
        {data.uncontrolled?.weeks.map(({ value, startDate }) => (
          <TableCell
            key={`uncontrolled-${DateTime.fromISO(startDate).toFormat(
              'dd-MMM-yyyy'
            )}`}
            align="right"
          >
            {value}
          </TableCell>
        ))}
        <TableCell align="right" data-testid="uncontrolled-count">
          {data.uncontrolled?.total}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default FlightsControlled;
