import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { UserRole, UserStatus } from 'argus-common/enums';
import {
  UserViewModel,
  useAdmin,
  UserUpdateForm,
  UserCreationForm,
} from '~/state/admin';
import {
  CtrSettings as CtrZoneSettings,
  OrganisationsResponseBody,
} from 'fims-api-types';
import { AxiosResponse } from 'axios';
import zxcvbn from 'zxcvbn';

import { useIsSuperAdmin } from '~/state/session/hooks';

import * as fimsClient from '~/clients/fims-api-client';

import ErrorMessage from '~/components/shared/error-message/error-message';
import { AuthorizerResponse } from '~/clients/fims-api-client';
import { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';
import { IconButton, InputAdornment, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

enum SubmitType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

type ErrorsType = {
  [key: string]: string;
};

interface AdminModalProps {
  adminDefaults: any;
  closeModal(): any;
  selectedUser: UserViewModel;
}

interface SubmitEvent extends Event {
  submitter: {
    value: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'grid',
      width: 400,
    },
    input: {
      margin: `${theme.spacing(1)}px 0`,
    },
    toggleButton: {
      width: 150,
      padding: theme.spacing(1),
      backgroundColor: 'whitesmoke',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&:first-child': {
        borderRadius: `${theme.spacing(1)}px 0 0 ${theme.spacing(1)}px`,
      },
      '&:last-child': {
        borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
      },
    },
    toggleButtonGroup: {
      width: '100%',
      justifyContent: 'center',
    },
  })
);

const ipAddressRegex =
  /^((?:\b(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[0-9A-Fa-f:]+)\b|\b(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[0-9A-Fa-f:]+)\/\d{1,3}\b)(?:,\s*|$))+$/;
const ipAddressErrorMessage =
  'Invalid IP address(es). Must be comma separated IP addresses or CIDRs.';

const addUserSchema = yup.object().shape(
  {
    email: yup.string().email().required('Required'),
    password: yup
      .string()
      .min(8, 'A minimum of 8 characters is required')
      .required('Required')
      .when(['password', 'role'], (password, role) => {
        const passwordTest = zxcvbn(password);
        const minScore = role === UserRole.SUPER_ADMIN ? 3 : 2;
        return passwordTest.score >= minScore
          ? yup.string().required('Required')
          : yup
              .string()
              .oneOf(
                [],
                passwordTest.feedback?.warning ||
                  passwordTest.feedback?.suggestions[0] ||
                  'Password is too weak'
              );
      }),
    givenName: yup.string().required('Required givenName'),
    familyName: yup.string().required('Required'),
    mobileNo: yup.string().required('Required'),
    role: yup.string().required('Required'),
    allowedIpAddresses: yup.string().when('allowedIpAddresses', (value) => {
      return value
        ? yup.string().matches(ipAddressRegex, ipAddressErrorMessage)
        : yup.string().optional();
    }),
  },
  [
    ['allowedIpAddresses', 'allowedIpAddresses'],
    ['password', 'password'],
    ['role', 'role'],
  ]
);

const updateUserSchema = yup.object().shape(
  {
    id: yup.string().required('Required'),
    givenName: yup.string().required('Required'),
    familyName: yup.string().required('Required'),
    mobileNo: yup.string().required('Required'),
    role: yup.string().required('Required'),
    allowedIpAddresses: yup.string().when('allowedIpAddresses', (value) => {
      return value
        ? yup.string().matches(ipAddressRegex, ipAddressErrorMessage)
        : yup.string().optional();
    }),
  },
  [['allowedIpAddresses', 'allowedIpAddresses']]
);

type FormInputs = {
  email: string;
  password: string;
  givenName: string;
  familyName: string;
  mobileNo: string;
  role: UserRole;
  status: UserStatus;
  allowedIpAddresses: string;
};

type FormState = {
  errors: Partial<FormInputs>;
};

const initialFormState: FormState = {
  errors: {},
};

function formReducer(
  state: FormState,
  action: {
    type: string;
    errors?: any;
  }
) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'form-error': {
      return { ...state, errors: action.errors };
    }
    default: {
      return state;
    }
  }
}

function FormModal({
  adminDefaults,
  closeModal,
  selectedUser,
}: AdminModalProps) {
  const classes = useStyles();
  const emailInput = useRef<HTMLInputElement>();
  const passwordInput = useRef<HTMLInputElement>();
  const givenNameInput = useRef<HTMLInputElement>();
  const familyNameInput = useRef<HTMLInputElement>();
  const mobileNoInput = useRef<HTMLInputElement>();
  const allowedIpAddressesInput = useRef<HTMLInputElement>();

  const roleInput = useRef<HTMLSelectElement>();

  const [role, setRole] = useState<UserRole | ''>('');
  const [wasAuthoriser, setWasAuthoriser] = useState<boolean>(false);
  const [status, setStatus] = useState<UserStatus>(UserStatus.Active);
  const [organisation, setOrganisation] = useState<string>('');
  const [controlZones, setControlZones] = useState<string[]>(['']);

  // reference data for form
  const [organisations, setOrganisations] = useState<OrganisationsResponseBody>(
    []
  );
  const [ctrSettings, setCtrSettings] = useState<CtrZoneSettings[]>([]);
  const [authorizers, setAuthorizers] = useState<AuthorizerResponse[]>([]);
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const [organisationControlZones, setOrganisationControlZones] = useState<
    CtrZoneSettings[]
  >([]);

  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const { addUser, updateUser, userError } = useAdmin();

  const isSuperAdmin = useIsSuperAdmin();

  const getOrganisations = () => {
    fimsClient.getOrganisations().then((res: OrganisationsResponseBody) => {
      setOrganisations(res);
    });
  };

  const getControlZones = () => {
    fimsClient
      .getCtrSettings()
      .then((res: AxiosResponse<CtrZoneSettings[]>) =>
        setCtrSettings(res.data)
      );
  };

  const getAuthorizers = () => {
    fimsClient.getAuthorizers().then((res: AuthorizerResponse[]) => {
      setAuthorizers(res);
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  useEffect(() => {
    getOrganisations();
    getControlZones();
    getAuthorizers();
    if (selectedUser) {
      setStatus(selectedUser.status);
      setRole(selectedUser.role);
      setWasAuthoriser(selectedUser.role === UserRole.AUTHORISER);
      if (isSuperAdmin) {
        setOrganisation(selectedUser.organisation?.toString());
        if (selectedUser.controlZones) {
          setControlZones(selectedUser.controlZones);
        }
      }
    } else {
      setStatus(UserStatus.Active);
      setRole('');
      if (isSuperAdmin) {
        setOrganisation('');
      }
    }
  }, [selectedUser, isSuperAdmin]);

  const populateControlZonesForUser = useCallback(() => {
    const authorizerType = authorizers.find(
      (authorizer) =>
        authorizer.id ===
        organisations
          .find((org) => org.id === organisation)
          ?.authorizer?.toString()
    )?.type;
    if (AuthorizerType.ControlZone === authorizerType) {
      setOrganisationControlZones(ctrSettings);
    } else {
      setOrganisationControlZones([]);
      setControlZones(['']);
    }
  }, [authorizers, ctrSettings, organisation, organisations]);

  useEffect(() => {
    if (
      organisation?.length > 0 &&
      authorizers?.length > 0 &&
      ctrSettings?.length > 0
    ) {
      populateControlZonesForUser();
    }
  }, [organisation, authorizers, ctrSettings, populateControlZonesForUser]);

  const processFormValidation = (
    validationErrors: { path: string; message: string }[] = []
  ) => {
    const errors: ErrorsType = {};
    validationErrors.forEach(({ path, message }) => {
      errors[path] = message;
      return errors;
    });
    dispatch({ type: 'form-error', errors });
  };

  const handleRoleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value as UserRole | '';
    setRole(value);
    if (value === UserRole.VIEWER && wasAuthoriser) {
      processFormValidation([
        {
          path: 'role',
          message: 'User role cannot be changed from Authoriser to Viewer',
        },
      ]);
    } else {
      processFormValidation();
    }
  };

  const handleOrganisationChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const {
      target: { value },
    } = event;
    setOrganisation(value);
    if (value === '') {
      setControlZones(['']);
      setOrganisationControlZones([]);
    }
  };

  const handleControlZoneChanges = (
    event: React.ChangeEvent<{ name?: string; value: string | string[] }>
  ) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0) {
      const controlZoneValues = (
        typeof value === 'string' ? value.split(',') : value
      ).filter((controlZoneValue) => controlZoneValue !== '');
      setControlZones(controlZoneValues);
    } else {
      setControlZones(['']);
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    const submitter = (event.nativeEvent as SubmitEvent).submitter.value;

    if (submitter === SubmitType.ADD) {
      const email = (
        event.currentTarget.elements.namedItem('email') as HTMLInputElement
      ).value;
      const password = (
        event.currentTarget.elements.namedItem('password') as HTMLInputElement
      ).value;
      const givenName = (
        event.currentTarget.elements.namedItem('givenName') as HTMLInputElement
      ).value;
      const familyName = (
        event.currentTarget.elements.namedItem('familyName') as HTMLInputElement
      ).value;
      const mobileNo = (
        event.currentTarget.elements.namedItem('mobileNo') as HTMLInputElement
      ).value;
      const allowedIpAddresses = isSuperAdmin
        ? (
            event.currentTarget.elements.namedItem(
              'allowedIpAddresses'
            ) as HTMLInputElement
          ).value
        : '';

      const data = {
        email,
        password,
        familyName,
        givenName,
        mobileNo,
        role,
        status,
        allowedIpAddresses,
      };

      try {
        await addUserSchema.validate(data, {
          strict: true,
          abortEarly: false,
        });
        if (role === '') return;
        const createUserForm: UserCreationForm = {
          email,
          password,
          name: {
            familyName,
            givenName,
          },
          mobileNo,
          role,
          status,
        };
        if (isSuperAdmin) {
          createUserForm.organisation = organisation;
          if (controlZones?.filter((cz) => cz !== '').length > 0) {
            createUserForm.controlZones = controlZones;
          }
          createUserForm.allowedIpAddresses = allowedIpAddresses;
        }
        addUser(createUserForm);
        closeModal();
      } catch (error) {
        processFormValidation(error.inner);
      }
    } else {
      const givenName = (
        event.currentTarget.elements.namedItem('givenName') as HTMLInputElement
      ).value;
      const familyName = (
        event.currentTarget.elements.namedItem('familyName') as HTMLInputElement
      ).value;
      const mobileNo = (
        event.currentTarget.elements.namedItem('mobileNo') as HTMLInputElement
      ).value;
      const allowedIpAddresses = isSuperAdmin
        ? (
            event.currentTarget.elements.namedItem(
              'allowedIpAddresses'
            ) as HTMLInputElement
          ).value
        : '';

      const data = {
        id: selectedUser.id,
        familyName,
        givenName,
        mobileNo,
        role,
        status,
        allowedIpAddresses,
      };

      try {
        await updateUserSchema.validate(data, {
          strict: true,
          abortEarly: false,
        });
        if (role === '') return;
        const userUpdateForm: UserUpdateForm = {
          id: selectedUser.id,
          name: {
            familyName,
            givenName,
          },
          mobileNo,
          role,
          status,
        };

        if (isSuperAdmin) {
          userUpdateForm.organisation = organisation;
          if (controlZones?.filter((cz) => cz !== '').length > 0) {
            userUpdateForm.controlZones = controlZones;
          }
          userUpdateForm.allowedIpAddresses = allowedIpAddresses;
        }
        updateUser(userUpdateForm);
        closeModal();
      } catch (error) {
        processFormValidation(error.inner);
      }
    }
  };

  return (
    <Dialog open onClose={closeModal}>
      <DialogTitle data-testid="admin-user-modal-heading">
        {selectedUser ? 'Update User' : 'Add User'}
      </DialogTitle>
      <br />
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.content}>
          <FormControl error={Boolean(formState.errors.email)}>
            <TextField
              className={classes.input}
              disabled={Boolean(selectedUser)}
              type="text"
              name="email"
              label="Email"
              inputProps={{
                ref: emailInput,
                defaultValue: selectedUser && selectedUser.email,
              }}
              error={Boolean(formState.errors.email)}
              data-testid="admin-user-modal-email"
            />
            <FormHelperText className="input-hint">
              {formState.errors.email}
            </FormHelperText>
          </FormControl>
          {!selectedUser && (
            <FormControl error={Boolean(formState.errors.password)}>
              <TextField
                className={classes.input}
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  ref: passwordInput,
                }}
                error={Boolean(formState.errors.password)}
                data-testid="admin-user-modal-password"
              />
              <FormHelperText className="input-hint">
                {formState.errors.password}
              </FormHelperText>
            </FormControl>
          )}
          <FormControl error={Boolean(formState.errors.givenName)}>
            <TextField
              className={classes.input}
              type="text"
              label="Given Name"
              name="givenName"
              inputProps={{
                ref: givenNameInput,
                defaultValue:
                  selectedUser &&
                  selectedUser.name &&
                  selectedUser.name.givenName,
              }}
              error={Boolean(formState.errors.givenName)}
              data-testid="admin-user-modal-given-name"
            />
            <FormHelperText className="input-hint">
              {formState.errors.givenName}
            </FormHelperText>
          </FormControl>
          <FormControl error={Boolean(formState.errors.familyName)}>
            <TextField
              className={classes.input}
              type="text"
              name="familyName"
              label="Family Name"
              inputProps={{
                ref: familyNameInput,
                defaultValue:
                  selectedUser &&
                  selectedUser.name &&
                  selectedUser.name.familyName,
              }}
              error={Boolean(formState.errors.familyName)}
              data-testid="admin-user-modal-family-name"
            />
            <FormHelperText className="input-hint">
              {formState.errors.familyName}
            </FormHelperText>
          </FormControl>
          <FormControl error={Boolean(formState.errors.mobileNo)}>
            <TextField
              className={classes.input}
              type="text"
              name="mobileNo"
              label="Mobile Number"
              inputProps={{
                ref: mobileNoInput,
                defaultValue: selectedUser?.mobileNo,
              }}
              error={Boolean(formState.errors.mobileNo)}
              data-testid="admin-user-modal-mobile"
            />
            <FormHelperText className="input-hint">
              {formState.errors.mobileNo}
            </FormHelperText>
          </FormControl>
          <FormControl error={Boolean(formState.errors.role)}>
            <InputLabel id="label">Role</InputLabel>
            <Select
              className={classes.input}
              displayEmpty
              id="role"
              name="role"
              value={role}
              inputProps={{
                ref: roleInput,
              }}
              error={Boolean(formState.errors.role)}
              onChange={handleRoleChange}
              data-testid="admin-user-modal-role"
            >
              <MenuItem value=""></MenuItem>
              {Boolean(adminDefaults) &&
                Boolean(adminDefaults.userRole) &&
                adminDefaults.userRole.map((userRole: any) => (
                  <MenuItem
                    value={userRole}
                    key={userRole}
                    data-testid="admin-user-modal-role-item"
                  >
                    {userRole}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText className="input-hint">
              {formState.errors.role}
            </FormHelperText>
          </FormControl>

          {isSuperAdmin && organisations && organisations.length > 0 && (
            <>
              <FormControl error={Boolean(formState.errors.organisation)}>
                <InputLabel id="label">Organisation</InputLabel>
                <Select
                  className={classes.input}
                  displayEmpty
                  id="organisation"
                  name="organisation"
                  value={organisation}
                  error={Boolean(formState.errors.organisation)}
                  onChange={handleOrganisationChange}
                  data-testid="admin-user-modal-organisation"
                >
                  <MenuItem value=""></MenuItem>
                  {organisations?.map((org) => (
                    <MenuItem
                      key={org.id}
                      value={org.id}
                      data-testid="admin-user-modal-organisation-item"
                    >
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {organisationControlZones?.length > 0 && (
                <FormControl error={Boolean(formState.errors.controlZones)}>
                  <InputLabel data-testid="label">Control Zones</InputLabel>
                  <Select
                    className={classes.input}
                    displayEmpty
                    id="controlZones"
                    name="controlZones"
                    value={controlZones}
                    error={Boolean(formState.errors.controlZones)}
                    onChange={handleControlZoneChanges}
                    multiple
                    data-testid="admin-user-modal-control-zone"
                  >
                    <MenuItem value=""></MenuItem>
                    {ctrSettings?.map((ctrSetting) => (
                      <MenuItem
                        key={ctrSetting.ctrCode}
                        value={ctrSetting.ctrCode}
                        data-testid="admin-user-modal-control-zones-item"
                      >
                        {`${ctrSetting.ctrCode} - ${ctrSetting.ctrName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {isSuperAdmin && (
            <FormControl error={Boolean(formState.errors.allowedIpAddresses)}>
              <TextField
                className={classes.input}
                type="text"
                label="Allowed IP Addresses"
                name="allowedIpAddresses"
                inputProps={{
                  ref: allowedIpAddressesInput,
                  defaultValue: selectedUser?.allowedIpAddresses,
                }}
                error={Boolean(formState.errors.allowedIpAddresses)}
                data-testid="admin-user-modal-allowed-ip-addresses"
              />
              <FormHelperText className="input-hint">
                {formState.errors.allowedIpAddresses ||
                  'Comma separated list. Use "0.0.0.0/0" to allow access from everywhere. Will use the environment default if no value is set.'}
              </FormHelperText>
            </FormControl>
          )}
          <br />
          <ToggleButtonGroup
            exclusive
            value={status}
            aria-label="user-status-toggle"
            className={classes.toggleButtonGroup}
            data-testid="admin-user-modal-toggle-button-group"
          >
            {adminDefaults.userStatus.map((value: UserStatus) => (
              <ToggleButton
                key={value}
                value={value}
                selected={status === value}
                className={classes.toggleButton}
                onClick={() => setStatus(value)}
                data-testid="admin-user-modal-toggle-button"
              >
                {value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </DialogContent>
        <br />
        {userError && <ErrorMessage main="" sub={userError} />}
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeModal}
            data-testid="admin-user-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            value={selectedUser ? SubmitType.UPDATE : SubmitType.ADD}
            data-testid="admin-user-modal-save-button"
          >
            {selectedUser ? 'Update User' : 'Add User'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FormModal;
