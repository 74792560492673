import React, { useContext } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { LoginContext } from '../../../../../../context/LoginState';
import { theme } from '../../../../../app/mui-theme';

export default function NoOpsControl() {
  const { showSecondaryNOA, toggleShowSecondaryNOA } = useContext(LoginContext);

  const groupStyle = {
    color: theme.palette.primary.contrastText,
  };

  return (
    <FormGroup row style={groupStyle}>
      <FormControlLabel
        control={
          <Switch
            checked={showSecondaryNOA}
            onChange={toggleShowSecondaryNOA}
            name="toggleShowSecondary"
            color="primary"
            data-testid="show-secondary-noa-toggle"
          />
        }
        label="Secondary No-Ops"
      />
    </FormGroup>
  );
}
