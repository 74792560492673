import { useEffect, useState } from 'react';
import {
  getPushNotification,
  addPushNotification,
} from '~/clients/fims-api-client';

export function usePushNotification() {
  const localStorageBrowserId = 'pushNotification-browserId';
  const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
  const publicVapidKey = window.env.WEBPUSH_VAPID_PUBLICKEY;
  const [searchWebPushSubscriptions, setSearchWebPushSubscriptions] =
    useState(false);
  const [hasLocalSubscription, setHasLocalSubscription] = useState(false);
  const [pushNotificationBrowserId, setPushNotificationBrowserId] = useState(
    localStorage.getItem(localStorageBrowserId)
  );

  useEffect(
    () => {
      getBrowserId();
      getWebPushSubscriptionUser()
        .then(() => {
          if (searchWebPushSubscriptions) {
            verifySubscriptionState();
            setInterval(() => {
              verifySubscriptionState();
            }, 30000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchWebPushSubscriptions]
  );

  const isPushNotificationDisabled = () => {
    const isWebpushNotificationEnabled =
      window.env.WEB_PUSH_NOTIFICATION_ENABLED?.toLowerCase() === 'true';
    return (
      !isWebpushNotificationEnabled ||
      window.Notification?.permission !== 'granted'
    );
  };

  async function getWebPushSubscriptionUser() {
    const pushNotification =
      localStorage.getItem('profile') !== null
        ? await getPushNotification()
        : null;
    let browserHasPushNotification = false;
    browserHasPushNotification =
      pushNotification && pushNotification.webPushSubscriptions.length > 0;
    setSearchWebPushSubscriptions(true);
    return browserHasPushNotification;
  }

  function getBrowserId() {
    if (!pushNotificationBrowserId) {
      const browserId = generateRandomString(32);
      localStorage.setItem(localStorageBrowserId, browserId);
      setPushNotificationBrowserId(browserId);
    }

    function generateRandomString(length: number): string {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }

      return result;
    }
  }
  const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      // eslint-disable-next-line
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const subscribe = async (requestPermission: boolean = false) => {
    if (!('serviceWorker' in navigator)) return;
    const registration = await navigator.serviceWorker.ready;
    const result = requestPermission
      ? await window.Notification?.requestPermission()
      : window.Notification?.permission;
    if (result === 'denied') {
      alert(
        'You have denied the push notification permission. If you want to receive notifications, ' +
          'please reset the permission in your browser settings or contact the AirShare support team.'
      );
      return;
    }
    if (!result) {
      return;
    }
    // Subscribe to push notifications
    let subscription: PushSubscription;

    let added = false;
    let hasSubscriptionDB = false;
    if (!requestPermission) {
      subscription = await registration.pushManager.getSubscription();
      hasSubscriptionDB = await getWebPushSubscriptionUser();
    }
    if (!subscription) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });
      added = true;
    } else if (!hasSubscriptionDB) {
      added = true;
    }
    if (added && localStorage.getItem('profile') !== null) {
      const dataSubscription = {
        browserId: pushNotificationBrowserId,
        topic: ACTIVATION_REQUEST,
        ...subscription.toJSON(),
      };
      await addPushNotification(dataSubscription);
    }
    setHasLocalSubscription(true);
    if (requestPermission) {
      await registration.showNotification('AirSpace Manager', {
        body: 'Push notification enabled!',
      });
    }
  };

  async function verifySubscriptionState() {
    // check notification permission, if granted, generate new keys
    if (window.Notification?.permission === 'granted') {
      await subscribe(false);
    }
  }

  return {
    getWebPushSubscriptionUser,
    urlBase64ToUint8Array,
    setPushNotificationBrowserId,
    getBrowserId,
    pushNotificationBrowserId,
    subscribe,
    searchWebPushSubscriptions,
    verifySubscriptionState,
    hasLocalSubscription,
    setHasLocalSubscription,
    isPushNotificationDisabled,
  };
}
