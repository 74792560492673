import React from 'react';
import MapViewControl from './map-view-control';
import TopBanner from './top-banner';
import { Search } from 'asm-web-components';
import type { SearchProps } from 'asm-web-components/src/components/search/search';

export const MapTopPanel = (props: Readonly<SearchProps>) => {
  return (
    <div
      data-testid="map-top-panel"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        marginLeft: '1rem',
        marginRight: '1rem',
        width: 'calc(100% - 2rem)',
        top: '16px',
      }}
    >
      <MapViewControl />
      <TopBanner />
      <Search
        className={props.className}
        placesService={props.placesService}
        userMarker={props.userMarker}
        onChange={props.onChange}
      />
    </div>
  );
};
