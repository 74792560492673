import {
  AuthorizationResponseBody,
  DigitalClearanceStatus,
  DigitalAuthRequest,
  SegmentStatusCode,
  ApproveSegmentsRequestBody,
} from 'fims-api-types';

import { fimsAPI } from '../api';

export const getActivationRequests = async (): Promise<DigitalAuthRequest[]> =>
  fimsAPI
    .get<DigitalAuthRequest[]>('/activation-requests')
    .then((resp) => resp.data)
    .catch(() => {
      throw new Error('Unable to fetch activation flights');
    });

export const updateActivationStatus = async (payload: {
  flightId: string;
  segmentId?: string;
  status: DigitalClearanceStatus | SegmentStatusCode;
  reason?: string;
}): Promise<AuthorizationResponseBody> =>
  fimsAPI
    .put('/activation-requests', payload)
    .then((resp) => resp.data)
    .catch((error) => {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error('Unable to update activation status');
    });

export const approveSegments = async (
  payload: ApproveSegmentsRequestBody
): Promise<AuthorizationResponseBody> =>
  fimsAPI
    .put('/activation-approval', payload)
    .then((resp) => resp.data)
    .catch((error) => {
      if (error?.response?.data?.errors?.length > 0) {
        throw new Error(error?.response?.data?.errors?.join(', '));
      }
      throw new Error('Unable to update approval status');
    });
