/* istanbul ignore file */
import React from 'react';
import { isArray } from 'lodash';

export function ObjectInspect(props: any) {
  function objectContent(objectKey: string) {
    if (isArray(props.selectedObject[objectKey])) {
      return (
        <div className="value">
          <input
            type="readonly"
            title={JSON.stringify(props.selectedObject[objectKey])}
            value={JSON.stringify(props.selectedObject[objectKey])}
          />
        </div>
      );
    }
    switch (typeof props.selectedObject[objectKey]) {
      case 'object':
        return (
          <div className="value">
            <ObjectInspect selectedObject={props.selectedObject[objectKey]} />
          </div>
        );
      case 'string':
        return (
          <div className="value">
            <input
              type="readonly"
              title={props.selectedObject[objectKey]}
              value={props.selectedObject[objectKey]}
            />
          </div>
        );
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      case 'number':
        return (
          <div className="value">
            <input
              type="readonly"
              title={props.selectedObject[objectKey]}
              value={props.selectedObject[objectKey]}
            />
          </div>
        );
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      case 'boolean':
        return (
          <div className="value">
            <input
              type="readonly"
              title={props.selectedObject[objectKey]}
              value={props.selectedObject[objectKey]}
            />
          </div>
        );
      default:
        return (
          <div className="value">
            <input
              type="readonly"
              title={JSON.stringify(props.selectedObject[objectKey])}
              value={JSON.stringify(props.selectedObject[objectKey])}
            />
          </div>
        );
    }
  }
  return (
    <div>
      {Object.keys(props?.selectedObject).map((objectKey, index) => {
        return (
          <div key={index} className="row">
            <div className="name">{objectKey}</div>
            {objectContent(objectKey)}
          </div>
        );
      })}
    </div>
  );
}
