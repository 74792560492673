import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useLayoutEffect,
} from 'react';
import type { Polygon } from '@turf/helpers';
import {
  createStyles,
  makeStyles,
  IconButton,
  Theme,
  Typography,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import PublishIcon from '@material-ui/icons/Publish';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmActionDialog from './confirm-action-dialog';
import { MapControls, TextTooltip } from 'asm-web-components';

import { MapHelpers } from './hooks/helpers';
import {
  useAirspaceLayer,
  useCenterOnUserLocation,
  useConformanceMonitoring,
  useMapSurveillance,
  useMapHandlers,
  useMapLayers,
  useNoaLayer,
  useSurveillanceInfoWindow,
  useMap,
  useMapStyleChange,
  useZoom,
} from '../../../../hooks';
import useMarkers from './hooks/markers.hook';
import { useDynamicDrawingManager } from './hooks/dynamic-drawing-manager.hook';

import {
  AircraftType,
  FlightRequestViewModel,
  LatLng,
  OrganisationSettingsResponseBody,
  shapeOptions,
  SurveillanceViewModel,
} from 'fims-api-types';

import { LoginContext } from '../../../../context/LoginState';

import ConfirmAreaButtons from '../../managed-airspace/confirm-area-buttons';

import {
  MapStyleCode,
  getMapStylesByCode,
} from '../../../../state/map-styles/index';
import { useFlightArea } from '../../../../state/flight-area';
import { getFlightRoute } from '../../../../lib/routes';
import * as fimsClient from '../../../../clients/fims-api-client';

import { getCenterAndZoom, handleGeojsonUpload, selectAORs } from './helpers';
import SurveillanceLatencyDisplay from './surveillance-latency-display';
import { useProfile } from '~/state/session';

import './map.scss';
import { MapTopPanel } from './map-top-panel/map-top-panel';

interface HistoryState {
  lat?: number;
  lng?: number;
  zoom?: number;
  stylecode?: MapStyleCode;
}

export type AreasOfRespCoords = { lat: number; lng: number }[][];

interface MapProps {
  settings: OrganisationSettingsResponseBody;
  surveillanceData: SurveillanceViewModel[];
  flightRequests: FlightRequestViewModel[];
  fetchFlightList: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    managedAreaError: {
      backgroundColor: alpha(theme.palette.error.main, 0.6),
      height: 'rem',
      padding: '5px 20px 5px 20px',
      borderRadius: '12px',
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      textAlign: 'center',
      bottom: '8rem',
      left: '38%',
      width: '22%',
    },
    uploadButton: {
      borderRadius: '0.5rem',
      alignItems: 'center',
      left: 'calc(50% + 55px)',
      marginBottom: '53px',
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
    selectEntireAorButton: {
      borderRadius: '0.5rem',
      alignItems: 'center',
      left: 'calc(50% - 103px)',
      marginBottom: '53px',
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.5)',
      },
    },
  })
);

function Map({
  surveillanceData,
  settings,
  flightRequests,
  fetchFlightList,
}: Readonly<MapProps>) {
  const navigate = useNavigate();
  const location = useLocation();

  const { mapDefaults, tower } = useProfile();

  const hState = location?.state;
  const google = window.google;

  const { mapHelpers, setMapHelpers, mapStyleCode } = useContext(LoginContext);

  const { stylecode = mapStyleCode }: HistoryState = hState || {};

  const { center, zoom: initialZoom } = getCenterAndZoom({
    history: {
      lat: hState?.lat,
      lng: hState?.lng,
      zoom: hState?.zoom,
    },
    orgDefault: mapDefaults,
    appConfig: settings?.mapDefaults,
  });

  const defaultMapOptions = {
    center,
    zoom: initialZoom || null,
    // @ts-ignore
    onReady: handleMapReady,
    disableDefaultUI: true,
    mapId: '361d5174b74ea5ce', // FIMS main dark vector map
    gestureHandling: 'greedy',
    ...getMapStylesByCode(mapStyleCode),
  };

  const mapRef = useRef();
  const [map, rerenderMap] = useMap(google, mapRef, defaultMapOptions);

  const {
    isCreatingAirspace,
    setIsCreatingAirspace,
    selectedAirspace,
    setSelectedAirspace,
    airspaceIsClosed,
  } = useContext(LoginContext);

  useAirspaceLayer(google, map, 'unknown', selectedAirspace);

  const [isDrawingAirspace, setIsDrawingAirspace] = useState(false);
  const [isRequestToLandOpen, setIsRequestToLandOpen] = useState(false);
  const [flightIdRequestToLand, setFlightIdRequestToLand] = useState(0);

  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(
    null
  );
  const [areasOfResponsibilityScree, setAreasOfResponsibilityScree] =
    useState<google.maps.Polygon | null>(null);
  const [flightAreaPolygon, setFlightAreaPolygon] =
    useState<google.maps.Polygon | null>(null);
  const { setup } = useSurveillanceInfoWindow(map, surveillanceData);
  const { selectedFlightArea } = useFlightArea();
  const [manageAirspaceError, setManageAirspaceError] = useState<string | null>(
    null
  );
  const [areasOfResponsibilityCoords, setAreasOfResponsibilityCoords] =
    useState<AreasOfRespCoords | null>(null);

  const [
    { userMarker, intialMapInteractionDetected, placesServiceInstance },
    {
      animationPrep,
      handleCenterPositionClick,
      handlePlaceSearchSelection,
      handleInitialInteraction,
      setPlacesServiceInstance,
      setUserMarker,
    },
  ] = useMapHandlers(map, mapHelpers);
  const [{ zoom }, { handleMapZoom, setZoom }] = useZoom(
    map,
    initialZoom || null
  );

  let lastZoom = -1;

  const [, handleMapStyleChange] = useMapStyleChange(
    map,
    initialZoom || null,
    // @ts-ignore
    handleMapReady,
    rerenderMap,
    stylecode
  );

  const showTowerOffWatchBanner = tower?.watchEnabled && !tower?.onWatch;

  useEffect(() => {
    if (!mapHelpers && google && map) {
      setMapHelpers(new MapHelpers(google, map));
    }
  }, [google, map, mapHelpers, setMapHelpers]);

  useEffect(() => () => setMapHelpers(null), [setMapHelpers]);

  useMapLayers(google, map);
  useNoaLayer(google, map);

  const onMarkerClick = useCallback(
    (flightId: number, type: AircraftType) => {
      if (type === AircraftType.Drone) {
        navigate(getFlightRoute(flightId));
      }
    },
    [navigate]
  );

  useMapSurveillance(
    google,
    map,
    surveillanceData,
    zoom,
    {
      setup,
      supportedTypes: [],
    },
    onMarkerClick
  );
  useCenterOnUserLocation(
    google,
    map,
    intialMapInteractionDetected,
    userMarker,
    setUserMarker,
    mapHelpers,
    animationPrep,
    true
  );

  useConformanceMonitoring(surveillanceData, flightRequests, map, google);

  const { handleFlightAreaSelected, createDrawingManager, removeCreatedEvent } =
    useDynamicDrawingManager();

  useEffect(() => {
    async function getAreasOfResp() {
      const coordinates = await fimsClient.getAreasOfResponsibility();
      setAreasOfResponsibilityCoords(coordinates);
    }
    getAreasOfResp();
  }, [isCreatingAirspace]);

  useEffect(() => {
    if (google) {
      const placesService = new google.maps.places.PlacesService(map);
      setPlacesServiceInstance(placesService);
    }
  }, [map, google, setPlacesServiceInstance]);

  useEffect(() => {
    if (map) {
      if (
        isCreatingAirspace &&
        !areasOfResponsibilityScree &&
        areasOfResponsibilityCoords &&
        areasOfResponsibilityCoords?.length !== 0
      ) {
        const scree = new google.maps.Polygon({
          strokeWeight: 0,
          fillColor: 'gray',
          fillOpacity: 0.6,
          map,
        });
        const earthCoords = [
          new google.maps.LatLng(-85.1054596961173, -180),
          new google.maps.LatLng(85.1054596961173, -180),
          new google.maps.LatLng(85.1054596961173, 180),
          new google.maps.LatLng(-85.1054596961173, 180),
          new google.maps.LatLng(-85.1054596961173, 0),
        ];
        scree.setPaths([earthCoords, ...areasOfResponsibilityCoords]);
        setAreasOfResponsibilityScree(scree);
      }
      if (!isCreatingAirspace) {
        areasOfResponsibilityScree?.setMap(null);
        setAreasOfResponsibilityScree(null);
      }
    }
  }, [
    isCreatingAirspace,
    areasOfResponsibilityScree,
    setAreasOfResponsibilityScree,
    areasOfResponsibilityCoords,
    google,
    map,
  ]);

  useEffect(() => {
    if (selectedFlightArea && !flightAreaPolygon) {
      const polygon: google.maps.Polygon = new google.maps.Polygon({
        map,
        paths: selectedFlightArea.coordinates,
        ...shapeOptions,
      });

      setFlightAreaPolygon(polygon);
    } else if (flightAreaPolygon) {
      flightAreaPolygon.setMap(null);
      setFlightAreaPolygon(null);
    }
  }, [selectedFlightArea, flightAreaPolygon, map, google]);

  const handleMapInteractionCallback = useCallback(
    () => handleInitialInteraction(initialZoom),
    [handleInitialInteraction, initialZoom]
  );

  function handleMapReady() {
    if (map) {
      setMapHelpers(new MapHelpers(google, map));

      handleMapInteractionCallback();

      google.maps.event.addListener(map, 'zoom_changed', () => {
        let newZoom = map.getZoom();
        newZoom = newZoom < 0 ? 0 : newZoom;
        newZoom = newZoom > 22 ? 22 : newZoom;
        if (lastZoom !== newZoom) {
          setZoom(newZoom);
          lastZoom = newZoom;
        }
      });

      const placesService = new google.maps.places.PlacesService(map);
      setPlacesServiceInstance(placesService);
    }
  }

  useEffect(() => {
    if (map) {
      const drawingManager = drawingManagerRef.current;

      if (!drawingManager) {
        drawingManagerRef.current = createDrawingManager();
      }

      if (drawingManager) {
        if (isCreatingAirspace && !isDrawingAirspace) {
          drawingManager.setOptions({
            drawingMode: null,
            drawingControl: true,
            map,
          });

          drawingManager.addListener(
            'overlaycomplete',
            (e: google.maps.drawing.OverlayCompleteEvent) =>
              handleFlightAreaSelected(
                e,
                setSelectedAirspace,
                (message?: string) => {
                  setSelectedAirspace(null);
                  setManageAirspaceError(message?.length ? message : null);
                }
              )
          );

          setIsDrawingAirspace(true);
        }

        if (!isCreatingAirspace && isDrawingAirspace) {
          removeCreatedEvent();
          setIsDrawingAirspace(false);
        }

        if (isCreatingAirspace && isDrawingAirspace) {
          drawingManager.setOptions({
            map,
          });
        }
      }
    }
  }, [
    google,
    map,
    areasOfResponsibilityCoords,
    isCreatingAirspace,
    handleFlightAreaSelected,
    setIsDrawingAirspace,
    isDrawingAirspace,
    createDrawingManager,
    removeCreatedEvent,
    setSelectedAirspace,
    airspaceIsClosed,
    mapStyleCode,
  ]);

  const onAirspaceCancel = useCallback(() => {
    const drawingManager = drawingManagerRef.current;

    drawingManager?.setOptions({
      drawingMode: null,
      drawingControl: false,
      map: null,
    });
    drawingManagerRef.current = null;
    setIsCreatingAirspace(false);
    setManageAirspaceError(null);
  }, [drawingManagerRef, setIsCreatingAirspace]);

  const exitDrawingMode = () => {
    const drawingManager = drawingManagerRef.current;
    drawingManager?.setOptions({
      drawingMode: null,
    });
  };

  const onAirspaceSelectSucess = (
    polygon: Polygon | null,
    centerPoint: LatLng
  ) => {
    setSelectedAirspace(polygon);
    mapHelpers.smoothlyAnimatePanTo(new google.maps.LatLng(centerPoint), () =>
      mapHelpers.animateMapZoomTo(10)
    );
    removeCreatedEvent();
    setManageAirspaceError(null);
    exitDrawingMode();
  };

  const onAirspaceSelectFail = (errMsg?: string) => {
    setSelectedAirspace(null);
    setManageAirspaceError(errMsg);
    exitDrawingMode();
  };

  const classes = useStyles();

  useLayoutEffect(
    () => () => {
      onAirspaceCancel();
    },
    [onAirspaceCancel]
  );

  const requestToLandHandler = (flightId: number) => {
    setFlightIdRequestToLand(flightId);
    setIsRequestToLandOpen(!!flightId);
    fetchFlightList();
  };

  useMarkers({ map, flightRequests, zoom, mapStyleCode, requestToLandHandler });

  return (
    <div
      className={showTowerOffWatchBanner ? 'map map-with-banner' : 'map'}
      data-testid="map"
    >
      <div
        className="map__container"
        ref={mapRef}
        data-testid="map-container"
      />
      {isCreatingAirspace && (
        <Typography
          className="select-area-header"
          data-testid="select-area-header"
        >
          Select managed area
        </Typography>
      )}

      <MapTopPanel
        className="map__search"
        placesService={placesServiceInstance}
        userMarker={userMarker}
        onChange={handlePlaceSearchSelection}
      />
      <SurveillanceLatencyDisplay />

      <MapControls
        handleMapStyleChange={handleMapStyleChange}
        handleCenterPositionClick={handleCenterPositionClick}
        handleMapZoomIn={() => handleMapZoom(1)}
        handleMapZoomOut={() => handleMapZoom(-1)}
      />
      {isCreatingAirspace && manageAirspaceError && (
        <Typography
          className={classes.managedAreaError}
          data-testid="managed-map-error"
        >
          {manageAirspaceError}
        </Typography>
      )}
      {isCreatingAirspace && (
        <>
          <input
            type="file"
            data-testid="upload-button-file"
            id="upload-button-file"
            accept=".geojson,.json"
            hidden
            onChange={(e) =>
              handleGeojsonUpload(
                e,
                onAirspaceSelectSucess,
                onAirspaceSelectFail
              )
            }
          />
          <label htmlFor="upload-button-file">
            <TextTooltip title="upload geojson">
              <IconButton className={classes.uploadButton} component="span">
                <PublishIcon />
              </IconButton>
            </TextTooltip>
          </label>
          {areasOfResponsibilityCoords?.length ? (
            <TextTooltip title="select all area of responsibility">
              <IconButton
                data-testid="select-all-aor"
                className={classes.selectEntireAorButton}
                component="span"
                onClick={() =>
                  selectAORs(
                    areasOfResponsibilityCoords,
                    onAirspaceSelectSucess,
                    onAirspaceSelectFail
                  )
                }
              >
                <SelectAllIcon />
              </IconButton>
            </TextTooltip>
          ) : null}
          <ConfirmAreaButtons
            isDisabled={!selectedAirspace}
            onConfirm={() => navigate(`/managed-airspaces/add`)}
            onClose={() => {
              onAirspaceCancel();
              setSelectedAirspace(null);
            }}
          />
        </>
      )}
      <ConfirmActionDialog
        precannedMessagesAppCode="REQUEST_TO_LAND_REASONS"
        description="This option will cancel flight authorisation."
        title={`Cancel flight authorisation: ${flightIdRequestToLand}`}
        placeholder="Indicate the reason for the cancellation, you can select one of the options above or write your own message."
        open={isRequestToLandOpen}
        setOpen={setIsRequestToLandOpen}
        callback={async (reason: string) => {
          if (reason) {
            await fimsClient.requestToLand({
              reason: `${reason}`,
              flightId: flightIdRequestToLand,
            });
          }
          requestToLandHandler(0);
        }}
      />
    </div>
  );
}

export default Map;
