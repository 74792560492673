import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelectors } from './selectors';

export function createFormHooks<FormType>(
  actionCreators: any,
  selectors: FormSelectors<FormType>,
  schema: any
) {
  const {
    getForm,
    getIsValid,
    getValidation,
    getTouched,
    getErrors,
    getIsSubmitted,
  } = selectors;

  const {
    formUpdated,
    formValidated,
    formFieldValidationReset,
    formTouched,
    formErrorsReset,
    formSubmitted,
    formCleared,
    formValidationReset,
  } = actionCreators;

  // State Hooks
  function useFormState(): FormType {
    return useSelector(getForm);
  }

  function useUpdateFormState() {
    const dispatch = useDispatch();

    return (payload: Object) => dispatch(formUpdated(payload));
  }

  function useValidation() {
    return useSelector(getValidation);
  }

  function useUpdateValidation() {
    const dispatch = useDispatch();

    const updateValidation = (payload: any) => dispatch(formValidated(payload));
    const resetFieldValidation = (payload: any) =>
      dispatch(formFieldValidationReset(payload));
    const resetFormValidation = (payload: any = null) =>
      dispatch(formValidationReset(payload));

    return [updateValidation, resetFieldValidation, resetFormValidation];
  }

  function useTouched(): Dictionary<boolean> {
    return useSelector(getTouched);
  }

  function useUpdateTouched() {
    const dispatch = useDispatch();

    return (payload: Dictionary<boolean>) => dispatch(formTouched(payload));
  }

  function useIsSubmitted() {
    return useSelector(getIsSubmitted);
  }

  function useUpdateIsSubmitted() {
    const dispatch = useDispatch();

    return (payload: any) => dispatch(formSubmitted(payload));
  }

  function useClearForm() {
    const dispatch = useDispatch();

    return () => dispatch(formCleared());
  }

  function useHandleSubmit(callback?: (event: any) => void) {
    const [updateValidation] = useUpdateValidation();
    const form = useFormState();
    const resetErrors = useResetErrors();
    const updateIsSubmitted = useUpdateIsSubmitted();

    return (event: any) => {
      event.preventDefault();
      updateIsSubmitted(true);
      updateValidation(form);
      resetErrors();

      let isValid = true;

      try {
        schema.validateSync(form, { abortEarly: false });
      } catch (error) {
        isValid = false;
        updateValidation(form);
      }

      if (isValid) {
        callback(event);
      }
    };
  }

  function useErrors(): { message?: string } {
    return useSelector(getErrors);
  }

  function useResetErrors() {
    const dispatch = useDispatch();

    return () => dispatch(formErrorsReset());
  }

  function useIsErrored() {
    const errors = useSelector(getErrors) as any;
    const validation = useSelector(getValidation) as any;
    const touched = useSelector(getTouched);
    const isSubmitted = useIsSubmitted();

    return (fieldName: string) =>
      Boolean(
        (errors[fieldName] || validation[fieldName]) &&
          (isSubmitted || touched[fieldName])
      );
  }

  function useFieldError() {
    const isErrored = useIsErrored();
    const errors = useSelector(getErrors) as any;
    const validation = useSelector(getValidation) as any;

    return (fieldName: string) => {
      const isErr = isErrored(fieldName);

      if (isErr) {
        const val = validation[fieldName];
        const err = errors[fieldName];

        return val || err;
      }

      return null;
    };
  }

  function useIsValid() {
    return useSelector(getIsValid);
  }

  function useHydrateForm() {
    const updateForm = useUpdateFormState();

    return (defaultForm: any) => {
      updateForm(defaultForm);
    };
  }

  function useSetFieldValue() {
    const touched = useTouched();
    const updateTouched = useUpdateTouched();
    const updateForm = useUpdateFormState();

    return <K extends keyof FormType>(name: K, value: FormType[K]) => {
      updateForm({ [name]: value });
      if (
        !touched[name as string] &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        updateTouched({ ...touched, [name]: true });
      }
    };
  }

  // Handler Hooks
  function useInputHandlerProps() {
    return {
      onFocus: useOnFocus(),
      onBlur: useOnBlur(),
      onChange: useOnChange(),
    };
  }

  function useControlHandlerProps() {
    return {
      onFocus: useOnFocus(),
      onBlur: useOnBlur(),
      onClick: useOnClick(),
    };
  }

  function useOnFocus(callback?: (e: any) => void) {
    const touched = useTouched();
    const updateTouched = useUpdateTouched();
    const [, resetFieldValidation] = useUpdateValidation();

    return (event: any) => {
      const fieldName = event.currentTarget.name;

      if (fieldName && !touched[fieldName]) {
        updateTouched({ ...touched, [fieldName]: true });
      }
      resetFieldValidation({ fieldName });

      if (callback) {
        callback(event);
      }
    };
  }

  function useOnBlur(callback?: (e: any) => void) {
    const form = useFormState();
    const [updateValidation] = useUpdateValidation();

    return (event: any) => {
      const { value, name } = event.currentTarget;
      updateValidation({ ...form, [name]: value });

      if (callback) {
        callback(event);
      }
    };
  }

  function useOnChange(callback?: (e: any) => void) {
    const form = useFormState();
    const updateForm = useUpdateFormState();

    return (event: any) => {
      const { value, name } = event.currentTarget;
      updateForm({ ...form, [name]: value });

      if (callback) {
        callback(event);
      }
    };
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  function useOnClick(callback?: (e: any) => void) {
    const form = useFormState();
    const updateForm = useUpdateFormState();

    return (event: any) => {
      const { value, name } = event.currentTarget;
      updateForm({ ...form, [name]: value });

      if (callback) {
        callback(event);
      }
    };
  }

  return {
    useClearForm,
    useErrors,
    useFieldError,
    useFormState,
    useControlHandlerProps,
    useInputHandlerProps,
    useHandleSubmit,
    useIsErrored,
    useIsValid,
    useResetErrors,
    useSetFieldValue,
    useValidation,
    useOnChange,
    useOnClick,
    useOnBlur,
    useOnFocus,
    useTouched,
    useIsSubmitted,
    useUpdateIsSubmitted,
    useUpdateValidation,
    useHydrateForm,
  };
}
