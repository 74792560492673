import { call, all, put, takeEvery } from 'redux-saga/effects';
import { contentAPI } from '../../clients/api';
import {
  MapStylesActionType,
  mapStylesFetchFailed,
  mapStylesFetchSucceeded,
  MapStyleFetchSucceededPayload,
  MapStyleCode,
} from './actions';

interface MapStyle {
  code: MapStyleCode;
  mimeType: 'application/json';
  data: string;
}

interface MapStylesResponse {
  data: MapStyle;
}

function* fetchMapStyles() {
  try {
    const [standard, dark, gray]: MapStylesResponse[] = yield all([
      call(contentAPI.get, '/map-styles/standard.json'),
      call(contentAPI.get, '/map-styles/dark.json'),
      call(contentAPI.get, '/map-styles/gray.json'),
    ]);

    const payload: MapStyleFetchSucceededPayload = {
      standard: standard.data,
      gray: gray.data,
      dark: dark.data,
    };

    yield put(mapStylesFetchSucceeded(payload));
  } catch (e) {
    yield put(mapStylesFetchFailed(e.message));
  }
}

export function* mapStylesSaga() {
  yield takeEvery(MapStylesActionType.FETCH_REQUESTED, fetchMapStyles);
}
