import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import { FlightAreaViewModel } from 'fims-api-types';
import {
  FlightAreaActionType,
  FlightAreaFetchSucceededAction,
} from './actions';
import { FlightAreaFetchStatus } from './constants';

export interface FlightAreaState {
  selectedFlightArea: FlightAreaViewModel;
  flightAreaFetchStatus: FlightAreaFetchStatus;
}

const flightAreaReducer = createReducer(
  {
    [FlightAreaActionType.FETCH_SUCCEEDED]: (
      _: FlightAreaViewModel,
      { payload }: FlightAreaFetchSucceededAction
    ) => payload,
    [FlightAreaActionType.FETCH_RESET]: (): any => null,
  },
  null,
  false
);

const INITIAL_FETCH_STATUS = FlightAreaFetchStatus.IDLE;
const flightAreaFetchStatusReducer = createReducer(
  {
    [FlightAreaActionType.FETCH_REQUESTED]: () =>
      FlightAreaFetchStatus.FLIGHT_AREA_FETCH_IN_PROGRESS,
    [FlightAreaActionType.FETCH_SUCCEEDED]: () => FlightAreaFetchStatus.SUCCESS,
    [FlightAreaActionType.FETCH_FAILED]: () => FlightAreaFetchStatus.FAILED,
  },
  INITIAL_FETCH_STATUS,
  false
);

export default combineReducers({
  selectedFlightArea: flightAreaReducer,
  flightAreaFetchStatus: flightAreaFetchStatusReducer,
});
