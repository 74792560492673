import { createSelector } from 'reselect';
import get from 'lodash/get';
import { FormState } from './reducer';

export interface FormSelectors<FormType> {
  getForm: (_: any) => FormState<FormType>['form'];
  getIsValid: (_: any) => FormState<FormType>['isValid'];
  getValidation: (_: any) => FormState<FormType>['validation'];
  getTouched: (_: any) => FormState<FormType>['touched'];
  getErrors: (_: any) => FormState<FormType>['errors'];
  getIsSubmitted: (_: any) => FormState<FormType>['isSubmitted'];
}

export function createSelectors<FormType>(
  reducerPath: string,
  formName: string
): FormSelectors<FormType> {
  const getFormState = (state: any) =>
    get(state, `${reducerPath}.${formName}.form`, null);
  const getForm = createSelector([getFormState], (state) => state);

  const getIsValidState = (state: any) =>
    get(state, `${reducerPath}.${formName}.isValid`, null);
  const getIsValid = createSelector([getIsValidState], (state) => state);

  const getValidationState = (state: any) =>
    get(state, `${reducerPath}.${formName}.validation`, null);
  const getValidation = createSelector([getValidationState], (state) => state);

  const getTouchedState = (state: any) =>
    get(state, `${reducerPath}.${formName}.touched`, null);
  const getTouched = createSelector([getTouchedState], (state) => state);

  const getErrorState = (state: any) =>
    get(state, `${reducerPath}.${formName}.errors`, null);
  const getErrors = createSelector([getErrorState], (state) => state);

  const getIsSubmittedState = (state: any) =>
    get(state, `${reducerPath}.${formName}.isSubmitted`, null);
  const getIsSubmitted = createSelector(
    [getIsSubmittedState],
    (state) => state
  );

  return {
    getForm,
    getIsValid,
    getValidation,
    getTouched,
    getErrors,
    getIsSubmitted,
  };
}
