import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { type PaperProps } from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
  type ApproveSegmentsRequest,
  type DetailedFlightRequestViewModelV2,
  FRStatusCode,
  getStatusColor,
  SegmentClearanceStatusCode,
} from 'fims-api-types';

import { useFocussedFlightRequest } from '../../../../../state/focussed-flight-request';
import * as FIMSApi from '../../../../../clients/fims-api-client';
import ApproveSegmentsDialogContent from './approve-segments-dialog-content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogButton: {
      height: 36,
      width: 94,
    },
    characterCount: {
      float: 'right',
    },
    dialogContent: {
      height: 180,
      width: 480,
    },
    errorMessage: {
      color: theme.palette.error.main,
      position: 'absolute',
      bottom: theme.spacing(6),
      right: theme.spacing(3),
    },
  })
);

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  focussedStatus: {
    canAuthorize: boolean;
    code: FRStatusCode;
  };
  style: object;
  authorizerName?: string;
}

const ApproveSegments = ({
  focussedFlightRequest,
  focussedStatus,
  style = {},
}: Props) => {
  const [open, setOpen] = useState(false);
  const [approveSegments, setApproveSegments] =
    useState<ApproveSegmentsRequest>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { fetchFlightRequest } = useFocussedFlightRequest(
    focussedFlightRequest?.flightId
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setApproveSegments(
      focussedFlightRequest?.flightAreas?.map((area) => {
        return {
          segmentId: area.properties.segmentId,
          approve:
            area.properties.clearance === SegmentClearanceStatusCode.APPROVED,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickSend = async () => {
    setIsSubmitting(true);
    FIMSApi.approveSegments({
      flightId: focussedFlightRequest.flightId,
      segments: approveSegments,
    })
      .then(() => {
        setOpen(false);
        setIsSubmitting(false);
        fetchFlightRequest();
      })
      .catch((err: any) => {
        setErrorMessage(err.message);
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setErrorMessage('');
      setOpen(false);
    }
  };

  const classes = useStyles();

  const disabled = focussedStatus?.code !== FRStatusCode.Activated;

  if (focussedFlightRequest?.flightAreas?.length <= 1) {
    return null;
  }

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleClickOpen}
        variant="contained"
        style={
          disabled
            ? style
            : {
                ...style,
                color: 'white',
                backgroundColor: getStatusColor(FRStatusCode.Activated),
              }
        }
        data-testid="approve-segments-button"
      >
        Approve Segments
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        data-testid="approve-segments-dialog"
        PaperComponent={PaperComponent}
      >
        <DialogTitle
          id="approve-segments-draggable-title"
          data-testid="approve-segments-dialog-title"
        >
          Approve Segments
        </DialogTitle>
        <ApproveSegmentsDialogContent
          focussedFlight={focussedFlightRequest}
          approveSegments={approveSegments}
          setApproveSegments={setApproveSegments}
          errorMessage={errorMessage}
        />

        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="outlined"
            disabled={isSubmitting}
            className={classes.dialogButton}
            data-testid="approve-segments-dialog-cancel-button"
          >
            Cancel
          </Button>
          {isSubmitting ? (
            <Button
              className={classes.dialogButton}
              disabled
              variant="contained"
              data-testid="approve-segments-dialog-submit-button-disabled"
            >
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button
              onClick={handleClickSend}
              color="primary"
              className={classes.dialogButton}
              variant="contained"
              data-testid="approve-segments-dialog-send-button"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#approve-segments-draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default ApproveSegments;
