import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';

import {
  FRStatusCode,
  DetailedFlightRequestViewModelV2,
  getStatusColor,
  AuthorizerType,
  UserRole,
  resolveSingleStatus,
} from 'fims-api-types';

import {
  activateFlightRequest,
  terminateFlightRequest,
} from '../../../../clients/fims-api-client';
import { LoginContext } from '~/context/LoginState';
import { useFocussedFlightRequest } from '../../../../state/focussed-flight-request';
import { useProfile } from '../../../../state/session';

import SendTxtButton from './send-txt-button/send-txt-button';
import StatusConfirmationDialog from './status-confirmation-dialog/status-confirmation-dialog';
import ConfirmationDialog from './confirmation-dialog/confirmation-dialog';
import ApproveSegments from './approve-segments/approve-segments';
import { DeclaredButton } from './declared-button/declared-button';

const buttonStyles = {
  marginLeft: '15px',
  width: 100,
  fontFamily: 'Calibri',
  padding: '6px 16px',
  fontSize: '0.875rem',
  minWidth: '64px',
  lineHeight: 1.75,
};

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  focussedStatus: {
    canAuthorize: boolean;
    code: FRStatusCode;
  };
}

export default function ActionPanel({
  focussedFlightRequest,
  focussedStatus,
}: Readonly<Props>) {
  const { airspaceIsClosed } = useContext(LoginContext);
  const { authorizer, controlZones, roles } = useProfile();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [atcNote, setAtcNote] = useState('');
  const [operatorNote, setOperatorNote] = useState('');
  const [newStatus, setNewStatus] = useState<FRStatusCode | null>(null);
  const [submitter, setSubmitter] = useState('');
  const [isActionable, setIsActionable] = useState<boolean>(false);
  const [isWithinControlZone, setIsWithinControlZone] =
    useState<boolean>(false);
  const [isActivationAllowed, setIsActivationAllowed] =
    useState<boolean>(false);
  const [canBeTerminated, setCanBeTerminated] = useState(false);

  const { focussedSubmitter } = useFocussedFlightRequest(
    focussedFlightRequest?.flightId
  );

  useEffect(() => {
    const isSuperAdmin = roles.includes(UserRole.SUPER_ADMIN);
    const isAllowed =
      (roles.includes(UserRole.AUTHORISER) || roles.includes(UserRole.ADMIN)) &&
      !roles.includes(UserRole.VIEWER);
    if (authorizer?.type === AuthorizerType.ControlZone || isSuperAdmin) {
      const userHasMatchingControlZone =
        (focussedStatus?.canAuthorize !== false &&
          !!focussedFlightRequest?.controlZoneCode &&
          !!controlZones?.includes(focussedFlightRequest.controlZoneCode) &&
          isAllowed) ||
        isSuperAdmin;
      setIsActionable(userHasMatchingControlZone);
      setIsWithinControlZone(userHasMatchingControlZone);

      const flightDateTime = DateTime.fromISO(
        focussedFlightRequest?.localStartDateTime
      );
      const flightLocalEndDateTime = DateTime.fromISO(
        focussedFlightRequest?.localEndDateTime
      ).toLocal();
      //Check that the flight is for today and that the flight is due to start more than 30 minutes from now
      if (
        !airspaceIsClosed &&
        focussedStatus?.code === FRStatusCode.ProvAuth &&
        flightDateTime.hasSame(DateTime.local(), 'day') &&
        flightLocalEndDateTime >= DateTime.local() &&
        focussedFlightRequest?.flightAreas?.length <= 1 &&
        resolveSingleStatus(focussedFlightRequest.status) !==
          FRStatusCode.Pending
      ) {
        setIsActivationAllowed(userHasMatchingControlZone);
      } else {
        setIsActivationAllowed(false);
      }
    } else {
      setIsActionable(focussedStatus?.canAuthorize !== false && isAllowed);
      setIsWithinControlZone(false);
    }
  }, [
    authorizer,
    focussedStatus,
    setIsActionable,
    setIsWithinControlZone,
    controlZones,
    focussedFlightRequest,
    roles,
    setIsActivationAllowed,
    airspaceIsClosed,
  ]);

  useEffect(() => {
    setCanBeTerminated(
      focussedStatus?.code === FRStatusCode.Activated && isActionable
    );
  }, [focussedStatus, isActionable]);

  const handleOpenDialog = (status: FRStatusCode, chosenSubmitter: string) => {
    setNewStatus(status);
    setSubmitter(chosenSubmitter);
    setDialogOpen(true);
  };

  const disabledButtons = {
    [FRStatusCode.ProvAuth]:
      airspaceIsClosed ||
      focussedStatus?.code === FRStatusCode.ProvAuth ||
      focussedStatus?.code === FRStatusCode.Activated,
    [FRStatusCode.Authorized]:
      airspaceIsClosed ||
      focussedStatus?.code === FRStatusCode.Authorized ||
      focussedStatus?.code === FRStatusCode.Activated,
    [FRStatusCode.Declared]:
      airspaceIsClosed || focussedStatus?.code === FRStatusCode.Declared,
    [FRStatusCode.Declined]: focussedStatus?.code === FRStatusCode.Declined,
    [FRStatusCode.Activated]: focussedStatus?.code === FRStatusCode.Activated,
  };

  const handleActivateFlight = async () => {
    if (isActivationAllowed && !canBeTerminated) {
      setConfirmationDialogOpen(true);
    }
  };

  const handleTerminateFlight = async () => {
    if (canBeTerminated) {
      setConfirmationDialogOpen(true);
    }
  };

  const confirmationDialogCallback = async () => {
    if (canBeTerminated) {
      await terminateFlightCallback();
    } else {
      await activateFlightCallback();
    }
  };

  const terminateFlightCallback = async () => {
    if (canBeTerminated) {
      const terminationResponse = await terminateFlightRequest(
        focussedFlightRequest.flightId
      );
      setCanBeTerminated(false);
      const apiResponse = await terminationResponse;
      if (apiResponse?.data?.errors) {
        return { errors: apiResponse?.data?.errors };
      } else {
        return { success: true };
      }
    } else {
      return {
        errors:
          'Flight cannot be activated, flight strip has already been printed',
      };
    }
  };

  const activateFlightCallback = async () => {
    if (isActivationAllowed) {
      const activationResponse = await activateFlightRequest(
        focussedFlightRequest.flightId
      );
      setIsActivationAllowed(false);
      const apiResponse = await activationResponse;
      if (apiResponse?.data?.errors) {
        return { errors: apiResponse?.data?.errors };
      } else {
        return { success: true };
      }
    } else {
      return {
        errors:
          'Flight cannot be activated, flight strip has already been printed',
      };
    }
  };

  if (!isActionable) {
    return null;
  }

  return (
    <>
      <StatusConfirmationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        newStatus={newStatus}
        atcNote={atcNote}
        operatorNote={operatorNote}
        setAtcNote={setAtcNote}
        setOperatorNote={setOperatorNote}
        submitter={submitter}
        focussedSubmitter={focussedSubmitter}
        flightRequest={focussedFlightRequest}
      />
      <ConfirmationDialog
        title={canBeTerminated ? 'Terminate Flight' : 'Activate Flight'}
        submitCallback={confirmationDialogCallback}
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        warning={
          canBeTerminated
            ? 'This will immediately mark the flight as terminated. This should only be done when the pilot is unable to mark the flight as terminated themselves.'
            : 'This action changes the start time of the flight. No temporal airspace restrictions, daylight tables or conflicts with other flight requests are rechecked.'
        }
      />
      <div
        style={{
          position: 'relative',
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          width: 'calc(100% - 1rem)',
          maxHeight: '61px',
        }}
        data-testid="flight-decision-triggers"
      >
        <div
          data-testid="left-action-panel-buttons"
          style={{ display: 'flex' }}
        >
          <SendTxtButton style={buttonStyles} flight={focussedFlightRequest} />
          {authorizer?.type === AuthorizerType.ControlZone && (
            <>
              {canBeTerminated && (
                <Button
                  style={
                    canBeTerminated
                      ? {
                          ...buttonStyles,
                          backgroundColor: getStatusColor(
                            FRStatusCode.Declined
                          ),
                          color: 'white',
                        }
                      : buttonStyles
                  }
                  onClick={() => handleTerminateFlight()}
                  variant="contained"
                  data-testid="flight-terminate-button"
                >
                  Terminate Flight
                </Button>
              )}
              {!canBeTerminated && (
                <Button
                  style={
                    isActivationAllowed
                      ? {
                          ...buttonStyles,
                          color: 'white',
                          backgroundColor: getStatusColor(
                            FRStatusCode.Activated
                          ),
                        }
                      : buttonStyles
                  }
                  disabled={!isActivationAllowed}
                  onClick={() => handleActivateFlight()}
                  variant="contained"
                  data-testid="flight-activate-button"
                >
                  Activate Flight
                </Button>
              )}
            </>
          )}
        </div>
        <div
          data-testid="right-action-panel-buttons"
          style={{ display: 'flex' }}
        >
          <ApproveSegments
            style={buttonStyles}
            focussedFlightRequest={focussedFlightRequest}
            focussedStatus={focussedStatus}
            authorizerName={authorizer.name}
          />
          <Button
            disabled={
              isWithinControlZone
                ? disabledButtons[FRStatusCode.ProvAuth]
                : disabledButtons[FRStatusCode.Authorized]
            }
            onClick={() => {
              if (isWithinControlZone) {
                handleOpenDialog(
                  FRStatusCode.ProvAuth,
                  'provisionally-authorize'
                );
              } else {
                handleOpenDialog(FRStatusCode.Authorized, 'authorize');
              }
            }}
            value="provisionally-authorize"
            variant="contained"
            style={
              isWithinControlZone
                ? disabledButtons[FRStatusCode.ProvAuth]
                  ? buttonStyles
                  : {
                      ...buttonStyles,
                      backgroundColor: getStatusColor(FRStatusCode.ProvAuth),
                      color: 'white',
                    }
                : disabledButtons[FRStatusCode.Authorized]
                  ? buttonStyles
                  : {
                      ...buttonStyles,
                      backgroundColor: getStatusColor(
                        isWithinControlZone
                          ? FRStatusCode.ProvAuth
                          : FRStatusCode.Authorized
                      ),
                      color: 'white',
                    }
            }
            data-testid="flight-authorize-button"
          >
            {isWithinControlZone ? 'Prov. Authorise' : 'Authorise'}
          </Button>
          <Button
            disabled={
              disabledButtons[FRStatusCode.Declined] ||
              disabledButtons[FRStatusCode.Activated]
            }
            onClick={() => handleOpenDialog(FRStatusCode.Declined, 'decline')}
            value="decline"
            variant="contained"
            style={
              disabledButtons[FRStatusCode.Declined] ||
              disabledButtons[FRStatusCode.Activated]
                ? buttonStyles
                : {
                    ...buttonStyles,
                    backgroundColor: getStatusColor(FRStatusCode.Declined),
                    color: 'white',
                  }
            }
            data-testid="flight-decline-button"
          >
            Decline
          </Button>
          <DeclaredButton
            style={buttonStyles}
            isWithinControlZone={isWithinControlZone}
            handleOpenDialog={handleOpenDialog}
            disabledButtons={disabledButtons}
          />
        </div>
      </div>
    </>
  );
}
