import { ManagedAreaCode, MapCodeType } from '@airshare/external-api-types';

const defaultLogo = {
  category: 'tempory area',
  logo: {
    category: 'aeropath',
    code: 'r',
  },
};

export const genericDefaultInfo = {
  minZoom: 10,
  mapZoom: 10,
  isMapLayer: true,
  geoQueryInclude: true,
};

type MapCodeStyleInfo = {
  mapCode: MapCodeType;
  pilotVisible: boolean;
  tileDisplay?: boolean;
  atcVisible: boolean;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
  category: string;
  logo: {
    category: string;
    code: string;
  };
};

const exclusiveZoneInfo: MapCodeStyleInfo = {
  mapCode: 'r',
  pilotVisible: true,
  tileDisplay: false,
  atcVisible: true,
  strokeColor: '#7f5bd4',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#7f5bd4',
  fillOpacity: 0.25,
  ...defaultLogo,
};

const flightPathInfo: MapCodeStyleInfo = {
  mapCode: 'r',
  pilotVisible: false,
  tileDisplay: false,
  atcVisible: true,
  strokeColor: '#7f5bd4',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#7f5bd4',
  fillOpacity: 0.25,
  ...defaultLogo,
};

const NFZInfo: MapCodeStyleInfo = {
  mapCode: 'nfz',
  pilotVisible: true,
  atcVisible: true,
  strokeColor: '#00BEB6',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#00BEB6',
  fillOpacity: 0.2,
  ...defaultLogo,
};

const EmergencyAirspaceClosureInfo: MapCodeStyleInfo = {
  mapCode: 'r',
  pilotVisible: true,
  atcVisible: true,
  strokeColor: '#00BEB6',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#00BEB6',
  fillOpacity: 0.2,
  ...defaultLogo,
};

const aerodromeInfo: MapCodeStyleInfo = {
  mapCode: 'ad',
  pilotVisible: true,
  atcVisible: true,
  strokeColor: '#0078d4',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#0078d4',
  fillOpacity: 0.25,
  category: 'ad',
  logo: {
    category: 'aeropath',
    code: 'ad',
  },
};

const unknownInfo: MapCodeStyleInfo = {
  mapCode: 'unknown',
  pilotVisible: true,
  atcVisible: true,
  strokeColor: 'black',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: 'black',
  fillOpacity: 0.2,
  ...defaultLogo,
};

const advisoryInfo: MapCodeStyleInfo = {
  mapCode: 'r',
  pilotVisible: true,
  atcVisible: false,
  tileDisplay: false,
  strokeColor: '#7f5bd4',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#7f5bd4',
  fillOpacity: 0.25,
  ...defaultLogo,
};

export function getMapCodeStyleInfo(
  type?: ManagedAreaCode | 'unknown',
  isVisible?: boolean,
  isTileDisplay?: boolean
): MapCodeStyleInfo {
  switch (type) {
    case ManagedAreaCode.NFZ:
      return NFZInfo;
    case ManagedAreaCode.ExclusiveZone:
      return exclusiveZoneInfo;
    case ManagedAreaCode.FlightPath:
      return {
        ...flightPathInfo,
        pilotVisible:
          isVisible !== undefined ? isVisible : flightPathInfo.pilotVisible,
      };
    case ManagedAreaCode.EmergencyAirspaceClosure:
      return EmergencyAirspaceClosureInfo;
    case ManagedAreaCode.Advisory:
      return {
        ...advisoryInfo,
        pilotVisible:
          isVisible !== undefined ? isVisible : advisoryInfo.pilotVisible,
        tileDisplay: isTileDisplay ?? advisoryInfo.tileDisplay,
      };
    case ManagedAreaCode.Aerodrome:
      return aerodromeInfo;
    case 'unknown':
      return unknownInfo;
    default:
      return advisoryInfo;
  }
}
