import type { Feature, Geometry, Polygon, MultiPolygon } from '@turf/turf';

import type {
  AdditionalInfo,
  LatLng,
  ManagedAreaAudit,
  PagedResponse,
  UasOperationCategory,
  UserRole,
  UserStatus,
} from '@airshare/external-api-types';

export { UasOperationCategory, UserRole, UserStatus };
export { versionDescription } from 'argus-common/version';
import type {
  Area,
  AuditLogViewModel,
  CertificationType,
  CtrSettings,
  RequiredAuthChange,
  SegmentClearanceStatusCode,
  SegmentStatusCode,
} from '.';

import type { Uav } from 'argus-data-model/db/schemas/flight-request/_uav';
import type { UavModel } from 'argus-data-model/db/schemas/uav-manufacturer';
import type { Note } from 'argus-data-model/db/schemas/flight-request/_note';
import type { SmsDelivery } from 'messaging/src/sms/send-sms';

import type { Status } from 'argus-data-model/db/schemas/flight-request/_status';
import type { FlightRequest } from 'argus-data-model/db/schemas/flight-request';
import type { FlightLocation } from 'argus-data-model/db/schemas/flight-request/_flight-location';

import type { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';
import type { RequestToPilot } from 'argus-data-model/db/schemas/flight-request/_additional-info';

export * from './flight-audit';
export * from './organisation-interfaces';

export type GetCtrZoneSettingsResponseBody =
  | CtrSettings[]
  | { message: string };

export interface SetCtrSettingsPayload {
  ctrCode: string;
  digitalAuthorization: boolean;
}

export interface FlightAreaViewModel {
  flightId: number;
  coordinates: LatLng[];
}

export interface FlightContact {
  givenName: string;
  familyName: string;
  mobileNo: string;
  certification: string;
  company: string;
}

export interface FlightContactV2 {
  givenName: string;
  familyName: string;
  mobileNo: string;
  certification: string;
  company: string;
}

export interface NoteViewModel {
  authorName: string;
  text: string;
}

export interface RequireAuthChangeViewModel extends RequiredAuthChange {
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
}

export interface ConflictedFlightRequest {
  flightId: number;
  pilot: {
    givenName: string;
    familyName: string;
  };
  isShielded: boolean;
  controlZone: string;
  utcStartDateTime: string;
  utcEndDateTime: string;
  reportFormatStartDateTime: string;
  reportFormatEndDateTime: string;
  status: Status[];
  geometry: Geometry;
  flightAreas: StyledFeatures;
  deadCenter: LatLng;
}

export interface ConflictGroup {
  conflictId: number;
  flightRequests: ConflictedFlightRequest[];
}

export interface DetailedFlightRequestViewModelV2
  extends BasicFlightRequestView {
  address: string;
  launchElevationFeet: number;
  utcStartDateTime: string;
  utcEndDateTime: string;
  pilot: FlightContactV2;
  accountContact: FlightContactV2;
  company: string;
  nickname: string;
  createdAt: string;
  durationMinutes: number;
  maxAltitudeFeet: number;
  flightType: string;
  isCameraInUse: boolean;
  isPriorityFlight?: boolean;
  uasOperationCategory?: UasOperationCategory;
  isShielded: boolean;
  otherInformation: string;
  emergencyProcedure: string;
  controlZoneCode: string;
  uav: Uav;
  auditLog: AuditLogViewModel[];
  conflicts: ConflictedFlightRequest[];
  atcNotes: string[];
  requiredAuthChanges?: RequireAuthChangeViewModel[];
  authorizationAreaIds?: string[];
  exceededAllocatedTime: boolean;
}

export enum IconType {
  Alert = 'Alert',
  RequestToLand = 'RequestToLand',
  RequestingClearance = 'RequestingClearance',
  CallTower = 'CallTower',
  ExceedAllocatedTime = 'ExceedAllocatedTime',
  None = '',
  IsPriorityFlight = 'IsPriorityFlight',
}

export type StyledFeature = Feature<
  Area,
  {
    segmentId: string | null;
    name: string;
    title: string;
    status?: SegmentStatusCode;
    clearance?: SegmentClearanceStatusCode;
    leadSegment: boolean;
    flightId: number;
    pilot: string;
    altitude: string;
    rule: string;
    localStartDateTime: string;
    localEndDateTime: string;
    exceededAllocatedTime: boolean;
    areaStyling: {
      strokeColor: string;
      fillColor: string;
      fillOpacity: number;
      strokeOpacity: number;
      strokeWeight: number;
    };
    markerStyling: {
      center: LatLng;
      icon: IconType;
      icons?: IconType[];
      color: string;
    };
    additionalInfo?: AdditionalInfo;
    segmentStreetAddress?: string;
  }
>;
export type StyledFeatures = StyledFeature[];

export enum ReadableOperationType {
  Commercial = 'Commercial',
  Recreational = 'Recreational',
  UamOperation = 'UAM Operation',
  None = 'None',
}

export enum ReadableProcedureToMeasureAltitude {
  DJI = 'DJI',
  Tethered = 'Tethered',
  VisualHeightKeeping = 'Visual Height Keeping',
  None = 'None',
}

interface BasicFlightRequestView {
  id: string;
  flightId: number;
  flightType: string;
  isShielded: boolean;
  hasCertifiedTransmitter: boolean;
  hasVhfRadioContact: boolean;
  descriptionOfOperatingArea: string;
  procedureToMeasureAltitude: string;
  emergencyProcedure: string;
  flightPurpose: string;
  controlZone: string;
  status: Status[];
  requestToLand?: boolean;
  requestsToPilot?: RequestToPilot[];
  geometry: FlightRequest['geometry'];
  flightAreas: StyledFeatures;
  deadCenter: LatLng;
  rule: CertificationType;
  timezone: string;
  localStartDateTime: string;
  localEndDateTime: string;
}

export interface FlightRequestViewModel extends BasicFlightRequestView {
  pilot: {
    givenName: string;
    familyName: string;
    mobileNo?: string;
  };
  digitalClearance?: {
    status?: string;
    updatedAt?: Date;
    createdAt?: Date;
  };
  streetAddress?: string;
  atcNotes: Note[];
  createdOn: Date;
  company: string;
  isCameraInUse: boolean;
  isPriorityFlight?: boolean;
  vehicle: string;
  maxWeight: string;
  duration: number;
  dateTime: string;
  flightLocation?: FlightLocation;
}

export interface FlightRequestsViewModelPaged extends PagedResponse {
  data: FlightRequestViewModel[];
}

export interface PilotViewModel {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  mobile: string;
  company: string;
  status: UserStatus;
  operatorType: string;
  certificationType: string;
}

export interface PilotViewModelPaged extends PagedResponse {
  data: PilotViewModel[];
}

export interface PilotUpdateForm
  extends Omit<PilotViewModel, 'email' | 'company'> {}

export interface UavViewModel {
  name: string;
  manufacturer: string;
  model: string;
  weight: string; // ! FIXME This can be tightened up
  serialNumber: string;
  pilot: {
    familyName: string;
    givenName: string;
    company: string;
    email: string;
  };
}

export interface UavViewModelPaged extends PagedResponse {
  data: UavViewModel[];
}

export interface UavManufacturersCreateForm {
  name: string;
  isActive: boolean;
  models: UavModel[];
}

export interface UavManufacturersUpdateForm extends UavManufacturersCreateForm {
  _id: string;
}

export interface UavManufacturersViewModel {
  _id?: string;
  name: string;
  isActive: boolean;
  models: UavModel[];
}

interface ManagedAirspaceProperties {
  activity?: string;
  lowerLimitFeet: number;
  upperLimitFeet?: number;
  workinghours?: string;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
  code: ManagedAreaCode;
  icaoCode?: string;
  name: string;
  description: string;
  category?: string;
  mapCode?: string;
  minZoom: number;
  pilotVisible: boolean;
  tileDisplay?: boolean;
  atcVisible: boolean;
  isMapLayer: boolean;
  geoQueryInclude: boolean;
  isActive: boolean;
  logo?: {
    category: string;
    code: string;
  };
  siteName?: string;
  address?: string;
  phone?: string;
  email?: string;
  startDateTime: string;
  endDateTime?: string;
  authorizedPilots?: string[];
}

export interface ManagedAirspaceViewModel {
  id?: string;
  type: string;
  geometry: Polygon | MultiPolygon;
  properties?: ManagedAirspaceProperties;
  auditLog?: ManagedAreaAudit[];
}

export enum ErrorCode {
  FlightNotFound = 'FLIGHT_NOT_FOUND',
  FlightIdRequired = 'FLIGHT_ID_REQUIRED',
  AtcNoteRequired = 'ATC_NOTE_REQUIRED',
  OperatorNoteRequired = 'OPERATOR_NOTE_REQUIRED',
  StatusCodeRequired = 'STATUS_CODE_REQUIRED',
  StatusCodeInvalid = 'STATUS_CODE_INVALID',
  UnexpectedError = 'UNEXPECTED_ERROR',
  UserNotAuthorized = 'USER NOT AUTHORISED',
  OutsideValidAuthTime = 'OUTSIDE_VALID_AUTH_TIME',
  InvalidInput = 'INVALID_INPUT',
}

export interface UserViewModel {
  id: string;
  email: string;
  name: {
    givenName: string;
    familyName: string;
  };
  mobileNo: string;
  role: UserRole;
  status: UserStatus;
  organisation?: string;
  controlZones?: string[];
  allowedIpAddresses?: string;
}

export interface UserCreationForm extends Omit<UserViewModel, 'id'> {
  password: string;
}

export interface SmsInputData {
  message: string;
  messageId: string;
  flightId: number;
  deliveryType: SmsDelivery;
  from?: string;
  auth?: string;
}

export interface UserUpdateForm
  extends Omit<UserViewModel, 'email' | 'password'> {}

export interface BackgroundJobStatus {
  name: string;
  id: string;
  status: string;
  children: {
    completed: number;
    pending: number;
    error: number;
    total: number;
  };
}

import { ATC_AUTH_DEFAULT_NAME } from 'argus-api-shared/lib/constants';

export { ATC_AUTH_DEFAULT_NAME };
