import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { Typography } from '@material-ui/core';

import {
  UserRole,
  type Profile,
  AuthorizerType,
  DetailedFlightRequestViewModelV2,
} from 'fims-api-types';

import { postSMS } from '../../../../../clients/fims-api-client';
import { useProfile } from '../../../../../state/session';
import { useFocussedFlightRequest } from '../../../../../state/focussed-flight-request';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 36,
      width: 94,
    },
    characterCount: {
      float: 'right',
    },
    dialogContent: {
      height: 180,
      width: 480,
    },
    errorMessage: {
      color: theme.palette.error.main,
      position: 'absolute',
      bottom: theme.spacing(6),
      right: theme.spacing(3),
    },
  })
);

interface Props {
  flight: DetailedFlightRequestViewModelV2;
  style: object;
}

const getSmsHeader = (authorizer: Profile['authorizer']) => {
  switch (authorizer.type) {
    case AuthorizerType.ControlZone:
      return `${authorizer.name}: `;
    case AuthorizerType.Aerodrome:
      return `From ${authorizer.name} aerodrome: `;
    case AuthorizerType.AirspaceOwner:
      return `From ${authorizer.name}: `;
    default:
      return '';
  }
};

export default function SendTxtButton({ flight, style }: Readonly<Props>) {
  const { authorizer, roles } = useProfile();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [textfieldValue, setTextfieldValue] = useState<string>('');

  const { fetchFlightRequest } = useFocussedFlightRequest(flight?.flightId);

  const maxSmsLength = 459;
  const smsHeader = getSmsHeader(authorizer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickSend = async () => {
    if (textfieldValue.length + smsHeader.length > maxSmsLength) {
      setErrorMessage(`Message length exceeds ${maxSmsLength} characters`);
      return;
    }
    if (!textfieldValue.length) {
      setErrorMessage('Message cannot be empty');
      return;
    }
    setIsSubmitting(true);
    const data = {
      message: smsHeader + textfieldValue,
      flightId: flight?.flightId,
    };
    postSMS(data)
      .then(() => {
        setOpen(false);
        setTextfieldValue('');
        setIsSubmitting(false);
        fetchFlightRequest();
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setErrorMessage('');
      setTextfieldValue('');
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextfieldValue(event.target.value);
  };

  const classes = useStyles();

  const inAreaOfAuthorizer =
    !!flight?.status?.find((s) => s.authorizer === authorizer?.name) ||
    authorizer.type === AuthorizerType.ControlZone;
  const disabled =
    !inAreaOfAuthorizer ||
    !roles?.some((r) =>
      [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.AUTHORISER].includes(r)
    );

  return (
    <>
      <Button
        disabled={disabled}
        color="primary"
        onClick={handleClickOpen}
        variant="contained"
        style={{ ...style }}
        data-testid="send-sms-button"
      >
        Send SMS
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        data-testid="send-sms-dialog"
      >
        <DialogTitle data-testid="send-sms-dialog-title">
          Send SMS to Pilot
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography data-testid="send-sms-dialog-header">
            {smsHeader}
          </Typography>
          <TextField
            margin="dense"
            data-testid="send-sms-dialog-text-message"
            label="Message"
            variant="outlined"
            type="text"
            rows={4}
            fullWidth
            multiline
            value={textfieldValue}
            onChange={handleChange}
            inputProps={{ maxLength: maxSmsLength - smsHeader.length }}
          />
          <Typography
            className={classes.characterCount}
            style={{
              color:
                textfieldValue.length + smsHeader.length > maxSmsLength
                  ? '#f44336'
                  : 'inherit',
            }}
            data-testid="send-sms-dialog-text-length"
          >
            {textfieldValue.length + smsHeader.length} / {maxSmsLength}
          </Typography>

          <Typography
            className={classes.errorMessage}
            data-testid="send-sms-dialog-error-message"
          >
            {errorMessage}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="outlined"
            disabled={disabled || isSubmitting}
            className={classes.button}
            data-testid="send-sms-dialog-cancel-button"
          >
            Cancel
          </Button>
          {isSubmitting ? (
            <Button
              className={classes.button}
              disabled
              variant="contained"
              data-testid="send-sms-dialog-submit-button-disabled"
            >
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button
              onClick={handleClickSend}
              color="primary"
              className={classes.button}
              startIcon={<SendIcon />}
              variant="contained"
              data-testid="send-sms-dialog-send-button"
            >
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
