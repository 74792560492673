import { FRStatusCode } from '@airshare/external-api-types';

export function resolveSingleStatus<A extends { code: FRStatusCode }>(
  status: A[] | undefined
): FRStatusCode {
  if (!status?.length) {
    return FRStatusCode.Declared;
  }

  if (status.some((x) => x.code === FRStatusCode.Terminated))
    return FRStatusCode.Terminated;
  if (status.some((x) => x.code === FRStatusCode.Cancelled))
    return FRStatusCode.Cancelled;
  if (status.some((x) => x.code === FRStatusCode.Activated))
    return FRStatusCode.Activated;
  if (status.some((x) => x.code === FRStatusCode.RNZAFAuthReq))
    return FRStatusCode.RNZAFAuthReq;
  if (status.some((x) => x.code === FRStatusCode.Declined))
    return FRStatusCode.Declined;
  if (status.some((x) => x.code === FRStatusCode.Conflicted))
    return FRStatusCode.Conflicted;
  if (status.some((x) => x.code === FRStatusCode.Pending))
    return FRStatusCode.Pending;
  if (status.some((x) => x.code === FRStatusCode.ProvAuth))
    return FRStatusCode.ProvAuth;
  if (status.some((x) => x.code === FRStatusCode.Authorized))
    return FRStatusCode.Authorized;
  if (status.some((x) => x.code === FRStatusCode.Declared))
    return FRStatusCode.Declared;
  return FRStatusCode.Declared;
}

export function formatStatus(
  status: string | null,
  audienceIsATC: boolean
): string | null {
  switch (status) {
    case FRStatusCode.RNZAFAuthReq:
      return process.env.ENVIRONMENT === 'demo'
        ? 'RAF Auth. Req.'
        : 'RNZAF Auth. Req.';
    case FRStatusCode.Terminated:
      return audienceIsATC ? 'Terminated' : FRStatusCode.Terminated;
    case FRStatusCode.Authorized:
      return 'Authorised';
    default:
      return status || null;
  }
}

export function getStatusText<A extends { code: FRStatusCode }>(
  status: string | A[],
  audienceIsATC: boolean
) {
  const singleStatus = Array.isArray(status)
    ? resolveSingleStatus(status)
    : status;
  return formatStatus(singleStatus, audienceIsATC);
}

export const getConsolidatedStatus = <
  A extends { code: FRStatusCode; canAuthorize: boolean },
>(
  statusList: A[],
  matchingStatus?: A
): {
  code: FRStatusCode;
  canAuthorize: boolean;
} => ({
  code: matchingStatus?.code || resolveSingleStatus(statusList),
  canAuthorize: !!matchingStatus?.canAuthorize,
});
