import React, { useState, useEffect, useRef } from 'react';
import { Badge, Button, CircularProgress, Typography } from '@material-ui/core';
import { PowerSettingsNewOutlined } from '@material-ui/icons';
import AppBarMenu from '../app-bar-menu';
import { DateTime } from 'luxon';
import * as fimsClient from '../../../../clients/fims-api-client';
import { useProfile } from '~/state/session';
import { setProfile } from '~/state/session/actions';
import store from '~/state/store';
import { TowerOffWatchBanner } from '../../../../components/protected-layout/home-screen/map/tower-off-watch-banner';
import { useStyles } from './useStyles';
import { Profile, UserRole } from 'fims-api-types';
import { useLocation } from 'react-router';

export function OnOffButton() {
  function getNow() {
    return DateTime.now().set({ millisecond: 0, second: 0 }).toJSDate();
  }

  const [now, setNow] = useState(getNow());
  const [loading, setLoading] = useState(false);
  const [intervalEnabled, setIntervalEnabled] = useState(true);
  const sessionProfile = useProfile();
  const roles = sessionProfile.roles;
  const [profile, setLocalProfile] = useState(sessionProfile);
  const location = useLocation();

  const classes = useStyles();
  const onWatch = profile.tower?.onWatch;
  const nextOnWatch = profile.tower?.nextOnWatch
    ? DateTime.fromISO(`${profile.tower?.nextOnWatch}`).toJSDate()
    : null;

  const canChangeStatus =
    roles.includes(UserRole.ADMIN) || roles.includes(UserRole.AUTHORISER);

  useEffect(() => {
    function getLocalTower() {
      const localProfile = localStorage.getItem('profile');
      return localProfile ? JSON.parse(localProfile).tower : null;
    }
    const tower = profile.tower;
    if (!tower) {
      return;
    }
    const localTowerProfile = getLocalTower();
    if (!localTowerProfile) {
      return;
    }
    const hasChanges =
      localTowerProfile.onWatch !== tower.onWatch ||
      localTowerProfile.nextOnWatch !== tower.nextOnWatch ||
      localTowerProfile.todayOffWatch !== tower.todayOffWatch;

    if (hasChanges) {
      const localProfile = {
        ...profile,
        tower: localTowerProfile,
      };

      setLocalProfile(localProfile);
      store.dispatch(setProfile(localProfile));
    }
  }, [now, profile]);

  const UPDATE_INTERVAL = 1000;
  useEffect(() => {
    if (!profile.tower) {
      return;
    }

    const interval = setInterval(() => {
      const nowInterval = getNow();
      if (nowInterval.toISOString() !== now.toISOString()) {
        setNow(getNow());
      }
      if (!intervalEnabled) {
        clearInterval(interval);
      }
    }, UPDATE_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [profile, intervalEnabled, now]);

  const watchChangeHandler = async (
    action: 'on-watch' | 'off-watch',
    validFrom?: Date
  ) => {
    document.body.click();
    await updateWatchStatus(setLoading, profile, action, validFrom);
  };

  useEffect(() => {
    async function setOnWatch() {
      const hasError = await updateWatchStatus(setLoading, profile, 'on-watch');
      if (hasError) {
        setIntervalEnabled(false);
      }
    }
    if (!now) {
      return;
    }
    const shouldBeOnWatch =
      !loading && !onWatch && nextOnWatch && nextOnWatch <= now;
    if (shouldBeOnWatch) {
      setOnWatch();
    }
  }, [loading, nextOnWatch, now, onWatch, profile]);

  const cancelHandler = () => {
    document.body.click();
  };

  const cancelButton = (
    <Button
      variant="outlined"
      className={classes.secondaryButton}
      onClick={cancelHandler}
    >
      Cancel
    </Button>
  );

  const appBarContainerRef = useRef(null);

  return (
    <>
      <span ref={appBarContainerRef} data-testid="application-bar-tower-watch">
        <AppBarMenu
          label={
            <Badge className="tower-watch-badge">
              {!loading && (
                <PowerSettingsNewOutlined
                  className={
                    profile.tower?.onWatch ? classes.onWatch : classes.offWatch
                  }
                />
              )}
              {loading && (
                <CircularProgress
                  size={24}
                  className={
                    profile.tower?.onWatch ? classes.onWatch : classes.offWatch
                  }
                />
              )}
            </Badge>
          }
          contentTop={
            <>
              {canChangeStatus && (
                <>
                  {loading && (
                    <div className={classes.content}>
                      <CircularProgress />
                    </div>
                  )}
                  {!loading && (
                    <div className={classes.content}>
                      {!onWatch && (
                        <div>
                          <Typography variant="body1">
                            Do you want to start the tower watch early?
                          </Typography>
                          <div className={classes.actions}>
                            {cancelButton}
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={loading}
                              onClick={() => watchChangeHandler('on-watch')}
                            >
                              Set ON-WATCH
                            </Button>
                          </div>
                        </div>
                      )}
                      {onWatch && (
                        <div>
                          <Typography variant="body1">
                            Do you want to set the tower off-watch now?
                          </Typography>
                          <div className={classes.actions}>
                            {cancelButton}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => watchChangeHandler('off-watch')}
                            >
                              Set OFF-WATCH
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          }
          items={[]}
          placement="bottom-end"
        />
      </span>
      {location.pathname == '/' && !profile.tower?.onWatch && (
        <TowerOffWatchBanner
          actionHandler={() => {
            appBarContainerRef.current.children[0].click();
          }}
        />
      )}
    </>
  );
}

async function updateWatchStatus(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  profile: Profile,
  watch: 'on-watch' | 'off-watch',
  validFrom?: Date
) {
  let hasError = false;
  setLoading(true);
  try {
    const response = await fimsClient.setTowerWatch(watch, validFrom);
    profile.tower = {
      ...response.data,
      nextOnWatch: response.data.nextOnWatch,
      nextOffWatch: response.data.nextOffWatch,
      todayOffWatch: response.data.todayOffWatch,
    };
    localStorage.setItem('profile', JSON.stringify(profile));
    store.dispatch(setProfile(profile));
  } catch (error) {
    console.log('Error setting watch', error);
    hasError = true;
  }
  setLoading(false);
  return hasError;
}
