import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FlightsPerMonth } from '../../../clients/fims-api-client';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

const FlightRequestsPerMonth = ({
  className,
  data,
}: {
  className: string;
  data: FlightsPerMonth[];
}) => {
  const formattedData = {
    labels: data.map((x) => x.month),
    datasets: [
      {
        label: 'Total Flights',
        data: data.map((x) => x.total),
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };
  return (
    <div className={className} data-testid="airshare-flights-logged-graph">
      <Line options={options} data={formattedData} />
    </div>
  );
};

export default FlightRequestsPerMonth;
