import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  ListItem,
  Chip,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import type { DigitalAuthRequest } from 'fims-api-types';

import { AuthorizationActions } from './authorization-actions';
import FlightSummary from './flight-summary';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    priorityFlightPanel: {
      width: '12rem',
      display: 'flex',
      justifyContent: 'right',
    },
    priorityFlightChip: {
      background: '#ff9800',
      color: '#fff',
      borderColor: '#ff9800',
      padding: '0.5rem 1rem',
      width: '9rem',
      position: 'relative',
      boxShadow: '2px 2px 4px #ccc',
    },
  })
);

export default function ActivationRequest({
  digitalAuthRequest,
  updateDigitalAuthRequests,
  expanded,
  setExpanded,
  closeDrawer,
}: {
  digitalAuthRequest: DigitalAuthRequest;
  updateDigitalAuthRequests: () => Promise<void>;
  expanded: boolean;
  setExpanded: (id: string | false) => void;
  closeDrawer: () => void;
}) {
  const classes = useStyles();

  const displayFlightId = digitalAuthRequest.segmentId
    ? `${digitalAuthRequest.flightId}-${digitalAuthRequest.segmentId}`
    : digitalAuthRequest.flightId;

  return (
    <ListItem
      key={digitalAuthRequest.key}
      style={{ paddingBottom: 0 }}
      data-testid="authorisation-request-list-item"
    >
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(expanded ? false : digitalAuthRequest.key)}
        style={{ flexDirection: 'row-reverse' }}
      >
        <AccordionSummary
          id={`${digitalAuthRequest.key}-bh-header`}
          data-testid="digital-auth-header"
          aria-controls={`${digitalAuthRequest.key}-bh-content`}
          expandIcon={<ExpandMore />}
          style={{
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          <Typography
            variant="h6"
            style={{ marginLeft: '1rem' }}
            data-testid="authorisation-request-flight"
          >
            {`Activation Request: Flight ${displayFlightId}`}
          </Typography>
          {digitalAuthRequest.additionalInfo?.isPriorityFlight && (
            <div className={classes.priorityFlightPanel}>
              <Chip
                label="Priority Flight"
                className={classes.priorityFlightChip}
                data-testid="priority-flight-chip"
              />
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ minWidth: '200px' }}>
          <FlightSummary
            flightSummaryInfo={digitalAuthRequest}
            closeDrawer={closeDrawer}
          />
        </AccordionDetails>
        <Divider light />
        <AuthorizationActions
          flightId={digitalAuthRequest.flightId}
          updateFlightRequests={updateDigitalAuthRequests}
          segmentId={digitalAuthRequest.segmentId}
        />
      </Accordion>
    </ListItem>
  );
}
