import { io, ManagerOptions, Socket } from 'socket.io-client';
import { AuthEvent } from 'argus-events/auth';

export function createAuthenticatedSocket(
  url: string,
  refreshToken: Function,
  query: ManagerOptions['query'] = {}
): Promise<Socket> {
  const localToken = getLocalToken();
  const socket = io(url, {
    transports: ['websocket'],
    secure: true,
    auth: {
      token: localToken,
    },
    query,
  });

  return new Promise((resolve) => {
    socket.on('disconnect', (reason: string) => {
      console.info(`Socket disconnected: ${reason}`);
      socket.close();
    });

    socket.on('connect_error', (error: Error) => {
      console.error(
        `Error while connecting to socket: ${error.message} - Will retry`
      );
      socket.close();

      if (error.message === AuthEvent.AuthenticationFailed) {
        // Token has expired. We call an API to force the token to be refreshed through the
        // standard approach.
        console.info('Token expired. Websocket forcing refresh');
        refreshToken();
      }

      // Update the auth token (as it may have expired)
      const updatedLocalToken = getLocalToken();
      if (updatedLocalToken) {
        socket.auth = {
          token: updatedLocalToken,
        };
        socket.connect();
      }
    });

    socket.on('connect', () => {
      console.info('Socket connected');
      resolve(socket);
    });
  });
}

function getLocalToken() {
  const localProfile = localStorage.getItem('profile');
  return localProfile ? JSON.parse(localProfile)?.token : null;
}
