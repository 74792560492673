import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { ruleTypes } from 'argus-data-model/db/schemas/rules-config';
import React, { useContext } from 'react';
import { NodeContext } from 'react-flow-builder';

const startEndStyles: CSSProperties = {
  minHeight: '64px',
  minWidth: '80px',
  borderRadius: '50%',
  lineHeight: '64px',
  color: '#fff',
  textAlign: 'center',
  padding: '10px',
};
const textNodeStyles: CSSProperties = {
  maxWidth: '224px',
  borderRadius: '4px',
  color: '#666',
  background: '#fff',
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
  padding: '8px',
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    startNode: {
      ...startEndStyles,
      backgroundColor: '#338aff',
    },
    endNode: {
      ...startEndStyles,
      backgroundColor: '#666',
    },
    queryNode: {
      position: 'relative',
      minHeight: '120px',
      minWidth: '250px',
      maxWidth: '400px',
      textAlign: 'center',
      display: 'flex',
    },
    queryNodeSvg: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    queryNodeSvgPath: {
      fill: 'transparent',
      stroke: ' blue',
    },
    queryNodeText: {
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      textAlign: 'center',
    },
    queryResultNode: {
      ...textNodeStyles,
      minHeight: '24px',
      padding: '12px 16px',
    },
    actionNode: {
      ...textNodeStyles,
      maxHeight: '118px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
    },
    nodeTooltip: {
      visibility: 'hidden',
      minWidth: '400px',
      backgroundColor: 'silver',
      color: 'black',
      textAlign: 'center',
      padding: '5px 0',
      borderRadius: '6px',
      position: 'absolute',
      zIndex: 3,
    },
  })
);

const StartNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const classes = useStyles();
  return <div className={classes.startNode}>{node.name}</div>;
};

const EndNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const classes = useStyles();
  return (
    <div className={classes.endNode}>
      {node.name}
      <span className={classes.nodeTooltip}>{JSON.stringify(node.data)}</span>
    </div>
  );
};

const QueryNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const classes = useStyles();
  return (
    <div className={classes.queryNode}>
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        className={classes.queryNodeSvg}
      >
        <filter id="dropshadow" height="125%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <path
          d="M2,30 2,70 30,98 70,98 98,70 98,30 70,2 30,2z"
          className={classes.queryNodeSvgPath}
        />
      </svg>
      <div className={classes.queryNodeText}>{node.name}</div>
      <span className={classes.nodeTooltip}>{JSON.stringify(node.data)}</span>
    </div>
  );
};

const ActionNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const classes = useStyles();
  let className = classes.actionNode;
  if (
    node.data?.type === ruleTypes.ACTION &&
    node.data?.params &&
    node.data?.func === 'setStatusCode'
  ) {
    className = `${classes.actionNode} set-status-code-${node.data.params
      ?.toLowerCase()
      .replace('prov. auth.', 'prov-auth')
      .replace('rnzaf auth. req.', 'rnzaf-auth-req')}`;
  } else if (node.data?.type === ruleTypes.LIB && node.data?.func) {
    className = `${classes.actionNode} lib-${node.data?.func?.toLowerCase()}`;
  }
  return (
    <div className={className}>
      {node.name}
      <span className={classes.nodeTooltip}>{JSON.stringify(node.data)}</span>
    </div>
  );
};

const QueryResultNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const classes = useStyles();
  return <div className={classes.queryResultNode}>{node.name}</div>;
};

export default {
  StartNodeDisplay,
  EndNodeDisplay,
  QueryNodeDisplay,
  ActionNodeDisplay,
  QueryResultNodeDisplay,
};
