import { ToObjectOptions } from 'mongoose';
export interface Timestamps {
  createdAt?: Date;
  updatedAt?: Date;
}

export const requiredMessage = 'This field is required';

export const toJsonOptions: ToObjectOptions = {
  virtuals: true, // serialize any virtual properties
  getters: false, // serialize any getters, such as 'id' (which is added by mongoose dynamically)
  versionKey: false, // hide the _v property
  transform: (_: any, ret: any) => {
    // eslint-disable-next-line no-param-reassign
    delete ret._id; // don't serialize the '_id' property, as we are using the 'id' getter
    return ret;
  },
};
