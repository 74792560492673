import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { addRemoveElement } from './lib';
import { menuStyles } from './menu-styles';
import FilterDropdown from './filter-dropdown';

type Props = {
  open: string;
  anchorRefPurpose: React.MutableRefObject<HTMLButtonElement>;
  handlePurposeClose: (event: React.MouseEvent<EventTarget>) => void;
  handleTogglePurpose: () => void;
  purposeFilterValues: string[];
  selectedPurpose: string[];
  setSelectedPurpose: (purposes: string[]) => void;
};

export default function PurposeSelector({
  open,
  anchorRefPurpose,
  handlePurposeClose,
  handleTogglePurpose,
  purposeFilterValues,
  selectedPurpose,
  setSelectedPurpose,
}: Readonly<Props>) {
  const classes = menuStyles();

  const menuListItems = purposeFilterValues.map((label) => (
    <MenuItem
      key={label}
      className={classes.menuItem}
      selected={selectedPurpose?.includes(label)}
      onClick={() => {
        const newPurpose = addRemoveElement(label, selectedPurpose);
        setSelectedPurpose(newPurpose);
      }}
      data-testid="purpose-list-item"
    >
      {label}
    </MenuItem>
  ));

  return (
    <FilterDropdown
      buttonText="Purpose"
      open={open}
      openItem="purpose"
      anchorRef={anchorRefPurpose}
      closeButton={handlePurposeClose}
      toggleButton={handleTogglePurpose}
      menuListItems={menuListItems}
    />
  );
}
