import { type FlightRequestViewModel } from 'fims-api-types';

import { getPinOptions } from '../../../../../../lib/styled-features';

export const getLeadPinOptions = (flight: FlightRequestViewModel) => {
  const leadSegment = flight?.flightAreas.find((x) => x.properties.leadSegment);
  const flightArea = leadSegment || flight?.flightAreas[0];
  const pinOptions = getPinOptions(flightArea);

  return pinOptions;
};

export const getMarkerPosition = (
  flight: FlightRequestViewModel
): google.maps.LatLng => {
  const leadSegment = flight.flightAreas.find((x) => x.properties.leadSegment);
  if (leadSegment) {
    return new google.maps.LatLng(leadSegment.properties.markerStyling.center);
  }

  return new google.maps.LatLng(flight.deadCenter);
};
