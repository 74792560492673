import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { type Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import type {
  ApproveSegmentsRequest,
  DetailedFlightRequestViewModelV2,
} from 'fims-api-types';
import GridTextItem from '../../../../shared/GridTextItem';

import SegmentDetailRow from './segment-detail-row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      minWidth: '400px',

      paddingTop: 0,

      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.black,
    },
    headerGrid: {
      position: 'sticky',
      top: 0,
      zIndex: 1,

      display: 'grid',

      gridTemplateColumns: '1fr 1.5fr 1fr',
      gridTemplateRows: '[row1] 2.5rem',

      backgroundColor: theme.palette.common.white,
    },
    detailsGrid: {
      display: 'grid',

      gridTemplateColumns: '1fr 1.5fr 1fr',
    },
    errorMessage: {
      color: theme.palette.error.main,
      bottom: theme.spacing(8),
      right: theme.spacing(3),
    },
  })
);

const ApproveSegmentsDialogContent = ({
  focussedFlight,
  approveSegments,
  setApproveSegments,
  errorMessage,
}: {
  focussedFlight: DetailedFlightRequestViewModelV2;
  approveSegments: ApproveSegmentsRequest;
  setApproveSegments: React.Dispatch<
    React.SetStateAction<ApproveSegmentsRequest>
  >;
  errorMessage?: string;
}) => {
  const classes = useStyles({});

  return (
    <DialogContent className={classes.modalContent}>
      <div className={classes.headerGrid}>
        <GridTextItem text="Segment Id" isHeader={true} />
        <GridTextItem text="Status" isHeader={true} />
        <GridTextItem text="Approve" isHeader={true} />
      </div>

      <div className={classes.detailsGrid}>
        {focussedFlight?.flightAreas.map((flightArea) => {
          return (
            <SegmentDetailRow
              key={flightArea?.properties.segmentId}
              flightArea={flightArea}
              approveSegments={approveSegments}
              setApproveSegments={setApproveSegments}
            />
          );
        })}
      </div>
      <Typography
        className={classes.errorMessage}
        data-testid="approve-segments-dialog-error-message"
      >
        {errorMessage}
      </Typography>
    </DialogContent>
  );
};

export default ApproveSegmentsDialogContent;
