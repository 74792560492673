import React from 'react';

export const AlertSVG =
  '<svg data-type="warning" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="12" viewBox="0 0 24 24" width="12"><g><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"  fill=" white"/><animate attributeName="opacity" values="0;1;1;1;0" dur="1000ms" repeatCount="indefinite"/> </g></svg>';

export const HourglassAnimatedSVG =
  '<svg data-type="hourglass" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="12" viewBox="0 0 24 24" width="12"> <g> <path fill=" white" d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z"> <animate attributeName="opacity" values="0;1;1;0;0" dur="3s" repeatCount="indefinite"/> </path> <path fill=" white" d="M18,22l-0.01-6L14,12l3.99-4.01L18,2H6v6l4,4l-4,3.99V22H18z M8,7.5V4h8v3.5l-4,4L8,7.5z"> <animate attributeName="opacity" values="1;0;0;1;1" dur="3s" repeatCount="indefinite" /> </path> </g></svg>';

export const ArrowDropUpIconSVG =
  '<svg data-type="arrow" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="12" viewBox="0 0 24 24" width="12" fill="white" transform="scale(2,2)"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 14l5-5 5 5z"/></svg>';

export const CallSVG =
  '<svg data-type="call" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="12" viewBox="0 0 24 24" width="12" fill="white"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>';

export const PriorityFlightSVG =
  '<svg data-type="priority" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="19" viewBox="0 -1 24 24" width="19"> <g> <circle cx="12" cy="19" r="2" fill="white"/><path d="M10 3h4v12h-4z" fill="white"/> </g></svg>';

export const AlarmClockSVG =
  '<svg data-type="alarmclock" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 12 12" height="14" viewBox="0 0 14 14" width="14" fill="white"><path d="M0 0h14v14H0z" fill="none"/><path d="M7 3.5a4.08 4.08 0 0 1 3.833 5.484l0.886 0.886A5.163 5.163 0 0 0 12.25 7.584a5.25 5.25 0 0 0 -5.25 -5.25c-0.823 0 -1.593 0.193 -2.286 0.531L5.6 3.751C6.038 3.594 6.51 3.5 7 3.5m5.834 -0.164L10.15 1.085l-0.752 0.893 2.684 2.251zM1.704 1.336 0.963 2.083l0.776 0.776 -0.647 0.542 0.829 0.829 0.647 -0.549 0.466 0.466A5.224 5.224 0 0 0 1.75 7.584a5.247 5.247 0 0 0 8.686 3.966l1.284 1.284 0.741 -0.741L2.27 1.908zM9.607 10.727a4.085 4.085 0 0 1 -5.751 -5.751zM4.679 1.914 3.85 1.085l-0.501 0.414 0.829 0.829z"><animate attributeType="CSS" attributeName="display" from="block" to="none" dur="1s" repeatCount="indefinite"/></path></svg>';

export const AlarmClockColoured = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 12 12"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    fill="#ffb511"
    data-testid="alarm-clock-icon-coloured"
  >
    <path d="M0 0h14v14H0z" fill="none" />
    <path d="M7 3.5a4.08 4.08 0 0 1 3.833 5.484l0.886 0.886A5.163 5.163 0 0 0 12.25 7.584a5.25 5.25 0 0 0 -5.25 -5.25c-0.823 0 -1.593 0.193 -2.286 0.531L5.6 3.751C6.038 3.594 6.51 3.5 7 3.5m5.834 -0.164L10.15 1.085l-0.752 0.893 2.684 2.251zM1.704 1.336 0.963 2.083l0.776 0.776 -0.647 0.542 0.829 0.829 0.647 -0.549 0.466 0.466A5.224 5.224 0 0 0 1.75 7.584a5.247 5.247 0 0 0 8.686 3.966l1.284 1.284 0.741 -0.741L2.27 1.908zM9.607 10.727a4.085 4.085 0 0 1 -5.751 -5.751zM4.679 1.914 3.85 1.085l-0.501 0.414 0.829 0.829z">
      <animate
        attributeType="CSS"
        attributeName="display"
        to="none"
        from="block"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);
