import { takeLatest, takeEvery, call, select, put } from 'redux-saga/effects';

import { SuperAdminConsoleGlobalSettings } from 'fims-api-types';
import { getGlobalSettings } from './selectors';
import { fimsAPI } from '../../clients/api';
import {
  SettingsActionType,
  fetchSettingsSuccess,
  fetchSettingsFailed,
  updateSettingSuccess,
  updateSettingFailed,
  SettingsUpdateSettingsAction,
} from './actions';
import { SessionActionType } from '../session/actions';

export interface SettingsResponse {
  data: SuperAdminConsoleGlobalSettings;
}

function* fetchSettings() {
  try {
    const { data }: SettingsResponse = yield call(fimsAPI.get, '/settings');
    yield put(fetchSettingsSuccess(data));
  } catch (e) {
    yield put(fetchSettingsFailed());
  }
}

function* updateSetting({
  payload: { section, setting, value },
}: SettingsUpdateSettingsAction) {
  const settings: SuperAdminConsoleGlobalSettings =
    yield select(getGlobalSettings);
  const updatedSettings = {
    ...settings,
    [section]: { ...settings[section], [setting]: value },
  };

  try {
    yield call(fimsAPI.post, '/settings', updatedSettings);
    yield put(updateSettingSuccess());
  } catch (e) {
    yield put(updateSettingFailed());
  }
}

export function* settingsSaga() {
  yield call(fetchSettings);

  yield takeLatest(SettingsActionType.FETCH_SETTINGS, fetchSettings);
  yield takeEvery(SettingsActionType.UPDATE_SETTING, updateSetting);
  yield takeEvery(SessionActionType.SET_PROFILE, fetchSettings);
}
