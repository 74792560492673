import { useCallback, useContext, useMemo } from 'react';
import type { Socket } from 'socket.io-client';

import { createAuthenticatedSocket } from 'airshare-web-utils/socket-authenticated';

import { LoginContext } from '../context/LoginState';
import { fimsAPI } from '../clients/api';
import { useIsAuthenticated } from '../state/session/hooks';
import { useDebounce } from '~/hooks/use-debounce.hook';

// @ts-ignore
const URL = `${window.env.ARGUS_WEBSOCKET_SERVICE_URL}/fims`;

export const useSocket = (): Socket | null => {
  const { socket, setSocket } = useContext(LoginContext);
  const isAuthenticated = useIsAuthenticated();
  const getUser = () => {
    // Reload settings to force refresh of token if user is still logged in
    if (localStorage.getItem('profile') !== null) fimsAPI.get('/settings');
  };

  const establishSocket = useCallback(async () => {
    const newSocket = await createAuthenticatedSocket(URL, getUser);
    setSocket((prev: Socket) => {
      if (prev) prev.disconnect();
      return newSocket;
    });
  }, [setSocket]);

  const debouncedEstablishSocket = useDebounce(async () => {
    await establishSocket();
  }, 2000);

  const disconnect = useCallback(() => {
    socket?.disconnect();
    setSocket((prev: Socket) => {
      if (prev) prev.disconnect();
      return null;
    });
  }, [socket, setSocket]);

  useMemo(() => {
    if (!socket?.id && isAuthenticated) {
      debouncedEstablishSocket();
    }
  }, [socket?.id, debouncedEstablishSocket, isAuthenticated]);

  useMemo(() => {
    if (socket?.id && !isAuthenticated) {
      disconnect();
    }
  }, [socket?.id, disconnect, isAuthenticated]);

  return socket;
};
