import { TableHeading } from '~/lib/table';

export const TABLE_HEADINGS: TableHeading[] = [
  {
    label: 'Email',
    sortKey: 'email',
    align: 'left',
  },
  {
    label: 'Name',
    sortKey: 'givenName',
    align: 'left',
  },
  {
    label: 'Mobile No',
    sortKey: 'mobileNo',
    align: 'left',
  },
  {
    label: 'Role',
    sortKey: 'role',
    align: 'left',
  },
  {
    label: 'Status',
    sortKey: 'status',
    align: 'center',
  },
];
