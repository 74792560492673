import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Slider from '@material-ui/core/Slider';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';

import { useProfile } from '../../../state/session';
import {
  getOrganisationSettings,
  updateOrganisation,
} from '../../../clients/fims-api-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 500,
      color: 'rgb(105, 105, 105)',
    },
    form: {
      paddingTop: '33px',
      width: '100%',
    },
    input: {
      margin: `${theme.spacing(1)}px 0`,
    },
    buttons: {
      order: 4,
      marginTop: `${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'flex-end',
    },
    error: {
      color: theme.palette.error.main,
    },
    grid: {
      display: 'block',
    },
    orgSettingsPropertyContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    orgSettingsPropertyLabel: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    orgSettingsPropertyValue: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  })
);

interface OrganisationSettingsProps {
  open: boolean;
  closeModal(): any;
}

const OrganisationSettings = ({
  open,
  closeModal,
}: OrganisationSettingsProps) => {
  const [beepFrequency, setBeepFrequency] = useState<number>(180);
  const [isMaxOperationLimitSet, setIsMaxOperationLimitSet] =
    useState<boolean>(false);
  const [maxOperationLimit, setMaxOperationLimit] = useState<number>(Infinity);
  const [origValues, setOrigValues] = useState<{
    beepFrequency?: number;
    maxOperationLimit?: number;
  }>({});
  const [errors, setErrors] = useState([]);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const profile = useProfile();
  const classes = useStyles();

  useEffect(() => {
    const fetchOrganisationSettings = async () => {
      if (open) {
        const organisationSettings = await getOrganisationSettings(
          profile.organisation
        );
        setBeepFrequency(organisationSettings.beepFrequency ?? 180);
        const maxOpsLimit = organisationSettings?.maxOperationLimit;
        const isMaxOpsLimitSet = !isNaN(maxOpsLimit) && maxOpsLimit >= 0;
        setMaxOperationLimit(isMaxOpsLimitSet ? maxOpsLimit : Infinity);
        setIsMaxOperationLimitSet(isMaxOpsLimitSet);
        setOrigValues({
          beepFrequency: organisationSettings?.beepFrequency ?? 180,
          maxOperationLimit: isMaxOpsLimitSet ? maxOpsLimit : Infinity,
        });
      }
    };
    fetchOrganisationSettings();
  }, [profile.organisation, open]);

  const restoreSettings = () => {
    setBeepFrequency(origValues?.beepFrequency ?? 180);
    setMaxOperationLimit(origValues?.maxOperationLimit ?? Infinity);
    setIsMaxOperationLimitSet(
      origValues?.maxOperationLimit !== Infinity ?? false
    );
    setSettingsChanged(false);
    setErrors([]);
  };

  useEffect(() => {
    setSettingsChanged(
      beepFrequency !== origValues?.beepFrequency ||
        maxOperationLimit !== origValues?.maxOperationLimit
    );

    const isMaxOpsLimitStillInChange =
      isMaxOperationLimitSet && maxOperationLimit === Infinity;
    if (isMaxOpsLimitStillInChange) {
      setErrors((errs) => [
        ...errs,
        'Max Operation Limit must be specified to enable this setting',
      ]);
    }
  }, [beepFrequency, isMaxOperationLimitSet, maxOperationLimit, origValues]);

  const saveOrgSettings = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateOrganisation({
        id: profile.organisation,
        settings: {
          beepFrequency,
          maxOperationLimit:
            maxOperationLimit === Infinity ? -1 : maxOperationLimit,
        },
      });
      setSettingsChanged(false);
      closeModal();
    } catch (error) {
      setErrors([error.toString()]);
    }
  };

  const handleBeepFrequencyChange = (beepFreq: number) => {
    setErrors([]);
    setBeepFrequency(beepFreq);
  };

  const handleSliderChange = (newValue: number) => {
    handleBeepFrequencyChange(newValue);
  };

  const handleMaxOperationLimitChange = (maxOpsLimit: number) => {
    setErrors([]);
    setMaxOperationLimit(maxOpsLimit);
  };

  const handleMaxOperationLimitToggle = (isOn: boolean) => {
    setErrors([]);
    setIsMaxOperationLimitSet(isOn);
    if (!isOn) {
      setMaxOperationLimit(Infinity);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        restoreSettings();
        closeModal();
      }}
    >
      <DialogContent className={classes.content}>
        <Typography
          variant="h6"
          data-testid="settings-modal-organisation-settings-heading"
        >
          Airspace Settings
        </Typography>
        <form onSubmit={saveOrgSettings} className={classnames(classes.form)}>
          <Grid container spacing={1} alignItems="center">
            {profile?.authorizer?.type === AuthorizerType.ControlZone && (
              <>
                <Grid item xs={12} className={classnames(classes.grid)}>
                  <div
                    className={classnames(classes.orgSettingsPropertyContainer)}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classnames(classes.orgSettingsPropertyLabel)}
                      data-testid="beep-frequency-header"
                    >
                      Beep Frequency (s)
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classnames(classes.orgSettingsPropertyValue)}
                      data-testid="beep-frequency-slider-value"
                    >
                      {beepFrequency}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Slider
                    value={
                      typeof beepFrequency === 'number' ? beepFrequency : 0
                    }
                    onChange={(_, value) => {
                      handleSliderChange(value as number);
                    }}
                    aria-labelledby="input-slider"
                    min={1}
                    max={180}
                    data-testid="beep-frequency-slider"
                  />
                </Grid>
              </>
            )}
            {window.env.EURO_REGULATIONS?.toLowerCase() === 'true' && (
              <Grid item xs={12} className={classnames(classes.grid)}>
                <div
                  className={classnames(classes.orgSettingsPropertyContainer)}
                >
                  <Typography
                    variant="subtitle1"
                    className={classnames(classes.orgSettingsPropertyLabel)}
                    data-testid="max-operation-limit-header"
                  >
                    Max. Operation Limit
                  </Typography>

                  <Checkbox
                    name="setMaxOpsLimitCheckbox"
                    size="medium"
                    color="primary"
                    checked={isMaxOperationLimitSet}
                    onChange={(_, value) => {
                      handleMaxOperationLimitToggle(value);
                    }}
                    data-testid="set-max-operation-limit-checkbox"
                  />
                </div>
                {isMaxOperationLimitSet && (
                  <TextField
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    variant="standard"
                    type="number"
                    value={maxOperationLimit}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val = e.target.value;
                      if (!isNaN(parseInt(val))) {
                        handleMaxOperationLimitChange(parseInt(val));
                      }
                    }}
                    className={classnames(classes.orgSettingsPropertyValue)}
                    data-testid="max-operation-limit-value"
                  />
                )}
              </Grid>
            )}
          </Grid>
          {errors.length > 0 ? (
            <Grid item xs={12} key="org-settings-errors">
              <Typography
                className={classnames(classes.error)}
                data-testid="organisation-settings-error-message"
              >
                {errors}
              </Typography>
            </Grid>
          ) : null}
          <Grid container className={classnames(classes.buttons)}>
            <Grid item xs={12}>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    restoreSettings();
                    closeModal();
                  }}
                  data-testid="organisation-settings-cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!settingsChanged || errors.length > 0}
                  variant="contained"
                  color="primary"
                  type="submit"
                  value="save"
                  data-testid="organisation-settings-save-button"
                >
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrganisationSettings;
