import React, { useContext } from 'react';
import TimeFrameControl from './time-frame-control';
import NoOpsControl from './no-ops-control';
import {
  LoginContext,
  MapView24Hour,
} from '../../../../../../context/LoginState';

export default function MapView() {
  const { mapView24hour } = useContext(LoginContext);

  const minWidth = mapView24hour === MapView24Hour.DATE ? '320px' : '220px';

  return (
    <div style={{ alignSelf: 'start', minWidth }}>
      <div
        className="map-view-control"
        style={{
          display: 'grid',
          flexDirection: 'column',
          gridRowGap: '0.25rem',
        }}
      >
        <TimeFrameControl />
        <NoOpsControl />
      </div>
    </div>
  );
}
