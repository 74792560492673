import { takeLatest, call, put, take, race, delay } from 'redux-saga/effects';

import { ConflictGroup } from 'fims-api-types';
import { fimsAPI } from '~/clients/api';
import {
  ConflictedFlightsActionType,
  conflictedFlightsFetchFailed,
  conflictedFlightsFetchSucceeded,
  conflictedFlightsPollingStopped,
} from './actions';

interface ConflictedFlightsResponse {
  data: ConflictGroup[];
}

function* fetchConflictedFlights() {
  try {
    const { data }: ConflictedFlightsResponse = yield call(
      fimsAPI.get,
      '/conflicted-flights'
    );

    yield put(conflictedFlightsFetchSucceeded(data));
  } catch (e) {
    yield put(conflictedFlightsFetchFailed());
    throw e;
  }
}

function* pollConflictedFlights() {
  try {
    while (true) {
      yield call(fetchConflictedFlights);
      yield delay(5000);
    }
  } catch (e) {
    yield put(conflictedFlightsPollingStopped());
  }
}

function* startPollingConflictedFlights() {
  yield race([
    call(pollConflictedFlights),
    take(ConflictedFlightsActionType.STOPPED_POLLING),
  ]);
}

export function* conflictedFlightsSaga() {
  yield takeLatest(
    ConflictedFlightsActionType.FETCH_REQUESTED,
    fetchConflictedFlights
  );
  yield takeLatest(
    ConflictedFlightsActionType.STARTED_POLLING,
    startPollingConflictedFlights
  );
}
