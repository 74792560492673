import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import type { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import Segment from './Segment';
import { listItemStyle } from './styles';

import '../flight.scss';

interface Props {
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  segmentsOpen?: string[];
  setSegmentsOpen?: (segments: string[]) => void;
}

const Segments = ({
  focussedFlightRequest,
  segmentsOpen = [],
  setSegmentsOpen = () => {},
}: Props) => {
  const [open, setOpen] = useState(segmentsOpen?.length > 0);

  if (!focussedFlightRequest.flightAreas[0].properties.segmentId) {
    return null;
  }

  const isOpen = open || segmentsOpen?.length > 0;

  return (
    <div data-testid="flight-info-segments">
      <ListItem
        style={listItemStyle}
        dense
        button
        onClick={() => {
          setOpen(!open);
          setSegmentsOpen([]);
        }}
        data-testid="flight-segments-heading"
      >
        <ListItemText primary="Segments" />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {focussedFlightRequest?.flightAreas.map((flightArea) => (
          <Segment
            key={flightArea.properties.segmentId}
            timezone={focussedFlightRequest.timezone}
            properties={flightArea.properties}
            segmentsOpen={segmentsOpen}
            setSegmentsOpen={setSegmentsOpen}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default Segments;
