import { useDispatch, useSelector } from 'react-redux';
import { FlightAreaViewModel } from 'fims-api-types';
import {
  flightAreaFetchRequested,
  flightAreaFetchReset,
  FlightAreaFetchRequestsAction,
  FlightAreaFetchResetAction,
} from './actions';
import { getSelectedFlightArea } from './selectors';

interface FlightAreaWithDispatchers {
  selectedFlightArea: FlightAreaViewModel;
  fetchFlightArea(flightId: number): FlightAreaFetchRequestsAction;
  resetFlightArea(): FlightAreaFetchResetAction;
}

export function useFlightArea(): FlightAreaWithDispatchers {
  const dispatch = useDispatch();

  const selectedFlightArea = useSelector(getSelectedFlightArea);
  const fetchFlightArea = (flightId: number) =>
    dispatch(flightAreaFetchRequested({ flightId }));
  const resetFlightArea = () => dispatch(flightAreaFetchReset());

  return { selectedFlightArea, fetchFlightArea, resetFlightArea };
}
