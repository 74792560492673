import { useCallback, useRef } from 'react';
import createTurfCircle from '@turf/circle';
import truncate from '@turf/truncate';
import { Polygon, polygon as createTurfPolygon, Position } from '@turf/helpers';
import * as fimsClient from '../../../../../clients/fims-api-client';

export function useDynamicDrawingManager() {
  const currentEventRef =
    useRef<google.maps.drawing.OverlayCompleteEvent | null>(null);

  const removeCreatedEvent = useCallback(
    (event: google.maps.drawing.OverlayCompleteEvent | null = null) => {
      const previousEvent = currentEventRef.current;
      previousEvent?.overlay?.setMap(null);
      currentEventRef.current = event;
    },
    []
  );

  const handleFlightAreaSelected = useCallback(
    async (
      event: google.maps.drawing.OverlayCompleteEvent,
      setSelectedAirspace: (polygon: Polygon | null) => void,
      handleMapError: (message?: string) => void
    ) => {
      if (event?.overlay) {
        const polygon = getPolygonFromEvent(event);

        if (polygon) {
          const isValid = await validatePolygonWithinAreasOfResp(polygon);
          if (isValid) {
            handleMapError();
            setSelectedAirspace(polygon);
          } else {
            handleMapError(
              'Part or all of the area drawn is outside your area of responsibility. Please correct and try again'
            );
          }
        } else {
          handleMapError();
        }
      } else {
        handleMapError();
      }
      removeCreatedEvent(event);
    },
    [removeCreatedEvent]
  );

  const createDrawingManager = useCallback(() => {
    const shapeOptions = {
      fillColor: '#000000',
      fillOpacity: 0.2,
      strokeColor: '#000000',
      strokeOpacity: 0.4,
      strokeWeight: 2,
      clickable: false,
      editable: true,
      zIndex: 1,
    };

    const nextDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      circleOptions: shapeOptions,
      polygonOptions: shapeOptions,
    });

    return nextDrawingManager;
  }, []);

  return {
    handleFlightAreaSelected,
    createDrawingManager,
    removeCreatedEvent,
  };
}

function getPolygonFromEvent(
  event: google.maps.drawing.OverlayCompleteEvent
): Polygon | null {
  if (event && 'type' in event) {
    const polygon =
      event.type === google.maps.drawing.OverlayType.POLYGON
        ? getGeojsonPolygonFromGoogleMapsPolygon(
            event.overlay as google.maps.Polygon
          )
        : event.type === google.maps.drawing.OverlayType.CIRCLE
          ? getGeojsonPolygonFromGoogleMapsCircle(
              event.overlay as google.maps.Circle
            )
          : null;
    return polygon;
  }
  return null;
}

function getGeojsonPolygonFromGoogleMapsCircle(
  circle: google.maps.Circle | null
): Polygon | null {
  try {
    if (circle) {
      const centre = circle.getCenter();
      if (centre) {
        const center: [number, number] = [centre.lng(), centre.lat()];
        const radius = circle.getRadius();

        const { geometry } = createTurfCircle(center, radius / 1000);

        return geometry;
      }
    }
    return null;
  } catch (err) {
    return null;
  }
}

function getGeojsonPolygonFromGoogleMapsPolygon(
  polygon: google.maps.Polygon | null
): Polygon | null {
  try {
    if (polygon) {
      const path: google.maps.MVCArray<google.maps.LatLng> = polygon.getPath();
      const coordinates: Position[][] = [[]];

      const firstTuple: Position = [path.getAt(0).lng(), path.getAt(0).lat()];

      path.forEach((_, index) => {
        coordinates[0].push([path.getAt(index).lng(), path.getAt(index).lat()]);
      });

      coordinates[0].push(firstTuple);

      const turfPolygon = truncate(createTurfPolygon(coordinates));

      return turfPolygon.geometry;
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function validatePolygonWithinAreasOfResp(
  polygon: Polygon
): Promise<boolean> {
  return fimsClient
    .validateManagedAreaGeometry(polygon)
    .then((resp) => resp.valid)
    .catch(() => false);
}
