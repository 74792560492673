import React, { useState, useCallback } from 'react';
import { DateTime } from 'luxon';
import {
  Badge,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close, NotificationsOutlined } from '@material-ui/icons';
import { SoundPlayer } from 'asm-web-components';
import { NotificationResponse } from 'fims-api-types';
import * as fimsClient from '../../../../clients/fims-api-client';
import { useInterval } from '../../../../state/custom-hooks/useInterval';
import Notifications from './notifications';

const TITLE = 'Notifications';
const FLIGHT_REQUEST_REFRESH_INTERVAL = 10000;
const AUDIO_INTERVAL = { minutes: 3 };

export enum ComponentType {
  SMS = 'SMS',
  DIGITAL_AUTHORIZATION = 'DIGITAL_AUTHORIZATION',
  DIGITAL_ACTIVATION = 'DIGITAL_ACTIVATION',
}

const NOTIFICATIONS_DIALOG_AUTO_OPEN_ENABLED =
  !window.env.NOTIFICATIONS_DIALOG_AUTO_OPEN_ENABLED ||
  window.env.NOTIFICATIONS_DIALOG_AUTO_OPEN_ENABLED?.toLowerCase() === 'true';

export default function NotificationsDialog() {
  const [notifications, setNotifications] = useState<NotificationResponse[]>(
    []
  );
  const [manuallyClosed, setManuallyClosed] = useState(false);
  const [manuallyOpen, setManuallyOpen] = useState(false);
  const [lastPlayed, setLastPlayed] = useState(
    DateTime.now().toUTC().minus(AUDIO_INTERVAL)
  );
  const open =
    manuallyOpen ||
    (!manuallyClosed &&
      notifications?.length > 0 &&
      NOTIFICATIONS_DIALOG_AUTO_OPEN_ENABLED);
  const playAudio = SoundPlayer();

  const updateNotifications = useCallback(async () => {
    const incomingNotifications = await fimsClient.getNotifications();
    const incomingNotificationIds: string[] = incomingNotifications.map(
      (x: NotificationResponse) => x.id
    );
    const currentNotificationIds: string[] = notifications.map(
      (x: NotificationResponse) => x.id
    );
    const currentTime = DateTime.now().toUTC();
    if (notifications?.length === 0 && incomingNotifications?.length !== 0) {
      await playAudio();
      setLastPlayed(currentTime);
    } else if (
      lastPlayed &&
      lastPlayed.plus(AUDIO_INTERVAL) < currentTime &&
      incomingNotifications.length
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      await playAudio();
      setLastPlayed(currentTime);
    }
    if (
      !incomingNotificationIds.every((x: string) =>
        currentNotificationIds.includes(x)
      ) ||
      !incomingNotificationIds.length
    ) {
      setManuallyClosed(false);
    }
    setNotifications(incomingNotifications);
  }, [lastPlayed, notifications, playAudio]);

  useInterval(updateNotifications, FLIGHT_REQUEST_REFRESH_INTERVAL);

  function handleMenuClick() {
    setManuallyClosed(false);
    if (!notifications?.length || !NOTIFICATIONS_DIALOG_AUTO_OPEN_ENABLED) {
      setManuallyOpen(true);
    }
  }

  const menuItem = (
    <div onClick={handleMenuClick} data-testid="notifications-menu-item">
      <IconButton
        style={{
          color: 'white',
        }}
      >
        <Badge
          max={9}
          color="secondary"
          badgeContent={notifications.length}
          overlap="rectangular"
        >
          <NotificationsOutlined />
        </Badge>
      </IconButton>
    </div>
  );

  if (!open) {
    return menuItem;
  }

  return (
    <>
      {menuItem}
      <Dialog
        open={open}
        aria-labelledby="authorization-requests-modal"
        aria-describedby="authorization-requests-modal"
        fullWidth
        maxWidth="sm"
        data-testid="notifications-dialog"
      >
        <DialogTitle disableTypography>
          <Typography
            style={{ fontSize: 20 }}
            data-testid="notifications-title"
          >
            {TITLE}
          </Typography>
          <IconButton
            style={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: 'gray',
            }}
            onClick={() => {
              setManuallyClosed(true);
              setManuallyOpen(false);
            }}
            data-testid="notifications-dialog-close-button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Notifications
          notifications={notifications}
          updateNotifications={updateNotifications}
          setManuallyClosed={setManuallyClosed}
        />
      </Dialog>
    </>
  );
}
