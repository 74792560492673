import { createAction } from 'airshare-web-utils/redux-helpers';
import { SuperAdminConsoleGlobalSettings } from 'fims-api-types';

export enum SettingsActionType {
  FETCH_SETTINGS = 'settings/FETCH_SETTINGS',
  FETCH_SETTINGS_SUCCESS = 'settings/FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILED = 'settings/FETCH_SETTINGS_FAILED',
  UPDATE_SETTING = 'settings/UPDATE_SETTING',
  UPDATE_SETTINGS_SUCCESS = 'settings/UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILED = 'settings/UPDATE_SETTINGS_FAILED',
}

export interface UpdateSettingPayload {
  section: keyof SuperAdminConsoleGlobalSettings;
  setting: string;
  value: string | number;
}

export interface SettingsFetchSettingsAction {
  type: SettingsActionType.FETCH_SETTINGS;
}
export interface SettingsFetchSettingsSuccessAction {
  type: SettingsActionType.FETCH_SETTINGS_SUCCESS;
  payload: SuperAdminConsoleGlobalSettings;
}
export interface SettingsFetchSettingsFailedAction {
  type: SettingsActionType.FETCH_SETTINGS_FAILED;
}
export interface SettingsUpdateSettingsAction {
  type: SettingsActionType.UPDATE_SETTING;
  payload: UpdateSettingPayload;
}
export interface SettingsupdateSettingsSuccessAction {
  type: SettingsActionType.UPDATE_SETTINGS_SUCCESS;
}
export interface SettingsUpdateSettingsFailedAction {
  type: SettingsActionType.UPDATE_SETTINGS_FAILED;
}

export const fetchSettings: () => SettingsFetchSettingsAction = createAction(
  SettingsActionType.FETCH_SETTINGS
);
export const fetchSettingsSuccess: (
  payload: SuperAdminConsoleGlobalSettings
) => SettingsFetchSettingsSuccessAction = createAction(
  SettingsActionType.FETCH_SETTINGS_SUCCESS
);
export const fetchSettingsFailed: () => SettingsFetchSettingsFailedAction =
  createAction(SettingsActionType.FETCH_SETTINGS_FAILED);
export const updateSetting: (
  payload: UpdateSettingPayload
) => SettingsUpdateSettingsAction = createAction(
  SettingsActionType.UPDATE_SETTING
);
export const updateSettingSuccess: () => SettingsupdateSettingsSuccessAction =
  createAction(SettingsActionType.UPDATE_SETTINGS_SUCCESS);
export const updateSettingFailed: () => SettingsUpdateSettingsFailedAction =
  createAction(SettingsActionType.UPDATE_SETTINGS_FAILED);
