/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import {
  activateSubscription,
  createSubscription,
  deleteSubscription,
  getAreas,
  getMessage,
  getSubscriptions,
  pauseSubscription,
} from './lib/subscriptions-api-client';
import './styles.scss';
import {
  ED269Body,
  UASZonesUpdateSubscriptionReply,
  UASZonesUpdateSubscriptionsReply,
} from './lib/types';
import { Map } from './Map';
import { ObjectInspect } from './ObjectInspect';
import { AxiosResponse } from 'axios';
import { ConfigForm } from './lib/ConfigForm';

function SubscriptionsDemo() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [endpoint, setEndpoint] = useState<string>();
  const [organisationId, setOrganisationId] = useState<string>();
  const [accessKey, setAccessKey] = useState<string>();
  const [tick, setTick] = useState<boolean>(false);

  const [logTab, setLogTab] = useState<string>('console');

  const [
    enableSubscriptionCreationEditor,
    setEnableSubscriptionCreationEditor,
  ] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<
    UASZonesUpdateSubscriptionReply[]
  >([]);
  const [uasZones, setUasZones] = useState<
    { area: ED269Body; version: string }[]
  >([]);
  const [selectedObject, setSelectedObject] = useState<
    ED269Body | UASZonesUpdateSubscriptionReply
  >(null);
  const [selectedCenter, setSelectedCenter] = useState<{
    lat: number;
    lng: number;
  }>(null);
  const [credentialsValid, setCredentialsValid] = useState<boolean>(false);
  const subscriptionMessages = useRef([]);
  const consoleLog = useRef([]);

  useEffect(() => {
    async function getSubscriptionMessages() {
      const locations = [
        ...new Set(
          subscriptions
            .filter(
              (subscription) => subscription.subscriptionStatus != 'DELETED'
            )
            .map((subscription) => subscription.publicationLocation)
        ),
      ];

      locations.forEach(async (location) => {
        let continueRequesting = true;
        while (continueRequesting) {
          let response = await getMessage(location);
          continueRequesting = false;
          if (response.data) {
            subscriptionMessages.current.push({
              data: response.data,
              headers: response.headers,
            });
            //The message contains an UASZone update
            if (response.data.UASZone) {
              const index = uasZones.findIndex(
                (zone) =>
                  zone.area.identifier === response.data.UASZone.identifier
              );

              if (index > -1) {
                uasZones[index].area = response.data.UASZone;
                uasZones[index].version = 'updated';
                setUasZones(uasZones);
              } else {
                uasZones.push({
                  area: response.data.UASZone,
                  version: 'updated',
                });
                setUasZones(uasZones);
              }

              //TODO: Fix, shoudl update automatically
              document.getElementById('messagesLog').click();
              setTimeout(() => {
                document
                  .getElementById(response.data.UASZone.identifier)
                  .click();
                document
                  .getElementById(response.data.UASZone.identifier)
                  .scrollIntoView();
              }, 200);
            }

            continueRequesting = true;
          }
        }
      });
    }

    const interval = setInterval(async () => {
      await getSubscriptionMessages();
    }, 10000);
    return () => clearInterval(interval);
  }, [subscriptionMessages, subscriptions, uasZones, setUasZones]);

  function onConfigSet(
    usernameSet: string,
    passwordSet: string,
    endpointSet: string,
    organisationIdSet: string,
    accessKeySet: string,
    areas: ED269Body[],
    responseSubscriptions: AxiosResponse<UASZonesUpdateSubscriptionsReply>
  ) {
    setUsername(usernameSet);
    setPassword(passwordSet);
    setEndpoint(endpointSet);
    setOrganisationId(organisationIdSet);
    setAccessKey(accessKeySet);
    setUasZones(
      areas.map((area) => {
        return { area, version: 'baseline' };
      })
    );
    //Hide from UI deleted subscriptions
    setSubscriptions(
      responseSubscriptions.data.subscriptions.filter(
        (subscription) => subscription.subscriptionStatus !== 'DELETED'
      )
    );
    setCredentialsValid(true);
  }

  let timer: NodeJS.Timeout;
  const updateTick = () => {
    timer =
      !timer &&
      setInterval(() => {
        setTick(!tick);
      }, 1000);
  };

  useEffect(() => {
    updateTick();
    return () => clearInterval(timer);
  });

  return (
    <>
      <div className="demo-subscriptions">
        {!credentialsValid && <ConfigForm onConfigSet={onConfigSet} />}

        {credentialsValid && (
          <div className="dashboard">
            <div className="tools">
              <div className="side">
                <div className="uas-zones">
                  <header>
                    <h2>UAS Zones ({uasZones.length})</h2>
                    <div className="actions">
                      <button
                        onClick={async () => {
                          //TODO: should get all not only paginated results
                          const response = await getAreas(
                            endpoint,
                            organisationId,
                            accessKey
                          );
                          setUasZones(
                            response.data.result.features.map((area) => {
                              return { area, version: 'baseline' };
                            })
                          );
                        }}
                      >
                        ↻
                      </button>
                    </div>
                  </header>
                  <table>
                    <thead>
                      <tr>
                        <th className="col-id">Id</th>
                        <th className="col-status">Last update</th>
                        <th className="col-actions">Version</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uasZones
                        .sort((area1, area2) =>
                          area1.area.name < area2.area.name ? -1 : 1
                        )
                        .map((UASZone, index) => (
                          <tr
                            key={index}
                            id={UASZone.area.identifier}
                            onClick={(e) => {
                              e.preventDefault();
                              const isActive =
                                e.currentTarget.classList.contains('active');

                              document
                                .querySelectorAll('tr.active')
                                .forEach((element) => {
                                  (element as HTMLElement).classList.remove(
                                    'active'
                                  );
                                });

                              if (!isActive) {
                                e.currentTarget.classList.add('active');

                                setSelectedObject(UASZone.area);
                                setSelectedCenter({
                                  lat: (
                                    UASZone.area.geometry[0]
                                      .horizontalProjection
                                      .coordinates[0][0] as Array<number>
                                  )[1] as number,
                                  lng: (
                                    UASZone.area.geometry[0]
                                      .horizontalProjection
                                      .coordinates[0][0] as Array<number>
                                  )[0] as number,
                                });
                              } else {
                                e.currentTarget.classList.remove('active');
                                setSelectedObject(null);
                              }
                            }}
                          >
                            {/* identifier, version, updateDateTime, country? */}
                            <td
                              className="col-id"
                              title={UASZone.area.identifier}
                            >
                              <span>
                                {UASZone.area.name} - {UASZone.area.identifier}
                              </span>
                            </td>
                            <td
                              className="col-status"
                              title={UASZone.area.dataSource?.updateDateTime}
                            >
                              <span>
                                {UASZone.area.dataSource?.updateDateTime}
                              </span>
                            </td>
                            <td className={`col-actions ${UASZone.version}`}>
                              {UASZone.version}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="subscriptions">
                  <header>
                    <h2>Subscriptions</h2>
                    <div className="actions">
                      <button
                        onClick={async () => {
                          setEnableSubscriptionCreationEditor(true);
                        }}
                      >
                        +
                      </button>
                      <button
                        onClick={async () => {
                          consoleLog.current.push('updating subscriptions');
                          const response = await getSubscriptions(
                            endpoint,
                            username,
                            password
                          );
                          setSubscriptions(
                            response.data.subscriptions.filter(
                              (subscription) =>
                                subscription.subscriptionStatus !== 'DELETED'
                            )
                          );
                        }}
                      >
                        ↻
                      </button>
                    </div>
                  </header>
                  <table>
                    <thead>
                      <tr>
                        <th className="col-id">Id</th>
                        <th className="col-status">Status</th>
                        <th className="col-actions">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map((subscription, index) => (
                        <tr
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            const isActive =
                              e.currentTarget.classList.contains('active');

                            document
                              .querySelectorAll('tr.active')
                              .forEach((element) => {
                                (element as HTMLElement).classList.remove(
                                  'active'
                                );
                              });

                            if (!isActive) {
                              e.currentTarget.classList.add('active');

                              setSelectedObject(subscription);
                              setSelectedCenter({
                                lat: (
                                  subscription.airspaceVolume
                                    .horizontalProjection
                                    .coordinates[0][0] as Array<number>
                                )[1] as number,
                                lng: (
                                  subscription.airspaceVolume
                                    .horizontalProjection
                                    .coordinates[0][0] as Array<number>
                                )[0] as number,
                              });
                            } else {
                              e.currentTarget.classList.remove('active');
                              setSelectedObject(null);
                            }
                          }}
                        >
                          <td
                            className="col-id"
                            title={subscription.subscriptionID}
                          >
                            <span>{subscription.subscriptionID}</span>
                          </td>
                          <td className="col-status">
                            {subscription.subscriptionStatus}
                          </td>
                          <td className="col-actions">
                            {subscription.subscriptionStatus === 'PAUSED' && (
                              <button
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  try {
                                    consoleLog.current.push(
                                      'activating subscription'
                                    );
                                    await activateSubscription(
                                      endpoint,
                                      username,
                                      password,
                                      subscription.subscriptionID
                                    );
                                    const response = await getSubscriptions(
                                      endpoint,
                                      username,
                                      password
                                    );
                                    setSubscriptions(
                                      response.data.subscriptions.filter(
                                        (subs) =>
                                          subs.subscriptionStatus !== 'DELETED'
                                      )
                                    );
                                  } catch (error: any) {
                                    console.log(
                                      'There was an error activating the subscription',
                                      error
                                    );
                                    window.alert(
                                      'There was an error activating the subscription'
                                    );
                                  }
                                }}
                              >
                                {' '}
                                ⏵{' '}
                              </button>
                            )}

                            {subscription.subscriptionStatus === 'ACTIVE' && (
                              <button
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  try {
                                    consoleLog.current.push(
                                      'pausing subscription'
                                    );
                                    await pauseSubscription(
                                      endpoint,
                                      username,
                                      password,
                                      subscription.subscriptionID
                                    );
                                    const response = await getSubscriptions(
                                      endpoint,
                                      username,
                                      password
                                    );
                                    setSubscriptions(
                                      response.data.subscriptions.filter(
                                        (subs) =>
                                          subs.subscriptionStatus !== 'DELETED'
                                      )
                                    );
                                  } catch (error: any) {
                                    console.log(
                                      'There was an error pausing the subscription',
                                      error
                                    );
                                    window.alert(
                                      'There was an error pausing the subscription'
                                    );
                                  }
                                }}
                              >
                                {' '}
                                ⏸{' '}
                              </button>
                            )}

                            <button
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (
                                  !confirm(
                                    'Do you want to delete this subscription?'
                                  )
                                ) {
                                  return;
                                }
                                try {
                                  await deleteSubscription(
                                    endpoint,
                                    username,
                                    password,
                                    subscription.subscriptionID
                                  );
                                  const response = await getSubscriptions(
                                    endpoint,
                                    username,
                                    password
                                  );
                                  //todo handle errors
                                  setSubscriptions(response.data.subscriptions);
                                } catch (error: any) {
                                  console.log(
                                    'There was an error pausing the subscription',
                                    error
                                  );
                                  window.alert(
                                    'There was an error pausing the subscription'
                                  );
                                }
                              }}
                              disabled={
                                !(
                                  subscription.subscriptionStatus ===
                                    'ACTIVE' ||
                                  subscription.subscriptionStatus === 'PAUSED'
                                )
                              }
                            >
                              {' '}
                              ✕{' '}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {selectedObject && (
                <div className="side">
                  <div className="object-inspect-container">
                    <header>
                      <h2>DETAILS</h2>
                    </header>
                    <div className="object-inspect-container">
                      <ObjectInspect selectedObject={selectedObject} />
                    </div>
                  </div>
                </div>
              )}

              <div className="map-tool">
                <div id="myMap" style={{ width: '100%', height: '100%' }} />
                <Map
                  id="myMap"
                  uasZones={uasZones}
                  subscriptions={subscriptions}
                  selectedCenter={selectedCenter}
                  selectedObject={selectedObject}
                  enableSubscriptionCreationEditor={
                    enableSubscriptionCreationEditor
                  }
                  subscriptionCreationEditionCancel={() => {
                    setEnableSubscriptionCreationEditor(false);
                  }}
                  subscriptionCreationEditionContinue={async (polygon: any) => {
                    //Close the polygon
                    polygon.push(polygon[0]);
                    consoleLog.current.push('creating subscription');
                    await createSubscription(
                      endpoint,
                      username,
                      password,
                      polygon
                    );
                    //todo: handle errors
                    const response = await getSubscriptions(
                      endpoint,
                      username,
                      password
                    );
                    setSubscriptions(
                      response.data.subscriptions.filter(
                        (subs) => subs.subscriptionStatus !== 'DELETED'
                      )
                    );

                    setEnableSubscriptionCreationEditor(false);
                  }}
                  options={{
                    center: { lat: -41.0082, lng: 174.9784 },
                    zoom: 8,
                  }}
                  onMapLoad={(_map: any) => {}}
                />
              </div>
            </div>
            <div className="logs">
              <header>
                <nav>
                  <a
                    className={logTab === 'console' ? 'active' : ''}
                    onClick={() => {
                      setLogTab('console');
                    }}
                  >
                    Logs
                  </a>
                  <a
                    id="messagesLog"
                    className={logTab === 'queue' ? 'active' : ''}
                    onClick={() => {
                      setLogTab('queue');
                    }}
                  >
                    Subscription messages ({subscriptionMessages.current.length}
                    )
                    <span style={{ fontFamily: 'monospace' }}>
                      {tick ? '.' : ' '}
                    </span>
                  </a>
                </nav>
              </header>
              {logTab === 'queue' && (
                <div className="queue-log">
                  {subscriptionMessages.current.length === 0 && (
                    <div className="row">
                      <div className="name"> &nbsp; </div>
                      <div className="value">
                        <input
                          type="readonly"
                          defaultValue="No messages recieved"
                        />
                      </div>
                    </div>
                  )}
                  {Object.keys(subscriptionMessages.current.reverse()).map(
                    (key: any, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="name">
                            {subscriptionMessages.current.length - key}
                          </div>
                          <div className="value">
                            <input
                              type="readonly"
                              defaultValue={JSON.stringify(
                                subscriptionMessages.current[key]
                              )}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {logTab === 'console' && (
                <div className="queue-log">
                  {consoleLog.current.length === 0 && (
                    <div className="row">
                      <div className="name"> &nbsp; </div>
                      <div className="value">
                        <input type="readonly" defaultValue="No logs found" />
                      </div>
                    </div>
                  )}
                  {Object.keys(consoleLog.current.reverse()).map(
                    (key: any, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="name">
                            {consoleLog.current.length - key}
                          </div>
                          <div className="value">
                            <input
                              type="readonly"
                              defaultValue={JSON.stringify(
                                consoleLog.current[key]
                              )}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SubscriptionsDemo;
