import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import {
  formatStatus,
  resolveSingleStatus,
  type StyledFeatures,
} from 'fims-api-types';

import { useConflictedFlights } from '../../../state/conflicted-flights/hooks';
import { TABLE_HEADINGS } from './conflicted-flights-table-constants';
import DetailMap from '../../shared/detail-map/index';
import { useSurveillanceWithLatency } from '../../../websocket/use-surveillance';
import { getStatusClassnames } from '../../helpers';

import './conflicted-flights.scss';
import GoogleApiLoader from '../../../components/shared/google-api-loader';

const ConflictedFlights = () => {
  const navigate = useNavigate();

  const [
    conflictedFlights,
    startPollingConflictedFlights,
    stopPollingConflictedFlights,
  ] = useConflictedFlights();
  const [selectedConflict, setSelectedConflict] = useState('');
  const [selectedConflictFlightAreas, setSelectedConflictFlightAreas] =
    useState<StyledFeatures>(null);
  const [surveillanceData] = useSurveillanceWithLatency();

  const conflictSelected = useCallback(
    (id: string) => {
      setSelectedConflict(id);

      const selectedConflictGroup = conflictedFlights.find(
        ({ conflictId }) => conflictId === Number(id)
      );

      setSelectedConflictFlightAreas(
        selectedConflictGroup.flightRequests.reduce(
          (p: StyledFeatures, n) => p.concat(n.flightAreas),
          []
        )
      );
    },
    [conflictedFlights]
  );

  const conflictedSelectedCallback = useCallback(
    (id: string) => {
      conflictSelected(id);
    },
    [conflictSelected]
  );

  useEffect(() => {
    startPollingConflictedFlights();

    return () => {
      stopPollingConflictedFlights();
    };
  }, [startPollingConflictedFlights, stopPollingConflictedFlights]);

  useEffect(() => {
    if (conflictedFlights) {
      const firstConflictGroup = conflictedFlights[0];

      if (firstConflictGroup) {
        conflictedSelectedCallback(`${firstConflictGroup.conflictId}`);
      }
    }
  }, [conflictedFlights, conflictedSelectedCallback]);

  const handleClick = (event: React.SyntheticEvent<HTMLElement>) => {
    const { id, type } = event.currentTarget.dataset;
    if (type === 'toggle-list-content') {
      conflictSelected(id);
    }
  };

  return (
    <div className="conflicted-flights">
      <Typography className="conflicted-flights-heading" variant="h3">
        Conflicted Flights
      </Typography>
      {conflictedFlights && conflictedFlights.length < 1 ? (
        <div className="conflicted-flights-empty">No conflicted flights</div>
      ) : (
        <dl className="conflicted-flights-list">
          {conflictedFlights.map(({ conflictId, flightRequests }, index) => (
            <React.Fragment key={conflictId}>
              <dt
                className={cx(
                  'conflicted-flights-list-term',
                  { 'is-active': selectedConflict === '' && index === 0 },
                  { 'is-active': selectedConflict === conflictId.toString() }
                )}
                onClick={handleClick}
                data-type="toggle-list-content"
                data-id={conflictId}
              >
                {`Conflict #${conflictId}`}
              </dt>
              <dd className="conflicted-flight-list-description">
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADINGS.map((heading) => (
                        <TableCell align={heading.align} key={heading.sortKey}>
                          {heading.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flightRequests.map((flightRequest) => {
                      const overallStatus = resolveSingleStatus(
                        flightRequest.status
                      );
                      const statusText = formatStatus(overallStatus);
                      return (
                        <TableRow
                          className="table__row"
                          key={flightRequest.flightId}
                          onClick={() =>
                            navigate(`/flight/${flightRequest.flightId}`)
                          }
                        >
                          <TableCell>{flightRequest.flightId}</TableCell>
                          <TableCell>
                            {[
                              flightRequest.pilot.givenName,
                              flightRequest.pilot.familyName,
                            ].join(' ')}
                          </TableCell>
                          <TableCell>
                            {flightRequest.isShielded ? 'Y' : 'N'}
                          </TableCell>
                          <TableCell>
                            {flightRequest.controlZone || '-'}
                          </TableCell>
                          <TableCell>
                            {flightRequest.reportFormatStartDateTime}
                          </TableCell>
                          <TableCell>
                            {flightRequest.reportFormatEndDateTime}
                          </TableCell>
                          <TableCell
                            align="center"
                            classes={{
                              root: `is-${overallStatus
                                .replace(/\s/g, '')
                                .toLowerCase()}`,
                            }}
                          >
                            <span
                              className={cx(
                                'table-row-status',
                                ...getStatusClassnames(overallStatus)
                              )}
                            >
                              {statusText}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      )}
      <div className="conflicted-flights-map">
        <GoogleApiLoader className="detail-map">
          <DetailMap
            surveillanceData={surveillanceData}
            flightAreas={selectedConflictFlightAreas}
          />
        </GoogleApiLoader>
      </div>
    </div>
  );
};

export default ConflictedFlights;
