import { useEffect, useState } from 'react';

import {
  type SurveillanceViewModel,
  type FlightRequestViewModel,
  shapeOptions,
} from 'fims-api-types';
import type { AircraftTelemetryWithAlerts } from 'argus-events/interfaces';
import { AircraftType, AlertStatus } from 'argus-common/enums';
import { ColorCode } from '../components/helpers';
import { geometryToGooglePaths } from '../lib/google-helpers';

interface BreachedFlight {
  flightId: number;
  status: AlertStatus;
  breachedPolygon: google.maps.Polygon;
}

export const useConformanceMonitoring = (
  surveillanceData: SurveillanceViewModel[],
  flightRequests: {
    flightId: FlightRequestViewModel['flightId'];
    flightAreas: FlightRequestViewModel['flightAreas'];
    geometry: FlightRequestViewModel['geometry'];
  }[],
  map: any,
  google: any
) => {
  const [breachedFlights, setBreachedFlights] = useState<BreachedFlight[]>([]);

  useEffect(() => {
    if (flightRequests.length) {
      const survBreaches: AircraftTelemetryWithAlerts[] =
        getBreachFlights(surveillanceData);

      survBreaches.forEach((survBreach) => {
        const matchingFlightRequest = flightRequests.find(
          (mFr) => mFr.flightId === survBreach.flightId
        );
        const existingBreach = breachedFlights.find(
          (breachedState) =>
            breachedState.flightId === matchingFlightRequest?.flightId
        );
        const alreadyExists = !!existingBreach;
        const hasChanged =
          existingBreach?.status &&
          existingBreach.status !== survBreach.alertConformanceStatus;
        if (hasChanged) {
          const staleBreach = breachedFlights.find(
            (bf) => bf.flightId === existingBreach.flightId
          );
          staleBreach.breachedPolygon.setMap(null);
          const freshBreaches = breachedFlights.filter(
            (bf) => bf.flightId !== existingBreach.flightId
          );
          setBreachedFlights(freshBreaches);
        }

        if (matchingFlightRequest && (!alreadyExists || hasChanged)) {
          const polyg = drawFlightRequestForBreachedDrone(
            survBreach.alertConformanceStatus,
            matchingFlightRequest,
            google
          );
          polyg.breachedPolygon?.setMap(map);
          setBreachedFlights((oldArray) => [...oldArray, polyg]);
        }
      });

      breachedFlights.forEach((breachedState) => {
        if (!survBreaches.find((y) => y.flightId === breachedState.flightId)) {
          breachedState.breachedPolygon.setMap(null);
          const removeStaleFR = breachedFlights.filter(
            (y) => y.flightId !== breachedState.flightId
          );
          setBreachedFlights(removeStaleFR);
        }
      });
    }
  }, [
    surveillanceData,
    breachedFlights,
    map,
    flightRequests,
    setBreachedFlights,
    google,
  ]);
};

export const getBreachFlights = (
  surveillanceData: AircraftTelemetryWithAlerts[]
): AircraftTelemetryWithAlerts[] =>
  surveillanceData.filter(
    ({ alertConformanceStatus, type }: AircraftTelemetryWithAlerts) =>
      type === AircraftType.Drone &&
      [AlertStatus.Breach, AlertStatus.Alert].includes(alertConformanceStatus)
  );

const drawFlightRequestForBreachedDrone = (
  alertConformanceStatus: AlertStatus,
  breachedFlight: {
    flightId: FlightRequestViewModel['flightId'];
    flightAreas: FlightRequestViewModel['flightAreas'];
    geometry: FlightRequestViewModel['geometry'];
  },
  google: any
): BreachedFlight => {
  const googleCoords = geometryToGooglePaths(breachedFlight.geometry);
  const polygon: google.maps.Polygon = new google.maps.Polygon({
    paths: googleCoords,
    ...shapeOptions,
    fillColor:
      alertConformanceStatus === AlertStatus.Breach
        ? ColorCode.Breach
        : ColorCode.Alert,
    strokeColor:
      alertConformanceStatus === AlertStatus.Breach ? 'red' : 'orange',
    strokeOpacity: 1,
    fillOpacity: 1,
    zIndex: 2,
  });
  return {
    flightId: breachedFlight?.flightId,
    status: alertConformanceStatus,
    breachedPolygon: polygon,
  };
};
