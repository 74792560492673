import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { ConflictGroup } from 'fims-api-types';

import {
  ConflictedFlightsActionType,
  ConflictedFlightsFetchSucceededAction,
} from './actions';

import { ConflictedFlightsFetchStatus } from './constants';

export interface ConflictedFlightsState {
  data: ConflictGroup[];
  fetchStatus: ConflictedFlightsFetchStatus;
  isPolling: boolean;
}

const conflictedFlightsReducer = createReducer(
  {
    [ConflictedFlightsActionType.FETCH_SUCCEEDED]: (
      _: ConflictGroup[],
      { payload }: ConflictedFlightsFetchSucceededAction
    ) => payload,
  },
  [],
  false
);

const INITIAL_FETCH_STATUS = ConflictedFlightsFetchStatus.IDLE;
const fetchStatusReducer = createReducer(
  {
    [ConflictedFlightsActionType.FETCH_REQUESTED]: () =>
      ConflictedFlightsFetchStatus.CONFLICTED_FLIGHTS_FETCH_IN_PROGRESS,
    [ConflictedFlightsActionType.FETCH_SUCCEEDED]: () =>
      ConflictedFlightsFetchStatus.SUCCESS,
    [ConflictedFlightsActionType.FETCH_FAILED]: () =>
      ConflictedFlightsFetchStatus.FAILED,
    [ConflictedFlightsActionType.STOPPED_POLLING]: () =>
      ConflictedFlightsFetchStatus.IDLE,
  },
  INITIAL_FETCH_STATUS,
  false
);
const isPollingReducer = createReducer(
  {
    [ConflictedFlightsActionType.STARTED_POLLING]: () => true,
    [ConflictedFlightsActionType.STOPPED_POLLING]: () => false,
  },
  false
);

export default combineReducers({
  data: conflictedFlightsReducer,
  fetchStatus: fetchStatusReducer,
  isPolling: isPollingReducer,
});
