import React from 'react';
import { SplashPage } from './splash-page';

export function Maintenance() {
  return (
    <SplashPage
      primaryText="We are currently down for maintenance"
      secondaryText="We'll be back shortly"
    />
  );
}
