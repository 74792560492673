import { Schema, type Document } from 'mongoose';
import {
  FlightRequestAudit,
  FlightRequestAuditAction,
  StatusUpdateUser,
  ExponentUser,
} from '@airshare/external-api-types';

export {
  FlightRequestAudit,
  FlightRequestAuditAction,
  StatusUpdateUser,
  ExponentUser,
};

export interface FlightRequestAuditSchema
  extends FlightRequestAudit,
    Document {}

const flightRequestAuditSchema = new Schema<FlightRequestAuditSchema>(
  {
    action: {
      type: String,
      enum: Object.values(FlightRequestAuditAction),
      required: true,
    },
    user: {
      type: Schema.Types.Mixed,
      required() {
        return !(this as FlightRequestAudit).pilot;
      },
    },
    userId: {
      type: Schema.Types.ObjectId,
      ref: 'User',
    },
    pilot: {
      type: String,
      required() {
        const fra = this as FlightRequestAudit;
        return !!fra.organisation || !fra.user;
      },
    },
    organisation: {
      type: Schema.Types.ObjectId,
      ref: 'Organisation',
      required() {
        return !(this as FlightRequestAudit).user;
      },
    },
    message: String,
    context: Schema.Types.Mixed,
    createdAt: {
      type: Schema.Types.Date,
      required: false,
    },
    updatedAt: {
      type: Schema.Types.Date,
      required: false,
    },
  },
  { timestamps: true, _id: false, id: false }
);

export default flightRequestAuditSchema;
