import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import type {
  ConflictedFlightRequest,
  DetailedFlightRequestViewModelV2,
} from 'fims-api-types';

import { useProfile } from '../../../../state/session';

import { flightStatusIcon } from './StatusPills';
import * as fimsApi from '../../../../clients/fims-api-client';

import { warningItemStyle } from './styles';

import '../flight.scss';

interface Props {
  flightId: number;
  focussedFlightRequest: DetailedFlightRequestViewModelV2;
  setFocussedFlightRequest: (
    flightRequest: DetailedFlightRequestViewModelV2
  ) => void;
  setErrorMessage: (message: string) => void;
}

const flightDeconflictionEnabled: boolean =
  window.env.FLIGHT_DECONFLICTION_ENABLED === 'true';

const Conflicts = ({
  flightId,
  focussedFlightRequest,
  setFocussedFlightRequest,
  setErrorMessage,
}: Props) => {
  const navigate = useNavigate();
  const { authorizer } = useProfile();

  const [conflictedOpen, setConflictedOpen] = useState(true);
  const [isDeconflicting, setIsDeconflicting] = useState(false);

  const deconflictFlight = async (deconflictFlightId: number) => {
    setIsDeconflicting(true);
    const deconflictionResponse = await fimsApi.deconflictFlight(
      flightId,
      deconflictFlightId
    );
    if (deconflictionResponse.status === 200) {
      const flightRequest = await fimsApi.getFlightRequest(flightId);
      setFocussedFlightRequest(flightRequest);
    } else {
      setErrorMessage(deconflictionResponse.data.error?.join(', '));
    }
    setIsDeconflicting(false);
  };

  const renderDeconflict = (
    conflictedFlightRequest: ConflictedFlightRequest
  ) => {
    if (
      flightDeconflictionEnabled &&
      conflictedFlightRequest.flightId > focussedFlightRequest.flightId
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled={isDeconflicting}
          onClick={() => deconflictFlight(conflictedFlightRequest.flightId)}
          data-testid="flight-deconflict-flight"
        >
          Deconflict
        </Button>
      );
    } else {
      const conflictedFlightStatus = conflictedFlightRequest.status.find(
        (x) => x.authorizer === authorizer?.name
      );

      return flightStatusIcon(conflictedFlightStatus.code);
    }
  };

  return (
    <div data-testid="flight-info-conflicts">
      {focussedFlightRequest.conflicts?.length > 0 && (
        <ListItem
          style={warningItemStyle}
          dense
          button
          onClick={() => setConflictedOpen(!conflictedOpen)}
          data-testid="conflicting-flights"
        >
          <ListItemText primary="Conflicting Flights" />
          {conflictedOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={conflictedOpen} timeout="auto" unmountOnExit>
        {focussedFlightRequest?.conflicts &&
          focussedFlightRequest.conflicts.map((conflict) => (
            <div
              key={`conflict-${conflict.flightId}`}
              className="flight-deconflict-row"
            >
              <div
                onClick={() => navigate(`/flight/${conflict.flightId}`)}
                className="flight-conflicted-flights"
                style={{ justifyContent: 'space-around' }}
                data-testid="flight-conflicted-flights"
              >
                {conflict.flightId}
              </div>
              <div style={{ justifyContent: 'space-around' }}>
                {renderDeconflict(conflict)}
              </div>
            </div>
          ))}
      </Collapse>
    </div>
  );
};

export default Conflicts;
