import React, { useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LoginContext, MapView24Hour } from '../../../context/LoginState';

export function GlobalMapAlert({ errors }: Readonly<{ errors?: string[] }>) {
  const { mapView24hour } = useContext(LoginContext);

  if (errors?.length > 0) {
    return (
      <Snackbar open={errors?.length > 0} style={{ opacity: '0.8' }}>
        <Alert severity="error">{errors?.join(', ')}</Alert>
      </Snackbar>
    );
  }

  return (
    <>
      <Snackbar
        open={mapView24hour === MapView24Hour.DATE}
        style={{ opacity: '0.8' }}
      >
        <Alert severity="warning">
          Showing flights for the selected date and time only.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mapView24hour === MapView24Hour.NEXT24}
        style={{ opacity: '0.8' }}
      >
        <Alert severity="warning">
          Showing flights for the next 24 hours only.
        </Alert>
      </Snackbar>
    </>
  );
}
