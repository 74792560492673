/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { LatLng } from 'fims-api-types';

export interface GoogleMapConfig {
  center?: LatLng;
  zoom?: number;
  onReady?: () => void;
  disableDefaultUI?: boolean;
}

export function useMap(
  google: any,
  ref: React.MutableRefObject<undefined>,
  config: GoogleMapConfig = {}
): [google.maps.Map | null, () => void] {
  const [map, updateMap] = useState<google.maps.Map | null>(null);
  const [isReady, updateIsReady] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<GoogleMapConfig>({});

  useEffect(() => {
    if (google && ref.current) {
      const mapConfig = {
        ...config,
      };

      setCurrentConfig(mapConfig);

      updateMap(new google.maps.Map(ref.current, mapConfig));

      google.maps.event.trigger(map, 'ready');
      updateIsReady(true);
    }
  }, [google, ref]);

  useEffect(() => {
    if (map && config.onReady) {
      config.onReady();
    }
  }, [isReady]);

  function rerenderMap(newConfig: GoogleMapConfig = {}) {
    if (google && ref.current) {
      const mapConfig = {
        ...currentConfig,
        ...newConfig,
      };

      setCurrentConfig(mapConfig);

      updateMap(new google.maps.Map(ref.current, mapConfig));

      google.maps.event.trigger(map, 'ready');
      updateIsReady(true);
    }
  }

  return [map, rerenderMap];
}
