import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { addRemoveElement } from './lib';
import { menuStyles } from './menu-styles';
import FilterDropdown from './filter-dropdown';
import { getStatusText } from 'airspace-manager-common/status/multi-status-check';

type Props = {
  open: string;
  anchorRefStatus: React.MutableRefObject<HTMLButtonElement>;
  handleStatusClose: (event: React.MouseEvent<EventTarget>) => void;
  handleToggleStatus: () => void;
  statusFilterValues: string[];
  selectedStatus: string[];
  setSelectedStatus: (statuses: string[]) => void;
};

export default function StatusSelector({
  open,
  anchorRefStatus,
  handleStatusClose,
  handleToggleStatus,
  statusFilterValues,
  selectedStatus,
  setSelectedStatus,
}: Props) {
  const classes = menuStyles();

  const menuListItems = statusFilterValues.map((label) => (
    <MenuItem
      key={label}
      className={classes.menuItem}
      selected={selectedStatus?.includes(label)}
      onClick={() => {
        const newStatus = addRemoveElement(label, selectedStatus);
        setSelectedStatus(newStatus);
      }}
      data-testid="status-list-item"
    >
      {getStatusText(label, true)}
    </MenuItem>
  ));

  return (
    <FilterDropdown
      buttonText="Status"
      open={open}
      openItem="status"
      anchorRef={anchorRefStatus}
      closeButton={handleStatusClose}
      toggleButton={handleToggleStatus}
      menuListItems={menuListItems}
    />
  );
}
