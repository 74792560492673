import { createTheme } from '@material-ui/core/styles';

const unicodeRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';

const gothamBook = {
  fontFamily: 'GothamBook',
  fontStyle: 'normal',
  src: `local('GothamBook') url('./fonts/GothamBook.ttf') format('truetype')`,
  unicodeRange,
};

const gothamMedium = {
  fontFamily: 'GothamMedium',
  fontStyle: 'normal',
  src: `local('GothamMedium') url('./fonts/GothamMedium.ttf') format('truetype')`,
  unicodeRange,
};

const calibri = {
  fontFamily: 'Calibri',
  fontStyle: 'normal',
  src: `local('Calibri') url('./fonts/Calibri.ttf') format('truetype')`,
  unicodeRange,
};

export const theme = createTheme({
  palette: {
    primary: {
      light: '#5fa5f2',
      main: '#4377bb',
      dark: '#366291',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffdb92',
      main: '#ffb511',
      dark: '#db960e',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Calibri',
    h1: {
      fontFamily: 'GothamBook',
    },
    h2: {
      fontFamily: 'GothamBook',
    },
    h3: {
      fontFamily: 'GothamBook',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gothamBook, gothamMedium, calibri],
      },
    },
  },
});
