import { TableHeading } from '~/lib/table';

export const DEFAULT_PAGE_OPTIONS = [10, 20];
export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_ROWS_COUNT = 0;

export const DEFAULT_SORT_KEY = 'name';

export const TABLE_HEADINGS: TableHeading[] = [
  {
    label: 'Pilot Email',
    sortKey: 'email',
    align: 'left',
  },
  {
    label: 'Pilot Name',
    sortKey: 'givenName',
    align: 'left',
  },
  {
    label: 'Organisation',
    sortKey: 'company',
    align: 'left',
  },
  {
    label: 'Name',
    sortKey: 'name',
    align: 'left',
  },
  {
    label: 'Brand',
    sortKey: 'manufacturer',
    align: 'left',
  },
  {
    label: 'Model',
    sortKey: 'model',
    align: 'left',
  },
  {
    label: 'Weight',
    sortKey: 'weight',
    align: 'left',
  },
  {
    label: 'Serial Number',
    sortKey: 'serialNumber',
    align: 'left',
  },
];
