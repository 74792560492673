import React from 'react';

import '../audit-logs.scss';

export const OperatorNote = ({ operatorNote }: { operatorNote?: string }) => {
  return (
    <tr>
      <th
        className="note-header"
        data-testid="flight-audit-log-operator-note-title"
      >
        Operator note
      </th>
      <td className="note" data-testid="flight-audit-log-operator-note-value">
        {operatorNote || 'N/A'}
      </td>
    </tr>
  );
};
