import { combineReducers } from 'redux';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { DetailedFlightRequestViewModelV2 } from 'fims-api-types';

import {
  FlightRequestsActionType,
  FlightRequestsFetchFocussedFailedAction,
  FlightRequestsFetchFocussedSucceededAction,
  FlightRequestsUpdateDecisionRequestedAction,
} from './actions';
import {
  FocussedFlightRequestFetchStatus,
  FormInputStatus,
  FormStatus,
} from './constants';

interface Decision {
  status: FormInputStatus;
  errorCode: number;
  error: string;
}

export interface FocussedFlightRequestState {
  data: DetailedFlightRequestViewModelV2;
  decision: Decision;
  focussedError: string;
  focussedFetchStatus: FocussedFlightRequestFetchStatus;
  formInputStatus: FormInputStatus;
  formStatus: FormStatus;
  submitter: string;
}

const dataReducer = createReducer(
  {
    [FlightRequestsActionType.FETCH_FOCUSSED_SUCCEEDED]: (
      _: DetailedFlightRequestViewModelV2,
      { payload }: FlightRequestsFetchFocussedSucceededAction
    ) => payload,
    [FlightRequestsActionType.CLEAR_FOCUSSED_FLIGHT]: (): null => null,
    [FlightRequestsActionType.UPDATE_DECISION_SUCCEEDED]: (
      _: DetailedFlightRequestViewModelV2,
      { payload }: FlightRequestsFetchFocussedSucceededAction
    ) => payload,
  },
  null,
  false
);

const INITIAL_FOCUSSED_FETCH_STATUS = FocussedFlightRequestFetchStatus.IDLE;

const fetchStatusReducer = createReducer(
  {
    [FlightRequestsActionType.FETCH_FOCUSSED_REQUESTED]: () =>
      FocussedFlightRequestFetchStatus.FOCUSSED_FETCH_IN_PROGRESS,
    [FlightRequestsActionType.FETCH_FOCUSSED_SUCCEEDED]: () =>
      FocussedFlightRequestFetchStatus.SUCCESS,
    [FlightRequestsActionType.FETCH_FOCUSSED_FAILED]: () =>
      FocussedFlightRequestFetchStatus.FAILED,
  },
  INITIAL_FOCUSSED_FETCH_STATUS,
  false
);

const submitterReducer = createReducer(
  {
    [FlightRequestsActionType.UPDATE_DECISION_REQUESTED]: (
      _: string,
      { payload: { submitter } }: FlightRequestsUpdateDecisionRequestedAction
    ) => submitter,
    [FlightRequestsActionType.UPDATE_DECISION_SUCCEEDED]: (): null => null,
    [FlightRequestsActionType.UPDATE_DECISION_FAILED]: (): null => null,
  },
  null,
  false
);

const focussedFlightRequestErrorReducer = createReducer(
  {
    [FlightRequestsActionType.FETCH_FOCUSSED_FAILED]: (
      _: string,
      { payload }: FlightRequestsFetchFocussedFailedAction
    ) => payload,
    [FlightRequestsActionType.CLEAR_FOCUSSED_ERROR]: (): null => null,
  },
  null,
  false
);

export default combineReducers({
  data: dataReducer,
  fetchStatus: fetchStatusReducer,
  submitter: submitterReducer,
  error: focussedFlightRequestErrorReducer,
});
