import { RequiredAuthChangeDb as DBRequiredAuthChange } from 'argus-data-model/db/schemas/flight-request/index';
import {
  AcknowledgeFlightRequestProps,
  AuthChangeField,
  AuthChangeFieldClient,
  AuthChangeFieldUi,
  RequiredAuthChange,
} from '@airshare/external-api-types';

import type { AmmendmentsAcknowledgedContext } from '@airshare/external-api-types';

function getEnumKeyFromValue<T extends { [key: string]: string }>(
  enumType: T,
  enumValue: T[keyof T]
): keyof typeof enumType {
  const key = Object.keys(enumType).find((k) => enumType[k] === enumValue);
  return key ? (key as keyof typeof enumType) : undefined;
}

const fieldNameFromClient = (
  field: RequiredAuthChange['field']
): AuthChangeField => {
  const key = getEnumKeyFromValue(
    AuthChangeFieldClient,
    field as AuthChangeFieldClient
  );
  return key ? AuthChangeField[key] : (field as AuthChangeField);
};

const fieldNameToClient = (
  field: DBRequiredAuthChange['field']
): AuthChangeFieldClient => {
  const key = getEnumKeyFromValue(AuthChangeField, field as AuthChangeField);
  return key ? AuthChangeFieldClient[key] : (field as AuthChangeFieldClient);
};

const fieldNameToUI = (
  field: RequiredAuthChange['field']
): AuthChangeFieldUi => {
  const key = getEnumKeyFromValue(AuthChangeField, field as AuthChangeField);
  return key ? AuthChangeFieldUi[key] : (field as AuthChangeFieldUi);
};

const convertRequiredAuthChangeToClient = (
  authChange: DBRequiredAuthChange
): RequiredAuthChange => {
  const mappedFieldName = fieldNameToClient(authChange.field);

  return { ...JSON.parse(JSON.stringify(authChange)), field: mappedFieldName };
};

const convertRequiredAuthChangeFromClient = (
  authChange: RequiredAuthChange
): DBRequiredAuthChange => {
  const mappedFieldName = fieldNameFromClient(authChange.field);

  return { ...JSON.parse(JSON.stringify(authChange)), field: mappedFieldName };
};

const convertToAuditLogContext = (
  props: AcknowledgeFlightRequestProps
): AmmendmentsAcknowledgedContext => {
  return props.alertType
    ? { alertType: props.alertType }
    : {
        authorizerChanges: props.authorizerChanges?.map((a) =>
          fieldNameFromClient(a)
        ),
      };
};

export default {
  convertRequiredAuthChangeFromClient,
  convertRequiredAuthChangeToClient,
  fieldNameFromClient,
  fieldNameToClient,
  fieldNameToUI,
  convertToAuditLogContext,
};
