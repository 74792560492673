import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const menuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      zIndex: theme.zIndex.appBar,
    },
    menuItem: {
      zIndex: theme.zIndex.modal,
      display: 'block',
      justifyContent: 'flex-start',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },
    },
    labelButton: {
      color: `${theme.palette.common.black}`,
      backgroundColor: 'whitesmoke',
      paddingLeft: `${theme.spacing(1.5)}px`,
      paddingRight: `${theme.spacing(1.5)}px`,
    },
  })
);
