import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { createSearchParams } from 'react-router-dom';
import LogRocket from 'logrocket';

import { AuthorizerType } from 'argus-data-model/db/schemas/authorizers';
import { UserRole, AirshareUserRole } from 'argus-common/enums';
import { Scope } from 'argus-common/flight-requests.lib';

import { LoginContext } from '../../../../src/context/LoginState';
import SettingsModal from '../settings-modal/settings-modal';
import EmergencyAirspaceClosureModal from '../emergency-airspace-closure/emergency-airspace-closure-modal';
import { useProfile } from '../../../state/session';
import { useSignout } from '../../../state/session/hooks';
import AppBarMenu, { MenuItemInterface } from './app-bar-menu';
import NotificationsDialog from '../home-screen/notifications/notifications-dialog';
import { SettingsModalTitles } from '../../helpers';
import Logo from './logo.png';
import OrganisationSettings from '../settings-modal/OrganisationSettings';
import EmergencyAirspaceClosureEnabled from '../emergency-airspace-closure/emergency-airspace-closure-enabled-icon';
import EmergencyAirspaceClosureDisabled from '../emergency-airspace-closure/emergency-airspace-closure-disabled-icon';
import { OnOffButton } from './tower-watch/on-off-button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.appBar,
    },
    grow: {
      flexGrow: 1,
      zIndex: theme.zIndex.appBar,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      maxHeight: 45,
      cursor: 'pointer',
    },
    section: {
      display: 'flex',
    },
    menus: {
      display: 'flex',
      width: '100%',
      margin: `0 ${theme.spacing(4)}px`,
      justifyContent: 'flex-end',
    },
    toolbar: {
      paddingTop: theme.spacing(1) * 0.5,
      paddingBottom: theme.spacing(1) * 0.5,
    },
  })
);

function ApplicationBar() {
  const navigate = useNavigate();
  const { roles, authorizer, email, tower } = useProfile();
  LogRocket.identify(email);
  const signOut = useSignout();

  const classes = useStyles();
  const [modalTypeOpen, setModalTypeOpen] = useState<
    'settings' | 'organisation'
  >(undefined);
  const [settingsModalTitle, setSettingsModalTitle] =
    useState<SettingsModalTitles>(undefined);
  const [
    isEmergencyAirspaceClosureModalOpen,
    setIsEmergencyAirspaceClosureModalOpen,
  ] = useState(false);

  const {
    setIsCreatingAirspace,
    userCanUpdateEmergencyAirspaceClosure,
    airspaceIsClosed,
  } = useContext(LoginContext);

  const createNavigation = (pathname: string, scope?: Scope) => () =>
    navigate({
      pathname,
      search: scope ? `?${createSearchParams({ scope })}` : undefined,
    });

  const isSuperAdmin = [UserRole.SUPER_ADMIN].some((value) =>
    roles.includes(value)
  );

  const isAdmin =
    isSuperAdmin || [UserRole.ADMIN].some((value) => roles.includes(value));

  const showTowerWatchButton = tower?.watchEnabled;

  const hideUAVs = showTowerWatchButton;

  const profileMenuItems: MenuItemInterface[] = [
    ...(isSuperAdmin && authorizer?.type === AuthorizerType.ControlZone
      ? [
          {
            label: 'Global Settings',
            onClick: () => {
              setModalTypeOpen('settings');
              setSettingsModalTitle(SettingsModalTitles.DIGITAL_ACTIVATION);
            },
          },
        ]
      : []),
    ...(isAdmin && authorizer?.type === AuthorizerType.ControlZone
      ? [
          {
            label: 'CTR Zone Settings',
            onClick: () => {
              setModalTypeOpen('settings');
              setSettingsModalTitle(SettingsModalTitles.CTR_ZONES);
            },
            divider: false,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: 'Airspace Settings',
            onClick: () => {
              setModalTypeOpen('organisation');
              setSettingsModalTitle(SettingsModalTitles.ORGANISATION_SETTINGS);
            },
          },
        ]
      : []),
    ...(isSuperAdmin
      ? [
          {
            label: 'Organisations',
            onClick: createNavigation('/organisations'),
          },
        ]
      : []),
    ...(isSuperAdmin
      ? [
          {
            label: 'External Organisations',
            onClick: createNavigation('/external-organisations'),
          },
          {
            label: 'Rules Engine Flowchart',
            onClick: createNavigation('/rules-engine-flowchart'),
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: 'Admin',
            onClick: createNavigation('/admin'),
          },
        ]
      : []),
  ];
  profileMenuItems.push({
    label: 'Log Out',
    onClick: signOut,
    divider: profileMenuItems.length > 0,
  });

  const handleSelectAirspace = (): void => {
    navigate('/');
    setIsCreatingAirspace(true);
  };

  const handleModalClose = () => {
    setModalTypeOpen(undefined);
    setSettingsModalTitle(undefined);
  };

  const handleToggleEmergencyAirspaceClosure = (): void => {
    setIsEmergencyAirspaceClosureModalOpen(true);
  };

  const uavsMenuItems: MenuItemInterface[] = getuavMenuItems(
    createNavigation,
    roles
  );

  const airspaceMenuOptions: MenuItemInterface[] = getAirspaceMenuOptions(
    handleSelectAirspace,
    handleToggleEmergencyAirspaceClosure,
    createNavigation,
    roles,
    userCanUpdateEmergencyAirspaceClosure,
    airspaceIsClosed
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <img
            src={Logo}
            alt="logo"
            className={classes.logo}
            onClick={createNavigation('/')}
            data-testid="home-button"
          />
          <div className={classes.grow} />
          <div className={classes.menus}>
            <AppBarMenu
              label="Airspace"
              items={airspaceMenuOptions}
              startIcon={
                airspaceIsClosed ? <EmergencyAirspaceClosureEnabled /> : null
              }
            />
            <AppBarMenu
              label="Flights"
              items={[
                {
                  label: 'Unapproved',
                  onClick: createNavigation('/flights', Scope.unapproved),
                },
                {
                  label:
                    authorizer?.type === AuthorizerType.ControlZone
                      ? 'Provisionally Authorised'
                      : 'Authorised',
                  onClick: createNavigation('/flights', Scope.upcoming),
                },
                {
                  label: 'History',
                  onClick: createNavigation('/flights', Scope.historical),
                },
                {
                  label: 'All Flights',
                  onClick: createNavigation('/flights', Scope.all),
                },
              ]}
            />
            {authorizer?.type === AuthorizerType.ControlZone &&
              !hideUAVs &&
              uavsMenuItems.length > 0 && (
                <AppBarMenu label="UAVS" items={uavsMenuItems} />
              )}
            <AppBarMenu
              label="Reports"
              items={[
                {
                  label: 'View Reports',
                  onClick: createNavigation('/reports'),
                },
              ]}
            />
          </div>
          <div className={classes.section}>
            <NotificationsDialog />
            {showTowerWatchButton && <OnOffButton />}
            <AppBarMenu
              label={<AccountCircle />}
              items={profileMenuItems}
              placement="bottom-end"
              tooltip={
                <div>
                  <div>{email}</div>
                  <div>{roles.join(', ')}</div>
                </div>
              }
            />
          </div>
        </Toolbar>
      </AppBar>
      <SettingsModal
        open={modalTypeOpen === 'settings'}
        closeModal={handleModalClose}
        title={settingsModalTitle}
      />
      <OrganisationSettings
        open={modalTypeOpen === 'organisation'}
        closeModal={handleModalClose}
      />
      <EmergencyAirspaceClosureModal
        open={isEmergencyAirspaceClosureModalOpen}
        closeModal={() =>
          setIsEmergencyAirspaceClosureModalOpen(
            !isEmergencyAirspaceClosureModalOpen
          )
        }
      />
    </div>
  );
}

export default ApplicationBar;

function getAirspaceMenuOptions(
  handleSelectAirspace: () => void,
  handleToggleEmergencyAirspaceClosure: () => void,
  createNavigation: (route: string) => () => void,
  roles: (UserRole | AirshareUserRole)[],
  userCanUpdateEmergencyAirspaceClosure: boolean,
  airspaceIsClosed: boolean
) {
  const airspaceMenuOptions: MenuItemInterface[] = [
    {
      label: 'Managed Areas',
      onClick: createNavigation('/managed-airspaces'),
    },
  ];

  addIfAuthorized(
    airspaceMenuOptions,
    { label: 'Create New Area', onClick: handleSelectAirspace },
    roles,
    [UserRole.SUPER_ADMIN, UserRole.ADMIN]
  );

  if (userCanUpdateEmergencyAirspaceClosure) {
    addIfAuthorized(
      airspaceMenuOptions,
      {
        label: airspaceIsClosed
          ? 'Allow drone flights'
          : 'Restrict drone flights',
        onClick: handleToggleEmergencyAirspaceClosure,
        startIcon: airspaceIsClosed ? (
          <EmergencyAirspaceClosureDisabled />
        ) : (
          <EmergencyAirspaceClosureEnabled />
        ),
      },
      roles,
      [UserRole.AUTHORISER, UserRole.ADMIN]
    );
  }

  return airspaceMenuOptions;
}

function getuavMenuItems(
  createNavigation: (route: string) => () => void,
  roles: (UserRole | AirshareUserRole)[]
) {
  const menuItems: MenuItemInterface[] = [];
  addIfAuthorized(
    menuItems,
    { label: 'UAVs', onClick: createNavigation('/uavs') },
    roles,
    [UserRole.SUPER_ADMIN]
  );
  addIfAuthorized(
    menuItems,
    {
      label: 'UAV Manufacturers',
      onClick: createNavigation('/uav-manufacturers'),
    },
    roles,
    [UserRole.SUPER_ADMIN]
  );
  return menuItems;
}

function addIfAuthorized(
  menuItemList: MenuItemInterface[],
  item: { label: string; onClick: () => void; startIcon?: any },
  userRoles: (UserRole | import('argus-common/enums').AirshareUserRole)[],
  authRoles: UserRole[]
) {
  const isAuthorized = authRoles.some((value) => userRoles.includes(value));

  if (isAuthorized) {
    menuItemList.push(item);
  }
}
