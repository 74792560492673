import { initSentry } from 'airshare-web-utils/sentry';

initSentry({ app: 'argus-super-admin-web' });

import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { Settings } from 'luxon';
import detectTouchDevice from 'airshare-web-utils/detect-touch-device';

import store, { startSagas } from './state/store';
import { logOut } from './state/session/actions';
import { authAPI, fimsAPI } from './clients/api';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (window.env.LOGROCKET_APP_ID_FIMS) {
  LogRocket.init(window.env.LOGROCKET_APP_ID_FIMS);
  setupLogRocketReact(LogRocket);
}

import './styles/index.scss';

import App from './components/app/app';

// Where the device has touch events but not mouse events,
// add the 'touch-only' css class to the body tag
document.body.className += detectTouchDevice() ? ' touch-only' : '';
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js', {
      scope: '/',
    })
    .then((registration) => {
      registration.update();
    })
    .catch((err) => {
      console.log('Service Worker registration failed: ', err);
    });
}

function getProfile() {
  return JSON.parse(localStorage.getItem('profile'));
}

fimsAPI.onRequestAddAuthHeader(() => {
  const profile = getProfile();
  return profile ? `Bearer ${profile.token}` : null;
});
// Handle 401 errors with handleErrorStatus which will use token-refresh to get a new token
// If there is an unexpected error after successful token-refresh, redirect to the home page
// if the api that is failing is the token-refresh one, log out the user
fimsAPI.handleErrorStatus(
  401,
  (error?: any, url?: string) => {
    if (`${window.env.ARGUS_AUTH_API_URL}/v2/token-refresh` === url) {
      if (error?.response?.status === 401) {
        store.dispatch(logOut());
      }
      return;
    }
    window.location.href = '/';
  },
  'fims-web',
  `${window.env.ARGUS_AUTH_API_URL}/v2/token-refresh`
);

authAPI.handleErrorStatus(
  401,
  () => {
    store.dispatch(logOut());
  },
  'fims-web',
  `${window.env.ARGUS_AUTH_API_URL}/v2/token-refresh`
);

// set default locale for DateTime
Settings.defaultLocale = `${window.env.DEFAULT_LOCALE}`;

startSagas();

// Render the application
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);
