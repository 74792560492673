import React from 'react';
import type { AuditLogViewModel } from 'fims-api-types';

export const UserRow = ({ log }: { log: AuditLogViewModel }) => {
  const context = log.context;

  return (
    <tr>
      <th data-testid="flight-audit-log-user-title">User</th>
      <td data-testid="flight-audit-log-user-value">
        {log.user || context?.pilotName || 'N/A'}
      </td>
    </tr>
  );
};
