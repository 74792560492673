export const CertificationType = {
  PART_101: 'Part 101',
  PART_102: 'Part 102',
};

export const ExponentRole = {
  Pilot: 'Pilot',
  OrgAdmin: 'Organisation Admin',
  AuthAdmin: 'Authority Admin',
  AuthApprover: 'Authority Approver',
  AuthMonitor: 'Authority Monitor',
  AuthViewer: 'Authority Viewer',
};

export enum FRStatusCode {
  Activated = 'Activated',
  Authorized = 'Authorized',
  Cancelled = 'Cancelled',
  Conflicted = 'Conflicted',
  Declared = 'Declared',
  Declined = 'Declined',
  Pending = 'Pending',
  ProvAuth = 'Prov. Auth.',
  RNZAFAuthReq = 'RNZAF Auth. Req.',
  Terminated = 'Terminated',
}

export const FRStatusLabels = {
  [FRStatusCode.Activated]: 'Activated',
  [FRStatusCode.Authorized]: 'Authorised',
  [FRStatusCode.Cancelled]: 'Cancelled',
  [FRStatusCode.Conflicted]: 'Conflicted',
  [FRStatusCode.Declared]: 'Airways authorisation not required',
  [FRStatusCode.Declined]: 'Declined',
  [FRStatusCode.Pending]: 'Pending',
  [FRStatusCode.ProvAuth]: 'Provisionally Authorised',
  [FRStatusCode.RNZAFAuthReq]: 'RNZAF authorisation required',
  [FRStatusCode.Terminated]: 'Terminated',
};

export const FRShortStatusLabels = {
  ...FRStatusLabels,
  [FRStatusCode.Declared]: 'No Clear. Req.',
  [FRStatusCode.ProvAuth]: 'Prov. Auth.',
  [FRStatusCode.RNZAFAuthReq]: 'RNZAF Auth. Req.',
};

export const FRPilotStatusLabels = {
  ...FRStatusLabels,
  [FRStatusCode.Declared]: 'Declared',
};

export const FlightRequestTemplate = {
  [FRStatusCode.Declared]: 'fr-no-clearance',
  [FRStatusCode.ProvAuth]: 'fr-prov-auth',
  [FRStatusCode.Authorized]: 'fr-prov-auth',
  [FRStatusCode.RNZAFAuthReq]: 'fr-rnzaf-auth-req',
  [FRStatusCode.Pending]: 'fr-pending',
  [FRStatusCode.Cancelled]: 'fr-cancelled',
  [FRStatusCode.Declined]: 'fr-declined',
  [FRStatusCode.Conflicted]: 'fr-conflicted',
  [FRStatusCode.Terminated]: 'fr-terminated',
};

export const MultiFlightRequestTemplate: { [index: string]: string } = {
  [FRStatusCode.ProvAuth]: 'fr-multi-provauth',
  [FRStatusCode.Authorized]: 'fr-multi-authorised',
  [FRStatusCode.Declined]: 'fr-multi-declined',
  [FRStatusCode.Pending]: 'fr-multi-pending',
};

/* 
STATUS            | STATUSREF
---------------------------------------------------
Declared          | Airways authorisation not required
Prov. Auth.       | Provisionally Authorised
Declined          | Declined
Cancelled         | Cancelled
RNZAF Auth. Req.  | RNZAF authorisation required
Terminated        | Terminated
 */

export enum FlightPurpose {
  AerialFilming = 'Aerial Filming',
  Surveillance = 'Surveillance',
  SurveyMapping = 'Survey Mapping',
  InfrastructureInspection = 'Infrastructure Inspection',
  InventoryManagement = 'Inventory Management',
  ConstructionMonitoring = 'Construction Monitoring',
  Demonstration = 'Demonstration',
  Training = 'Training',
  UAMOperation = 'UAM Operation',
  Fishing = 'Fishing',
  Other = 'Other',
  Police = 'Police',
  Customs = 'Customs',
  TrafficSurveillance = 'Traffic Surveillance/Pursuit',
  EnvironmentalControl = 'Environmental Control',
  SearchAndRescue = 'Search & Rescue',
  Medical = 'Medical',
  Evacuations = 'Evacuations',
  FireFighting = 'Fire Fighting',
  VipSecurity = 'VIP Security',
}

export const priorityFlightPurposes = [
  FlightPurpose.Police,
  FlightPurpose.Customs,
  FlightPurpose.TrafficSurveillance,
  FlightPurpose.EnvironmentalControl,
  FlightPurpose.SearchAndRescue,
  FlightPurpose.Medical,
  FlightPurpose.Evacuations,
  FlightPurpose.FireFighting,
  FlightPurpose.VipSecurity,
];

export enum MobilePlatformEnum {
  IOS = 'ios',
  ANDROID = 'android',
}

export const DecisionStatus = {
  COMPLETED: 'Completed',
  REQUIRED: 'Required',
  OPTIONAL: 'Optional',
};

export enum DecisionStatusEnum {
  COMPLETED = 'Completed',
  REQUIRED = 'Required',
  OPTIONAL = 'Optional',
}

export const FlightPathMode = {
  CIRCLE: 'Circle',
  POLYGON: 'Polygon',
  MULTIPOLYGON: 'MultiPolygon',
  SEGMENTED: 'Segmented',
};

export enum FlightPathModeEnum {
  CIRCLE = 'Circle',
  POLYGON = 'Polygon',
  MULTIPOLYGON = 'MultiPolygon',
  SEGMENTED = 'Segmented',
}

export const GeoJsonFeatureType = {
  CIRCLE: 'Circle',
  LINE_STRING: 'LineString',
  POLYGON: 'Polygon',
  POINT: 'Point',
  MULTIPOLYGON: 'MultiPolygon',
};

export enum GeoJsonFeatureTypeEnum {
  CIRCLE = 'Circle',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  POINT = 'Point',
  MULTIPOLYGON = 'MultiPolygon',
}

export const ConfigKeys = {
  MINIMUM_APP_VERSION: 'MINIMUM_APP_VERSION',
};

export const PILOT_NAME_HACK = '|$|%|^|%|$|';

// NOTE: UAV weight
// - 'min' is inclusive
// - 'max' is exclusive.
export const UAVWeights = [
  {
    label: 'Between 15 and 25 kg',
    code: '15 to 25',
    min: 15,
    max: 25,
  },
  {
    label: 'Above 25 kg',
    code: '>25',
    min: 25,
    max: null,
  },
  {
    label: 'Less Than 15 kg',
    code: '<15',
    min: 0,
    max: 15,
  },
];

export const UNIVERSAL_DATE_FORMAT = 'YYYY-MM-DD';
export const UNIVERSAL_TIME_FORMAT = 'HH:mm';
export const UNIVERSAL_DATE_TIME_FORMAT = `${UNIVERSAL_DATE_FORMAT} ${UNIVERSAL_TIME_FORMAT}`; // locale independent date string

// HARD CODED to nz date format at the moment.
// Need to modify in the future to account for
// the user's time zone, or do date time display
// formatting on the client(s).
export const DATE_FORMAT = 'DD/MM/YY';
export const DATE_TIME_FORMAT = `${UNIVERSAL_TIME_FORMAT} ${DATE_FORMAT}`;

export const accountNotActivateError = {
  code: 'ACCOUNT_NOT_ACTIVATED',
  message:
    'Account is not activated. Please check your email and activate the account.',
};

export const DECISION_MAKER_NAME = {
  ARGUS_RULES_ENGINE: 'Argus Rules Engine',
  FLIGHT_REQUEST_CREATION: 'Flight Request Creation',
};

export const ATC_AUTH_DEFAULT_NAME = 'Air Traffic Control';
