import React from 'react';
import { DateTime } from 'luxon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FlightRequestsByZone } from '../../../clients/fims-api-client';

const FlightRequestsByZoneTable = ({
  className,
  data,
}: {
  className: string;
  data: FlightRequestsByZone;
}) => (
  <Table
    className={className}
    data-testid="airshare-flights-requests-by-control-zone-table"
  >
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell align="right">Week Beginning</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell>Control Zone</TableCell>
        {data.weeklyTotals?.map(({ startDate }) => (
          <TableCell
            align="right"
            key={DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          >
            {DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}
          </TableCell>
        ))}
        <TableCell align="right">Total</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.controlZones
        ?.sort((a, b) => {
          if (a.name === 'Outside Control Zone') return 1;
          return a.name.localeCompare(b.name);
        })
        .map(({ name, weeks, total }) => (
          <TableRow key={`cz-${name}`}>
            <TableCell>{name}</TableCell>
            {weeks.map(({ startDate, value }) => (
              <TableCell
                align="right"
                key={`${name}-${DateTime.fromISO(startDate).toFormat(
                  'dd-MMM-yyyy'
                )}`}
              >
                {value}
              </TableCell>
            ))}
            <TableCell align="right">{total}</TableCell>
          </TableRow>
        ))}
      <TableRow>
        <TableCell>Total</TableCell>
        {data.weeklyTotals.map(({ startDate, value }) => (
          <TableCell
            align="right"
            key={`total-${DateTime.fromISO(startDate).toFormat('dd-MMM-yyyy')}`}
          >
            {value}
          </TableCell>
        ))}
        <TableCell
          align="right"
          data-testid="airshare-flights-requests-by-control-zone-total-count"
        >
          {data.total}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default FlightRequestsByZoneTable;
