import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cx from 'classnames';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';

import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';

import {
  FlightRequestViewModel,
  resolveSingleStatus,
  formatStatus,
} from 'fims-api-types';
import { FRStatusCode } from 'argus-api-shared/lib/constants';
import {
  Scope,
  TimeFrame,
  scopeToStatus,
  scopeToTimeFrame,
} from 'argus-common/flight-requests.lib';
import { Loading } from 'asm-web-components';

import { useProfile } from '../../../state/session';
import FilterMenu from './filters/filter-menu';
import { pilotOrgName, atcNotes } from './flight-report.lib';
import './flight-report.scss';
import {
  useTableStyles,
  Order,
  DEFAULT_SORT_KEY,
  DEFAULT_SORT_ORDER,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_PAGE,
  TABLE_HEADINGS,
  DEFAULT_PAGE_OPTIONS,
} from './flight-report.model';
import * as fimsClientApi from '../../../clients/fims-api-client';
import { getStatusClassnames } from '../../helpers';
import { purposeFilters, statusFilters } from './filters/lib';
import { rulePartIcon } from '../flight/flight-information/StatusPills';

const FlightReport = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const { authorizer } = useProfile();
  const classes = useTableStyles({});

  const [flightRequests, setFlightRequest] = useState<FlightRequestViewModel[]>(
    []
  );
  const [loaded, setLoaded] = useState(false);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_KEY);
  const [order, setOrder] = useState(DEFAULT_SORT_ORDER);
  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE);
  const [meta, setMeta] = useState({
    pageIndex: DEFAULT_PAGE,
    pageSize: DEFAULT_ROWS_PER_PAGE,
    sortBy: DEFAULT_SORT_KEY,
    order: DEFAULT_SORT_ORDER,
    totalRecords: 0,
  });

  const [timeframe, setTimeframe] = useState<string>(TimeFrame.all);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [selectedScope, setSelectedScope] = useState('');

  const [initializing, setInitializing] = useState(true);

  const scope =
    searchParams.get('scope') in Scope
      ? (searchParams.get('scope') as Scope)
      : Scope.all;

  function handleSearchChange(searchValue: string = '') {
    setPageIndex(0);
    setLoaded(false);
    setSearch(searchValue);
  }

  useEffect(() => {
    if (scope && scope !== selectedScope) {
      setInitializing(true);
      setLoaded(false);
      setSelectedScope(scope);
      handleSearchChange();
    }
  }, [scope, selectedScope]);

  useEffect(() => {
    if (!selectedScope || !authorizer) {
      return;
    }

    setSelectedStatuses(scopeToStatus(selectedScope, authorizer));
    setTimeframe(scopeToTimeFrame(selectedScope));
    setPurposes([]);

    if (initializing) {
      setInitializing(false);
    }
  }, [initializing, scope, authorizer, selectedScope]);

  const getFlights = useCallback(async () => {
    const flights = await fimsClientApi.getFlightRequests({
      pageIndex,
      pageSize,
      sortBy,
      order,
      status: selectedStatuses,
      purpose: purposes,
      timeframe: timeframe,
      search,
    });
    setFlightRequest(flights.data);
    setMeta({
      pageIndex: flights.pageIndex,
      pageSize: flights.pageSize,
      sortBy,
      order,
      totalRecords: flights.totalRecords,
    });
    setLoaded(true);
  }, [
    pageIndex,
    pageSize,
    sortBy,
    order,
    selectedStatuses,
    purposes,
    timeframe,
    search,
  ]);

  const startPollingCallback = useCallback(() => getFlights(), [getFlights]);

  useEffect(() => {
    if (!initializing) {
      startPollingCallback();
      const interval = setInterval(() => {
        startPollingCallback();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [initializing, startPollingCallback]);

  const handleTimeFrameChangePage = (__: any, newPage: number) => {
    setPageIndex(0);
    setLoaded(false);
    setPageIndex(newPage);
  };

  const handleTimeFrameChangeRowsPerPage = (event: any) => {
    setPageIndex(0);
    setLoaded(false);
    setPageSize(parseInt(event.target.value, 10));
  };

  function onSortClick(heading: string) {
    setPageIndex(0);
    setLoaded(false);
    setSortBy(heading);
    const updatedOrder =
      order === Order.ascending ? Order.descending : Order.ascending;
    setOrder(updatedOrder);
  }

  function handleSearchKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      return (document.activeElement as HTMLElement)?.blur();
    }
    return null;
  }

  const debouncedHandleSearchChange = debounce(
    (searchValue: string) => handleSearchChange(searchValue),
    500
  );

  if (initializing) {
    return null;
  }

  return (
    <div className="flight-requests" data-testid="flight-requests">
      <Typography
        className="flight-requests__heading"
        variant="h3"
        data-testid="flight-request-heading"
      >
        Flights
      </Typography>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'space-between',
        }}
        data-testid="filter-and-search"
      >
        <FilterMenu
          statusFilterValues={statusFilters}
          purposeFilterValues={purposeFilters}
          selectedPurpose={purposes}
          selectedStatus={selectedStatuses}
          setSelectedPurpose={(selectedPurposes: string[]) => {
            setLoaded(false);
            setPageIndex(0);
            setPurposes(selectedPurposes);
          }}
          setSelectedStatus={(statuses: FRStatusCode[]) => {
            setLoaded(false);
            setPageIndex(0);
            setSelectedStatuses(statuses);
          }}
          timeframe={timeframe}
          setTimeframe={(tf) => {
            setLoaded(false);
            setPageIndex(0);
            setTimeframe(tf);
          }}
        />
        <TextField
          data-testid="search"
          name="search"
          type="search"
          className="search-box"
          placeholder="Search..."
          variant="outlined"
          size="small"
          onChange={(event) => debouncedHandleSearchChange(event.target.value)}
          onKeyDown={handleSearchKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {loaded ? (
        <Paper className={classes.root}>
          <Table className="table" data-testid="flight-table">
            <TableHead>
              <TableRow>
                {TABLE_HEADINGS.map((heading) => (
                  <TableCell
                    align={heading.align}
                    key={heading.label}
                    data-testid="flight-table-column-header"
                  >
                    {heading.sortKey !== '' ? (
                      <TableSortLabel
                        active={sortBy === heading.sortKey}
                        direction={order}
                        onClick={() => onSortClick(heading.sortKey)}
                        data-testid="flight-table-column-header-label"
                      >
                        {heading.label}
                      </TableSortLabel>
                    ) : (
                      heading.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {flightRequests.map((flightRequest: FlightRequestViewModel) => {
                const matchingStatus = flightRequest.status.find(
                  (x) => x.authorizer === authorizer?.name
                );
                const authorizerStatus = matchingStatus
                  ? matchingStatus.code
                  : resolveSingleStatus(flightRequest.status);
                return (
                  <TableRow
                    className="table__row"
                    key={flightRequest.id}
                    onClick={() =>
                      navigate(`/flight/${flightRequest.flightId}`)
                    }
                    data-testid="flight-table-row"
                  >
                    <TableCell data-testid="flight-table-row-flight-id">
                      {flightRequest.flightId}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-flight-request-org-name">
                      {pilotOrgName(flightRequest)}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-created-date">
                      {DateTime.fromJSDate(
                        new Date(flightRequest.createdOn)
                      ).toFormat('dd-LL-yyyy HH:mm')}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-flight-purpose">
                      {flightRequest.flightPurpose}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-flight-rule">
                      {rulePartIcon(flightRequest.rule)}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-start-date">
                      {flightRequest.localStartDateTime}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-end-date">
                      {flightRequest.localEndDateTime}
                    </TableCell>
                    <TableCell data-testid="flight-table-row-flight-request-act-notes">
                      {atcNotes(flightRequest)}
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{
                        root: `is-${authorizerStatus
                          ?.replace(/\s/g, '')
                          .toLowerCase()}`,
                      }}
                      data-testid="flight-table-row-flight-status"
                    >
                      <span
                        className={cx(
                          'table-row-status',
                          ...getStatusClassnames(authorizerStatus)
                        )}
                      >
                        {authorizerStatus
                          ? formatStatus(authorizerStatus)
                          : 'UNKNOWN'}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
            component="div"
            count={meta.totalRecords}
            rowsPerPage={meta.pageSize}
            page={meta.pageIndex}
            onPageChange={handleTimeFrameChangePage}
            onRowsPerPageChange={handleTimeFrameChangeRowsPerPage}
            data-testid="pagination"
          />
        </Paper>
      ) : (
        <Loading className="loading" />
      )}
    </div>
  );
};

export default FlightReport;
