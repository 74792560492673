import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import SearchIcon from '@material-ui/icons/Search';

import { UserRole } from 'argus-common/enums';
import * as fimsClient from '~/clients/fims-api-client';
import { PrivilegedOrganisation } from '~/clients/fims-api-client';
import { useProfile } from '~/state/session';

import EditOrganisationModal from './EditOrganisationModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px !important`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    addButton: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
    dialogTitle: {
      padding: '24px 24px 0 24px',
    },
    organisations: {
      padding: '0 16px 16px 16px',
    },
    editDialog: {
      position: 'relative',
      width: 400,
      padding: 24,
      paddingTop: 12,
      alignItems: 'center',
    },
    editSelect: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    pilotInfo: {
      marginBottom: 16,
    },
    searchBox: {
      float: 'right',
    },
    paperHeader: {
      padding: theme.spacing(1),
    },
    orgList: {
      marginTop: theme.spacing(4),
    },
  })
);

function ExternalOrganisations() {
  const enableGeofenceSubscription =
    window.env.EXTERNAL_API_ENABLE_GEOFENCE_SUBSCRIPTION_API?.toLowerCase() ===
    'true';
  const { roles } = useProfile();
  const [organizations, setOrganizations] = useState<PrivilegedOrganisation[]>(
    []
  );
  const [search, setSearch] = useState('');
  const [editingId, setEditingId] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();
  const isSuperAdmin = [UserRole.SUPER_ADMIN].some((role) =>
    roles.includes(role)
  );
  const isAdmin =
    isSuperAdmin || [UserRole.ADMIN].some((role) => roles.includes(role));

  if (!isSuperAdmin) {
    navigate('/');
  }

  useEffect(() => {
    async function getOrganizations() {
      const data = await fimsClient.getPrivilegedOrganizations();
      setOrganizations(data);
    }
    getOrganizations();
  }, [editingId]);

  const filteredOrganizations = [...organizations].filter((x) =>
    x.name.toLowerCase().includes(search.toLowerCase())
  );

  function renderListItem(organisation: PrivilegedOrganisation) {
    const { name, id, apiKey, locked, apiSubscriptionActive } = organisation;
    const apiKeyText = apiKey ? <div>API Access Enabled</div> : <div />;
    const apiSubText = apiSubscriptionActive ? (
      <div>Subscription API Access Enabled</div>
    ) : (
      <div />
    );

    return (
      <Fragment key={name}>
        <ListItem
          button
          onClick={() => setEditingId(id)}
          disabled={Boolean(locked)}
          data-testid="external-organisations-list-item"
        >
          <ListItemIcon data-testid="external-organisations-list-item-locked-icon">
            {locked ? <LockIcon /> : null}
          </ListItemIcon>
          <ListItemText
            primary={name}
            secondary={
              apiKey || apiSubscriptionActive ? (
                <>
                  {apiKeyText}
                  {enableGeofenceSubscription && apiSubText}
                </>
              ) : (
                <br />
              )
            }
            data-testid="external-organisations-list-item-text"
          />
        </ListItem>
        <Divider />
      </Fragment>
    );
  }

  return (
    <>
      <div className={classes.organisations}>
        <div className={classes.heading}>
          <Typography variant="h3" data-testid="external-organisations-header">
            External Organisations
          </Typography>
          {isAdmin ? (
            <Button
              className={classes.addButton}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setEditingId('new')}
              data-testid="add-external-organisations-button"
            >
              Add External Organisation
            </Button>
          ) : null}
        </div>
        <Paper>
          <div className={classes.paperHeader}>
            <TextField
              data-testid="standard-start-adornment"
              className={classes.searchBox}
              placeholder="Search..."
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.orgList}>
            <List
              component="nav"
              aria-label="organisations list"
              data-testid="external-organisations-list"
            >
              <Divider />
              {filteredOrganizations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(renderListItem)}
            </List>
          </div>
        </Paper>
      </div>
      {editingId && isAdmin && (
        <EditOrganisationModal
          closeModal={() => setEditingId('')}
          organization={
            organizations.find((x) => x.id === editingId) || {
              id: 'new',
              name: '',
              apiKey: null,
            }
          }
        />
      )}
    </>
  );
}

export default ExternalOrganisations;
