import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import {
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  DialogContentText,
} from '@material-ui/core';
import { ManagedAirspaceViewModel } from 'fims-api-types';
import { UserRole } from 'argus-common/enums';
import { useConfirmDialog } from 'asm-web-components';
import { ManagedAreaCode } from 'argus-data-model/db/schemas/managed-areas';

import { TABLE_HEADINGS } from './managed-airspace-constants';
import { LoginContext } from '../../../../src/context/LoginState';
import ManagedAirspaceTableRow from './managed-airspace-table-row';
import { useProfile } from '../../../state/session';

import * as fimsClient from '../../../clients/fims-api-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    managedAirspaces: {
      padding: '0 16px 16px 16px',
    },
    addButton: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
    heading: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px !important`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

const ManagedAirspaces = () => {
  const navigate = useNavigate();
  const [managedAirspaces, setManagedAirspaces] = useState<
    ManagedAirspaceViewModel[]
  >([]);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);

  const classes = useStyles();

  const { setIsCreatingAirspace, setForceReloadMap } = useContext(LoginContext);

  const { roles } = useProfile();
  const isAdmin = [UserRole.ADMIN, UserRole.SUPER_ADMIN].some((value) =>
    roles.includes(value)
  );

  useEffect(() => {
    getAirspaces();
  }, []);

  async function getAirspaces() {
    const data = await fimsClient.getManagedAirspaces(true);

    setManagedAirspaces(data.managedAreas);
  }

  function handleSelectAirspace() {
    navigate('/');
    setIsCreatingAirspace(true);
  }

  async function deleteAirspace() {
    if (!idToDelete) {
      return;
    }
    await fimsClient.editManagedAirspace({ id: idToDelete, isActive: false });
    setForceReloadMap(true);
    getAirspaces();
  }

  const markAreaForDeletion = (event: any, id: string) => {
    event.stopPropagation();
    setIdToDelete(id);
    const name = managedAirspaces.find((area) => area.id === id)?.properties
      ?.name;
    if (name) {
      const toDeleteDisplay = (
        <DialogContent>
          <DialogContentText data-testid="airspace-name">
            {name}
          </DialogContentText>
        </DialogContent>
      );
      setDeleteDialogContent(toDeleteDisplay);
      setShowDeleteDialog(true);
    } else {
      return deleteAirspace();
    }
  };

  const {
    dialog: confirmDeleteDialog,
    setShowConfirm: setShowDeleteDialog,
    setContentToConfirm: setDeleteDialogContent,
  } = useConfirmDialog('Confirm Area Deletion', deleteAirspace, () => {
    setIdToDelete(null);
  });

  return (
    <div
      className={classes.managedAirspaces}
      data-testid="manage-airspace-heading"
    >
      <div className={classes.heading}>
        <Typography className="airspace__heading" variant="h3">
          Managed Areas
        </Typography>
        {isAdmin && (
          <Button
            className={classes.addButton}
            variant="outlined"
            startIcon={<Add />}
            onClick={() => handleSelectAirspace()}
            data-testid="add-manage-airspace"
          >
            Create New Area
          </Button>
        )}
      </div>
      <Paper>
        <Table className="table" data-testid="manage-airspace-table">
          <TableHead>
            <TableRow>
              {TABLE_HEADINGS.map((heading, index) => (
                <TableCell align={heading.align} key={index}>
                  {heading.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {managedAirspaces
              .filter(
                (ma) =>
                  ma.properties.code ===
                  ManagedAreaCode.EmergencyAirspaceClosure
              )
              .map((managedAirspace: ManagedAirspaceViewModel) => (
                <ManagedAirspaceTableRow
                  key={managedAirspace.id}
                  managedAirspace={managedAirspace}
                  isAdmin={isAdmin}
                  handleSelectAirspace={() => {
                    navigate(`/managed-airspaces/${managedAirspace.id}`);
                  }}
                  deleteAirspace={(event: any) => {
                    if (managedAirspace.id) {
                      markAreaForDeletion(event, managedAirspace.id);
                    }
                  }}
                />
              ))}
            {managedAirspaces
              .filter(
                (ma) =>
                  ma.properties.code !==
                  ManagedAreaCode.EmergencyAirspaceClosure
              )
              .map((managedAirspace: ManagedAirspaceViewModel) => (
                <ManagedAirspaceTableRow
                  key={managedAirspace.id}
                  managedAirspace={managedAirspace}
                  isAdmin={isAdmin}
                  handleSelectAirspace={() => {
                    navigate(`/managed-airspaces/${managedAirspace.id}`);
                  }}
                  deleteAirspace={(event: any) => {
                    if (managedAirspace.id) {
                      markAreaForDeletion(event, managedAirspace.id);
                    }
                  }}
                />
              ))}
          </TableBody>
        </Table>
      </Paper>
      {confirmDeleteDialog}
    </div>
  );
};

export default ManagedAirspaces;
