import React, { useEffect } from 'react';
import { SplashPage } from './splash-page';

interface Props {
  isOffline: boolean;
  isBackendOffline: boolean;
  retry?: () => void;
}

const RETRY_INTERVAL = 10000;
export function Offline({ isOffline, isBackendOffline, retry }: Props) {
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isBackendOffline && retry) {
      timeout = setInterval(() => {
        retry();
      }, RETRY_INTERVAL);
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [isOffline, isBackendOffline, retry]);

  return (
    <>
      {isOffline && (
        <SplashPage
          primaryText="You appear to be offline"
          secondaryText="Please reconnect to the internet to use this app."
        />
      )}
      {isBackendOffline && (
        <SplashPage
          primaryText="You appear to be offline"
          secondaryText="If you are connected to the internet and still see this message, the server may be down."
          tertiaryText={
            <span>
              Please wait a few moments or click <a href="/">here</a> to try
              again.
            </span>
          }
        ></SplashPage>
      )}
    </>
  );
}
