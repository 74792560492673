export enum FlightPurpose {
  AerialFilming = 'Aerial Filming',
  Surveillance = 'Surveillance',
  SurveyMapping = 'Survey Mapping',
  InfrastructureInspection = 'Infrastructure Inspection',
  InventoryManagement = 'Inventory Management',
  ConstructionMonitoring = 'Construction Monitoring',
  Demonstration = 'Demonstration',
  Training = 'Training',
  UAMOperation = 'UAM Operation',
  Fishing = 'Fishing',
  Other = 'Other',
  Police = 'Police',
  Customs = 'Customs',
  TrafficSurveillance = 'Traffic Surveillance/Pursuit',
  EnvironmentalControl = 'Environmental Control',
  SearchAndRescue = 'Search & Rescue',
  Medical = 'Medical',
  Evacuations = 'Evacuations',
  FireFighting = 'Fire Fighting',
  VipSecurity = 'VIP Security',
}

export const priorityFlightPurposes = [
  FlightPurpose.Police,
  FlightPurpose.Customs,
  FlightPurpose.TrafficSurveillance,
  FlightPurpose.EnvironmentalControl,
  FlightPurpose.SearchAndRescue,
  FlightPurpose.Medical,
  FlightPurpose.Evacuations,
  FlightPurpose.FireFighting,
  FlightPurpose.VipSecurity,
];

export const generalFlightPurposes = [
  FlightPurpose.AerialFilming,
  FlightPurpose.Surveillance,
  FlightPurpose.SurveyMapping,
  FlightPurpose.InfrastructureInspection,
  FlightPurpose.InventoryManagement,
  FlightPurpose.ConstructionMonitoring,
  FlightPurpose.Demonstration,
  FlightPurpose.Training,
  FlightPurpose.UAMOperation,
  FlightPurpose.Fishing,
  FlightPurpose.Other,
];
