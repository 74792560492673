/* istanbul ignore file */
import { UASZonesUpdateSubscriptionsReply, MapAreasResponse } from './types';
// eslint-disable-next-line import/named
import createApi, { Api } from 'airshare-web-utils/api';
const crypto = require('crypto');

export const externalAPI = createApi(window.env.ARGUS_EXTERNAL_API);
externalAPI.defaults.headers.Accept = 'application/json';
externalAPI.defaults.headers['Content-Type'] = 'application/json';
externalAPI.defaults.timeout = 10000;

export const getSubscriptions = async (
  endpoint: string,
  username: string,
  password: string
) => {
  const auth = btoa(`${username}:${password}`);
  externalAPI.defaults.headers.Authorization = `Basic ${auth}`;
  return await externalAPI
    .get<UASZonesUpdateSubscriptionsReply>(
      `${endpoint}/geofencing-service/v1/subscriptions`
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export const createSubscription = async (
  endpoint: string,
  username: string,
  password: string,
  coordinates: number[]
) => {
  const auth = btoa(`${username}:${password}`);
  externalAPI.defaults.headers.Authorization = `Basic ${auth}`;
  const data = {
    airspaceVolume: {
      uomDimensions: 'FT',
      upperVerticalReference: 'AGL',
      lowerVerticalReference: 'AGL',
      horizontalProjection: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    },
  };
  return await externalAPI
    .post<UASZonesUpdateSubscriptionsReply[]>(
      `${endpoint}/geofencing-service/v1/subscriptions`,
      data
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export const activateSubscription = async (
  endpoint: string,
  username: string,
  password: string,
  subscriptionId: string
) => {
  const auth = btoa(`${username}:${password}`);
  externalAPI.defaults.headers.Authorization = `Basic ${auth}`;
  return await externalAPI
    .post<UASZonesUpdateSubscriptionsReply[]>(
      `${endpoint}/geofencing-service/v1/subscriptions/${subscriptionId}`,
      { subscriptionStatus: 'ACTIVE' }
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export const pauseSubscription = async (
  endpoint: string,
  username: string,
  password: string,
  subscriptionId: string
) => {
  const auth = btoa(`${username}:${password}`);
  externalAPI.defaults.headers.Authorization = `Basic ${auth}`;
  return await externalAPI
    .post<UASZonesUpdateSubscriptionsReply[]>(
      `${endpoint}/geofencing-service/v1/subscriptions/${subscriptionId}`,
      { subscriptionStatus: 'PAUSED' }
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export const deleteSubscription = async (
  endpoint: string,
  username: string,
  password: string,
  subscriptionId: string
) => {
  const auth = btoa(`${username}:${password}`);
  externalAPI.defaults.headers.Authorization = `Basic ${auth}`;
  return await externalAPI
    .delete<UASZonesUpdateSubscriptionsReply[]>(
      `${endpoint}/geofencing-service/v1/subscriptions/${subscriptionId}`
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export const getAreas = async (
  endpoint: string,
  organisationId: string,
  accessKey: string,
  pageIndex: number = 0
) => {
  externalAPI.defaults.headers.Accept = 'application/vnd.ed269';
  externalAPI.defaults.headers['organisation-id'] = organisationId;
  externalAPI.defaults.headers.Authorization = accessKey;
  return await externalAPI
    .get<MapAreasResponse>(
      `${endpoint}/v1/map-areas?pageSize=100&isActive=true&pageIndex=${pageIndex}`
    )
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};

export function createSharedAccessToken(
  uri: string,
  saName: string,
  saKey: string
) {
  if (!uri || !saName || !saKey) {
    throw 'Missing required parameter';
  }
  let encoded = encodeURIComponent(uri);
  let now = new Date();
  let week = 60 * 60 * 24 * 7;
  let ttl = Math.round(now.getTime() / 1000) + week;
  let signature = encoded + '\n' + ttl;
  let hash = crypto
    .createHmac('sha256', saKey)
    .update(signature, 'utf8')
    .digest('base64');
  return (
    'SharedAccessSignature sr=' +
    encoded +
    '&sig=' +
    encodeURIComponent(hash) +
    '&se=' +
    ttl +
    '&skn=' +
    saName
  );
}

const azureAPIs: any = {};

export const getMessage = async (publicationLocation: string) => {
  let url = new URL(publicationLocation.replace('amqps://', 'https://'));
  let token = createSharedAccessToken(
    url.host,
    decodeURIComponent(url.username),
    decodeURIComponent(url.password)
  );
  if (!azureAPIs[url.origin]) {
    azureAPIs[url.origin] = createApi(url.origin);
  }
  const azureAPI = azureAPIs[url.origin] as Api;
  azureAPI.defaults.headers.Authorization = token;
  return await azureAPI
    .delete<any>(`${url.origin}${url.pathname}/messages/head`)
    .then((resp) => resp)
    .catch(() => {
      throw new Error('Error executing api');
    });
};
