export { AlertStatus, AlertType } from '@airshare/messaging-types';
export {
  CertificationType,
  OperatorType,
  FlightRule,
  UasOperationCategory,
} from '@airshare/external-api-types';

export enum AircraftType {
  Airplane = 'AIRPLANE',
  Drone = 'DRONE',
}

export enum DeviceIdentifier {
  NotSpecific = 'NOT_SPECIFIC',
}

export enum DroneType {
  Known = 'KNOWN',
  Unknown = 'UNKNOWN',
}

export enum DroneTypeColour {
  Unknown = '#F00',
}

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  AUTHORISER = 'AUTHORISER',
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER',
}

export { AirshareUserRole } from '@airshare/pilot-types';

export enum ProcedureToMeasureAltitude {
  DJI = 'DJI',
  VisualHeightKeeping = 'VisualHeightKeeping',
  Tethered = 'Tethered',
  None = 'None',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Disabled = 'DISABLED',
}

export enum FormElementStatus {
  Disabled = 'DISABLED',
  Error = 'ERROR',
}

export {
  RequestToPilot,
  RequestToPilotType,
  RequestToPilotStatus,
} from '@airshare/external-api-types/flight-requests/database';

export enum UavCClass {
  C0 = 'C0',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  PrivatelyBuilt = 'PrivatelyBuilt',
  Legacy = 'Legacy',
}
