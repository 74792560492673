import * as React from 'react';
import * as Sentry from '@sentry/browser';

interface Props {
  customErrorPage: any;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, customErrorPage } = this.props;

    if (!hasError) {
      return children;
    }

    return customErrorPage ? customErrorPage() : <h4>Something went wrong.</h4>;
  }
}
