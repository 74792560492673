/* 
STATUS            | STATUSREF
---------------------------------------------------
Declared          | Airways authorisation not required
Prov. Auth.       | Provisionally Authorised
Declined          | Declined
Cancelled         | Cancelled
RNZAF Auth. Req.  | RNZAF authorisation required
Terminated        | Terminated
 */

import { Status } from './database';

export enum FRStatusCode {
  Activated = 'Activated',
  Authorized = 'Authorized',
  Cancelled = 'Cancelled',
  Conflicted = 'Conflicted',
  Declared = 'Declared',
  Declined = 'Declined',
  Pending = 'Pending',
  ProvAuth = 'Prov. Auth.',
  RNZAFAuthReq = 'RNZAF Auth. Req.',
  Terminated = 'Terminated',
}

export enum FRStatusCodeUnknown {
  Unknown = 'UNKNOWN',
}

export type FlightAdvisoryStatusCode = FRStatusCode | FRStatusCodeUnknown;

export enum PilotOnlyStatusCode {
  AcceptanceReq = 'Acceptance Req.',
}

export type PilotFacingStatusCode = FRStatusCode | PilotOnlyStatusCode;

export interface PilotOnlyStatus extends Omit<Status, 'code'> {
  code: PilotFacingStatusCode;
}

export const DecisionStatus = {
  COMPLETED: 'Completed',
  REQUIRED: 'Required',
  OPTIONAL: 'Optional',
};

export enum DecisionStatusEnum {
  COMPLETED = 'Completed',
  REQUIRED = 'Required',
  OPTIONAL = 'Optional',
}

export const DECISION_MAKER_NAME = {
  ARGUS_RULES_ENGINE: 'Argus Rules Engine',
  FLIGHT_REQUEST_CREATION: 'Flight Request Creation',
};

export const FRStatusLabels = {
  [FRStatusCode.Activated]: 'Activated',
  [FRStatusCode.Authorized]: 'Authorised',
  [FRStatusCode.Cancelled]: 'Cancelled',
  [FRStatusCode.Conflicted]: 'Conflicted',
  [FRStatusCode.Declared]: 'Airways authorisation not required',
  [FRStatusCode.Declined]: 'Declined',
  [FRStatusCode.Pending]: 'Pending',
  [FRStatusCode.ProvAuth]: 'Provisionally Authorised',
  [FRStatusCode.RNZAFAuthReq]: 'RNZAF authorisation required',
  [FRStatusCode.Terminated]: 'Terminated',
};

export const FRShortStatusLabels = {
  ...FRStatusLabels,
  [FRStatusCode.Declared]: 'No Clear. Req.',
  [FRStatusCode.ProvAuth]: 'Prov. Auth.',
  [FRStatusCode.RNZAFAuthReq]: 'RNZAF Auth. Req.',
};

export const FRPilotStatusLabels = {
  ...FRStatusLabels,
  [FRStatusCode.Declared]: 'Declared',
};
