// Properties required to show map area in Pilot Web, shared between managed-areas and advisories

import { Schema } from 'mongoose';
import type { Feature, Geometry } from 'geojson';
import { generateGeometrySchema } from '../geojson/geometry';
import {
  LogoData,
  MapProperties,
  MapCodeType,
} from '@airshare/external-api-types';

export { LogoData, MapProperties, MapCodeType };

export const logoSchema = new Schema<LogoData>(
  {
    category: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  { _id: false }
);

export function generateMapPropertiesSchema<E, P extends MapProperties>(
  extraProps: Schema<E>
): Schema<P> {
  return new Schema<P>({
    ...extraProps.obj,
    activity: {
      type: String,
      required: false,
    },
    lowerLimitFeet: {
      type: Number,
      required: false,
    },
    upperLimitFeet: {
      type: Number,
      required: false,
    },
    elevationFeet: {
      type: Number,
      required: false,
    },
    workinghours: {
      type: String,
      required: false,
    },
    strokeColor: {
      type: String,
      required: false,
    },
    strokeOpacity: {
      type: Number,
      required: false,
    },
    strokeWeight: {
      type: Number,
      required: false,
    },
    fillColor: {
      type: String,
      required: false,
    },
    fillOpacity: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    category: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    mapCode: {
      type: String,
      required: false,
    },
    mapZoom: {
      type: Number,
      required: true,
    },
    minZoom: {
      type: Number,
      required: false,
    },
    lat: {
      type: Number,
      required: false,
    },
    lng: {
      type: Number,
      required: false,
    },
    pilotVisible: {
      type: Boolean,
      required: true,
    },
    tileDisplay: {
      type: Boolean,
      required: false,
    },
    atcVisible: {
      type: Boolean,
      required: true,
    },
    isMapLayer: {
      type: Boolean,
      required: true,
    },
    geoQueryInclude: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    logo: {
      type: logoSchema,
      required: false,
    },
    siteName: {
      type: String,
      required: false,
    },
    siteDescription: {
      type: String,
      required: false,
    },
    address: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
    notamEmailAddress: {
      type: String,
      required: false,
    },
    policies: {
      type: [String],
      required: false,
    },
    website: {
      type: String,
      required: false,
    },
    deviationThresholdHorizontalMetres: {
      type: Number,
      required: false,
    },
    deviationThresholdVerticalFeet: {
      type: Number,
      required: false,
    },
  });
}

export function generateMapAreaSchema<
  G extends Geometry,
  E,
  P extends MapProperties,
>(types: string[], extraProps: Schema<E>) {
  return new Schema<Feature<G, P>>({
    type: {
      type: String,
      enum: ['Feature'],
      required: true,
    },
    geometry: generateGeometrySchema<G>(types),
    properties: generateMapPropertiesSchema<E, P>(extraProps),
  });
}
