import React, { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';

import { OrganisationResponse } from 'fims-api-types';

interface Props {
  organisation: OrganisationResponse;
  setEditingId: (id: string) => void;
  setEditingSettingsId: (id: string) => void;
  setDeletingId: (id: string) => void;
}

export default function OrgListItem({
  organisation,
  setEditingId,
  setEditingSettingsId,
  setDeletingId,
}: Props) {
  const { name, id, contactDetails } = organisation;
  return (
    <Fragment key={name}>
      <ListItem
        button
        onClick={() => setEditingId(id)}
        data-testid="organisations-list-item"
      >
        <ListItemText
          primary={name}
          secondary={contactDetails?.email}
          data-testid="organisations-list-item-text"
        />
        <ListItemSecondaryAction>
          <ListItemIcon>
            <Button
              variant="contained"
              data-testid="organisations-list-item-edit-defaults"
              onClick={() => setEditingSettingsId(id)}
              color="default"
              startIcon={
                <SettingsIcon titleAccess="Set organisation defaults" />
              }
            >
              Edit Default Settings
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              data-testid="organisations-list-item-delete-icon"
              onClick={() => setDeletingId(id)}
              color="default"
              startIcon={<DeleteIcon titleAccess="Delete" />}
            >
              Delete
            </Button>
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </Fragment>
  );
}
