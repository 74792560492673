import { DateTime } from 'luxon';
import { FRStatusCode, FlightRequestViewModel } from 'fims-api-types';
import { Authorizer } from 'argus-data-model/db/schemas/authorizers';
import { MapView24Hour } from '../../../context/LoginState';

const timezone = window.env.DEFAULT_TZ;

const isStatus = (
  flightStatusCode: FRStatusCode,
  flightDAStatus: string,
  filterStatus: FRStatusCode
): boolean => {
  if (filterStatus === FRStatusCode.Activated) {
    return flightDAStatus === 'Authorized' || flightStatusCode === filterStatus;
  }
  return flightStatusCode === filterStatus;
};

export const filterByTime = (
  flight: FlightRequestViewModel,
  mapView: MapView24Hour,
  flightStatusCode: FRStatusCode
): boolean => {
  if (flightStatusCode === FRStatusCode.Activated) {
    return true;
  }

  const now = DateTime.now();

  const in24HoursTime = now.plus({ hours: 24 });
  const startOfDay = now.setZone(timezone).startOf('day');
  const endOfDay = now.setZone(timezone).endOf('day');

  const flightDateTimeIso = DateTime.fromISO(flight.dateTime);

  if (mapView === MapView24Hour.NEXT24) {
    return (
      flightDateTimeIso < in24HoursTime &&
      flightDateTimeIso.plus({ minutes: flight.duration }) >= now
    );
  }

  if (mapView === MapView24Hour.CURRENT) {
    return (
      flightDateTimeIso <= now &&
      flightDateTimeIso.plus({ minutes: flight.duration }) >= now
    );
  }

  if (mapView === MapView24Hour.DATE) {
    return true;
  }

  // TODAY
  return flightDateTimeIso <= endOfDay && flightDateTimeIso >= startOfDay;
};

export const filterFlightStatusTime = (
  flight: FlightRequestViewModel,
  authorizer: Authorizer,
  mapView: MapView24Hour,
  statusCode: FRStatusCode
): boolean => {
  const flightStatusCode = flight.status.find(
    (x: any) => x.authorizer === authorizer.name
  )?.code;

  const isValidFlightDateTime = filterByTime(flight, mapView, flightStatusCode);

  return (
    isStatus(flightStatusCode, flight.digitalClearance.status, statusCode) &&
    isValidFlightDateTime
  );
};

export const filterFlightStatusesTime = (
  flight: FlightRequestViewModel,
  authorizer: Authorizer | null,
  mapView: MapView24Hour,
  statusCodes: FRStatusCode[]
): boolean => {
  const flightStatusCode = flight.status.find(
    (x: any) => x.authorizer === authorizer?.name
  )?.code;

  const isValidFlightDateTime = filterByTime(flight, mapView, flightStatusCode);
  const isAllowedStatus = statusCodes.some((allowedStatus) =>
    isStatus(flightStatusCode, flight.digitalClearance.status, allowedStatus)
  );

  return isAllowedStatus && isValidFlightDateTime;
};
